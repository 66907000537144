import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import NotFounded from 'pages/NotFound/NotFound';

import PPManager from './PPManager/PPManager';
import PPPartner from './PPPartner/PPPartner';


const PartnerProgram = () => {
  const accountData = useAppSelector(selectAccountData);

  switch (accountData.role) {
    case 'manager':
      return <PPManager />;
    case 'partner':
      return <PPPartner />;
    default:
      return <NotFounded />;
  }
};

export default PartnerProgram;
