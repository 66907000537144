import React from 'react';
import { createRoot } from 'react-dom/client';
import { init as sentryInit } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'i18n/i18n';
import App from 'app/App';
import ThemeProvider from 'contexts/ThemeProvider';
import { store } from 'store';
import './index.scss';

if (process.env.NODE_ENV === 'production') {
  sentryInit({
    dsn: 'https://4a02b3407a10450188c2f91612076ba2@o4504519121043456.ingest.sentry.io/4504520445853696',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
);
