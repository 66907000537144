import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';
import TextInput from 'components/shared/inputs/TextInput/TextInput';
import Button from 'components/shared/buttons/Button/Button';
import ResultModal from 'components/shared/ResultModal/ResultModal';

import { usePromocode } from '../usePromocode';

import './PromocodeActivate.scss';

const PromocodeActivate = () => {
  const { t } = useTranslation();
  const b = bemCn('promocode-activate');
  const {
    control,
    isActivatePromocodeLoading,
    onSubmit,
    isModalOpen,
    handleModalClose
  } = usePromocode();
  const { field, fieldState } = useController({
    name: 'promocode',
    control,
  });

  return (
    <div className={b()}>
      <form className={b('form')} action="#" method="POST" onSubmit={onSubmit}>
        {/* <form className={b('form')} onSubmit={onSubmit}> */}
        <TextInput
          {...field}
          variant="inverted"
          isTouched={fieldState.isTouched}
          error={fieldState.error}
          showError
          placeholder={`${t('promocodes.enter-promocode', 'Введите промокод')}`}
        />

        <div className={b('button-wrapper')}>
          <Button
            type="submit"
            variant="outline-primary"
            isLoading={isActivatePromocodeLoading}
          >
            {t('promocodes.activate.btn', 'Активировать промокод')}
          </Button>
        </div>
      </form>

      <ResultModal
        title={t('result-modal.promocode.success.title', 'Промокод активирован')}
        status="success"
        contentLabel="Activate promocode modal"
        isOpen={isModalOpen}
        description={`${t('result-modal.promocode.success.description', 'Промокод активирован')}`}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default PromocodeActivate;
