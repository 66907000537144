import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/shared/loader/Loader';
import { useFilteredEvent } from 'hooks/useFilteredEvent';
import { seletIsLineActive } from 'store/app/selectors';
import { useAppSelector } from 'hooks';
import { useGetEventStreamQuery } from 'store/app/appApi';

import FullEventInfoDesktop from './components/FullEventInfoDesktop/FullEventInfoDesktop';
import FullEventTeamsDesktop from './components/FullEventTeamsDesktop/FullEventTeamsDesktop';
import FullEventBets from '../FullEvent/FullEventBets/FullEventBets';
import ScorePeriods from '../FullEvent/ScorePeriods/ScorePeriods';
import EventCardBoard from '../EventCard/components/EventCardBoard/EventCardBoard';
import EventCardStream from '../EventCard/components/EventCardStream/EventCardStream';

import type { LineType } from 'types/line-data';

import './FullEventDesktop.scss';

type Props = {
  eventId: string;
  lineType: LineType;
};

// const mainTitle: Record<string, string> = {
//   0: 'Основные',
// };

const FullEventDesktop = ({ eventId, lineType }: Props) => {
  const { i18n } = useTranslation();
  const isLineActive = useAppSelector(seletIsLineActive);
  const { data: event, isLoading } = useFilteredEvent(
    {
      gameId: eventId,
      dataType: lineType,
      dataLang: i18n.resolvedLanguage
    },
    { pollingInterval: 4000, skip: !isLineActive }
  );
  console.log(event);

  const {
    data: streamUrl = '',
    isFetching: isStreamLoading,
  } = useGetEventStreamQuery({
    sport: event?.sportName ?? '',
    tournament: event?.tournament ?? '',
    team1: event?.team1 ?? '',
    team2: event?.team2 ?? '',
    gameStart: event?.eventDate ?? '',
  }, {
    skip: !event,
  });

  if (!event || isLoading) {
    return <Loader />;
  }

  const isEventOver = event.gameDesk === 'The game is over';
  if(isEventOver){
    return(<EventCardBoard event={event}/>);
  }

  return (
    <div className='full-event-desktop'>
      <div className="full-event-desktop__widgets">
        <FullEventInfoDesktop
          event={event}
          eventDate={event.eventDate}
          tournament={event.tournament}
          lineType={lineType}
          scoreFull={event.scoreFull}
          periodName={event.periodName}
        />
        <EventCardBoard
          event={event}
        />
        {lineType === 'live'
          && (
            <div className="full-event-desktop__scores">
              <ScorePeriods
                scorePeriods={event.scorePeriod}
                team1={event.team1}
                team2={event.team2}
                scoreFull={event.scoreFull}
                roundName={event.periodName.split(' ')[1]}
              />
            </div>
          )}
        {streamUrl && !isStreamLoading ? (
          <EventCardStream
            className="full-event-desktop__stream"
            streamUrl={streamUrl}
          />
        ) : null}
      </div>
      <div className="full-event-desktop__bets">
        <FullEventBets event={event} lineType={lineType} />
      </div>
    </div>
  );
};

export default FullEventDesktop;
