import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { crashGames } from 'utils/constants';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import ButtonToTop from 'components/shared/ButtonToTop/ButtonToTop';
import GamesCard from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';
import Pagination from 'components/shared/Pagination/Pagination';

function CrashGames() {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div className="games-list">
      <Breadcrumbs
        link="/home"
        title={t('main.nav.Home', 'Главная')}
      />
      <h1 className="games-list__title">
        {t('crash-games.title', 'Краш игры')}
      </h1>
      <div className="games-list__items">
        {crashGames.slice(pageNumber * 20 - 20, pageNumber * 20).map((game) => (
          <GamesCard
            key={`game-${game.id ?? '123'}-${game.name}`}
            item={game}
          />
        ))}
      </div>
      <Pagination
        selectPage={pageNumber}
        countPages={Math.ceil(crashGames.length / 20)}
        setSelectPage={setPageNumber}
      />
      <ButtonToTop />
    </div>
  );
}

export default CrashGames;
