import { useLocation } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';
import { mainPageLinks } from 'utils/constants/mainPageLinks';

import BurgerNavButton from './BurgerNavButton/BurgerNavButton';

const b = bemCn('burger-nav');
function BurgerNav() {
  const { pathname } = useLocation();
  return (
    <div className={b()}>
      {
        mainPageLinks.map((barItem) => (
          <BurgerNavButton
            isActive={pathname.startsWith(barItem.link)}
            barItem={barItem} key={barItem.id}
          />
        ))
      }
    </div>
  );
}

export default BurgerNav;
