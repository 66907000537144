import React from 'react';

import { bemCn } from 'utils/bem-cn';
import './AddButton.scss';

type Props = {
  onClick: () => void;
};

const AddButton = ({ onClick }: Props) => {
  const b = bemCn('add-button');
  return (
    <button className={b()} type="button" onClick={onClick}>
      <div className={b('icon')} />
    </button>
  );
};

export default React.memo(AddButton);
