import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';

import SvgIcon from '../SvgIcon/SvgIcon';

import './DownloadAppButton.scss';

const appIconName = {
  android: 'android',
  ios: 'apple',
};

const getTitle = (type: 'android' | 'ios') =>
  type === 'android'
    ? i18n.t('downloadapp.For Android', 'Для Android')
    : i18n.t('downloadapp.For iOS', 'Для iOS');

type Props = {
  type: 'android' | 'ios';
  link?: string;
  className?: string;
}

const DownloadAppButton = (props: Props) => {
  const b = bemCn('download-app-button');
  return (
    <a href={props.link} className={b({ [`${props.type}`]: true }, props.className)}>
      <SvgIcon className={b('os-icon')}
        name={appIconName[props.type]}
        width={27} height={27}
      />
      <span className={b('text')}>
        {i18n.t('downloadapp.Application', 'Приложение')}
      </span>
      <span className={b('text-os')}>{getTitle(props.type)}</span>
    </a>
  );
};

export default DownloadAppButton;
