import { useEffect, useMemo, useState } from 'react';

import { store } from 'store';
import { betapi, useLazyGetBetapiEventsListQuery } from 'store/betapi/betapi';
import { useGetHotEventsQuery } from 'store/hotEvents/hotEventsApi';
import { HotEvent } from 'store/hotEvents/type';
import { EventItem } from 'types/line-data';

const getSportsIds = (hotEvents: HotEvent[]) => {
  const sportsIds = new Set<string>();
  hotEvents.forEach((item) => {
    sportsIds.add(`${item.sportId}`);
  });

  return Array.from<string>(sportsIds);
};

const getGameIds = (hotEvents: HotEvent[]) => hotEvents.map((item) => `${item.gameId}`);

const fetchAllEvents = (sportsIds: string[], gameIds: string[]) =>
  Promise.all(
    sportsIds.map((item) => store.dispatch(betapi.endpoints.getBetapiEventsList.initiate({
      sportId: item, dataType: 'line', pageLength: 5000
    })))
  ).then((res) => ([] as EventItem[]).concat(...res.map(({ data = [] }) => data.filter((item) => gameIds.includes(item.id)))));


export const useGetHotEventsList = () => {
  const [events, setEvents] = useState<EventItem[] | null>(null);
  const {
    data: hotEvents = [],
    isFetching: isHotEventsFetching
  } = useGetHotEventsQuery();
  const [, { isFetching, isLoading }] = useLazyGetBetapiEventsListQuery();
  const gameIds = useMemo(() => getGameIds(hotEvents), [hotEvents]);
  const sportsIds = useMemo(() => getSportsIds(hotEvents), [hotEvents]);

  useEffect(() => {
    if (hotEvents.length) {
      fetchAllEvents(sportsIds, gameIds)
        .then((items) => setEvents(items))
        .catch(() => setEvents([]));
    }
  }, [hotEvents]);

  return {
    events,
    isFetching: isHotEventsFetching || isFetching || isLoading,
  };
};
