import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { ppConverter } from 'store/partnerProgram/ppConverter';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';

import { usePartnerLinks } from '../hooks/usePartnerLinks';
import PPPartnerModals from './PPPartnerModals';

import '../PPPartner.scss';

import type { PartnerLink, TTableProps } from 'store/partnerProgram/types';

interface TableData extends Omit<PartnerLink, 'actions'> {
  actions: string;
}

const PPPartnerLinks = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    items,
    isFetching,
    isError,
    pagination,
    modalParams,
    activeModal,
    onModalClose,
    handleActionsClick,
  } = usePartnerLinks();

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: `${t('created-she', 'Создана')}`,
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: `${t('link', 'Ссылка')}`,
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: `${t('for-project', 'Для проекта')}`,
      dataIndex: 'projectName',
      key: 'projectName',
    },
    // {
    //   title: t('partner-program.ref-code', 'Реферальный код') ?? 'Реферальный код',
    //   dataIndex: 'refCode',
    //   key: 'refCode',
    // },
    {
      title: t('stats', 'Статистика') ?? 'Статистика',
      dataIndex: 'actions',
      key: 'actions',
      render: (title, item: TableData) => (
        <div className={b('table-btn')}>
          <button
            className={b('btn')}
            title={t('stats', 'Статистика') ?? 'Статистика'}
            onClick={() => handleActionsClick({
              key: 'link-stats',
              id: item.projectId,
              title: item.projectName,
              created: item.created,
              requestParam: item.refCode,
            })}
          >
            <SvgIcon name="pie-chart" width={16} height={16} />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={b('content-header')}>
        <h2>
          {t('your-ref-links', 'Ваши реферальные ссылки')}
        </h2>
        <button
          className={b('add-btn')}
          onClick={() => handleActionsClick({
            key: 'new-link',
            id: 0,
            title: t('adding-new-link', 'Добавление новой ссылки'),
            created: '',
            requestParam: '',
          })}
        >
          + {t('new-link', 'Новая ссылка')}
        </button>
      </div>

      <PPTable
        columns={columns}
        className={b('table')}
        isLoading={isFetching}
        isError={isError}
        sourceData={ppConverter.partnerLinks(items ?? []) as unknown as TableData[]}
        pagination={pagination}
      />

      <PPPartnerModals
        activeModal={activeModal}
        onModalClose={onModalClose}
        modalParams={modalParams}
      />
    </>
  );
};

export default PPPartnerLinks;
