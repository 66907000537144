import { api } from 'store/api';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';
import { APIRoute } from 'utils/routes';
import { getLineLang } from 'utils/common';

import {
  transformWithdrawals,
  transformWallets,
  transfortCoupons,
  transformTransactionsList,
  transformTransactionsListShort,
  transformCoupon,
  UserAdapter,
  transformWithdrawalResponse,
  transfromFavoriteTournaments,
  transformAddFavoriteTournamentBody,
  transformAddFavoriteMatchBody,
  transformGetFavoriteMatchesRes,
} from './converters';

import type {
  AccountData,
  AccountDataResponse,
  ServerWallet,
  Wallet,
  SlotsSeccionResponce,
  TransactionsWidthrawal,
  GetCouponsParams,
  ServerCoupons,
  WithdrawalCollection,
  ServerWithdrawal,
  CancelWithdrawalResponse,
  UserGeoType,
  IpApiResponseType,
  Coupon,
  ChangeUserPasswordParams,
  ChangeUserPasswordResponse,
  TransactionsListServer,
  TransactionsList,
  TransactionsShortList,
  TransactionsShortListServer,
  GetCouponParams,
  UserSettings,
  FavoriteTournaments,
  AddFavoriteTournamentBody,
  AddFavoriteTournamentReqBody,
  AddFavoriteMatchReq,
  AddFavoriteMatchBody,
  FavoriteMatches,
} from 'types/user-data';

interface TransferParams {
  amount: number | '';
  send_to: string;
}

type BonusEventServer = {
  'event_name': string;
  'coupon_count': number;
}

type BonusEvent = {
  'eventName': string;
  'couponCount': number;
}
// const getArrayParams = (coupons: string[]) => coupons.reduce((acc, coupon, index) => (
//   `${acc}${index > 0 ? '&coupons=' : ''}${coupon}`
// ), '');

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserCountry: builder.query<UserGeoType, void>({
      query: () => APIRoute.IpAPI,
      transformResponse: (response: IpApiResponseType): UserGeoType => ({
        country: response.country || null,
        countryName: response.country_name || null,
        countryPhoneCode: response.country_calling_code || null,
        currency: response.currency || null,
      })
    }),

    getUserAccount: builder.query<AccountData, void>({
      query: () => APIRoute.UserMe,
      providesTags: ['Auth', 'User', 'Phone'],
      transformResponse: (response: AccountDataResponse): AccountData => ({
        ...response,
        name: response.name ?? `User ${response.id}`,
        id: response.id.toString(),
      }),
    }),

    getTransactions: builder.query<TransactionsShortList, number>({
      query: (page: number) => ({
        url: APIRoute.Transactions,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page
        }
      }),
      transformResponse: (res: TransactionsShortListServer) => transformTransactionsListShort(res),
    }),

    getDeposits: builder.query<TransactionsShortList, number>({
      query: (page: number) => ({
        url: APIRoute.Deposits,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      transformResponse: (res: TransactionsShortListServer) => transformTransactionsListShort(res),
    }),

    getBets: builder.query<TransactionsShortList, number>({
      query: (page: number) => ({
        url: APIRoute.Bets,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      transformResponse: (res: TransactionsShortListServer) => transformTransactionsListShort(res),
    }),

    getUserWithdrawals: builder.query<TransactionsShortList, number>({
      query: (page: number) => ({
        url: 'v1/user/transactions/withdrawals',
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      transformResponse: (res: TransactionsShortListServer) => transformTransactionsListShort(res),
    }),

    getTransfers: builder.query<TransactionsList, number>({
      query: (page: number) => ({
        url: APIRoute.Transfers,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      transformResponse: (res: TransactionsListServer) => transformTransactionsList(res),
    }),

    // TODO: Проверить типизацию данных из запроса
    getWithdrawals: builder.query<TransactionsWidthrawal, number>({
      query: (page: number) => ({
        url: APIRoute.Withdrawals,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
        }
      }),
      transformResponse: (res: ServerWithdrawal) => transformWithdrawalResponse(res),
      providesTags: ['Auth', 'User', 'Transactions'],
    }),

    // TODO: Проверить, нужен ли этот ендпоинт и зачем
    withdrawals: builder.query<WithdrawalCollection, void>({
      query: () => APIRoute.Withdrawals,
      providesTags: ['Auth', 'User', 'Transactions'],
      transformResponse: (response: ServerWithdrawal): WithdrawalCollection =>
        transformWithdrawals(response),
    }),

    getCoupons: builder.query<{ totalCount: number; items: Coupon[] }, GetCouponsParams>({
      query: (params) => ({
        url: APIRoute.Coupons,
        params: {
          'page_size': params?.pageSize || 50,
          'page': params?.pageNumber || 1,
          'type': params?.type,
        }
      }),
      providesTags: ['Auth', 'User', 'BetsHistory'],
      transformResponse: (response: ServerCoupons): { totalCount: number; items: Coupon[] } =>
        transfortCoupons(response),
    }),

    getCoupon: builder.query<Coupon, GetCouponParams>({
      query: ({ couponId, lang }) => ({
        url: `/v1/user/coupons/${couponId ?? '-1'}`,
        params: {
          lang: getLineLang(lang),
        }
      }),
      providesTags: ['Auth', 'User', 'BetsHistory'],
      transformResponse: transformCoupon
    }),

    getWallets: builder.query<Wallet[], void>({
      query: () => APIRoute.Wallets,
      providesTags: ['Auth', 'User', 'Wallets'],
      transformResponse: (response: ServerWallet[]) =>
        transformWallets(response),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(api.util.invalidateTags(['Wallets']));
        }, 30000);
      },
    }),

    addWallet: builder.mutation<string, string>({
      query: (currency: string) => ({
        url: APIRoute.Wallets,
        method: 'POST',
        params: {
          'currency': currency
        }
      }),
      invalidatesTags: ['Auth', 'User', 'Wallets']
    }),

    updateWallet: builder.mutation<string, string>({
      query: (currency: string) => ({
        url: APIRoute.Wallets,
        method: 'PUT',
        params: {
          'currency': currency
        }
      }),
      invalidatesTags: ['Auth', 'User', 'Wallets']
    }),

    cancelWithdrawal: builder.mutation<CancelWithdrawalResponse, number>({
      query: (withdrawalId: number) => ({
        url: APIRoute.CancelWithdrawal,
        method: 'POST',
        params: {
          'withdrawal_id': withdrawalId
        }
      }),
      invalidatesTags: ['Transactions', 'Wallets']
    }),

    sendTransfer: builder.mutation({
      query: (data: TransferParams) => ({
        url: APIRoute.TransferToUser,
        method: 'POST',
        body: data
      })
    }),

    getSlotsSessions: builder.query<SlotsSeccionResponce, number>({
      query: (page: number) => ({
        url: APIRoute.SlotSessions,
        params: {
          'page_size': TRANSACTIONS_ITEMS_PER_PAGE,
          'page': page,
          'ascending': true
        }
      })
    }),

    changeUserPassword: builder.mutation<ChangeUserPasswordResponse, ChangeUserPasswordParams>({
      query: ({ oldPassword, newPassword }: ChangeUserPasswordParams) => ({
        url: 'v1/auth/login/password/change',
        method: 'POST',
        body: {
          'new_password': newPassword,
          'old_password': oldPassword
        }
      }),
      invalidatesTags: ['Auth']
    }),

    updateUserPhone: builder.mutation({
      query: (phone: string) => ({
        url: 'v1/user',
        method: 'PUT',
        body: {
          'phone': phone
        }
      }),
      invalidatesTags: ['Phone']
    }),

    getUserBonusEvents: builder.query<BonusEvent[], void>({
      query: () => 'v1/user/bonus_events',
      transformResponse: (res: BonusEventServer[]): BonusEvent[] =>
        res.map((item) => ({
          eventName: item.event_name,
          couponCount: item.coupon_count,
        }))
    }),

    activatePromocode: builder.mutation({
      query: (promocode: string) => ({
        url: 'v1/user/promocode',
        method: 'POST',
        params: {
          promocode,
        },
      })
    }),

    getUserSettings: builder.query<UserSettings, void>({
      query: () => '/v1/user/settings',
      transformResponse: UserAdapter.settingsToClient,
      providesTags: ['Auth', 'Settings'],
    }),

    updateUserSettings: builder.mutation<UserSettings, UserSettings>({
      query: (params) => ({
        url: '/v1/user/settings',
        method: 'PUT',
        body: UserAdapter.settingsToServer(params)
      }),
      transformResponse: UserAdapter.settingsToClient,
      invalidatesTags: ['Settings'],
    }),

    getFavoriteTournaments: builder.query<FavoriteTournaments, void>({
      query: () => APIRoute.FavTournaments,
      transformResponse: transfromFavoriteTournaments,
      providesTags: ['Auth', 'FavTour']
    }),

    addFavoriteTournament: builder.mutation<AddFavoriteTournamentReqBody, AddFavoriteTournamentBody>({
      query: (params) => ({
        url: APIRoute.FavTournament,
        method: 'POST',
        body: transformAddFavoriteTournamentBody(params)
      }),
      invalidatesTags: ['FavTour'],
    }),

    deleteFavoriteTournament: builder.mutation<string, string>({
      query: (params) => ({
        url: APIRoute.FavTournament,
        method: 'DELETE',
        params: { id: params }
      }),
      invalidatesTags: ['FavTour'],
    }),

    addFavoriteMatch: builder.mutation<AddFavoriteMatchReq, AddFavoriteMatchBody>({
      query: (params) => ({
        url: APIRoute.FavMatch,
        method: 'POST',
        body: transformAddFavoriteMatchBody(params)
      }),
      invalidatesTags: ['FavMatch'],
    }),

    deleteFavoriteMatch: builder.mutation<unknown, string>({
      query: (param) => ({
        url: APIRoute.FavMatch,
        method: 'DELETE',
        params: { id: param }
      }),
      invalidatesTags: ['FavMatch'],
    }),

    getFavoriteMatches: builder.query<FavoriteMatches, void>({
      query: () => APIRoute.FavMatches,
      transformResponse: transformGetFavoriteMatchesRes,
      providesTags: ['Auth', 'FavMatch']
    })
  }),
});

export const {
  useGetUserCountryQuery,
  useGetUserAccountQuery,
  useGetTransactionsQuery,
  useGetDepositsQuery,
  useGetBetsQuery,
  useGetTransfersQuery,
  useGetWithdrawalsQuery,
  useWithdrawalsQuery,
  useGetCouponsQuery,
  useGetCouponQuery,
  useGetWalletsQuery,
  useAddWalletMutation,
  useUpdateWalletMutation,
  useCancelWithdrawalMutation,
  useGetSlotsSessionsQuery,
  useSendTransferMutation,
  useChangeUserPasswordMutation,
  useUpdateUserPhoneMutation,
  useGetUserBonusEventsQuery,
  useActivatePromocodeMutation,
  useLazyGetUserBonusEventsQuery,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useGetFavoriteTournamentsQuery,
  useAddFavoriteTournamentMutation,
  useDeleteFavoriteTournamentMutation,
  useAddFavoriteMatchMutation,
  useDeleteFavoriteMatchMutation,
  useGetFavoriteMatchesQuery,
} = userApi;
