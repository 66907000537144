import { api } from 'store/api';

import { HotEvent, HotEventServer } from './type';

export const hotEventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHotEvents: build.query<HotEvent[], void>({
      query: () => 'v1/hot_matches',
      transformResponse: (res: HotEventServer[]) => res.map((item) => ({
        id: item.id,
        gameId: item.game_id,
        gameMid: item.game_mid,
        gameNum: item.game_num,
        gameStart: item.game_start,
        countryId: item.country_id,
        countryName: item.country_name,
        tournamentId: item.tournament_id,
        tournamentName: item.tournament_name,
        opp1Icon: item.opp_1_icon,
        opp1Id: item.opp_1_id,
        opp1Name: item.opp_1_name,
        opp2Icon: item.opp_2_icon,
        opp2Id: item.opp_2_id,
        opp2Name: item.opp_2_name,
        sportId: item.sport_id,
        sportName: item.sport_name,
        dataType: item.data_type,
      })),
    }),
  })
});

export const {
  useGetHotEventsQuery,
} = hotEventsApi;
