import { useState } from 'react';

import {
  useGetManagerLinkQuery,
  useGetManagertStatsQuery,
} from 'store/partnerProgram/managerApi';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import {
  statsTitleList,
  defaultPartnerCurrency,
} from 'store/partnerProgram/constants';

import type { PartnerStats, TStatsItem, TFilterValues } from 'store/partnerProgram/types';
import type { SubmitHandler } from 'react-hook-form';

const statsKeys: (keyof PartnerStats)[] = [
  'currentIncome',
  'numClicks',
  'numRegistrations',
  'deposits',
  'adminDeposits',
  'firstDeposits',
  'betsPlaced',
  'betsWinnings',
  'betsIngame',
  'betsLosses',
  'betsIncome',

  'inbetPlaced',
  'inbetWinnings',
  'inbetIncome',
  'betgamesPlaced',
  'betgamesWinnings',
  'betgamesIncome',
  'vegasPlaced',
  'vegasWinnings',
  'vegasIncome',
  // 'pragmaticPlaced',
  // 'pragmaticWinnings',
  // 'pragmaticIncome',
  // 'tvbetPlaced',
  // 'tvbetWinnings',
  // 'tvbetIncome',
  // 'mascotPlaced',
  // 'mascotWinnings',
  // 'mascotIncome',
  // 'agtPlaced',
  // 'agtWinnings',
  // 'agtIncome',
  'income',
  'revshare',
];

const getStatsList = (stats?: PartnerStats) => {
  const statsList: TStatsItem[] = statsKeys.map((key) => {
    const val = stats ? stats[key] : 0;

    const statsItem = {
      title: statsTitleList[key],
      value: val ? val : 0,
      key: statsTitleList[key],
    };

    return statsItem;
  });

  return statsList;
};

export const useManagerStats = () => {
  const userCurrency = useAppSelector(selectPrimaryWallet).currency ?? defaultPartnerCurrency;
  const initParams = { currency: userCurrency };
  const [params, setParams] = useState(initParams);

  const {
    data: managerLinkData,
  } = useGetManagerLinkQuery();

  const {
    data: managerStatsData,
    isFetching: isManagerStatsLoading,
    isError: isManagerStatsError,
  } = useGetManagertStatsQuery(params);

  const statsList = managerStatsData && getStatsList(managerStatsData);
  const refLinkList = managerLinkData && Object.entries(managerLinkData).map(([key, link]) => link);

  const handleFilterChange: SubmitHandler<TFilterValues> = (data) => {
    setParams((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleFilterReset = () => {
    setParams({
      ...initParams,
    });
  };

  return {
    managerLinkData,
    managerStatsData,
    isManagerStatsLoading,
    isManagerStatsError,
    statsList,
    refLinkList,
    handleFilterChange,
    handleFilterReset,
  };
};
