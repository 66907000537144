
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import {
  bonusListRu,
  bonusListUA,
  bonusListEN,
  bonusListKZ,
  bonusListAZ,
  type BonusListType,
  bonusListUZ,
} from 'pages/StaticPages/BonusPage/BonusData';
import { selectPrimaryWalletCurrency, selectUserGeo } from 'store/user/selectors';

const BonusList: Record<string, BonusListType[]> = {
  'DEFAULT': bonusListEN,
  'RU': bonusListRu,
  'RUB': bonusListRu,
  'UA': bonusListUA,
  'UAH': bonusListUA,
  'UK': bonusListUA,
  'EN': bonusListEN,
  'KZ': bonusListKZ,
  'KZT': bonusListKZ,
  'KK': bonusListKZ,
  'AZ': bonusListAZ,
  'AZN': bonusListAZ,
  'UZ': bonusListUZ,
  'UZS': bonusListUZ,
};


export const useLocalizedBonuses = (): BonusListType[] => {
  const userGeo = useAppSelector(selectUserGeo);
  const userCurrency = useAppSelector(selectPrimaryWalletCurrency);
  const { i18n } = useTranslation();

  return BonusList[userCurrency as string]
    || BonusList[userGeo.country as string]
    || BonusList[i18n.resolvedLanguage?.toUpperCase() as string]
    || BonusList.DEFAULT;
};
