
import { ClassNameFormatter } from '@bem-react/classname';

import FooterNavItem, { FooterListItem } from './FooterNavItem';

type Props = {
  items: FooterListItem[];
  b: ClassNameFormatter;
}

const FooterNavList = ({ items, b }: Props) => (
  <ul className={b('list')}>
    {items.map((item) => {
      const keyVal = `${item.name}-${item.link ?? '-'}- ${item.href ?? '-'}`;
      return (
        <FooterNavItem
          key={keyVal}
          item={item}
          b={b}
        />
      );
    })}
  </ul>
);


export default FooterNavList;
