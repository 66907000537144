import { ClassNameFormatter } from '@bem-react/classname';
import { useTranslation } from 'react-i18next';

import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';
import { getDateTimeCommaFormat } from 'utils/time';

import type { BetInfo } from 'types/user-data';


type Props = {
  bet: BetInfo;
  isOrdinar: boolean;
  b: ClassNameFormatter;
}

const CouponCardBet = ({ bet, isOrdinar, b }: Props) => {
  const { i18n } = useTranslation();
  return (
    <div className={b('bet')}>
      <p className={b('bet-tournament')}>
        <span>
          {bet.sportName && `${bet.sportName} • `}
          {bet.tournament}:
        </span>
        <span className={b('bet-date')}>
          {getDateTimeCommaFormat(bet.gameDatetime, undefined, i18n.language)}
        </span>
      </p>
      <div className={b('bet-teams-wrap')}>
        <div className={b('bet-teams')}>
          <p className={b('bet-team')}>{bet.team1}</p>
          <p className={b('bet-team')}>{bet.team2}</p>
        </div>
        {!isOrdinar && (
          <CoefDisplay className={b('bet-ratio')}
            value={bet.coef.toFixed(2)}
          />
        )}
      </div>
    </div>
  );
};

export default CouponCardBet;
