import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import i18n from 'i18n/i18n';
import FormInputWrapper from 'components/shared/form/FormInput';
import Button from 'components/shared/buttons/Button/Button';
import { useSendTransferMutation } from 'store/user/userApi';
import { BackendError } from 'store/auth/utils';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';


import type { Currency } from 'types/wallets-data';

import './TransferForm.scss';

type FormFields = {
  amount: number | '';
  send_to: string;
};

type Props = {
  balance: number;
  currency: Currency | null;
};

const schema = ({ min, max, balance, userId }: { min: number; max: number; balance: number; userId: string }) => yup
  .object({
    amount: yup
      .number()
      .min(min, `${i18n.t('transfer.modal.errors.min-amount', 'Минимальная сумма перевода')} ${min}`)
      .max(max, `${i18n.t('transfer.modal.errors.max-amount', 'Максимальная сумма перевода')} ${max}`)
      .test('balanceTest', `${i18n.t('transfer.modal.errors.zero-amount', 'Перевод должен быть больше 0')}`, (value) => (value ? value > 0 : false))
      .test('balanceTest', `${i18n.t('transfer.modal.errors.balance', 'Недостаточно средств')}`, (value) => (value ? value <= balance : false))
      .required(`${i18n.t('transfer.modal.errors.empty-amount', 'Введите сумму перевода')}`)
      .typeError('Введите сумму'),
    'send_to': yup
      .string().required(`${i18n.t('transfer.modal.errors.empty-user-id', 'Введите ID')}`)
      .test('idTest', `${i18n.t('transfer.modal.errors.self-user-id', 'Вы не можете переводить самому себе')}`, (value) => (value !== userId)),
  })
  .required();

const handleResponseError = (error?: FetchBaseQueryError | SerializedError): string => {
  if (!!error && 'status' in error && error.data) {
    const { detail } = error.data as BackendError;

    const message = detail === 'User has bet or withdrawal limits'
      ? `${i18n.t('result-modal.transfer.withdrawal-bet-limits-error.title', 'Ошибка! У вас есть лимит на максимальную ставку или лимит на вывод средств')}`
      : i18n.t('result-modal.transfer.error.title', 'Произошла ошибка во время перевода');

    return message;
  }

  return error ? i18n.t('result-modal.transfer.error.title', 'Произошла ошибка во время перевода') : '';
};

const TransferForm = ({ balance, currency }: Props) => {
  const { t } = useTranslation();
  const { id: userId } = useAppSelector(selectAccountData);
  const [transferSuccess, setTransferSuccess] = useState<boolean>(false);
  const { handleSubmit, control } = useForm<FormFields>({
    defaultValues: { 'amount': '', 'send_to': '' },
    mode: 'all',
    resolver: yupResolver(
      schema({
        min: 0,
        max: balance,
        balance,
        userId
      }),
    ),
  });

  const [sendTransfer, { isError: isSendError, error: sendError }] = useSendTransferMutation();

  const handleFurmSubmit = async (transfer: FormFields) => {
    await sendTransfer(transfer);
    setTransferSuccess(true);
  };

  const sendErrorMessage = handleResponseError(sendError);

  const b = bemCn('transfer-form');

  return (
    <form className={b()} onSubmit={handleSubmit(handleFurmSubmit)}>
      <div className={b('fields')}>
        <FormInputWrapper<FormFields>
          showError
          type="text"
          control={control}
          name="send_to"
          placeholder={`${t('transfer.modal.sentd-to', 'ID пользователя')}`}
          currency={currency}
        />
      </div>
      <div className={b('fields')}>
        <FormInputWrapper<FormFields>
          showError
          type="money"
          control={control}
          name="amount"
          placeholder={`${t('transfer.modal.amount', 'Сумма')}`}
          currency={currency}
        />
        <div className={b('input-info')}>
          <p>{t('transfer.modal.max-amount', 'Максимальная сумма перевода')}</p>
          <p>{balance} {currency}</p>
        </div>
      </div>

      <Button className={b('button')} type='submit' >
        {t('transfer.modal.submit-btn', 'Перевести')}
      </Button>

      <ResultModal
        title={
          isSendError
            ? sendErrorMessage
            : t('result-modal.transfer.success.title', 'Перевод проведен успешно')
        }
        status={isSendError ? 'error' : 'success'}
        isOpen={transferSuccess}
        onClose={() => setTransferSuccess(false)}
      />
    </form>
  );
};

export default TransferForm;
