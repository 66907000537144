import { useRef, useState } from 'react';
import { useToggle } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import './GameListSelector.scss';

type Props = {
  className?: string;
  initSelected?: string;
  items: string[];
  onSelect?: (selected: string) => void;
}

// TODO: !!! УБРАТЬ ЭТОТ КОМПОНЕНТ И СДЕЛАТЬ НОРМАЛЬНЫЙ УНИВЕРСАЛЬНЫЙ СЕЛЕКТОР
const GameListSelector = ({ className, items, initSelected, onSelect }: Props) => {
  const [selected, setSelected] = useState<string | null>(initSelected ?? null);
  const [isOpened, toggleOpened, setOpened] = useToggle(false);
  const themeBlockRef = useRef<HTMLDivElement>(null);

  const handleSelect = (newItem: string) => {
    onSelect?.(newItem);
    setSelected(newItem);
    setOpened(false);
  };

  const b = bemCn('gamelist-selector');

  return (
    <div className={b(null, className)} ref={themeBlockRef}>
      <button className={b('selected')}
        type="button"
        onClick={toggleOpened}
      >
        {selected}
        <SvgIcon name='chevron-down' className={b('arrow')} width={7} height={7} />
      </button>
      {isOpened && (
        <ul className={b('list')}>
          {items.map((item) => (
            <li className={b('option', { selected: item === selected })}
              key={item}
              onClick={() => handleSelect(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GameListSelector;
