import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { getCurrencySymbol } from 'utils/currency';
import { bemCn } from 'utils/bem-cn';
import { changeActiveModal } from 'store/auth/authSlice';
import { useAppDispatch } from 'hooks';

import BalanceInfo from './BalanceInfo/BalanceInfo';
import DropdownWallet from './DropdownWallet/DropdownWallet';

import type { Currency } from 'types/wallets-data';

import './WalletsManagement.scss';

type Props = {
  currency: Currency | null;
  balance: number;
  bonusBalance: number;
  isWalletsLoading: boolean;
  cashbackLine: number;
  cashbackCasino: number;
};

const WalletsManagement = (props: Props) => {
  const {
    currency,
    balance,
    bonusBalance,
    isWalletsLoading,
    cashbackLine,
    cashbackCasino
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const b = bemCn('wallets-management');

  return (
    <div className={b()}>
      <div className={b('wallet-info')}>
        <BalanceInfo
          className={b('wallet-info-cell', { 'main-balance': true })}
          id="wallet"
          title={t('wallets.main-wallet', 'Основной счет')}
          balance={`${balance} ${getCurrencySymbol(currency)}`}
          size="m"
          isLoading={isWalletsLoading}
        />
        <div className={b('wallet-open-operation')}>
          <DropdownWallet />
        </div>
      </div>
      <div className={b('wallet-info')}>
        <BalanceInfo
          className={b('wallet-info-cell')}
          id="bonus-wallet"
          title={t('wallets.bonus-wallet', 'Основной счет')}
          balance={`${bonusBalance} ${getCurrencySymbol(currency)}`}
          size="m"
          isLoading={isWalletsLoading}
        />
      </div>

      <div className={b('bonuses')}>
        <BalanceInfo className={b('bonus-item')}
          id="casino-bonuses"
          title={t('wallets.casino-bonuses', 'Бонусы казино')}
          balance={`${cashbackCasino} ${getCurrencySymbol(currency)}`}
          size="sm"
        />
        <BalanceInfo className={b('bonus-item')}
          id="sport-bonuses"
          title={t('wallets.sport-bonuses', 'Бонусы спорт')}
          balance={`${cashbackLine} ${getCurrencySymbol(currency)}`}
          size="sm"
        />
      </div>

    </div>
  );
};

export default WalletsManagement;
