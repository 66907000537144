import { bemCn } from 'utils/bem-cn';
import './SocialList.scss';

const b = bemCn('social-list');

type SocialLinkType = {
  type: string;
  link: string;
}

type Props = {
  className?: string;
  items: SocialLinkType[];
}

const SocialList = ({ className, items }: Props) => (
  <ul className={b(null, className)}>
    {items.map((item) => (
      <li className={b('item')} key={`${item.type}-${item.link}`}>
        <a className={b('link', { [`${item.type}`]: !!item.type })}
          href={item.link}
          target='_blank'
          rel="noreferrer"
        />
      </li>
    ))}
  </ul>
);


export default SocialList;
