import React, { useEffect, useState } from 'react';

import Pagination from 'components/shared/Pagination/Pagination';
import { useGetTransfersQuery } from 'store/user/userApi';
import { scrollToTop } from 'utils/scrollToTop';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';
import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import { useAppSelector } from 'hooks';

import TransferItem from '../TransferItem/TransferItem';
import NoItems from '../../components/NoItems/NoItems';

const TransferPage = () => {
  const [selectPage, setSelectPage] = useState<number>(1);
  const primaryCurrency = useAppSelector(selectPrimaryWalletCurrency);
  const { data: transfers, isLoading } = useGetTransfersQuery(selectPage);

  useEffect(() => {
    scrollToTop(true);
  }, [selectPage]);

  const allItemsCount = transfers?.count || 0;
  const countPages = Math.ceil(allItemsCount / TRANSACTIONS_ITEMS_PER_PAGE);
  const isEmptyList = !isLoading && !transfers?.items?.length;

  if (isLoading || isEmptyList) {
    return (
      <NoItems isLoading={isLoading} isEmpty={isEmptyList} />
    );
  }

  return (
    <>
      {transfers?.items.map((transaction) => (
        <TransferItem
          key={transaction.id}
          transfer={transaction}
          primaryCurrency={primaryCurrency}
        />
      ))}

      <Pagination
        selectPage={selectPage}
        countPages={countPages}
        setSelectPage={(value) => setSelectPage(value)}
      />
    </>
  );
};
export default TransferPage;
