import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';


import { useLocalizedBanners } from 'hooks/useLocalizedBanners';
import Loader from 'components/shared/loader/Loader';
import ImgSmart from 'components/shared/ImgSmart/ImgSmart';
import { bemCn } from 'utils/bem-cn';
import './BannerSlider.scss';

type BannerImageWrapperProps = {
  link?: string;
  href?: string;
  children?: React.ReactNode;
  className?: string;
}

const BannerImageWrapper = ({ link, href, children, className }: BannerImageWrapperProps) => {
  if (link) {
    return (
      <Link to={link} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} className={className} target='_blank' rel="noreferrer">
      {children}
    </a>
  );
};


const BannerSlider = () => {
  const { bannersMobile, isLoading } = useLocalizedBanners();
  const b = bemCn('banner-slider');

  if (isLoading) {
    return (
      <div className={b()}>
        <Loader wrapperClassName={b('loader')} />
      </div>
    );
  }
  return (
    <div className={b()}>
      <Swiper
        pagination
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={1500}
        slidesPerView={1}
        loop={bannersMobile.length > 1}
        modules={[Pagination, Autoplay]}
        className={b('swiper')}
      >
        {bannersMobile.map((banner) => (
          <SwiperSlide key={banner.img} className={b('swiper-slide')}>
            <BannerImageWrapper link={banner.link} href={banner.href}>
              <ImgSmart className={b('image')}
                src={banner.img}
                width={345}
                height={166}
                alt=""
              />
            </BannerImageWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerSlider;
