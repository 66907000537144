import React from 'react';
import cn from 'classnames';

import SvgIcon from '../SvgIcon/SvgIcon';

import './IconBox.scss';

type IconType = 'caret' | 'search' | 'star' | 'cancel';

type Props = {
  children?: JSX.Element;
  type?: IconType;
  classNames?: string;
  onClick?: () => void;
};

const icons: Record<string, JSX.Element> = {
  caret: <SvgIcon name='chevron-down' className='icon-wrapper__icon' />,
  search: <SvgIcon name='search' className='icon-wrapper__icon' />,
  star: <SvgIcon name='star' className='icon-wrapper__icon' />,
  cancel: <SvgIcon name='close' className='icon-wrapper__icon' />,
};

const IconBox = ({ children, type, onClick, classNames }: Props) => {
  const icon = type && icons[type];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onClick?.();
    }
  };

  return (
    <div
      onClick={onClick}
      role="button"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      className={cn('icon-wrapper', classNames)}
    >
      {type && icon}
      {children && children}
    </div>
  );
};

export default IconBox;
