import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';
import TextInput from 'components/shared/inputs/TextInput/TextInput';
import Button from 'components/shared/buttons/Button/Button';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import AppModal from 'components/shared/AppModal/AppModal';
import FormInput from 'components/shared/form/FormInput';

import { usePromocode, type FormFields } from '../usePromocode';

import './PromocodeActivateDesktop.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const b = bemCn('promocode-activate-desktop');

const PromocodeActivateDesktop = (props: Props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const {
    control,
    isActivatePromocodeLoading,
    onSubmit,
    isModalOpen,
    handleModalClose
  } = usePromocode();

  return (
    <AppModal isOpen={isOpen} onClose={onClose} className={b()} >
      <h2 className={b('title')}>
        {t('promocodes.activate.title', 'Активация промокода')}
      </h2>

      <form className={b('form')} action="#" method="POST" onSubmit={onSubmit}>

        <FormInput<FormFields>
          className={b('field')}
          name="promocode"
          type="text"
          control={control}
          disabled={isActivatePromocodeLoading}
          placeholder={`${t('promocodes.enter-promocode', 'Введите промокод')}`}
          showError
        />

        <Button className={b('button')}
          type="submit"
          variant="primary"
          isLoading={isActivatePromocodeLoading}
        >
          {t('promocodes.activate.btn', 'Активировать промокод')}
        </Button>
      </form>

      <ResultModal
        title={t('result-modal.promocode.success.title', 'Промокод активирован')}
        status="success"
        contentLabel="Activate promocode modal"
        isOpen={isModalOpen}
        description={`${t('result-modal.promocode.success.description', 'Промокод активирован')}`}
        onClose={handleModalClose}
      />
    </AppModal>
  );
};

export default PromocodeActivateDesktop;
