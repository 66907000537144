import React, { useMemo, useState } from 'react';
import { useToggle } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import { useFilteredEvent } from 'hooks/useFilteredEvent';
import Loader from 'components/shared/loader/Loader';
import EvetnCardTabs from 'components/line/EventCard/components/EvetnCardTabs/EvetnCardTabs';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import GroupItem from '../GroupItem/GroupItem';

import type { FullEventItem, LineType } from 'types/line-data';

import './FullEventBets.scss';

type Props = {
  event: FullEventItem | undefined;
  lineType: LineType;
};

const POLLING_INTERVAL = 4000;

const FullEventBets = ({ event, lineType }: Props) => {
  const { i18n } = useTranslation();
  const [isBetsClosed, toggleBetsClosed, setBetsClosed] = useToggle(true);
  const [activeTab, setActiveTab] = useState(0);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { basketByGameId } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketByGameId: data?.itemsByGameId
    })
  });

  const { data: subGamesEvent, isLoading: isLoadingSubGames } = useFilteredEvent(
    {
      gameId: String(activeTab),
      dataType: lineType,
      dataLang: i18n.resolvedLanguage,
    },
    {
      pollingInterval: lineType === 'live' ? POLLING_INTERVAL : 10000,
      skip: activeTab === 0
    },
  );

  const groups = event?.groups ?? [];

  const currentCoeffs = !activeTab ? groups : subGamesEvent?.groups;

  const tabs = event?.subGames ?? [];
  const handleTabChange = (tabId: number) => {
    setActiveTab(tabId);
  };

  const basketItem = !activeTab
    ? basketByGameId?.[event?.id ?? '']?.basketId
    : basketByGameId?.[subGamesEvent?.id ?? '']?.basketId;

  return (
    <div>
      <EvetnCardTabs
        className='full-event-bets__tabs'
        tabs={tabs}
        activeTab={activeTab}
        onToggleClose={toggleBetsClosed}
        onTabChange={handleTabChange}
      />
      {isLoadingSubGames
        ? <Loader />
        : (event && currentCoeffs) && (
          <div>
            {currentCoeffs.map((group) => (
              <GroupItem
                key={group.name}
                group={group}
                eventId={`${activeTab || event.id}`}
                tournament={event.tournament}
                team1={event.team1}
                team2={event.team2}
                basketItem={basketItem}
                lineType={lineType}
                tab={activeTab}
                groupName={group.name}
                isCollapsedAll={isBetsClosed}
              />
            ))}
          </div>
        )}
    </div>
  );
};

export default FullEventBets;
