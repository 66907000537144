import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { ppConverter } from 'store/partnerProgram/ppConverter';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';

import { usePartnerProjects } from '../hooks/usePartnerProjects';
import PPPartnerModals from './PPPartnerModals';

import '../PPPartner.scss';

import type { PartnerProject, TTableProps } from 'store/partnerProgram/types';

interface TableData extends Omit<PartnerProject, 'actions'> {
  actions: string;
}

const PPPartnerProjects = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    items,
    isFetching,
    isError,
    pagination,
    modalParams,
    activeModal,
    onModalClose,
    handleActionsClick,
  } = usePartnerProjects();

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('name', 'Имя') ?? 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('registration-date', 'Дата регистрации') ?? 'Дата регистрации',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: `${t('stats', 'Статистика')}`,
      dataIndex: 'actions',
      key: 'actions',
      render: (title, item: TableData) => (
        <div className={b('table-btn')}>
          <button
            className={b('btn')}
            title="Статистика"
            onClick={() => handleActionsClick({
              key: 'project-stats',
              id: item.id,
              title: item.name,
              created: item.created,
              requestParam: item.id.toString(),
            })}
          >
            <SvgIcon name="pie-chart" width={16} height={16} />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={b('content-header')}>
        <h2>{t('your-projects', 'Ваши проекты')}</h2>

        <button
          className={b('add-btn')}
          onClick={() => handleActionsClick({
            key: 'new-project',
            id: 0,
            title: t('adding-new-project', 'Добавление нового проекта'),
            created: '',
            requestParam: '',
          })}
        >
          + {t('new-project', 'Новый проект')}
        </button>
      </div>

      <PPTable
        columns={columns}
        className={b('table')}
        isLoading={isFetching}
        isError={isError}
        sourceData={ppConverter.partnerProjects(items ?? []) as unknown as TableData[]}
        pagination={pagination}
      />

      <PPPartnerModals
        activeModal={activeModal}
        onModalClose={onModalClose}
        modalParams={modalParams}
      />
    </>
  );
};

export default PPPartnerProjects;
