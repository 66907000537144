import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import { useManagerPartnerLinkStats } from '../hooks/useManagerPartnerLinkStats';
import PPFilter from '../../components/PPFilter/PPFilter';
import PPStats from '../../components/PPStats/PPStats';

import '../PPManager.scss';

const PPManagerPartnerLinkStats = () => {
  const b = bemCn('pp-manager');

  const {
    statsList,
    isFetching,
    isError,
    refCode,
    handleFilterChange,
    handleFilterReset,
  } = useManagerPartnerLinkStats();

  return (
    <>
      <PPFilter
        onSubmit={handleFilterChange}
        onReset={handleFilterReset}
      />

      <PPStats
        title={`Статистика по реф. коду ${refCode}`}
        items={statsList}
        isLoading={isFetching}
        isError={isError}
      />
    </>
  );
};

export default PPManagerPartnerLinkStats;
