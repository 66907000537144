import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { slotsApi } from './slotsApi';

import type { SlotsState } from 'types/state';
import type { GamesList } from 'types/slots-data';

const initialState: SlotsState = {
  gamesList: [],
  activeSlotLink: '',
};

export const slotsData = createSlice({
  name: NameSpace.Slots,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        slotsApi.endpoints.getGamesList.matchFulfilled,
        (state, action: PayloadAction<GamesList>) => {
          state.gamesList = action.payload;
        },
      )
      .addMatcher(
        slotsApi.endpoints.getLink.matchFulfilled,
        (state, action: PayloadAction<string>) => {
          state.activeSlotLink = action.payload;
        }
      );
  },
});
