import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';
import { ppConverter } from 'store/partnerProgram/ppConverter';

import { useManagerPartnerLinks } from '../hooks/useManagerPartnerLinks';
import PPDropdown from '../../components/PPDropdown/PPDropdown';

import type { PartnerLink, TTableProps } from 'store/partnerProgram/types';

interface TableData extends Omit<PartnerLink, 'actions'> {
  actions: string;
}

type Props = {
  id: number;
};

const PPManagerPartnerLinks = (props: Props) => {
  const {
    id,
  } = props;

  const b = bemCn('pp-manager');
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const {
    partnerLinksItems,
    isPartnerLinksFetching,
    isPartnerLinksError,
    pagination,
    actionList,
    handleActionsClick,
  } = useManagerPartnerLinks(id ?? 0);

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: `${t('created-she', 'Создана')}`,
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: `${t('link', 'Ссылка')}`,
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: `${t('project-id', 'ID проекта')}`,
      dataIndex: 'projectId',
      key: 'projectId',
    },
    {
      title: `${t('project-name', 'Имя проекта')}`,
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: `${t('ref-code', 'Реферальный код')}`,
      dataIndex: 'refCode',
      key: 'refCode',
    },
    {
      title: `${t('actions', 'Действия')}`,
      dataIndex: 'actions',
      key: 'actions',
      render: (title, item: TableData) => (
        <PPDropdown
          items={actionList}
          onClick={(key) => handleActionsClick(item.refCode, key)}
        />
      ),
    },
  ];

  return (
    <PPTable
      columns={columns}
      className={b('table')}
      isLoading={isPartnerLinksFetching}
      isError={isPartnerLinksError}
      sourceData={ppConverter.managerPartnerLinks(partnerLinksItems ?? []) as unknown as TableData[]}
      pagination={pagination}
    />
  );
};

export default PPManagerPartnerLinks;
