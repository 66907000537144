import { BannerType } from 'hooks/useLocalizedBanners';
import { TBanner } from 'types/banners';

export const transformBanner = (banners: TBanner[]): BannerType[] => (
  banners.map((banner) => {
    const isRoute = banner.link.slice(0, 4) === 'http';
    return ({
      img: banner.url,
      [!isRoute ? 'link' : 'href']: banner.link
    });
  })
);
