import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import Button from 'components/shared/buttons/Button/Button';
// import BetInput from 'components/shared/inputs/BetInput/BetInput';
// import Checkbox from 'components/shared/inputs/Checkbox/Checkbox';
import SumSuggestButton from 'components/shared/buttons/SumSuggestButton/SumSuggestButton';
import FormInputWrapper from 'components/shared/form/FormInput';
import FormCheckbox from 'components/shared/form/FormCheckbox';
import { getBetingTaxInfo } from 'utils/getBetingTaxInfo';
// import { defaultLimits, defaultSystem } from 'store/payment/constants';
import { getCurrencySymbol } from 'utils/currency';
import { changeActiveModal } from 'store/auth/authSlice';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { getBetSuggestions } from 'store/basket/constants';
import { bemCn } from 'utils/bem-cn';

import BasketTaxInfo from './BasketTaxInfo/BasketTaxInfo';

import type { Currency } from 'types/wallets-data';

import './BasketForm.scss';

type FormFields = {
  amount: number | '';
  agreeWithCoef: boolean;
};

const schema = ({ min, max, balance }: { min: number; max: number; balance: number }) => yup.object({
  amount: yup
    .number()
    .min(min, `${i18n.t('cart.errors.min-amount', 'Минимальная сумма ставки - ')}${min}`)
    .max(max, `${i18n.t('cart.errors.max-amount', 'Максимальная сумма ставки - ')}${max}`)
    .test('balanceTest', `${i18n.t('cart.errors.balance', 'Недостаточно средств')}`, (value) => (value ? value <= balance : false))
    .required(`${i18n.t('cart.errors.empty-amount', 'Введите сумму ставки')}`)
    .typeError('Введите сумму'),
  agreeWithCoef: yup.bool(),
}).required();

type Props = {
  balance: number;
  currency: Currency;
  onPlaceBetClick: (amount: number, agreeWithCoef: boolean) => void;
  totalCoef: number;
  isLoading: boolean;
  disabled?: boolean;
  betLimits: {
    min: number;
    max: number;
  };
};

const b = bemCn('basket-buttons');
const BasketForm = (props: Props) => {
  const {
    balance,
    currency,
    onPlaceBetClick,
    totalCoef,
    isLoading,
    disabled,
    betLimits,
  } = props;
  const roundedBalance = Math.floor((balance * 100)) / 100;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const { handleSubmit, setValue, control, watch } = useForm<FormFields>({
    defaultValues: { amount: '', agreeWithCoef: true },
    mode: 'all',
    resolver: yupResolver(schema({ ...betLimits, balance })),
  });

  const values = watch();
  const taxInfo = getBetingTaxInfo(Number(values.amount), totalCoef);
  const handleSuggestionClick = (value: number) => {
    setValue('amount', value, { shouldValidate: true, shouldTouch: true });
  };

  const handleButtonClick = ({ amount, agreeWithCoef }: FormFields) => {
    if (!isAuthenticated) {
      dispatch(changeActiveModal('sign-in'));
    }
    if (amount) {
      onPlaceBetClick(amount, agreeWithCoef);
    }
  };

  const sumSuggestions = [
    ...getBetSuggestions(currency)
      .filter((item) => item !== roundedBalance),
    roundedBalance
  ].filter((suggest) => roundedBalance > 0 && suggest <= roundedBalance);

  return (
    <div className={b()}>
      <div className={b('bets-control')}>
        <div className={b('bet-input-label-wrapper')}>
          <div className={b('bet-input-label')}>
            <p>{t('cart.max-bet')}</p>
            <p className={b('min')}>
              {betLimits.max} {getCurrencySymbol(currency)}
            </p>
          </div>
          <div className={b('bet-input-label')}>
            <BasketTaxInfo type={'default'} currency={currency} {...taxInfo} />
            <p className={b('win')}>
              +{taxInfo.finalPay.toFixed(2)} {getCurrencySymbol(currency)}
            </p>
          </div>
        </div>
        <FormInputWrapper<FormFields>
          showError
          type="money"
          control={control}
          name="amount"
          placeholder={`${t('cart.amount')}`}
          currency={currency}
          variant="inverted"
        />
        <div className={b('suggestions')}>
          {sumSuggestions.map((suggestAmount, i) => {
            const keyVal = `${suggestAmount}-${i}`;
            return (
              <SumSuggestButton
                key={keyVal}
                amount={suggestAmount}
                currency={currency}
                variant="inverse"
                onClick={handleSuggestionClick}
              />
            );
          })}
        </div>
        <FormCheckbox<FormFields>
          className={b('accept')}
          control={control}
          name="agreeWithCoef"
          disabled={isLoading}
          showError
          inverted
        >
          {t('cart.coef-change-agree')}
        </FormCheckbox>
        <Button
          isLoading={isLoading}
          disabled={disabled || !isAuthenticated}
          variant="primary"
          onClick={handleSubmit(handleButtonClick)}
        >
          {t('cart.make-bet', 'Сделать ставку')}
        </Button>
      </div>
    </div>
  );
};

export default BasketForm;
