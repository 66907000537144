import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
// import Basket from 'components/basket/Basket/Basket';
import { useGetWalletsQuery } from 'store/user/userApi';
// import CasinoWidget from 'components/casino/CasinoWidget/CasinoWidget';
// import { setLiveEventsFilter } from 'store/app/appSlice';
// import { useAppDispatch } from 'hooks';
import Basket from 'components/basket/Basket/Basket';
import { bemCn } from 'utils/bem-cn';

import SideBarFilterDesktop from '../SideBarFilterDesktop/SideBarFilterDesktop';
import FullEventDesktop from '../FullEventDesktop/FullEventDesktop';
import DesktopLineList from './components/DesktopLineList/DesktopLineList';
// import CasinoWidget from 'components/casino/CasinoWidget/CasinoWidget';

import type { LineType } from 'types/line-data';

import './DesktopLine.scss';

export type DesktopFilterType = {
  activeCountry: string;
  activeSport: string;
  activeTournament: string;
};

type Props = {
  type?: LineType;
  previewItem: boolean;
};

const DesktopLine = ({ type, previewItem }: Props) => {
  // const dispatch = useAppDispatch();
  useGetWalletsQuery();
  const { t } = useTranslation();

  const { lineType: typeUrl, eventId = '' } = useParams();

  const lineType: LineType = type ?? typeUrl as LineType ?? 'line';

  // useEffect(() => {
  //   dispatch(setLiveEventsFilter({
  //     country: '0',
  //     tournament: '0',
  //     sport: '0',
  //   }));
  // }, [type]);

  const b = bemCn('desktop-live');

  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <div className={b('wrapper')}>
        <div className={b('left')}>
          <SideBarFilterDesktop lineType={lineType} />
        </div>
        <div className={b('content')}>
          {!previewItem
            ? <DesktopLineList lineType={type as LineType} />
            : (
              <FullEventDesktop
                eventId={eventId}
                lineType={typeUrl as LineType}
              />
            )}
        </div>
        <div className={b('right')}>
          <div className={b('basket')}>
            <Basket />
          </div>
          {/* <div className="desktop-live__casino">
            <CasinoWidget />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DesktopLine;
