import { createSelector } from '@reduxjs/toolkit';

import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import { NameSpace } from 'utils/constants';

import { paymentsApi } from './paymentsApi';

import type { RootState } from 'types/state';

export const selectTopupMethods = (state: RootState) => {
  const { data = {} } = paymentsApi.endpoints.getTopupMethods.select()(state);
  return data;
};

export const selectTopupMethodsList = createSelector(
  selectTopupMethods,
  selectPrimaryWalletCurrency,
  (methods, userCurrency) => methods[userCurrency ?? ''] ?? []
);

export const selectWithdrawalMethods = (state: RootState) => {
  const { data = {} } = paymentsApi.endpoints.getWithdrawalMethods.select()(state);
  return data;
};

export const selectWithdrawalMethodsList = createSelector(
  selectWithdrawalMethods,
  selectPrimaryWalletCurrency,
  (methods, userCurrency) => methods[userCurrency ?? ''] ?? []
);

export const selectInitError = (state: RootState) =>
  state[NameSpace.Payments].initError;

export const selectPaymentsActiveModal = (state: RootState) =>
  state[NameSpace.Payments].activeModal;
