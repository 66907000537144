import { useAppSelector } from 'hooks';
import { selectFilterTournament } from 'store/app/selectors';
import { bemCn } from 'utils/bem-cn';
import { FavoriteTournaments } from 'types/user-data';

import SideBarFilterDesktopTournament from '../SideBarFilterDesktopTournament/SideBarFilterDesktopTournament';

type Props = {
  tournaments: FavoriteTournaments;
};

const b = bemCn('side-bar-filter-desktop-tournaments');

function SideBarFilterDesktopFavoriteTournaments({ tournaments }: Props) {
  const activeTournament = useAppSelector(selectFilterTournament);

  return (
    <ul className={b()}>
      {
        tournaments.map(({ id, ...tournament }) => (
          <SideBarFilterDesktopTournament
            key={tournament.tournamentId}
            isActive={tournament.tournamentId === activeTournament}
            tournament={{
              id: tournament.tournamentId,
              counter: Number(tournament.countryId),
              name: tournament.tournamentName,
              sportId: Number(tournament.sportId),
              countryId: tournament.countryId
            }}
            isFavorite
            favoriteId={id}
            tournamentInfo={tournament}
          />
        ))
      }
    </ul>
  );
}

export default SideBarFilterDesktopFavoriteTournaments;
