import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalsHead from 'components/profile/ModalsHead/ModalsHead';
import WalletsManagement from 'components/profile/components/WalletsManagement/WalletsManagement';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import OtherWallets from 'components/Layout/Header/UserblockDesktop/UserBalanceDesktop/components/OtherWallets/OtherWallets';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import AddWalletForm from '../components/AddWalletForm/AddWalletForm';

import './WalletManageModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletManageModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const { amount, currency, cashbackLine, cashbackCasino, bonusAmount } = useAppSelector(selectPrimaryWallet);
  const { t } = useTranslation();

  const b = bemCn('wallet-manage-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <ModalsHead title={t('user-nav.wallets-manage', 'Управление счетами')} />
      <div className={b('main')}>
        <WalletsManagement
          balance={amount}
          bonusBalance={bonusAmount}
          cashbackLine={cashbackLine}
          cashbackCasino={cashbackCasino}
          currency={currency}
          isWalletsLoading={false}
        />
      </div>
      <div className={b('other')}>
        <OtherWallets />
      </div>
      <div className={b('add')}>
        <AddWalletForm />
      </div>
    </AppModal>
  );
};

export default WalletManageModal;
