import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import PPFilter from 'pages/PartnerProgram/components/PPFilter/PPFilter';
import PPStats from 'pages/PartnerProgram/components/PPStats/PPStats';

import { usePartnerStats } from '../hooks/usePartnerStats';

import '../PPPartner.scss';

const PPPartnerStats = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    isFetching,
    isError,
    statsList,
    currentIncome,
    handleFilterChange,
    handleFilterReset,
  } = usePartnerStats();

  return (
    <>
      <div className={b('stats-header')}>
        <p className={b('stats-header-title')}>
          {`${currentIncome.title}: ${currentIncome?.value ?? '-'}`}
        </p>

        <PPFilter
          onSubmit={handleFilterChange}
          onReset={handleFilterReset}
        />
      </div>

      <PPStats
        title={`${t('your-stats', 'Ваша статистика')}`}
        items={statsList}
        isLoading={isFetching}
        isError={isError}
      />
    </>
  );
};

export default PPPartnerStats;
