import { useResponsive } from 'hooks/useResponsive';
import DesktopLine from 'components/line/DesktopLine/DesktopLine';
import { seletIsLineActive } from 'store/app/selectors';
import { useAppSelector } from 'hooks';
import { Maintenance } from 'utils/constants/maintenanceMessage';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';

import EventPageMobile from './EventPageMobile';


const EventPage = () => {
  const { isDesktop } = useResponsive();
  const isLineActive = useAppSelector(seletIsLineActive);

  if (!isLineActive) {
    return <SiteMaintenance message={Maintenance.line} />;
  }

  return isDesktop
    ? <DesktopLine previewItem />
    : <EventPageMobile />;
};


export default EventPage;
