import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useStartAgtSessionMutation } from 'store/slots/slotsApi';
import { bemCn } from 'utils/bem-cn';
import ScrollToTop from 'components/Layout/ScrollToTop/ScrollToTop';
import Loader from 'components/shared/loader/Loader';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import type { SerializedError } from '@reduxjs/toolkit';
import type { BackendError } from 'store/auth/utils';

import './AgtGame.scss';

export const handleAgtResponseError = (error?: FetchBaseQueryError | SerializedError): string => {
  if (!!error && 'status' in error && error.data) {
    const { detail } = error.data as BackendError;
    let message = '';

    if (typeof detail === 'string') {
      if (detail === 'Currency DEMO not allowed') {
        return `${i18n.t('games.error.invalid-currency', 'Сервис не доступен для вашей валюты. Выберите другую валюту в качестве основной.')}`;
      }
      return detail;
    } else {
      message = detail[0].msg;
    }

    return message;
  }

  return error ? `${i18n.t('reg.modal.errors.smth-wrong', 'Что-то пошло не так!')}` : '';
};

const AgtGame = () => {
  const { gameId } = useParams();
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [startSession, {
    isLoading,
    isError,
    error,
    data: agtGameSessinon,
  }] = useStartAgtSessionMutation();
  const isLoaded = gameId && agtGameSessinon && !isLoading;

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    const tabBar = document.querySelector('.tab-bar');
    if (tabBar) {
      tabBar.classList.add('visually-hidden');
    }
    return () => {
      document.body.style.overflowY = '';
      if (tabBar) {
        tabBar.classList.remove('visually-hidden');
      }
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && gameId) {
      startSession({
        gameId,
        closeUrl: `${window.location.origin}/games?filter=Agt`,
      });
    }
  }, [isAuthenticated, gameId]);

  const src = agtGameSessinon?.data.data ?? '';

  const b = bemCn('inbet-games');

  return (
    <div className={b()}>
      <ScrollToTop />
      <div className={b('container')}>
        {isError && (
          <div className={b('error')}>{handleAgtResponseError(error)}</div>
        )}
        <Loader className={b('loader')}
          wrapperClassName={b('loader-wrapper')}
        />
        {isLoaded && !isError && (
          <iframe className='inbet-games__content'
            title='Inbet Games'
            scrolling='no'
            allow="fullscreen"
            frameBorder="0"
            src={src}
          />
        )}
        <Link className={b('back')}
          to='/games?filter=Agt'
        >
          <SvgIcon name='chevron-right' className={b('back-icon')} />
          {t('games.list', 'Список игр')}
        </Link>
      </div>
    </div>
  );
};

export default AgtGame;
