import { useLocation } from 'react-router-dom';

import { routes } from 'utils/routes';
import i18n from 'i18n/i18n';
import { SiteSection } from 'types/siteSections';

import TabBarItem from './components/TabBarItem/TabBarItem';
import BasketTabItem from './components/BasketItem/BasketItem';

import './TabBar.scss';

type PageData = {
  link: string;
  title: string;
  id: SiteSection;
};

const tabPage: PageData[] = [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 'home',
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 'live',
  },
  {
    link: routes.betHistory.path,
    title: i18n.t('main.nav.Bet-history', routes.betHistory.title),
    id: 'betHistory',
  },
  {
    link: '/profile',
    title: i18n.t('burger.profile-info.title', 'Profile'),
    id: 'profile',
  },
  // {
  //   link: '/crash-games',
  //   title: i18n.t('main.nav.Crash', 'Краш игры'),
  //   id: 'crashGames',
  // },
];

const TabBar = () => {
  const { pathname } = useLocation();

  const tabBarItemsLayout = tabPage.map((menuItem) => (
    <TabBarItem
      key={menuItem.id}
      item={menuItem}
      isActive={pathname.startsWith(menuItem.link)}
    />
  ));

  const basketItem = <BasketTabItem key="basket" isActive={pathname.startsWith('.basket')} />;
  tabBarItemsLayout.splice(2, 0, basketItem);

  return (
    <div className="tab-bar">
      {tabBarItemsLayout}
    </div>
  );
};

export default TabBar;
