import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import { useAddFavoriteTournamentMutation, useDeleteFavoriteTournamentMutation } from 'store/user/userApi';
import { AddFavoriteTournamentBody } from 'types/user-data';

import { selectIsAuthenticated } from './../store/auth/selectors';

export const useFavoriteTournamentToggle = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [addFavoriteTournament, { isLoading: isAddLoading }] = useAddFavoriteTournamentMutation();
  const [deleteFavoriteTournament, { isLoading: isDeleteLoading }] = useDeleteFavoriteTournamentMutation();

  const toggleFavoriteTournament = useCallback(
    ({ isFavorite, tournamentInfo, favoriteId }: toggleFavoriteTournamentProps) => {
      if (isAuthenticated) {
        if (!isFavorite) {
          addFavoriteTournament(tournamentInfo);
        } else {
          deleteFavoriteTournament(favoriteId);
        }
      } else {
        dispatch(changeActiveModal('sign-in'));
      }
    }, [addFavoriteTournament, deleteFavoriteTournament, isAuthenticated, dispatch]);

  return { toggleFavoriteTournament, isLoading: isAddLoading || isDeleteLoading };
};

export interface toggleFavoriteTournamentProps {
  isFavorite: boolean;
  favoriteId: string;
  tournamentInfo: AddFavoriteTournamentBody;
}
