import React, { forwardRef } from 'react';

import TextInput, { InputProps } from 'components/shared/inputs/TextInput/TextInput';
// import { Currency } from 'store/wallets/types';
// import { getCurrencySymbol } from 'store/wallets/constants';

import './PhoneInput.scss';

interface Props extends InputProps {
  value: string;
  onChange?(value: string): void;
}

const availableInput = /^\+?\d{0,15}$/;

// eslint-disable-next-line react/display-name
const PhoneInput = forwardRef<HTMLInputElement, Props>(({
  placeholder = 'Ваш пароль',
  value,
  onChange,
  isTouched,
  ...props
}, ref) => {
  const handlePhoneChange = (valueAsString: string) => {
    const isValidPhone = availableInput.test(valueAsString);

    if (valueAsString === '') {
      onChange?.('+');
    } else if (isValidPhone) {
      onChange?.(valueAsString);
    }
  };

  return (
    <div className="money-input">
      <TextInput
        {...props}
        value={value}
        type="text"
        ref={ref}
        onChange={handlePhoneChange}
        placeholder={placeholder}
        isTouched={isTouched}
      />
    </div>

  );
});
export default PhoneInput;
