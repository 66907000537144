import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import TextContent from 'components/shared/TextContent/TextContent';


const b = bemCn('privacy-policy-page');
function AgeRestriction() {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <TextContent className={b('content')}>
        <h1>Age restrictions</h1>
        <p>
          You must be 18 or over and meet certain other eligibility criteria and
          requirements to register for an Account and use the Services. It is also
          important that you determine whether your use of the Services is illegal in your
          home jurisdiction or (if different) your current location, and you may not use
          the Services if this is the case.
        </p>
      </TextContent>
    </div>
  );
}

export default AgeRestriction;
