import React from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useFilteredEvent } from 'hooks/useFilteredEvent';
import { routes } from 'utils/routes';

import FullEventHeader from './FullEventHeader/FullEventHeader';
import FullEventBets from './FullEventBets/FullEventBets';

import type { LineType } from 'types/line-data';

import './FullEvent.scss';

interface Props {
  lineType: LineType;
  eventId: string;
}

const POLLING_INTERVAL = 4000;

const FullEvent = ({ eventId, lineType }: Props) => {
  const { i18n } = useTranslation();
  const { isLoading, data: event } = useFilteredEvent(
    {
      gameId: eventId,
      dataType: lineType,
      dataLang: i18n.resolvedLanguage,
    },
    {
      pollingInterval: lineType === 'live' ? POLLING_INTERVAL : 0,
    },
  );

  return (
    <div className="tournament">
      <Breadcrumbs link={routes[lineType].path} title={routes[lineType].title} />
      <div className="tournament__content">
        {isLoading
          ? <Loader />
          : (
            <>
              {event && <FullEventHeader event={event} lineType={lineType} />}
              <FullEventBets event={event} lineType={lineType} />
            </>
          )}
      </div>
    </div>
  );
};

export default FullEvent;
