import { MouseEventHandler } from 'react';

import { bemCn } from 'utils/bem-cn';

import SvgIcon from '../SvgIcon/SvgIcon';

import './FavoriteBtn.scss';

type Props = {
  className?: string;
  onClick?: MouseEventHandler;
  active?: boolean;
  disabled?: boolean;
  size?: number;
}

const FavoriteBtn = ({ onClick, className, active, size = 14, disabled }: Props) => {
  const b = bemCn('favorite-btn');
  return (
    <button className={b({ 'active': active }, className)}
      type='button'
      onClick={onClick}
      disabled={disabled}
      style={{ width: size, height: size }}
    >
      <SvgIcon className={b('icon')}
        name='star'
        width={size}
        height={size}
      />
    </button>
  );
};

export default FavoriteBtn;
