import { bemCn } from 'utils/bem-cn';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import Loader from 'components/shared/loader/Loader';

import PPStatsItem from './PPStatsItem';
import PPError from '../PPError/PPError';

import './PPStats.scss';

import type { TStatsItem } from 'store/partnerProgram/types';

type Props = {
  items?: TStatsItem[];
  isLoading?: boolean;
  isError?: boolean;
  title?: string;
};

const PPStats = (props: Props) => {
  const {
    items,
    isLoading,
    isError,
    title,
  } = props;

  const b = bemCn('pp-stats');

  if (isLoading || !items) {
    return <Loader />;
  }

  return (
    <div className={b()}>
      {title && (
        <h2 className={b('title')}>{title}</h2>
      )}

      {isError
        ? <PPError />
        : (
          <div className={b('list')}>
            {items.map((item) => (

              <PPStatsItem
                key={item.key}
                title={item.title}
                value={item.value}
              />
            ))}
          </div>
        )}
    </div>
  );
};

export default PPStats;
