import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useGetManagerPartnerPromocodesQuery,
} from 'store/partnerProgram/managerApi';

import type { ManagerPartner, TTableProps, TDropdownItem } from 'store/partnerProgram/types';

const actionList: TDropdownItem[] = [
  {
    key: 'promocode-stats',
    title: 'Статистика',
  },
  {
    key: 'promocode-players',
    title: 'Статистика рефералов',
  },
];

export const useManagerPartnerPromocodes = (partnerId: number) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const {
    data,
    isFetching,
    isError,
  } = useGetManagerPartnerPromocodesQuery({ partnerId, page: currentPage, pageSize });

  const items = data?.items;
  const totalCount = data?.count ?? 0;
  const pageCount = Math.ceil(totalCount / pageSize);

  const pagination: TTableProps<ManagerPartner, keyof ManagerPartner>['pagination'] = {
    current: currentPage,
    total: pageCount,
    onChange: (value) => setCurrentPage(value),
  };

  const handleActionsClick = (id: number, key: string) => {
    switch (key) {
      case 'promocode-stats':
        navigate(`promocodes/${id}`);
        break;
      case 'promocode-players':
        navigate(`promocodes/${id}/players`);
        break;
      default:
    }
  };

  return {
    data,
    isFetching,
    isError,
    items,
    pagination,
    actionList,
    handleActionsClick,
  };
};
