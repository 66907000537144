import React from 'react';

import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import './BalanceInfo.scss';

type BalanceProps = {
  id: 'wallet' | 'casino-bonuses' | 'sport-bonuses' | 'bonus-wallet';
  title: string;
  balance: string | null;
  size: 'sm' | 'm';
  className: string;
  isLoading?: boolean;
};

const BalanceInfo = (props: BalanceProps) => {
  const { id, title, balance, size, className, isLoading } = props;

  const b = bemCn('balance-info');

  return (
    <div className={b({ [`${id}`]: true }, className)}>
      <div className={b('wrapper')}>
        <p className={b('title')}>
          {title}
        </p>
        <p className={b('balance', { size })}>
          {isLoading ? <Loader /> : balance}
        </p>
      </div>
    </div>
  );
};

export default BalanceInfo;
