import React from 'react';

import CloseButton from 'components/shared/CloseButton/CloseButton';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import Basket from '../Basket/Basket';
import './BasketModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const b = bemCn('basket-modal');

const BasketModal = ({ isOpen, onClose }: Props) => (
  <AppModal
    isOpen={isOpen}
    onClose={onClose}
    className={b()}
    closeButton={
      <CloseButton className={b('close-btn')} onClick={onClose} />
    }
  >
    <Basket />
  </AppModal>
);

export default BasketModal;
