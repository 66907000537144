import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';

import WithdrawalForm from './WithdrawalForm/WithdrawalForm';

import type { TWithdrawalMethodsItem } from 'types/payments-data';
import './WithdrawalModal.scss';

type Props = {
  activeMethod: TWithdrawalMethodsItem | null;
  isOpen: boolean;
  onClose: () => void;
};

const b = bemCn('withdrawal-modal');

const WithdrawalModal = ({ activeMethod, onClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const { amount: balance } = useAppSelector(selectPrimaryWallet);

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <h2 className={b('title')}>
        {t('withdrawals.modal.title', 'Вывод')}
      </h2>
      <p className={b('description')}>
        {activeMethod?.title}
      </p>
      {activeMethod && (
        <WithdrawalForm
          method={activeMethod}
          balance={balance}
        />
      )}
    </AppModal>
  );
};

export default WithdrawalModal;
