import cn from 'classnames';

import { whatsappLink } from 'utils/constants';

import SvgIcon from '../SvgIcon/SvgIcon';

import './SupportIcon.scss';

type Props = {
  className?: string;
};

const SupportIcon = ({ className }: Props) => (
  <a href={whatsappLink} className={cn('support-icon', className)} target='_blank' rel="noreferrer">
    <SvgIcon name='support-tg' className='support'/>
  </a>
);

export default SupportIcon;
