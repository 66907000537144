import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import './WidgetHeader.scss';

type Props = {
  link: string;
  subtitle: string;
};

const WidgetHeader = ({ link, subtitle }: Props) => {
  const { t } = useTranslation();
  const b = bemCn('widget-header');
  return (
    <div className={b('show-more')}>
      <div className={b('link')}>
        <Link className={b('show-more-link')} to={link} >
          {t('top-events-widget.all', 'Все')}
          <SvgIcon className={b('arrow-icon')} name="arrow-right" width={9} height={9} />
        </Link>
      </div>
      <p className={b('show-more-details')}>
        {subtitle}
      </p>
    </div>
  );
};

export default WidgetHeader;
