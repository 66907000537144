import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/buttons/Button/Button';
import AppModal from 'components/shared/AppModal/AppModal';
import FormInputWrapper from 'components/shared/form/FormInput';
import { usePasswordPhoneVerifyMutation, usePasswordResetSmsMutation } from 'store/auth/authApi';
import { useAppSelector } from 'hooks';
import { selectVerifyPhone } from 'store/auth/selectors';
import { FetchErorr } from 'types/auth-data';
import { getSmsResendPermission } from 'utils/getSmsResendPermission';
import Loader from 'components/shared/loader/Loader';
import './ConfirmResetPasswordSms.scss';

type FormFields = {
  verifyCode: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = yup.object({
  verifyCode: yup.string()
    .length(4, `${i18n.t('confirm-phone.errors.wrong-length')}`)
    .required(`${i18n.t('confirm-phone.errors.empty-code', 'Введите номер')}`),
}).required();

const b = bemCn('confirm-reset-password');
function ConfirmResetPasswordSms({ isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const [verify, { isLoading, error }] = usePasswordPhoneVerifyMutation();
  const [resendSms, { isLoading: isResendLoading }] = usePasswordResetSmsMutation();
  const verifyPhone = useAppSelector(selectVerifyPhone) as string;
  const [isResendAble, setIsResendAble] = useState(true);

  const { handleSubmit, setError, control } = useForm<FormFields>({
    defaultValues: { verifyCode: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const handleResendSmsClick = () => {
    if (getSmsResendPermission(verifyPhone)) {
      resendSms(verifyPhone);
    } else {
      setIsResendAble(false);
      setError('verifyCode', {
        type: 'localStorage',
        message: `${t('confirm-phone.errors.resend-code')}`
      });
    }
  };

  const handleSend = async (data: FormFields) => {
    await verify(data.verifyCode);
  };

  useEffect(() => {
    if (error !== undefined) {
      const fetchError = error as FetchErorr;
      const errorMesage = fetchError.data.detail;

      setError('verifyCode', {
        type: 'server',
        message: errorMesage ?? '',
      });
    }
  }, [error]);

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <form onSubmit={handleSubmit(handleSend)}>
        <div className={b('header')}>
          <h2 className={b('title')}>
            {t('confirm-phone.title')}
          </h2>
          <p className={b('description')}>
            {t('confirm-phone.description')}
          </p>
        </div>
        <FormInputWrapper<FormFields>
          name='verifyCode'
          type='number'
          control={control}
          disabled={isLoading}
          autoComplete="current-password"
          placeholder={`${t('confirm-phone.verification')}`}
          showError
        />
        {
          isResendLoading
            ? <Loader wrapperClassName={b('loader')} />
            : <button disabled={!isResendAble} onClick={handleResendSmsClick} className={b('resend-code-btn')} type='button'>{t('confirm-phone.resend-code')}</button>
        }
        <Button className={b('button')}
          isLoading={isLoading}
          type='submit'
        >
          {t('confirm-phone.send-request', 'Отправить')}
        </Button>
      </form>
    </AppModal >
  );
}

export default ConfirmResetPasswordSms;
