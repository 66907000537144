import { timezoneStorageKey, defaultTimezone } from 'utils/constants/timezone';

export type ClickId = string | null | undefined;
export type WebId = string | null | undefined;
export type RefCode = string | null | undefined;

export const saveClickId = (clickId: ClickId) => {
  clickId && localStorage.setItem('clickId', clickId);
};
export const getClickId = (): ClickId =>
  localStorage.getItem('clickId') ?? undefined;
export const dropClickId = () =>
  localStorage.removeItem('clickId');

export const saveWebId = (webId: WebId) => {
  webId && localStorage.setItem('webId', webId);
};
export const getWebId = (): WebId =>
  localStorage.getItem('webId') ?? undefined;
export const dropWebId = () =>
  localStorage.removeItem('webId');

export const saveRefCode = (refCode: RefCode) => {
  refCode && localStorage.setItem('refCode', refCode);
};
export const getRefCode = (): RefCode =>
  localStorage.getItem('refCode') ?? undefined;
export const dropRefCode = () =>
  localStorage.removeItem('refCode');

export const saveTelegramShown = (status: boolean) => {
  localStorage.setItem('telegramShown', JSON.stringify(status));
};

export const getTelegramShown = (): boolean => {
  const item = localStorage.getItem('telegramShown');
  if (item === null) {
    return false;
  }
  return JSON.parse(item) as boolean;
};

export const getUserTimezone = (): number => {
  const storageTimezone = localStorage.getItem(timezoneStorageKey);

  if (storageTimezone !== null && !Number.isNaN(storageTimezone)) {
    return Number(storageTimezone);
  }

  localStorage.setItem(timezoneStorageKey, JSON.stringify(defaultTimezone));
  return defaultTimezone;
};
export const setUserTimezone = (selectedTimezone: string | number): void => {
  if (typeof selectedTimezone === 'string' && !Number.isNaN(selectedTimezone)) {
    localStorage.setItem(timezoneStorageKey, JSON.stringify(Number(selectedTimezone)));
  } else {
    localStorage.setItem(timezoneStorageKey, JSON.stringify(selectedTimezone));
  }
};
