import React from 'react';
import { Link } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';

import SvgIcon from '../SvgIcon/SvgIcon';
import './Breadcrumbs.scss';

type Props = {
  title: string;
  link: string;
  className?: string;
};

const Breadcrumbs = ({ title, link, className }: Props) => {
  const b = bemCn('breadcrumbs');
  return (
    <div className={b(null, className)}>
      <Link to={link}>
        <p className={b('wrapper')}>
          <SvgIcon className={b('icon')} name='chevron-right' width={7} height={7} />
          {title}
        </p>
      </Link>
    </div>
  );
};

export default Breadcrumbs;
