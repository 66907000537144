import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrencyTitleWithoutAbbreviation } from 'utils/currency';
import { getBalanceDiffType, getTransactionTitle } from 'store/user/utils';
import { getDateTimeSlashFormat } from 'utils/time';
import { bemCn } from 'utils/bem-cn';

import type { TransactionShort, TransactionsWidthrawal, Withdrawal, WithdrawalCollection } from 'types/user-data';

import './Transactions.scss';


type Props = {
  transaction: TransactionShort;
  transactionsWithdrawal?: Withdrawal[];
};

const TransactionItem = ({ transaction, transactionsWithdrawal }: Props) => {
  const {
    type,
    currency,
    created,
    amount,
    id: transactionId,
  } = transaction;

  const comment = transactionsWithdrawal?.find((item) => item.transactionId === transactionId)?.commentary;

  const diffType = amount < 0 ? 'decrease' : getBalanceDiffType(type);
  const { t } = useTranslation();


  const b = bemCn('transaction-item');

  return (
    <div className={b()}>
      <div className={b('row')}>
        <p className={b('title')}>{getTransactionTitle(type)}</p>
        <p>{getDateTimeSlashFormat(created)}</p>
      </div>
      <div className={b('row')}>
        <p>{t('transactions.account', 'Счет')}: {getCurrencyTitleWithoutAbbreviation(currency)}</p>
        <p className={ b('amount', {[`${diffType}`]: !!diffType})}>
          {diffType === 'increase' && '+'}
          {amount >= 0 && diffType === 'decrease' && '-'}
          {`${amount} ${currency}`}
        </p>
      </div>
      <div className={b('row')}>
        {comment && (
          <p>
            {t('transactions.withdrawal-comment', 'Комментарий')}:
            <span> {comment}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default TransactionItem;
