import { useToggle } from 'usehooks-ts';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import type { StatItem } from 'types/line-data';
import './EventCardStats.scss';

type Props = {
  stats: StatItem[];
  className?: string;
}

const EventCardStats = ({ stats, className }: Props) => {
  const { t } = useTranslation();
  const [isClosed, toggleClosed] = useToggle(false);

  if (stats.length <= 0) {
    return null;
  }

  const b = bemCn('event-card-stats');

  return (
    <div className={b({ closed: isClosed }, className)}>
      <button className={b('title')}
        type='button'
        onClick={toggleClosed}
      >
        {t('event.statistics', 'Статистика')}
        <SvgIcon className={b('toggle-icon')}
          name='chevron-down'
          width={10}
          height={10}
        />
      </button>
      {!isClosed && (
        <ul className={b('list')}>
          {stats.map((stat, i) => {
            const keyVal = `${i}$-{stat.name}-${stat.opp1}-${stat.opp2}`;
            return (
              <li className={b('item')} key={keyVal}>
                <span className={b('team-1')}>{stat.opp1}</span>
                <span className={b('name')}>{stat.name}</span>
                <span className={b('team-2')}>{stat.opp2}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default React.memo(EventCardStats);
