import TextContent from 'components/shared/TextContent/TextContent';
import { logoText } from 'utils/constants';

const RiskNotification = () => (
  <TextContent as="article">
    <h1>Уведомление о рисках</h1>

    <p>Играя на сайте {logoText}, вы осознаете, что существует определенный риск потери денежных средств, которые были внесены на ваш счет. Кроме того, стоит отметить, что в некоторых странах онлайн-азартные игры могут быть незаконными.</p>
    <p>Мы хотели бы проинформировать вас о том, что администрация сайта не оказывает юридическую поддержку и не может гарантировать законность использования услуг нашего веб-сайта. Кроме того, дружественная компания {logoText} не может гарантировать Клиенту, что услуги нашего веб-сайта соответствуют требованиям законодательства вашей страны.</p>
    <p>Решение о правомерности использования наших услуг администрация сайта оставляет полностью на ваше усмотрение и предполагает наличие личной ответственности перед законом вашей юрисдикции. Посещение нашего веб-сайта и участие в играх происходит исключительно на ваш риск.</p>
    <p>Отметим, что веб-сайты и игры {logoText} доступны без каких-либо явных или подразумеваемых гарантий.</p>
  </TextContent>
);

export default RiskNotification;
