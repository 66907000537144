import type { BetLimitsByCurrency, SubgamesLimits, SubgamesLimitsCurrency } from 'types/bet-limits';
import type { BasketItem as IBasketItem } from 'types/basket-data';
import type { Currency } from 'types/wallets-data';
// import { suggestValues } from '../payment/constants';

type Limit = {
  min: number;
  max: number;
};

export const betLimits: Record<Currency, Limit> = {
  RUB: {
    min: 50,
    max: 300000
  },
  USD: {
    min: 1,
    max: 10000
  },
  EUR: {
    min: 1,
    max: 10000
  },
  KZT: {
    min: 100,
    max: 1000000,
  },
  UAH: {
    min: 10,
    max: 100000
  },
  TJS: {
    min: 5,
    max: 30000
  },
};

export const defaultLimits = {
  min: 1,
  max: 999999,
};

export const betSuggests: Record<Currency, number[]> = {
  RUB: [1000, 3000, 5000],
  USD: [15, 30, 50],
  EUR: [1000, 5000, 10000],
  KZT: [5000, 1000, 25000],
  UAH: [500, 1000, 5000],
  TJS: [100, 500, 1000],
};

export const getBetSuggestions = (currency: Currency): number[] => betSuggests[currency] ?? betSuggests.USD;

export const getDefaultBetLimits = (currency: Currency): { min: number; max: number } => betLimits[currency] ?? defaultLimits;

type GetBetLimitsParams = {
  currency: string;
  walletMaxBet: number;
  currencyLimits: BetLimitsByCurrency;
  subgamesLimits: SubgamesLimits;
  walletSubgamesLimits: SubgamesLimitsCurrency | null;
  basketItems: IBasketItem[];
}

type GetBetLimits = {
  max: number;
  min: number;
}
// Название subgame для основного времени
const mainGameName = 'Основные';

export const getBetLimits = (params: GetBetLimitsParams): GetBetLimits => {
  const {
    currency,
    currencyLimits,
    subgamesLimits,
    basketItems,
    walletMaxBet,
    walletSubgamesLimits,
  } = params;

  const defaultAppLimits = getDefaultBetLimits(currency);
  const systemCurrencyMax = currencyLimits[currency];
  const currencySubgameLimit = subgamesLimits[currency];

  let subgameLimitsList: number[] = [
    walletMaxBet,
    systemCurrencyMax,
  ];

  basketItems.forEach((item) => {
    const isMainGame = item.gameId === item.gameMid;
    const sport = item.sportNameRu || item.sportName;
    const subgame = isMainGame
      ? mainGameName
      : item.subGameNameRu || item.subGameName;
    const groupName = item.groupName;
    const systemSubgameLimit = currencySubgameLimit?.[sport]?.[subgame]?.total;
    const systemGroupLimit = currencySubgameLimit?.[sport]?.[subgame]?.groups?.[groupName];
    const walletSubgameLimit = walletSubgamesLimits?.[sport]?.[subgame]?.total;
    const walletGroupLimit = walletSubgamesLimits?.[sport]?.[subgame]?.groups?.[groupName];


    if (typeof systemSubgameLimit === 'number') {
      subgameLimitsList.push(systemSubgameLimit);
    }
    if (typeof systemGroupLimit === 'number') {
      subgameLimitsList.push(systemGroupLimit);
    }
    if (typeof walletSubgameLimit === 'number') {
      subgameLimitsList.push(walletSubgameLimit);
    }
    if (typeof walletGroupLimit === 'number') {
      subgameLimitsList.push(walletGroupLimit);
    }
  });

  subgameLimitsList = subgameLimitsList.filter((item) => !!item);

  if (subgameLimitsList.length <= 0) {
    subgameLimitsList.push(defaultAppLimits.max);
  }

  return {
    min: Math.max(1, defaultAppLimits.min),
    max: Math.min(...subgameLimitsList),
  };
};
