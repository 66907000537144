const flagsById: Record<string, string> = {
  1: 'russia',
  2: 'ukraine',
  4: 'australia',
  5: 'austria',
  6: 'azerbaijan',
  9: 'angola',
  11: 'andorra',
  14: 'argentina',
  15: 'armenia',
  21: 'bahrain',
  22: 'belarus',
  24: 'belgium',
  27: 'bulgaria',
  28: 'bolivia',
  29: 'bosnia-and-herzegovina',
  30: 'botswana',
  31: 'brazil',
  34: 'burkina-faso',
  36: 'bhutan',
  39: 'united-kingdom',
  40: 'hungary',
  41: 'venezuela',
  43: 'vietnam',
  50: 'guatemala',
  53: 'germany',
  55: 'gibraltar',
  56: 'honduras',
  60: 'greece',
  62: 'denmark',
  65: 'dominican-republic',
  66: 'egypt',
  70: 'israel',
  71: 'india',
  72: 'indonesia',
  73: 'jordan',
  75: 'iran',
  76: 'ireland',
  77: 'iceland',
  78: 'spain',
  79: 'italy',
  82: 'kazakhstan',
  83: 'cambodia',
  85: 'canada',
  86: 'qatar',
  87: 'kenya',
  88: 'cyprus',
  90: 'china',
  91: 'colombia',
  95: 'costa-rica',
  96: '',
  98: 'kuwait',
  102: 'latvia',
  104: 'liberia',
  105: 'lebanon',
  107: 'lithuania',
  109: 'luxembourg',
  113: 'republic-of-macedonia',
  115: 'malaysia',
  116: 'mali',
  118: 'malta',
  120: 'mexico',
  124: 'mongolia',
  133: 'netherlands',
  134: 'nicaragua',
  135: 'new-zealand',
  137: 'norway',
  139: 'united-arab-emirates',
  140: 'oman',
  142: 'panama',
  144: 'paraguay',
  145: 'peru',
  147: 'poland',
  148: 'portugal',
  151: 'rwanda',
  152: 'romania',
  153: 'united-states',
  154: 'el-salvador',
  156: 'san-marino',
  158: 'saudi-arabia',
  165: 'senegal',
  168: 'serbia',
  169: 'singapore',
  170: 'syria',
  171: 'slovakia',
  172: 'slovenia',
  178: 'tajikistan',
  179: 'taiwan',
  180: 'thailand',
  181: 'tanzania',
  182: 'togo',
  187: 'tunisia',
  190: 'turkey',
  191: 'uganda',
  192: 'uzbekistan',
  193: 'uruguay',
  194: 'faroe-islands',
  197: 'finland',
  198: 'france',
  201: 'croatia',
  203: 'montenegro',
  204: 'czech-republic',
  205: 'chile',
  206: 'switzerland',
  207: 'sweden',
  209: 'ecuador',
  212: 'estonia',
  213: 'ethiopia',
  214: 'south-africa',
  215: 'south-korea',
  218: 'japan',
  219: 'scotland',
  223: 'european-union',
  224: '',
  225: 'world',
  228: 'kyrgyzstan',
  230: 'wales',
  231: 'england',
  236: 'united-kingdom',
  279: 'kosovo'
};

const flagsNameByCode:Record<string,string | undefined> = {
  'RU': 'russia',
  'UA': 'ukraine',
  'AU': 'australia',
  'AT': 'austria',
  'AZ': 'azerbaijan',
  'AO': 'angola',
  'AD': 'andorra',
  'AR': 'argentina',
  'AM': 'armenia',
  'BH': 'bahrain',
  'BE': 'belgium',
  'BG': 'bulgaria',
  'BW': 'botswana',
  'BR': 'brazil',
  'BF': 'burkina-faso',
  'BT': 'bhutan',
  'HU': 'hungary',
  'GT': 'guatemala',
  'DE': 'germany',
  'GI': 'gibraltar',
  'GR': 'greece',
  'GB': 'united-kingdom',
  'DK': 'denmark',
  'EG': 'egypt',
  'IN': 'india',
  'IE': 'ireland',
  'ES': 'spain',
  'IT': 'italy',
  'KE': 'kenya',
  'CY': 'cyprus',
  'HK': 'hong-kong',
  'CO': 'colombia',
  'CR': 'costa-rica',
  'KW': 'kuwait',
  'LR': 'liberia',
  'LU': 'luxembourg',
  'MK': 'republic-of-macedonia',
  'ML': 'mali',
  'MT': 'malta',
  'NL': 'netherlands',
  'NI': 'nicaragua',
  'AE': 'united-arab-emirates',
  'PE': 'peru',
  'PL': 'poland',
  'PT': 'portugal',
  'RW': 'rwanda',
  'RO': 'romania',
  'US': 'united-states',
  'SV': 'el-salvador',
  'SM': 'san-marino',
  'SN': 'senegal',
  'RS': 'serbia',
  'SK': 'slovakia',
  'SI': 'slovenia',
  'TH': 'thailand',
  'TZ': 'tanzania',
  'TG': 'togo',
  'TR': 'turkey',
  'UG': 'uganda',
  'FR': 'france',
  'HR': 'croatia',
  'ME': 'montenegro',
  'CZ': 'czech-republic',
  'CL': 'chile',
  'CH': 'switzerland',
  'SE': 'sweden',
  'ET': 'ethiopia',
  'KZ': 'kazakhstan',
  'CA': 'canada',
  'BS': 'bahamas',
  'BB': 'barbados',
  'AI': 'anguilla',
  'PK': 'pakistan',
  'AF': 'afghanistan',
  'LK': 'sri-lanka',
  'MM': 'myanmar',
  'MV': 'maldives',
  'AG': 'antigua-and-barbuda',
  'VG': 'british-virgin-islands',
  'VI': 'virgin-islands',
  'KY': 'cayman-islands',
  'BM': 'bermuda',
  'GD': 'grenada',
  'TC': 'turks-and-caicos',
  'JM': 'jamaica',
  'MS': 'montserrat',
  'MP': 'northern-marianas-islands',
  'AB': 'abkhazia',
  'GU': 'guam',
  'AS': 'american-samoa',
  'SX': 'sint-maarten',
  'LC': 'st-lucia',
  'DM': 'dominica',
  'VC': 'st-vincent-and-the-grenadines',
  'PR': 'puerto-rico',
  'DO': 'dominican-republic',
  'TT': 'trinidad-and-tobago',
  'KN': undefined,
  'SS': 'south-sudan',
  'MA': 'morocco',
  'EH': undefined,
  'DZ': 'algeria',
  'TN': 'tunisia',
  'LY': 'libya',
  'GM': 'gambia',
  'MR': 'mauritania',
  'GN': 'guinea',
  'CI': undefined,
  'NE': 'niger',
  'BJ': 'benin',
  'MU': 'mauritius',
  'SL': 'sierra-leone',
  'GH': 'ghana',
  'NG': 'nigeria',
  'TD': 'chad',
  'CF': 'central-african-republic',
  'CM': 'cameroon',
  'CV': 'cape-verde',
  'ST': 'sao-tome-and-prince',
  'GQ': 'equatorial-guinea',
  'GA': 'gabon',
  'CG': 'republic-of-the-congo',
  'CD': 'democratic-republic-of-congo',
  'GW': 'guinea-bissau',
  'IO': 'british-indian-ocean-territory',
  'SC': 'seychelles',
  'SD': 'sudan',
  'SO': 'somalia',
  'DJ': 'djibouti',
  'BI': 'burundi',
  'MZ': 'mozambique',
  'ZM': 'zambia',
  'MG': 'madagascar',
  'RE': '',
  'YT': '',
  'TF': '',
  'ZW': 'zimbabwe',
  'NA': 'namibia',
  'MW': 'malawi',
  'LS': 'lesotho',
  'SZ': '',
  'KM': 'comoros',
  'ZA': 'south-africa',
  'SH': 'united-kingdom',
  'ER': 'eritrea',
  'AW': 'aruba',
  'FO': 'faroe-islands',
  'GL': 'greenland',
  'IS': 'iceland',
  'AL': 'albania',
  'FI': 'finland',
  'AX': 'aland-islands',
  'LT': 'lithuania',
  'LV': 'latvia',
  'EE': 'estonia',
  'MD': 'moldova',
  'BY': 'belarus',
  'MC': 'monaco',
  'VA': 'vatican-city',
  'XK': 'kosovo',
  'BA': 'bosnia-and-herzegovina',
  'LI': 'liechtenstein',
  'UK': 'united-kingdom',
  'GG': 'guernsey',
  'IM': 'isle-of-man',
  'JE': 'jersey',
  'NO': 'norway',
  'SJ': 'norway',
  'BV': 'norway',
  'FK': 'falkland-islands',
  'GS': '',
  'BZ': 'belize',
  'HN': '',
  'PA': 'panama',
  'PM': '',
  'HT': 'haiti',
  'MX': 'mexico',
  'CU': 'cuba',
  'VE': 'venezuela',
  'GP': '',
  'BL': 'st-barts',
  'MF': '',
  'BO': 'bolivia',
  'GY': 'guyana',
  'EC': 'ecuador',
  'GF': '',
  'PY': 'paraguay',
  'MQ': 'martinique',
  'SR': 'suriname',
  UY: 'uruguay',
  BQ: undefined,
  CW: 'curacao',
  MY: 'malaysia',
  CX: undefined,
  CC: 'cocos-island',
  ID: 'indonesia',
  PH: 'philippines',
  NZ: 'new-zealand',
  PN: 'pitcairn-islands',
  SG: 'singapore',
  TL: 'east-timor',
  AQ: undefined,
  NF: 'norfolk-island',
  HM: 'australia',
  BN: 'brunei',
  NR: 'nauru',
  PG: 'papua-new-guinea',
  TO: 'tonga',
  SB: 'solomon-islands',
  VU: 'vanuatu',
  FJ: 'fiji',
  PW: 'palau',
  WF: undefined,
  CK: 'cook-islands',
  NU: 'niue',
  WS: 'samoa',
  KI: 'kiribati',
  NC: undefined,
  TV: 'tuvalu',
  PF: 'french-polynesia',
  TK: 'tokelau',
  FM: 'micronesia',
  MH: 'marshall-island',
  JP: 'japan',
  KR: 'south-korea',
  VN: 'vietnam',
  MO: 'macao',
  KH: 'cambodia',
  LA: 'laos',
  CN: 'china',
  BD: 'bangladesh',
  TW: 'taiwan',
  LB: 'lebanon',
  JO: 'jordan',
  SY: '',
  IQ: 'iraq',
  SA: 'saudi-arabia',
  YE: 'yemen',
  OM: 'oman',
  PS: 'palestine',
  IL: 'israel',
  QA: 'qatar',
  MN: 'mongolia',
  NP: 'nepal',
  IR: 'iran',
  TJ: 'tajikistan',
  TM: 'turkmenistan',
  GE: 'georgia',
  KG: 'kyrgyzstan',
  UZ: 'uzbekistan',
};

export const getCountryIcon = (id: string) => flagsById[id] || 'world';
export const getCountryIconByCode = (countryCode: string | null) => {
  if (countryCode === null) {
    return 'world';
  }
  return flagsNameByCode[countryCode] || 'world';
};
