import React from 'react';

import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';
import Pagination from 'components/shared/Pagination/Pagination';

import PPError from '../PPError/PPError';

import './PPTable.scss';

import type { TTableProps } from 'store/partnerProgram/types';

function PPTable<T, K extends keyof T>(props: TTableProps<T, K>) {
  const {
    columns,
    sourceData,
    className,
    isLoading,
    isError,
    title,
    pagination,
  } = props;

  const b = bemCn('pp-table');
  const classNames = `${b()} ${className ? className : ''}`;

  if (isLoading || !sourceData) {
    return <Loader />;
  }

  return (
    <div className={b('wrapper')}>
      {title && (
        <h2 className={b('title')}>{title}</h2>
      )}

      <table className={classNames}>

        <thead className={b('header')}>
          <tr className={b('row')}>
            {columns.map((col, index) => (
              <th
                className={b('header-item')}
                key={`table-header-${index}`}
              >
                {col.title}
              </th>
            ))}
          </tr>
        </thead>

        {isError
          ? <PPError />
          : (
            <tbody className={b('body')}>
              {sourceData.map((item, index) => (
                <tr className={b('row')} key={`table-row-${index}`}>
                  {columns.map((col) => (
                    <td className={b('col')} key={col.key as string}>
                      {col.render
                        ? col.render(item[col.dataIndex], item)
                        : item[col.dataIndex] as unknown as string}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}

      </table>

      <Pagination
        selectPage={pagination.current}
        countPages={pagination.total}
        setSelectPage={pagination.onChange}
      />
    </div>
  );
}

export default PPTable;
