import i18n from 'i18n/i18n';

export type MaintenanceMessage = {
  title: string;
  description: string;
}

const DefaultMaintenanceMessage = {
  title: i18n.t('maintenance.default.title'),
  description: i18n.t('maintenance.default.description'),
};

const LineMaintenanceMessage = {
  title: i18n.t('maintenance.line.title'),
  description: i18n.t('maintenance.line.description'),
};

const GamesMaintenanceMessage = {
  title: i18n.t('maintenance.games.title'),
  description: i18n.t('maintenance.games.description'),
};

const WithdrawalsMaintenanceMessage = {
  title: i18n.t('maintenance.withdrawals.title'),
  description: i18n.t('maintenance.withdrawals.description'),
};

const NotAvailableInUA = {
  title: 'Сайт недоступний у вашому регіоні',
  description: 'Сайт недоступний у вашому регіоні'
};

export const Maintenance = {
  default: DefaultMaintenanceMessage,
  line: LineMaintenanceMessage,
  games: GamesMaintenanceMessage,
  withdrawals: WithdrawalsMaintenanceMessage,
  notAvailableInUA: NotAvailableInUA,
};
