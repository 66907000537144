import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CancelIcon } from 'assets/bets/defeat.svg';
import { getCurrencyTitleWithoutAbbreviation } from 'utils/currency';
import { getBalanceDiffType, getWithdrawalStatusFilter } from 'store/user/utils';
import Loader from 'components/shared/loader/Loader';
import { useCancelWithdrawalMutation } from 'store/user/userApi';
import { getDateTimeSlashFormat } from 'utils/time';
import { bemCn } from 'utils/bem-cn';

import type { Currency } from 'types/wallets-data';
import type { Withdrawal } from 'types/user-data';
import './WithdrawalItem.scss';

type Props = {
  primaryCurrency?: Currency | null;
  comment?: string | null;
  withdrawal: Withdrawal;
};

const WithdrawalItem = (props: Props) => {
  const {
    primaryCurrency,
    comment,
    withdrawal,
  } = props;

  const {
    id,
    created,
    currency,
    type,
    amount,
    status
  } = withdrawal;
  const { t } = useTranslation();
  const [
    cancelWithdrawal,
    { isLoading: isCancelLoading }
  ] = useCancelWithdrawalMutation();

  const diffType = getBalanceDiffType(type);

  const isCreated = status === 'created';
  const isCanceled = status === 'canceled';
  const isApproved = status === 'approved';

  const handleCancelClick = () => {
    cancelWithdrawal(id);
  };

  const b = bemCn('withdrawal-transaction-item');

  return (
    <div className={b()}>
      <div className={b('row')}>
        <div className={b('title-wrapper')}>
          <p className={b('title')}>{getWithdrawalStatusFilter(status)}</p>
          {isApproved && (
            <p className={b('status', { [`${status}`]: true })}>
              {`(${getWithdrawalStatusFilter(status)})`}
            </p>
          )}
        </div>
        <p>{getDateTimeSlashFormat(created)}</p>
      </div>
      <div className={b('row')}>
        <p>
          {t('transactions.account', 'Счет')}: {getCurrencyTitleWithoutAbbreviation(currency)}
        </p>
        <p className={b('amount', { [`${diffType}`]: true })} >
          {diffType === 'increase' && '+'}
          {diffType === 'decrease' && '-'}
          {`${amount} ${currency}`}
        </p>
      </div>
      <div className={b('row')}>
        {comment && (
          <p>
            {t('transactions.withdrawal-comment', 'Комментарий')}: <span>{comment}</span>
          </p>
        )}
      </div>
      {isCreated && (
        <div className={b('row')}>
          <p className={b('status', { [`${status}`]: true })} >
            {getWithdrawalStatusFilter(status)}
          </p>
          {isCreated && (
            <div className={b('cancel-wrapper')}
              onClick={handleCancelClick}
            >
              <p className={b('cancel')}>
                {t('common.to-cancel', 'Отменить')}
              </p>
              {isCancelLoading
                ? <Loader />
                : <CancelIcon className={b('cancel-icon')} />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WithdrawalItem;
