import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'hooks';
import { logout } from 'store/auth/authSlice';
import { selectAccountData, selectAdditionalWallets, selectPrimaryWallet } from 'store/user/selectors';
import { useGetWalletsQuery } from 'store/user/userApi';
import { whatsappLink } from 'utils/constants';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import I18n from 'i18n/i18n';
import { useLogoutMutation } from 'store/auth/authApi';
import { bemCn } from 'utils/bem-cn';

import PersonalInfo from '../components/PersonalInfo/PersonalInfo';
import AdditionalWallets from '../components/AdditionalWallets/AdditionalWallets';
import ProfileLink, { ProfileLinkType } from './ProfileLink/ProfileLink';
import ProfileSectionLinks from './ProfileSectionLinks/ProfileSectionLinks';
import MainWalletInfoMobile from './MainWalletInfoMobile/MainWalletInfoMobile';
import LinkContainer from './ProfileLink/LinkContainer';
import LinkIcon from './ProfileLink/LinkIcon';

import type { KeyboardEvent } from 'react';

import './Profile.scss';

const getProfileSections = (): ProfileLinkType[] => [
  {
    name: 'settings',
    title: I18n.t('profile.nav.settings', 'Настройки'),
    description: `${I18n.t('profile.nav.settings-description', 'Возможность скрыть баланс и отредактировать личные данные')}`,
    disabled: false,
    link: 'settings'
  },
  {
    name: 'favorite',
    title: I18n.t('common.favorite'),
    link: '/profile/favorite-list',
    description: `${I18n.t('profile.nav.favorite-description', 'Список избранных турниров и матчей')}`
  },
  {
    name: 'bet-history',
    title: I18n.t('profile.nav.bet-history', 'История ставок'),
    description: `${I18n.t('profile.nav.bet-history-description', 'История всех ставок')}`,
    disabled: false,
    link: '/bet-history',
  },
  {
    name: 'transactions',
    title: I18n.t('profile.nav.transactions', 'Детализация'),
    description: `${I18n.t('profile.nav.transactions-description', 'Все операции, что повлияли на изменение баланса')}`,
    disabled: false,
    link: 'transactions',
  },
  {
    name: 'promo-codes',
    title: I18n.t('profile.nav.promo-codes', 'Промокоды'),
    description: `${I18n.t('profile.nav.promo-codes-description', 'Активация промокода и история активации промокодов')}`,
    link: 'promo-codes',
  },
  {
    name: 'withdrawals',
    title: I18n.t('profile.nav.withdrawals', 'Вывод'),
    description: `${I18n.t('profile.nav.withdrawals-description', 'При выборе определенного метода клиент вводит сумму и реквизиты для вывода')}`,
    disabled: false,
    link: 'withdrawals',
  },
  {
    name: 'support',
    title: I18n.t('profile.nav.support', 'Служба поддержки'),
    description: `${I18n.t('profile.nav.support-description', 'Если у вас возникли вопросы, мы обязательно вам поможем')}`,
    disabled: false,
    href: whatsappLink
  },
  // {
  //   name: 'documents',
  //   title: I18n.t('profile.nav.documents', 'Мои документы'),
  //   description: `${I18n.t('profile.nav.documents-description', 'На этой странице клиент может подгрузить документы')}`,
  //   link: 'documents',
  // },
];

const Profile = () => {
  const accountData = useAppSelector(selectAccountData);
  const primaryWallet = useAppSelector(selectPrimaryWallet);
  const additionalWallets = useAppSelector(selectAdditionalWallets);
  const { isLoading: isWalletsLoading } = useGetWalletsQuery();
  const { t } = useTranslation();
  const [serverLogout] = useLogoutMutation();

  const { name, id, role } = accountData;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isUserPartner = role === 'manager' || role === 'partner';

  const handleLogoutClick = () => {
    serverLogout()
      .then(() => {
        dispatch(logout());
      })
      .catch((err) => console.log('<ProfileLogoutError>:', err));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      dispatch(logout());
    }
  };

  const b = bemCn('profile');

  return (
    <main className={b()}>
      <header className={b('header')}>
        <h1 className={b('title')}>
          {t('main.nav.Profile', 'Личный кабинет')}
        </h1>
        <div
          className={b('log-out')}
          role="button"
          tabIndex={0}
          onClick={handleLogoutClick}
          onKeyDown={handleKeyDown}
        >
          <p className={b('log-out-title')}>{t('user-nav.logout', 'Выйти')}</p>
          <SvgIcon className={b('log-out-icon')} name="exit" width={12} height={12} />
        </div>
      </header>

      <div className={b('section')}>
        <div className={b('account-info')}>
          <PersonalInfo
            name={name}
            userId={id.toString()}
          />
          <hr className={b('separator')} />
          <MainWalletInfoMobile
            wallet={primaryWallet}
            isLoading={isWalletsLoading}
          />
          {/* <hr className={b('separator')} />
          <div className={b('additional-wallets')}>
            <AdditionalWallets variant="short" wallets={additionalWallets} />
          </div>
          <ProfileLink
            name='wallets-manage'
            title={t('profile.nav.wallets-manage', 'Управление счетами')}
            link='/profile/wallet-manage'
            simple
          /> */}
        </div>
      </div>

      <hr className={b('separator')} />

      <div className={b('sections-links')}>
        <ProfileSectionLinks items={getProfileSections()} />
      </div>

      <hr className={b('separator')} />

      {isUserPartner && (
        <LinkContainer className={b('section')}
          link={'/partner-program'}
        >
          <div className={'profile-link__wrapper'}>
            <div className={'profile-link__icon'}><LinkIcon name={'promo-codes'} /></div>
            <p className={'profile-link__title'}>
              {t('profile.nav.partner-program', 'Партнёрская программа')}
            </p>
            <SvgIcon className={'profile-link__arrow'} name='chevron-right' width={8} height={8} />
          </div>
          <p className={'profile-link__description'}>
            {t('partner-program.profile-enter', 'Вход в личный кабинет')}
          </p>
        </LinkContainer>
      )}
    </main>
  );
};

export default Profile;
