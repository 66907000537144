import { BasketItem, BasketItemRes, TBasket, TBasketByGameId } from 'types/basket-data';


export const adapteBasketItems = (res: BasketItemRes[]): BasketItem[] =>
  res.map((bet) =>({
    dataType: bet.data_type,
    basketId: bet.basket_id,
    gameId: `${bet.game_id}`,
    gameMid: `${bet.game_mid}`,
    eventDate: bet.event_date,
    team1: bet.opp_1_name,
    team2: bet.opp_2_name,
    subGameName: bet.sub_game_name,
    subGameNameRu: bet.sub_game_name_ru,
    tournament: bet.tournament_name,
    groupName: bet.group_name,
    outcomeName: bet.outcome_name,
    sportName: bet.sport_name,
    sportNameRu: bet.sport_name_ru,
    rate: bet.rate,
    available: bet.available,
  }));

export const reduceBasketByGameId = (items: BasketItem[]): TBasketByGameId =>
  items.reduce<TBasketByGameId>((acc, item) => {
    acc[item.gameId] = item;
    return acc;
  }, {});

export const adapteBasket = (res: BasketItemRes[] = []): TBasket => {
  const items = adapteBasketItems(res);

  return {
    count: items.length,
    items: items,
    itemsByGameId: reduceBasketByGameId(items),
  };
};
