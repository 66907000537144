import React from 'react';

import i18n from 'i18n/i18n';
import ResultModal from 'components/shared/ResultModal/ResultModal';

type ModalType = 'confirmation' | 'success' | 'error' | 'reset-error' | 'reset-success' | 'success-send-reset-link';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  type: ModalType;
  error?: string | null;
};

// TODO: Вынести Messages в константы
const Messages: Record<ModalType, { title: string; description: string }> = {
  confirmation: {
    title: i18n.t('result-modal.confirmation.title', 'Подтверждение'),
    description: i18n.t('result-modal.confirmation.description', 'Мы направили вам на почту письмо с подтверждением'),
  },
  success: {
    title: i18n.t('result-modal.confirmation.success.title', 'Поздравляем!'),
    description: i18n.t('result-modal.confirmation.success.description', 'Вы успешно зарегистрированы на нашем сайте'),
  },
  error: {
    title: i18n.t('result-modal.confirmation.error.title', 'Ошибка верификации'),
    description: i18n.t('result-modal.confirmation.error.description', 'Обратитесь в службу поддержки'),
  },
  'reset-success': {
    title: i18n.t('result-modal.confirmation.reset-success.title', 'Пароль успешно изменен'),
    description: i18n.t('result-modal.confirmation.reset-success.description', 'Можете войти в аккаунт'),
  },
  'reset-error': {
    title: i18n.t('result-modal.confirmation.reset-error.title', 'Ошибка смены пароля'),
    description: i18n.t('result-modal.confirmation.reset-error.description', 'Обратитесь в службу поддержки'),
  },
  'success-send-reset-link': {
    title: i18n.t('result-modal.confirmation.success-send-reset-link.title', 'Ссылка на восстановление пароля отправлена'),
    description: i18n.t('result-modal.confirmation.success-send-reset-link.description', 'Проверьте вашу электронную почту'),
  }
};

const Confirmation = ({ isOpen, closeModal, type, error }: Props) => {
  const isSuccess = !['error', 'reset-error'].includes(type);
  const { title, description } = Messages[type];

  return (
    <ResultModal
      title={title}
      description={description}
      isOpen={isOpen}
      onClose={closeModal}
      contentLabel="Sign up AuthModal"
      error={error}
      status={isSuccess ? 'success' : 'error'}
    />
  );
};

export default Confirmation;
