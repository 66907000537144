import ReactModal from 'react-modal';
import { useDebounce } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';

import CloseButton from '../CloseButton/CloseButton';

import './AppModal.scss';


const b = bemCn('app-modal');

type AppModalProps = {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  contentLabel?: string;
  id?: string;
  closeButton?: React.ReactNode;
}

const AppModal = (props: AppModalProps) => {
  const {
    className,
    children,
    id,
    isOpen,
    onClose,
    contentLabel,
    closeButton,
  } = props;

  const debouncedIsOpen = useDebounce(isOpen, 300);

  return (
    <ReactModal
      portalClassName={b()}
      className={b('content', className)}
      overlayClassName={b('overlay', { 'fade-in': isOpen, 'fade-out': !isOpen })}

      isOpen={isOpen || debouncedIsOpen}
      onRequestClose={onClose}

      appElement={document.getElementById('root') || undefined}
      parentSelector={() => document.getElementById('app') ?? document.body}
      contentLabel={contentLabel}
      id={id}
    >
      {closeButton ? closeButton : (
        <CloseButton className={b('close-button')} onClick={onClose} />
      )}
      {children}
    </ReactModal>
  );
};

export default AppModal;
