import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { currencies as availableCurrencies } from 'utils/constants';
import AppModal from 'components/shared/AppModal/AppModal';

import PhoneTab from './components/PhoneTab/PhoneTab';

import './SignUp.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TabsIds = {
  email: 1,
  telegram: 2,
  oneClick: 3,
  phone: 4,
} as const;

const userCurrency = availableCurrencies[0];
const SignUp = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const b = bemCn('registration-modal');
  return (
    <AppModal onClose={onClose} isOpen={isOpen} className={b()}>
      <div className={b('header')}>
        <h1 className={b('header-title')}>
          {t('reg.modal.title', 'Регистрация')}
        </h1>
      </div>
      <PhoneTab
        id={TabsIds.phone}
        userCurrency={userCurrency}
        availableCurrencies={availableCurrencies}
      />
    </AppModal>
  );
};

export default SignUp;
