import { getUserTimezone } from './localStorage';

export const countDownTimer = (date: string) => {
  const end = new Date(date);

  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;

  const now = new Date();
  const distance = end.getTime() - now.getTime();

  const days = Math.floor(distance / _day);
  const hours = Math.floor((distance % _day) / _hour);
  const minutes = Math.floor((distance % _hour) / _minute);
  const seconds = Math.floor((distance % _minute) / _second);
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

interface IDateTime {
  date: Date;
  startOf: (period?: string) => DateTime;
  endOf: (period?: string) => DateTime;
  utcOffset: (offset: number) => DateTime;
  toISOString: () => string;
}

export class DateTime implements IDateTime {
  date: Date;

  constructor(date?: string) {
    this.date = date ? new Date(date) : new Date();
  }

  startOf(period?: string) {
    this.date.setHours(0);
    return this;
  }

  endOf(period?: string) {
    this.date.setHours(23)
    this.date.setMinutes(59)
    this.date.setSeconds(59)
    this.date.setMilliseconds(999);
    return this;
  }

  utcOffset(offset: number) {
    this.date.setHours(this.date.getHours() - offset);
    return this;
  }

  toISOString() {
    return this.date.toISOString();
  }
}

const formatTimezoneDate = (date: Date, timezoneOffset: number) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
  newDate.setHours(newDate.getHours() + timezoneOffset);

  return newDate;
};

// Предыдущий вариант преобразования даты и времени
// export const toLocaleDateString = (date: Date, options?: Intl.DateTimeFormatOptions, lang?: string) => (
//   date.toLocaleDateString(lang ?? 'ru-RU', { timeZone: 'Europe/Moscow', ...options })
// );

// export const toLocaleTimeString = (date: Date, options?: Intl.DateTimeFormatOptions, lang?: string) => (
//   date.toLocaleTimeString(lang ?? 'ru-RU', { timeZone: 'Europe/Moscow', ...options })
// );

export const toLocaleDateString = (date: Date, options?: Intl.DateTimeFormatOptions, lang?: string) => {
  const timezoneOffset = getUserTimezone();
  const formattedDate = formatTimezoneDate(date, timezoneOffset).toLocaleDateString(lang ?? 'ru-RU', options);

  return formattedDate;
};

export const toLocaleTimeString = (date: Date, options?: Intl.DateTimeFormatOptions, lang?: string) => {
  const timezoneOffset = getUserTimezone();
  const formattedTime = formatTimezoneDate(date, timezoneOffset).toLocaleTimeString(lang ?? 'ru-RU', options);

  return formattedTime;
};


export const getDateTimeCommaFormat = (dateTimeString: string, options?: Intl.DateTimeFormatOptions, lang?: string) => {
  const dateTime = new Date(dateTimeString);
  const createdDate = toLocaleDateString(
    dateTime,
    { day: 'numeric', month: 'long', ...options },
    lang
  );
  const createdTime = toLocaleTimeString(
    dateTime,
    { hour: '2-digit', minute: '2-digit', ...options },
    lang
  );
  return `${createdDate}, ${createdTime}`;
};

export const getDateTimeSlashFormat = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);
  const createdDate = toLocaleDateString(dateTime);
  const createdTime = toLocaleTimeString(dateTime, { hour: '2-digit', minute: '2-digit' });
  return `${createdDate} / ${createdTime}`;
};

export const getDateCommaFormat = (dateTimeString: string, options?: Intl.DateTimeFormatOptions, lang?: string) => {
  const dateTime = new Date(dateTimeString);
  const createdDate = toLocaleDateString(dateTime);
  return `${createdDate}`;
};

export const monthList = ['Янв', 'Фев', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
