import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import { useManagerPartnerStats } from '../hooks/useManagerPartnerStats';
import PPFilter from '../../components/PPFilter/PPFilter';
import PPStats from '../../components/PPStats/PPStats';

import '../PPManager.scss';

type Props = {
  partnerId: number;
};

const PPManagerPartnerStats = (props: Props) => {
  const { partnerId } = props;
  const b = bemCn('pp-manager');

  const {
    statsList,
    isFetching,
    isError,
    handleFilterChange,
    handleFilterReset,
  } = useManagerPartnerStats(partnerId);

  return (
    <>
      <PPFilter
        onSubmit={handleFilterChange}
        onReset={handleFilterReset}
        light
      />

      <PPStats
        // title={`Статистика партнёра с ID ${partnerId}`}
        items={statsList}
        isLoading={isFetching}
        isError={isError}
      />
    </>
  );
};

export default PPManagerPartnerStats;
