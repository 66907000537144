import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import * as React from 'react';

import CustomCheckbox from '../inputs/CustomCheckbox/CustomCheckbox';

type Props<TFormValues extends FieldValues> = {
  showError?: boolean;
  disabled?: boolean;
  inverted?: boolean;
  children?: React.ReactNode;
  className?: string;
} & UseControllerProps<TFormValues>;

const FormCheckbox = <TFormValues extends Record<string, unknown>>({
  showError,
  disabled,
  children,
  inverted,
  className,
  ...props
}: Props<TFormValues>) => {
  const { field, fieldState } = useController(props);
  const { value } = field;

  return (
    <CustomCheckbox
      {...field}
      className={className}
      errorMessage={fieldState.error?.message}
      inverted={inverted}
      checked={value as boolean}
      isError={showError && fieldState.isTouched && !!fieldState.error}
      disabled={disabled}
    >
      {children}
    </CustomCheckbox>
  );
};

export default FormCheckbox;
