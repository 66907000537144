import type { Currency } from 'types/wallets-data';

export const suggestValues: Record<Currency, number[]> = {
  RUB: [1000, 5000, 10000, 25000],
  USD: [15, 30, 50, 100],
  KZT: [5000, 1000, 25000, 5000],
  UAH: [500, 1000, 5000, 1000],
  EUR: [1000, 5000, 10000, 25000],
  TJS: [100, 500, 1000, 2500],
};
