import { useCounter } from 'usehooks-ts';
import { useEffect, useState } from 'react';

import { bemCn } from 'utils/bem-cn';
import './EventCardTimer.scss';

type Props = {
  timer: number;
  className?: string;
  stoped?: boolean;
}

const EventCardTimer = (props: Props) => {
  const {
    count,
    setCount,
    increment,
  } = useCounter(0);
  const [, setLastTimer] = useState(0);

  useEffect(() => {
    let changerTimer: NodeJS.Timer;
    setLastTimer((prevLastTimer) => {
      if (prevLastTimer !== props.timer) {
        if (!props.stoped) {
          changerTimer = setInterval(increment, 1000);
        } else {
          clearInterval(changerTimer);
        }
        setCount(props.timer);
      }
      return props.timer;
    });

    return () => clearInterval(changerTimer);
  }, [props.timer, props.stoped]);

  const second = count % 60;
  const minute = Math.floor(count / 60);

  const b = bemCn('event-card-timer');

  return (
    <p className={b(null, props.className)}>
      <span className={b('value')}>
        {minute >= 10 ? minute : `0${minute}`}
      </span>
      :
      <span className={b('value')}>
        {second >= 10 ? second : `0${second}`}
      </span>
    </p>
  );
};

export default EventCardTimer;
