import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


import { useLocalizedBanners } from 'hooks/useLocalizedBanners';
import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import ImgSmart from 'components/shared/ImgSmart/ImgSmart';
import './PromoDesktop.scss';

type BannerImageWrapperProps = {
  link?: string;
  href?: string;
  children?: React.ReactNode;
  className?: string;
}

const BannerImageWrapper = ({ link, href, children, className }: BannerImageWrapperProps) => {
  if (link) {
    return (
      <Link to={link} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} className={className} target='_blank' rel="noreferrer">
      {children}
    </a>
  );
};

const PromoDesktop = () => {
  const { isLoading, bannersDesktop } = useLocalizedBanners();
  const { big, small } = bannersDesktop;

  const b = bemCn('promo-desktop');
  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('big')}>
          {isLoading
            ? <Loader className={b('loader')} />
            : (
              <Swiper className={b('swiper')}
                pagination
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                speed={2000}
                modules={[Pagination, Autoplay]}
                loop={big.length > 1}
              >
                {big.map((banner) => (
                  <SwiperSlide className={b('banner')} key={banner.img} >
                    <BannerImageWrapper link={banner.link} href={banner.href}>
                      <ImgSmart className={b('image')}
                        src={banner.img}
                        alt=""
                      />
                    </BannerImageWrapper>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
        </div>
        <div className={b('small', { top: true }, b('desktop__banner'))}>
          {isLoading
            ? <Loader className={b('loader')} />
            : (
              <BannerImageWrapper link={small[0].link} href={small[0].href}>
                <ImgSmart className={b('image')}
                  src={small[0].img}
                  alt=""
                />
              </BannerImageWrapper>
            )}
        </div>
        <div className={b('small', { bottom: true })}>
          {isLoading
            ? <Loader className={b('loader')} />
            : (
              <BannerImageWrapper link={small[1].link} href={small[1].href}>
                <ImgSmart className={b('image')}
                  src={small[1].img}
                  alt=""
                />
              </BannerImageWrapper>
            )}
        </div>
      </div>
    </div>
  );
};

export default PromoDesktop;
