import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import SectionIcon from 'components/shared/SectionIcon/SectionIcon';
import { bemCn } from 'utils/bem-cn';
import { useAppSelector, useAppDispatch } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { changeActiveModal } from 'store/auth/authSlice';

import type { SiteSection } from 'types/siteSections';

import './TabBarItem.scss';

type Props = {
  item: {
    id: SiteSection;
    link: string;
    title: string;
  };
  isActive: boolean;
};

const b = bemCn('tab-bar-item');
const TabBarItem = (props: Props) => {
  const {
    isActive,
    item,
  } = props;

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (item.id === 'profile' && !isAuthenticated) {
      event.preventDefault();
      dispatch(changeActiveModal('sign-in'));
      // return;
    }
  };

  return (
    <Link
      className={b()}
      to={item.link}
      key={item.id}
      onClick={onClick}
    >
      <SectionIcon
        className={b('section-icon')}
        id={item.id}
        isBackgroundShowing={isActive}
        variant="rounded-shadow"
      />
      <p className={b('title', { 'active': isActive })}>
        {item.title}
      </p>
    </Link >
  );
};

export default React.memo(TabBarItem);
