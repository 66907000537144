import { bemCn } from 'utils/bem-cn';
import EventCardDesktop from 'components/line/EventCard/EventCardDesktop/EventCardDesktop';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { TournamenWithEvents, getSportIconName, sortByTopEntries } from 'utils/common';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';

import './TournamentCardDesktop.scss';

type Props = {
  tournament: TournamenWithEvents;
};

const b = bemCn('tournament-card-desktop');
const TournamentCardDesktop = ({ tournament }: Props) => {
  const sportId = tournament.events.find((event) => event.sportId.length > 0)?.sportId;
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const favoriteEventsIds = favoriteEvents.map((event) => event.matchId);

  const { basketByGameId } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketByGameId: data?.itemsByGameId
    })
  });

  const sortedEvents = sortByTopEntries(tournament.events, favoriteEventsIds, ({ id }) => id);

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h2 className={b('title')}>
          <SvgIcon className={b('icon')}
            name={getSportIconName(sportId ?? '')}
            width={20}
            height={20}
          />
          {tournament.name}
        </h2>
      </div>
      <div className={b('events')}>
        {sortedEvents.map((event) => {
          const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id;
          return (
            <EventCardDesktop
              className={b('event')}
              key={event.id}
              event={event}
              basketItem={basketByGameId?.[event.id]?.basketId}
              isFavorite={favoriteId !== undefined}
              favoriteId={favoriteId ?? 'notFavoried'}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TournamentCardDesktop;
