import type {
  GamesList,
  GamesListResponse,
  VegasGameServer,
  VegasGames,
  MascotGameServer,
  MascotGames,
  AgtGame,
  AgtGames,
  AgtGameServer,
} from 'types/slots-data';
import type { Entries } from 'types/common';

export const transformGamesList = (response: GamesListResponse): GamesList => {
  const gamesList = Object.entries(response) as Entries<GamesListResponse>;

  return gamesList.filter(([, value]) => value.mobile === 1)
    .map(([key, value]) => ({
      id: key,
      name: value.name,
    }));
};


export const AdapterSlots = {
  vegasGames: (gamesList: VegasGameServer[]): VegasGames => {
    const types = new Set<string>();
    const sections = new Set<string>();

    const items = gamesList.filter((item) => item.section_id !== 'aviatrix').map((game) => {
      types.add(game.type);
      sections.add(game.section_id);

      return {
        id: game.id,
        name: game.name,
        // preview: `https://vegas-club.win/lobbyGamePicImg/default/${game.id}.jpg`,
        preview: `${process.env.PUBLIC_URL}/vegas-club-preview/${game.id}.jpg`,
        sectionId: game.section_id,
        type: game.type,
        tags: game.tags,
        label: game.section_id,
        link: `/games/vegas/${game.id}`,
      };
    });

    return {
      types: Array.from(types),
      sections: Array.from(sections).filter((item) => item !== 'pragmatic').sort(),
      items: items.filter((item) => item.sectionId !== 'pragmatic'),
    };
  },

  mascotGames: (gamesList: MascotGameServer[]): MascotGames => {
    const types = new Set<string>();
    const sections = new Set<string>();

    const items = gamesList.map((game) => {
      types.add(game.type);
      sections.add(game.section_id);

      return {
        id: game.id,
        name: game.name,
        preview: `${process.env.PUBLIC_URL}/mascot-preview/${game.id}.png`,
        sectionId: game.section_id,
        type: game.type,
        tags: game.tags,
        label: game.section_id,
        link: `/games/mascot/${game.id}`,
      };
    });

    return {
      types: Array.from(types),
      sections: Array.from(sections).sort(),
      items,
    };
  },

  agtGames: (gamesList: AgtGameServer[]): AgtGames => {
    const types = new Set<string>();
    const categories = new Set<string>();

    const items: AgtGame[] = gamesList.map((game) => {
      types.add(game.game_type);
      categories.add(game.category);
      // Реализовать получения ссылки на превью-картинку
      const previewUrl = game.lobby[0][0].src;

      return {
        name: game.visible_name,
        visibleName: game.visible_name,
        gameId: game.game_id,
        category: game.category,
        gameType: game.game_type,
        mobile: game.mobile,
        preview: previewUrl,
        link: `/games/agt/${game.game_id}`,
      };
    });

    return {
      types: Array.from(types),
      categories: Array.from(categories).sort(),
      items,
    };
  },

};
