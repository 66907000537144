
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/buttons/Button/Button';
import { getCurrencySymbol } from 'utils/currency';
import { useAppDispatch } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import Loader from 'components/shared/loader/Loader';

import type { PrimaryWallet } from 'types/user-data';

import './MainWalletInfoMobile.scss';

type Props = {
  wallet: PrimaryWallet;
  isLoading?: boolean;
}

const MainWalletInfoMobile = ({ wallet, isLoading }: Props) => {
  const {
    currency,
    amount,
    bonusAmount,
    cashbackLine,
    cashbackCasino
  } = wallet;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleTransferClick = () =>
    dispatch(changeActiveModal('transfer-to-user'));

  const roundedBalance = Math.floor((amount * 100)) / 100;
  const roundedBonusBalance = Math.floor((bonusAmount * 100)) / 100;

  const b = bemCn('main-wallet-info-mobile');
  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('balances')}>

          <div className={b('balance', { main: true })}>
            <p className={b('balance-label')}>
              {t('wallets.main-wallet', 'Основной счет')}
            </p>
            <p className={b('balance-amount')}>
              {isLoading
                ? <Loader />
                : `${roundedBalance} ${getCurrencySymbol(currency)}`}
            </p>
          </div>
          <div className={b('balance', { bonus: true })}>
            <p className={b('balance-label')}>
              {t('wallets.bonus-wallet', 'Бонусный счет')}
            </p>
            <p className={b('balance-amount')}>
              {isLoading
                ? <Loader />
                : `${roundedBonusBalance} ${getCurrencySymbol(currency)}`}
            </p>
          </div>
        </div>
        <div className={b('controls')}>
          {/* <div className={b('controls-item')}>
            <Button variant="outline-secondary" onClick={handleTransferClick}>
              {t('wallets.transfer', 'Перевод')}
            </Button>
          </div> */}
          <Link className={b('controls-item')} to="/profile/withdrawals">
            <Button variant="outline-primary">
              {t('wallets.withdrawal', 'Вывод')}
            </Button>
          </Link>
          <Link className={b('controls-item')} to="/profile/topups" >
            <Button>
              {t('wallets.topup', 'Пополнить')}
            </Button>
          </Link>
        </div>
        <div className={b('cashbaсks')}>
          <div className={b('balance', { 'cashback-casino': true })}>
            <p className={b('balance-label')}>
              {t('wallets.casino-bonuses', 'Бонусы казино')}
            </p>
            <p className={b('balance-amount')}>
              {cashbackCasino} {getCurrencySymbol(currency)}
            </p>
          </div>
          <div className={b('balance', { 'cashback-sport': true })}>
            <p className={b('balance-label')}>
              {t('wallets.sport-bonuses', 'Бонусы спорт')}
            </p>
            <p className={b('balance-amount')}>
              {cashbackLine} {getCurrencySymbol(currency)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainWalletInfoMobile;
