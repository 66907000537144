import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { NameSpace } from 'utils/constants';

import type { RootState } from 'types/state';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState)[NameSpace.Auth];
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: NameSpace.BaseApi,
  baseQuery,
  tagTypes: ['Auth', 'Settings', 'User', 'Slots', 'Wallets', 'Transactions', 'BetsHistory', 'Basket', 'Phone', 'FavTour', 'FavMatch', 'PartnerProjects', 'PartnerLinks', 'PartnerPromocodes'],
  endpoints: () => ({}),
});
