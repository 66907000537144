import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useGetManagerPartnersQuery,
} from 'store/partnerProgram/managerApi';

import type { ManagerPartner, TTableProps, TDropdownItem } from 'store/partnerProgram/types';

const partnerActionList: TDropdownItem[] = [
  {
    key: 'links',
    title: 'Ссылки',
  },
  {
    key: 'promocodes',
    title: 'Промокоды',
  },
  {
    key: 'partner-stats',
    title: 'Статистика партнёра',
  },
  {
    key: 'partner-ref-stats',
    title: 'Статистика рефералов',
  },
];

export const useManagerPartners = () => {
  const navigate = useNavigate();
  const [selectedPartnerId, setSelectedPartnerId] = useState<number | null>(null);
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const {
    data: managerPartnersData,
    isFetching: isManagerPartnersFetching,
    isError: isManagerPartnerError,
  } = useGetManagerPartnersQuery({ page: currentPage, pageSize });

  const items = managerPartnersData?.items;
  const totalCount = managerPartnersData?.count ?? 0;
  const pageCount = Math.ceil(totalCount / pageSize);

  const pagination: TTableProps<ManagerPartner, keyof ManagerPartner>['pagination'] = {
    current: currentPage,
    total: pageCount,
    onChange: (value) => setCurrentPage(value),
  };

  const handlePartnerActionsClick = (id: number, key: string) => {
    setSelectedPartnerId(id);

    switch (key) {
      case 'links':
        setActiveModal('links');
        break;
      case 'promocodes':
        setActiveModal('promocodes');
        break;
      case 'partner-stats':
        setActiveModal('partner-stats');
        break;
      case 'partner-ref-stats':
        navigate(`${id}/players`);
        break;
      default:
    }
  };

  const onModalClose = () => setActiveModal(null);

  return {
    managerPartnersData,
    isManagerPartnersFetching,
    isManagerPartnerError,
    totalCount,
    items,
    pagination,
    selectedPartnerId,
    partnerActionList,
    activeModal,
    onModalClose,
    handlePartnerActionsClick,
  };
};
