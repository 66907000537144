import { bemCn } from 'utils/bem-cn';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import type { TMenuItem } from 'store/partnerProgram/types';

import './PPMenu.scss';

type Props = {
  menuItems: TMenuItem[];
};

const PPMenu = (props: Props) => {
  const {
    menuItems,
  } = props;

  const b = bemCn('pp-menu');
  const containerRef = useHorizontalScroll<HTMLDivElement>();

  return (
    <div className={b('wrapper')}>
      <div className={b()} ref={containerRef}>
        {menuItems.map((menuItem) => (
          <div className={b('item')} key={menuItem.key}>{menuItem.label}</div>
        ))}
      </div>
    </div>
  );
};

export default PPMenu;
