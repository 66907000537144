import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import PPRefLinks from 'pages/PartnerProgram/components/PPRefLinks/PPRefLinks';

import { useManagerStats } from '../hooks/useManagerStats';
import PPFilter from '../../components/PPFilter/PPFilter';
import PPStats from '../../components/PPStats/PPStats';

import '../PPManager.scss';

const PPManagerStats = () => {
  const b = bemCn('pp-manager');
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const {
    isManagerStatsLoading,
    isManagerStatsError,
    statsList,
    refLinkList,
    handleFilterChange,
    handleFilterReset,
  } = useManagerStats();

  return (
    <>
      <PPRefLinks
        title={`${t('your-ref-links', 'Ваши реферальные ссылки')}:`}
        links={refLinkList}
      />

      <PPFilter
        onSubmit={handleFilterChange}
        onReset={handleFilterReset}
      />

      <PPStats
        title={`${t('your-stats', 'Ваша статистика')}`}
        items={statsList}
        isLoading={isManagerStatsLoading}
        isError={isManagerStatsError}
      />
    </>
  );
};

export default PPManagerStats;
