import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

import { bemCn } from 'utils/bem-cn';
import { useGetBannerMessagesQuery } from 'store/app/appApi';

import './BannerMessages.scss';

const BannerMessages = () => {
  const { data: bannerMessagesList = [] } = useGetBannerMessagesQuery();
  const b = bemCn('banner-messages');

  return (
    <div className={b()}>
      <Swiper className={b('swiper')}
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={2000}
        modules={[Pagination, Autoplay]}
        loop={bannerMessagesList.length > 1}
      >
        {bannerMessagesList.map((banner) => (
          <SwiperSlide className={b('banner')} key={banner.id} >
            <span>{banner.text}</span>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerMessages;
