import i18n from 'i18n/i18n';

import { initialChangeUserPasswordErrors } from './userSlice';

import type { BackendError } from 'store/auth/utils';
import type { WithdrawalStatus , TransacitonType, ChangeUserPasswordErrors } from 'types/user-data';

export const transactionTitles: Record<TransacitonType, string> = {
  'bet_placement': i18n.t('transactions.type.bet-placement','Постановка ставки'),
  'bet_placement_error': i18n.t('transactions.type.bet-placement-error','Ошибка постановки ставки'),
  'bet_cancel': i18n.t('transactions.type.bet-cancel', 'Отмена ставки'),
  'bet_recalc': i18n.t('transactions.type.bet-recalc', 'Перерасчёт ставки'),
  'bet_refund': i18n.t('transactions.type.bet-refund','Возврат ставки'),
  'bet_win': i18n.t('transactions.type.bet-win','Выигрыш по ставке'),
  'admin_refill': i18n.t('transactions.type.admin-refill','Начисление администратором'),
  'admin_withdrawal': i18n.t('transactions.type.admin-withdrawal','Списание администратором'),
  'user_deposit': i18n.t('transactions.type.user-deposit','Пополнение'),
  'user_withdrawal': i18n.t('transactions.type.user-withdrawal','Вывод'),
  'withdrawal_cancel': i18n.t('transactions.type.withdrawal-cancel','Отмена вывода'),
  'user_user_transfer': i18n.t('transactions.type.user-user-transfer','Перевод'),
  'user_self_transfer': i18n.t('transactions.type.user-self-transfer','Перевод между счетами'),
  'slots_bet': i18n.t('transactions.type.slots-bet','Постановка ставки (слоты)'),
  'slots_win': i18n.t('transactions.type.slots-win','Выигрыш по ставке (слоты)'),
  'slots_refund': i18n.t('transactions.type.slots-refund', 'Возврат ставки (слоты)'),
  'betgames_payin': i18n.t('transactions.type.betgames-payin', 'Постановка ставки (Betgames)'),
  'betgames_payout': i18n.t('transactions.type.betgames-payout', 'Выигрыш по ставке (Betgames)'),
  'betgames_combo_payin': i18n.t('transactions.type.betgames-combo-payin', 'Постановка комбинированой ставки (Betgames)'),
  'betgames_combo_payout': i18n.t('transactions.type.betgames-combo-payout', 'Выигрыш по комбинированой ставке (Betgames)'),
  'betgames_promo_payout': i18n.t('transactions.type.betgames-promo-payout', 'Выигрыш по промо ставке (Betgames)'),
  'casino_bet': i18n.t('transactions.type.casino_bet', 'Постановка ставки (казино)'),
  'casino_win': i18n.t('transactions.type.casino_win', 'Выигрыш по ставке (Казино)'),
  'line_cashback_conversion': i18n.t('transactions.type.line_cashback_conversion', 'Начисление кэшбэка на основной счёт (спорт)'),
  'line_cashback_accrual': i18n.t('transactions.type.line_cashback_accrual', 'Начисление кэшбэка на бонусный счёт (спорт)'),
  'casino_cashback_conversion': i18n.t('transactions.type.casino_cashback_conversion', 'Начисление кэшбэка на основной счёт (казино)'),
  'casino_cashback_accrual': i18n.t('transactions.type.casino_cashback_accrual', 'Начисление кэшбэка на бонусный счёт (казино)'),
  'bet_placement_bonus': i18n.t('transactions.type.bet_placement_bonus', 'Постановка ставки (бонусный)'),
  'bet_recalc_bonus': i18n.t('transactions.type.bet_recalc_bonus', 'Перерасчёт ставки (бонусный)'),
  'bet_refund_bonus': i18n.t('transactions.type.bet_refund_bonus', 'Возврат ставки (бонусный)'),
  'bet_win_bonus': i18n.t('transactions.type.bet_win_bonus', 'Выигрыш по ставке (бонусный)'),
  'bet_placement_error_bonus': i18n.t('transactions.type.bet_placement_error_bonus', 'Ошибка постановки ставки (бонусный)'),
  'admin_refill_bonus': i18n.t('transactions.type.admin_refill_bonus', 'Начисление администратором (бонусный)'),
  'admin_withdrawal_bonus': i18n.t('transactions.type.admin_withdrawal_bonus', 'Списание администратором (бонусный)'),
};

export const getTransactionTitle = (transactionType: TransacitonType) => transactionTitles[transactionType] ?? transactionType;

export const withdrawalStatus: Record<WithdrawalStatus, string> = {
  created:  i18n.t('transactions.withdrawal-status.created','Создан'),
  approved: i18n.t('transactions.withdrawal-status.approved','Одобрен'),
  canceled: i18n.t('transactions.withdrawal-status.canceled','Отменен'),
};

export const getWithdrawalStatusFilter = (status: WithdrawalStatus | undefined) => (status && withdrawalStatus?.[status]) ?? withdrawalStatus.created;

export const balaceDiffType: Record<string, TransacitonType[]> = {
  increase: [
    'withdrawal_cancel',
    'admin_refill',
    'admin_refill_bonus',
    'user_deposit',
    'bet_placement_error',
    'bet_placement_error_bonus',
    'bet_refund',
    'bet_refund_bonus',
    'bet_win',
    'bet_win_bonus',
    'slots_win',
    'slots_refund',
    'betgames_payout',
    'betgames_combo_payout',
    'betgames_promo_payout',
    'casino_win',
    'line_cashback_conversion',
    'line_cashback_accrual',
    'casino_cashback_conversion',
    'casino_cashback_accrual',
  ],
  decrease: [
    'user_withdrawal',
    'admin_withdrawal',
    'admin_withdrawal_bonus',
    'bet_placement',
    'bet_placement_bonus',
    'bet_cancel',
    'bet_recalc',
    'bet_recalc_bonus',
    'slots_bet',
    'betgames_payin',
    'betgames_combo_payin',
    'casino_bet'
  ],
  same: [
    'user_self_transfer',
    'user_user_transfer',
  ]
};

export const getBalanceDiffType = (type: TransacitonType) => {
  let result = 'same';
  Object.keys(balaceDiffType).forEach((key) => {
    if (balaceDiffType[key].includes(type)) {
      result = key;
    }
  });
  return result;
};

export const handleChangeUserPasswordErrors = (status: number, data: BackendError): ChangeUserPasswordErrors => {
  const { detail } = data;
  const errors: ChangeUserPasswordErrors = { ...initialChangeUserPasswordErrors };

  if (typeof detail === 'string') {
    if (detail === 'Invalid current password' || detail === 'Password incorrect') {
      errors.oldPassword = i18n.t('backend-errors.change-pass.password-incorrect', 'Неверный пароль');
    } else if (detail === 'The new password must be different') {
      errors.newPassword = i18n.t('backend-errors.change-pass.the-new-password-must-be-different', 'Новый пороль должен отличаться');
    } else if (detail === 'New password is too short') {
      errors.newPassword = i18n.t('backend-errors.change-pass.new-password-is-too-short', 'Новый слишком короткий');
    } else {
      errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      if (!err.msg) { return; }
      const message = err.msg;

      if ((Object.keys(errors)).includes(location)) {
        errors[location as keyof ChangeUserPasswordErrors] = message;
      } else {
        errors.common = message;
      }
    });
  }

  return errors;
};

export const setShowBalanceHeader = (isShow:boolean) => {
  localStorage.setItem(
    'showBalanceHeader',
    JSON.stringify({ isShow }),
  );
};

export const getShowBalanceHeader = ():boolean => {
  const item = localStorage.getItem('showBalanceHeader');
  if (item === null) {
    return false;
  }
  const parsedItem = JSON.parse(item) as { isShow: boolean };

  return parsedItem.isShow;
};
