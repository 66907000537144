import { NameSpace } from 'utils/constants';

import type { RootState } from 'types/state';

export const selectLoginError = (state: RootState) => state[NameSpace.Auth].loginError;

export const selectRegisterEmailError = (state: RootState) => state[NameSpace.Auth].registerEmailError;
export const selectRegisterTgError = (state: RootState) => state[NameSpace.Auth].registerTelegramError;
export const selectRegisterPhoneError = (state: RootState) => state[NameSpace.Auth].registerPhoneError;

export const selectIsAuthenticated = (state: RootState) => state[NameSpace.Auth].isAuthenticated;

export const selectAuthActiveModal = (state: RootState) => state[NameSpace.Auth].activeModal;

export const selectPasswordResetId = (state: RootState) => state[NameSpace.Auth].passwordResetId;

export const selectPhoneVerifyError = (state: RootState) => state[NameSpace.Auth].phoneVerifyError;

export const selectTGVereficationULR = (state: RootState) => state[NameSpace.Auth].telegramVereficationUrl;

export const selectVerifyPhone = (state: RootState) => state[NameSpace.Auth].verifyPhone;
