import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { CountryItem, EventItem, FullEventItem, SportItem, TournamentItem } from 'types/line-data';
import { TournamenWithEvents, getLineLang } from 'utils/common';

import { AdapterBetapi } from './adapter';

import type {
  GetBetapiCountriesParams,
  GetBetapiEventParams,
  GetBetapiEventResponse,
  GetBetapiEventsByTourneyResponse,
  GetBetapiEventsListParams,
  GetBetapiEventsListResponse,
  GetBetapiSportsParams,
  GetBetapiSportsResponse,
  GetBetapiTournamensParams,
} from './types';


const betapiUrl = 'https://odds.stagbet.site/v1/';

export const betapi = createApi({
  reducerPath: 'betapi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/line',
  }),
  endpoints: (build) => ({

    getBetapiSports: build.query<SportItem[], GetBetapiSportsParams>({
      query: ({
        dataType,
        dataLang = 'en'
      }) =>
        `/sports/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiSportsResponse, _, { dataType }) =>
        AdapterBetapi.sportsList(res, dataType),
      keepUnusedDataFor: 5,
    }),

    getBetapiCountries: build.query<CountryItem[], GetBetapiCountriesParams>({
      query: ({
        sportId = 0,
        dataType,
        dataLang = 'en'
      }) => `/countries/${sportId}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: AdapterBetapi.countriesList,
      keepUnusedDataFor: 5,
    }),

    getBetapiTournaments: build.query<TournamentItem[], GetBetapiTournamensParams>({
      query: ({
        sportId = 0,
        countryId = 0,
        dataType,
        dataLang = 'en'
      }) => `/tournaments/${sportId}/${countryId}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: AdapterBetapi.tournamentsList,
      keepUnusedDataFor: 5,
    }),

    getBetapiEvent: build.query<FullEventItem | undefined, GetBetapiEventParams>({
      query: ({
        gameId,
        listType = 'sub',
        dataType,
        dataLang = 'en'
      }) => `/event/${gameId}/${listType}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiEventResponse) =>
        AdapterBetapi.eventDetailed(res.body),
      keepUnusedDataFor: 5,
    }),

    getBetapiEventsList: build.query<EventItem[], GetBetapiEventsListParams>({
      query: ({
        sportId = 0,
        tournamentId = 0,
        pageLength = 1000,
        dataType,
        dataLang = 'en'
      }) => `/events/${sportId}/${tournamentId}/list/${pageLength}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiEventsListResponse, _, { dataType }) =>
        AdapterBetapi.eventsList(res, dataType),
      keepUnusedDataFor: 5,
    }),
    getBetapiEventsByTourney: build.query<TournamenWithEvents[], GetBetapiEventsListParams>({
      query: ({
        sportId = 0,
        tournamentId = 0,
        pageLength = 1000,
        dataType,
        dataLang = 'en'
      }) => `/events/${sportId}/${tournamentId}/sub/${pageLength}/${dataType}/${getLineLang(dataLang)}`,
      transformResponse: (res: GetBetapiEventsByTourneyResponse, _, { dataType }) => AdapterBetapi.eventsByTournament(res, dataType),
      keepUnusedDataFor: 2,
    }),
  }),
});


export const {
  useGetBetapiSportsQuery,
  useGetBetapiCountriesQuery,
  useGetBetapiTournamentsQuery,
  useGetBetapiEventQuery,
  useGetBetapiEventsListQuery,
  useGetBetapiEventsByTourneyQuery,
  useLazyGetBetapiEventQuery,
  useLazyGetBetapiEventsListQuery,
} = betapi;
