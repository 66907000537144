import React from 'react';

import { bemCn } from 'utils/bem-cn';
import './ScorePeriods.scss';

type Props = {
  scorePeriods: string;
  team1: string;
  team2: string;
  scoreFull: string;
  roundName: string;
  className?: string;
};

const ScorePeriods = (props: Props) => {
  const {
    scorePeriods,
    team1,
    team2,
    scoreFull,
    roundName,
    className
  } = props;

  const periods = scorePeriods.split(';');

  const b = bemCn('score-table');

  return (
    <div className={b(null, className)}>
      <div className={b('body')}>
        <div className={b('head')}>
          <div className={b('cell', { title: true })}>
            {roundName}
          </div>
          <div className={b('cell')}>{team1}</div>
          <div className={b('cell')}>{team2}</div>
        </div>
        <div className={b('rows')}>
          <div className={b('row')}>
            <div className={b('cell', { 'null': true })} />
            <div className={b('cell', { 'total': true })}>
              {scoreFull.split(':')[0]}
            </div>
            <div className={b('cell', { 'total': true })}>
              {scoreFull.split(':')[1]}
            </div>
          </div>
          {periods.map((period, index) => {
            const [scoreT1, scoreT2] = period.split(':');
            const keyVal = `score-table-row-${index}`;
            return (
              <div className={b('row')} key={keyVal}>
                <div className={b('cell', { title: true })}>{index + 1}</div>
                <div className={b('cell')}>{scoreT1}</div>
                <div className={b('cell')}>{scoreT2}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScorePeriods;
