import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/shared/Pagination/Pagination';
import { useGetBetsQuery } from 'store/user/userApi';
import { scrollToTop } from 'utils/scrollToTop';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';

import TransactionItem from '../TransactionItem/TransactionItem';
import NoItems from '../../components/NoItems/NoItems';

const BetsPage = () => {
  const [selectPage, setSelectPage] = useState<number>(1);
  const { data: bets, isLoading } = useGetBetsQuery(selectPage);
  const { t } = useTranslation();

  useEffect(() => {
    scrollToTop(true);
  }, [selectPage]);

  const allItemsCount = bets?.count || 0;
  const countPages = Math.ceil(allItemsCount / TRANSACTIONS_ITEMS_PER_PAGE);
  const isEmptyList = !isLoading && !bets?.items?.length;

  if (isLoading || isEmptyList) {
    return (
      <NoItems
        isLoading={isLoading}
        isEmpty={isEmptyList}
        text={`${t('bet-history.bets-empty', 'Список ставок пуст')}`}
        buttonText={`${t('bet-history.make-bet', 'Сделать ставку')}`}
        withButton
      />
    );
  }

  return (
    <>
      {bets?.items.map((transaction) => (
        <TransactionItem
          key={transaction.id}
          transaction={transaction}
        />
      ))}

      <Pagination
        selectPage={selectPage}
        countPages={countPages}
        setSelectPage={(value) => setSelectPage(value)}
      />
    </>
  );
};

export default BetsPage;
