import React from 'react';
import cn from 'classnames';

import { getCurrencySymbol } from 'utils/currency';

import type { Currency } from 'types/wallets-data';

import './SumSuggestButton.scss';

type Props = {
  amount: number;
  currency: Currency | null;
  onClick: (value: number) => void;
  variant?: 'default' | 'inverse';
  className?: string;
};

const SumSuggestButton = (props: Props) => {
  const {
    onClick,
    currency,
    amount,
    variant = 'default',
    className
  } = props;

  const btnClassName = cn(
    'sum-suggest-button',
    { [`sum-suggest-button--${variant}`]: variant },
    className
  );

  return (
    <button
      type="button"
      className={btnClassName}
      onClick={() => onClick(amount)}
    >
      {amount} {getCurrencySymbol(currency)}
    </button>
  );
};

export default React.memo(SumSuggestButton);
