import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { ReactComponent as MenuIcon } from 'assets/icons/icon-menu.svg';
import { selectIsBurgerMenuOpen } from 'store/app/selectors';
import { setBurgerMenu } from 'store/app/appSlice';

import './BurgerButton.scss';

const b = bemCn('burger-button');
function BurgerButton() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isBurgerMenuOpen = useAppSelector(selectIsBurgerMenuOpen);

  useEffect(() => {
    dispatch(setBurgerMenu(false));
  }, [location.pathname, dispatch]);

  const handleMenuClick = () => {
    dispatch(setBurgerMenu(true));
  };

  const handleCloseClick = () => {
    dispatch(setBurgerMenu(false));
  };

  return (
    <div className={b()}>
      <button className={`${b('menu-btn')} ${b('btn', { active: !isBurgerMenuOpen })}`} onClick={handleMenuClick}>
        <MenuIcon />
      </button>
      <button className={`${b('close-btn')} ${b('btn', { active: isBurgerMenuOpen })}`} onClick={handleCloseClick}>
        <SvgIcon className={b('close-icon')} name='close' />
      </button>
    </div>
  );
}

export default BurgerButton;
