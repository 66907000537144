import { Link } from 'react-router-dom';

import { ReactComponent as NavIcon } from 'assets/icons/icon-nav.svg';
import { bemCn } from 'utils/bem-cn';
import SectionIcon from 'components/shared/SectionIcon/SectionIcon';
import { PageData } from 'utils/constants/mainPageLinks';

import './BurgerNavButton.scss';

type props = {
  barItem: PageData;
  isActive: boolean;
};

const b = bemCn('burger-nav-button');
function BurgerNavButton({ barItem, isActive }: props) {
  if (barItem.id === 'download-app') {
    return (
      <a
        className={b('', { 'active': isActive })}
        href={barItem.link}
        key={barItem.id}
      // target="_blank"
      // rel="noreferrer"
      >
        <div className={b('left-container')}>
          <SectionIcon
            className={b('icon')}
            id={barItem.id}
            isBackgroundShowing
            variant="rounded-shadow"
          />
          <span className={b('title')}>
            {barItem.title}
          </span>
        </div>
        <NavIcon width={18} />
      </a>
    );
  }

  return (
    <Link
      className={b('', { 'active': isActive })}
      to={barItem.link}
      key={barItem.id}
    >
      <div className={b('left-container')}>
        <SectionIcon
          className={b('icon')}
          id={barItem.id}
          isBackgroundShowing
          variant="rounded-shadow"
        />
        <span className={b('title')}>
          {barItem.title}
        </span>
      </div>
      <NavIcon width={18} />
    </Link>
  );
}

export default BurgerNavButton;
