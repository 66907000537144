import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetWalletsQuery } from 'store/user/userApi';
import AddedWalletItem from 'components/profile/WalletManage/components/AddedWalletItem/AddedWalletItem';
import { bemCn } from 'utils/bem-cn';
import './OtherWallets.scss';

const OtherWallets = () => {
  const { data: allWallets } = useGetWalletsQuery();
  const { t } = useTranslation();
  const b = bemCn('other-wallets');

  const notPrimaryWallet = allWallets?.filter((item) => !item.isPrimary);

  return (
    <div className={b()}>
      <div className={b('title')}>
        {t('wallets.other-wallets', 'Другие счета')}
      </div>
      <div className={b('list')}>
        {notPrimaryWallet?.map((item) => (
          <AddedWalletItem key={item.currency} wallet={item} />
        ))}
      </div>
    </div>
  );
};

export default OtherWallets;
