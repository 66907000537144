import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PPLayout from '../components/PPLayout/PPLayout';
import PPPartnerStats from './widgets/PPPartnerStats';
import PPPartnerPlayers from './widgets/PPPartnerPlayers';
import PPPartnerProjects from './widgets/PPPartnerProjects';
import PPPartnerLinks from './widgets/PPPartnerLinks';
import PPPartnerPromocodes from './widgets/PPPartnerPromocodes';

import type { TMenuItem } from 'store/partnerProgram/types';

import './PPPartner.scss';

const setActive = (props: { isActive: boolean; isPending: boolean }) => props.isActive ? 'pp--active-link' : '';

const PPManager = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const menuItems: TMenuItem[] = [
    {
      key: 'stats',
      label: <NavLink to="/partner-program" className={setActive} end>{t('stats', 'Статистика')}</NavLink>,
    },
    {
      key: 'players',
      label: <NavLink to="/partner-program/players" className={setActive}>{t('users', 'Пользователи')}</NavLink>,
    },
    {
      key: 'projects',
      label: <NavLink to="/partner-program/projects" className={setActive}>{t('projects', 'Проекты')}</NavLink>,
    },
    {
      key: 'links',
      label: <NavLink to="/partner-program/links" className={setActive}>{t('links', 'Ссылки')}</NavLink>,
    },
    {
      key: 'promocodes',
      label: <NavLink to="/partner-program/promocodes" className={setActive}>{t('promocodes', 'Промокоды')}</NavLink>,
    },
  ];

  return (
    <PPLayout
      title={t('pp', 'Партнёрская программа') ?? ''}
      role={t('partner', 'Партнёр')}
      menuItems={menuItems}
    >
      <Routes>
        <Route index element={<PPPartnerStats />} />
        <Route path="players" element={<PPPartnerPlayers />} />
        <Route path="projects" element={<PPPartnerProjects />} />
        <Route path="links" element={<PPPartnerLinks />} />
        <Route path="promocodes" element={<PPPartnerPromocodes />} />
        <Route path="*" element={<Navigate to="/partner-program" />} />
      </Routes>
    </PPLayout>
  );
};

export default PPManager;
