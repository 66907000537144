import { useTranslation } from 'react-i18next';

import PPModal from 'pages/PartnerProgram/components/PPModal/PPModal';
import PPDrawer from 'pages/PartnerProgram/components/PPDrawer/PPDrawer';

import PPPartnerProjectStats from './PPPartnerProjectStats';
import PPPartnerLinkStats from './PPPartnerLinkStats';
import PPPartnerPromocodeStats from './PPPartnerPromocodeStats';
import PPPartnerNewProject from './PPPartnerNewProject';
import PPPartnerNewLink from './PPPartnerNewLink';
import PPPartnerNewPromocode from './PPPartnerNewPromocode';

export type ModalParams = {
  key: string;
  id: number;
  title: string;
  created: string;
  requestParam: string;
};

type Props = {
  activeModal: string | null;
  onModalClose: () => void;
  modalParams: ModalParams;
  children?: React.ReactNode;
};

const PPPartnerModals = (props: Props) => {
  const {
    activeModal,
    onModalClose,
    modalParams,
    children,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <>
      <PPDrawer
        isOpen={activeModal === 'project-stats'}
        onClose={onModalClose}
        title={t('project-stats', 'Статистика проекта') ?? 'Статистика проекта'}
      >
        <PPPartnerProjectStats {...modalParams} />
      </PPDrawer>

      <PPDrawer
        isOpen={activeModal === 'link-stats'}
        onClose={onModalClose}
        title={t('ref-code-stats', 'Статистика по реферальному коду') ?? 'Статистика по реферальному коду'}
      >
        <PPPartnerLinkStats {...modalParams} />
      </PPDrawer>

      <PPDrawer
        isOpen={activeModal === 'promocode-stats'}
        onClose={onModalClose}
        title={t('promocode-stats', 'Статистика по промокоду') ?? 'Статистика по промокоду'}
      >
        <PPPartnerPromocodeStats {...modalParams} />
      </PPDrawer>

      {/* <PPModal
        isOpen={activeModal === 'project-stats'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPPartnerProjectStats {...modalParams} />
      </PPModal>

      <PPModal
        isOpen={activeModal === 'link-stats'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPPartnerLinkStats {...modalParams} />
      </PPModal>

      <PPModal
        isOpen={activeModal === 'promocode-stats'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPPartnerPromocodeStats {...modalParams} />
      </PPModal> */}

      <PPModal
        isOpen={activeModal === 'new-project'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPPartnerNewProject onModalClose={onModalClose} />
      </PPModal>

      <PPModal
        isOpen={activeModal === 'new-link'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPPartnerNewLink onModalClose={onModalClose} />
      </PPModal>

      <PPModal
        isOpen={activeModal === 'new-promocode'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPPartnerNewPromocode onModalClose={onModalClose} />
      </PPModal>

    </>

  );
};

export default PPPartnerModals;
