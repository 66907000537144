import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToTop } from 'utils/scrollToTop';

const exceptions = ['/slots/game/'];

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const shouldBeSmooth = !(exceptions.some((exception) => pathname.includes(exception)));
    scrollToTop(shouldBeSmooth);
  }, [pathname]);

  return null;
};

export default React.memo(ScrollToTop);
