
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import TournamentCardMobile from 'components/line/TournamentCard/TournamentCardMobile/TournamentCardMobile';
import { bemCn } from 'utils/bem-cn';

import type { TSportGroup } from 'types/line-data';

import './SportGroup.scss';

type Props = {
  sportGroup: TSportGroup;
}

const SportGroup = ({ sportGroup }: Props) => {
  const [closedTournaments, setClosedTournaments] = useState<string[]>([]);
  const isAllOpened = closedTournaments.length === 0;
  const { t } = useTranslation();

  const { tournamentsIds, tournaments, name } = sportGroup;

  const handleCloseAllToggle = () => {
    setClosedTournaments(() => isAllOpened ? tournamentsIds : []);
  };

  const handleCloseTournament = useCallback((tournament: string) => {
    setClosedTournaments((prevClosedTournaments) => {
      if (prevClosedTournaments.includes(tournament)) {
        return prevClosedTournaments.filter((tour) => tour !== tournament);
      } else {
        return [...prevClosedTournaments, tournament];
      }
    });
  }, []);

  const closeBtnTitle = !isAllOpened
    ? t('event.expand-all', 'Открыть все')
    : t('event.collapse-all', 'Свернуть все');

  const b = bemCn('sport-group');

  return (
    <div className={b()}>
      <div className={b('head')}>
        <p className={b('title')}>{name}</p>
        <button className={b('close-btn', { 'closed': !isAllOpened })} onClick={handleCloseAllToggle} type='button'>
          {closeBtnTitle}
          <SvgIcon className={b('close-icon')} name="chevron-down" width={10} height={10} />
        </button>
      </div>
      <div className={b('list')}>
        {tournaments.map((tournament) => (
          <TournamentCardMobile
            key={tournament.id}
            className={b('item')}
            tournament={tournament}
            onClose={() => handleCloseTournament(tournament.id)}
            closed={closedTournaments.includes(tournament.id)}
          />
        ))}
      </div>
    </div>
  );
};
export default SportGroup;
