import TextContent from 'components/shared/TextContent/TextContent';
import { logoText } from 'utils/constants';


const TermsOfRefund = () => (
  <TextContent as="article">
    <h1>Условия возврата финансовых средств</h1>

    <p>Возврат денежных средств после их использования в процессе игры не предусмотрен. Запрос на возврат будет рассмотрен только в случае, если он подается в течение первых 24 часов после предполагаемой транзакции или в течение 30 дней, если пользователь утверждает, что к его учетной записи имел доступ другой человек (или несовершеннолетний).</p>
    <p>Мы, компания {logoText}, оставляем за собой право отказать в возврате или обратной транзакции до тех пор, пока не будет установлена личность пользователя в соответствии с применимыми законами вашей юрисдикции.</p>
    <p>Вы соглашаетесь предоставить нотариально удостоверенную идентификацию или другие документы, подтверждающие вашу личность по нашему требованию. В случае, если такая идентификация не будет предоставлена в течение 30 дней с момента запроса, возврат или обратная транзакция не будут выполнены. В подобном случае ваша игровая учетная запись закрывается администрацией, после чего вы безвозвратно теряете средства, которые были на балансе игрока.</p>
    <p>Принятое администрацией {logoText} решение будет окончательным, обязательным и обжалованию не подлежащим. Клиент обязуется честно играть в игры из меню и не использовать никакие способы, влияющие на результат игры, включая компьютеры, математические уравнения, системы ставок и прочее.</p>
  </TextContent>
);

export default TermsOfRefund;
