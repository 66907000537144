import React, { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';

import SvgIcon from '../SvgIcon/SvgIcon';

import './Pagination.scss';

type Props = {
  className?: string;
  selectPage: number;
  countPages: number;
  setSelectPage: (value: React.SetStateAction<number>) => void;
};

const b = bemCn('pagination');

const Pagination = (props: Props) => {
  const { className, selectPage, countPages, setSelectPage } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    const value = Number(debouncedInputValue);
    if (value <= countPages && value >= 1) {
      setSelectPage(Number(value));
      setInputValue('');
    }
  }, [debouncedInputValue]);

  const pagePaginate = [];

  if (countPages - selectPage >= 2) {
    for (let number = selectPage; number <= countPages; ++number) {
      if (pagePaginate.length < 2) {
        pagePaginate.push(number);
      } else {
        break;
      }
    }
  } else {
    const firstItem = countPages - 2 > 0 && pagePaginate.push(countPages - 2);
    const secondItem = countPages - 1 > 0 && pagePaginate.push(countPages - 1);
  }

  if (countPages <= 1) {
    return null;
  }

  return (
    <div className={b(undefined, className)}>
      <button className={b('button', { prev: true })}
        disabled={selectPage === 1}
        type="button"
        onClick={() => setSelectPage(selectPage - 1)}
      >
        <SvgIcon className={b('button-icon')}
          name='arrow-right'
          width={11}
          height={11}
        />
      </button>
      {pagePaginate.map((item) => (
        <button className={b('button', { active: selectPage === item })}
          key={item}
          disabled={selectPage === item}
          onClick={() => setSelectPage(item)}
          type="button"
        >
          {item}
        </button>
      ))}
      <input className={b('button', { input: true })}
        value={inputValue}
        type="number"
        placeholder="..."
        onChange={(e) => setInputValue(e.target.value)}
      />
      <button className={b('button', { active: selectPage === countPages })}
        disabled={selectPage === countPages}
        onClick={() => setSelectPage(countPages)}
        type="button"
      >
        {countPages}
      </button>
      <button className={b('button', { next: true })}
        type="button"
        disabled={selectPage === countPages}
        onClick={() => setSelectPage(selectPage + 1)}
      >
        <SvgIcon className={b('button-icon')}
          name='arrow-right'
          width={11}
          height={11}
        />
      </button>
    </div>
  );
};

export default React.memo(Pagination);
