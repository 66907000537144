
export type Blacklist = {
  sports: string[];
  tournaments: string[];
  outcomes: string[];
  subgames: string[];
  bySport: {
    [sportId: number]: {
      sportId: number;
      sportName: string;
      subgames: string[];
      tournaments?: string[];
    };
  };
}

export const blacklist: Blacklist = {
  sports: [
    // 'Darts',
    // 'Дартс',
    // 'КиберСпорт',
    // 'Кибер хоккей',
    // 'Кибер UFC',
    // 'Кибер баскетбол',
    // 'Кибер теннис',
    // 'FIFA',
    // 'World of tanks',
    // 'Dota',
    // 'Кибер крикет',
    // 'Кибер рестлинг',
    // 'Mortal Kombat',
    // 'Лига легенд',
    // 'Rocket League',
    // 'Кибер регби',
    // 'Кибер NFL',
    // 'Кибер ралли',
    // 'Need for Speed',
    // 'Кибер гольф',
    // 'StreetFighter',
    // 'Counter Strike',
    // 'Кибер формула 1',
    // 'Кибер лакросс',
    // 'Steep',
    // 'Кибер флорбол',
    // 'KingOfFighters',
    // 'Injustice',
    // 'PES',
    // 'Tekken',
    // 'Кибер бильярд',
    // 'LaserLeague',
    // 'CrossOut',
    // 'PixelCupSoccer',
    // 'Killer Instinct',
    // 'Кибер настольный футбол',
    // 'Кибер австралийский футбол',
    // 'Blade and Soul',
    // 'AssaultSquad',
    // 'CutTheRope',
    // 'SubwaySurfers',
    // 'Sonic',
    // 'Crash',
    // 'Кибер волейбол',
    // 'Кибер велоспорт',
    // 'Overcooked',
    // 'Sekiro',
    // 'Бомбермен',
    // 'Dead Or Alive',
    // 'TABS',
    // 'RumbleStars',
    // 'RumbleStars',
    // 'Robot Champions',
    // 'Raid: Shadow Legends',
    // 'Super Kickers League',
    // 'Street Power Football',
    // 'Cyber Horse Racing',
    // 'Кибер боулинг',
    // 'WWE Battlegrounds',
    // 'Killer Joker',
    // 'SEGA Football',
    // 'Guilty Gear',
    // 'Hyper Brawl',
    // 'Big Rumble Boxing',
    // 'GigaBash',
    // 'Super Soccer Blast'
  ],
  tournaments: [
    // 'Alternative matches',
    // 'Alternative Matches',
    // 'Alternativ matçlar',
    // 'Alternativ matchlar',
    // 'Альтернативные матчи',
    // 'Альтернативні матчі',
    // 'ACL Indoor',
    // 'Россия. MNHL',
    // 'RHL',
    // 'ATP World Cyber Tour',
    // 'Table Basketball League',
    // '2K23. NBA',
    // 'Сетка Кап',
    // 'Ace Belarus Cup',
    // 'NBA 2K22. Cyber Euro League',
    // 'NBA 2K23. Cyber League',
    // '2K24. NBA Cyber Championship',
    // 'Short Football 5x5 L1',
    // 'Short Football 4x4',
    // 'Sport Football 4x4',
    // 'Short Football 4x4 L2',
    // 'Short Football 3x3',
    // 'Short Football 3x3 L1',
    // 'Short Football 3x3 L2',
    // 'Short Football 2x2',
    // 'Short Football 2x2 L2',
    // 'Short Football D1',
    // 'USSR. Division 3x3',
    // 'Division 4x4',
    // 'BudnesLiga LFL 5x5',
  ],
  outcomes: [
    'Азиатский',
    'Азиатская',
    'Азійський',
    'Азіатська',
    'Азіатський',
    'Asian',
    'Тотал на раунд',
    'Тотал фрагов',
    'Тотал ударов',
    'Тотал смертей',
    'Most Kills',
    'Most Deaths',
  ],
  subgames: [
    // 'Альтернативные исходы',
    // 'Альтернативні результати',
    // 'Alternative Outcomes',
    // 'Alternativ nəticələr',
    // 'Alternativ oqibatlar',

    // 'Экспресс исходы',
    // 'Експрес результати',
    // 'Accumulator Outcomes',
    // 'Ekspres nəticələr',
    // 'Ekspress oqibatlar',

    // 'Что раньше',
    // 'Що раніше',
    // 'First To Happen',
    // 'Daha tez',
    // 'Avvalroq nima',

    // 'Видеопросмотры',
    // 'Відеоперегляд',
    // 'VAR Checks',
    // 'Video baxışları',
    // 'Video ko\'rib chiqish',

    // 'Мед. бригада',
    // 'Мед. бригада',
    // 'Medical Team',
    // 'Tibbi Heyət',
    // 'Tibbiy guruh',
  ],
  bySport: {
    // 1: {
    //   sportId: 1,
    //   sportName: 'Футбол',
    //   subgames: [
    //     'Угловые',
    //     'Кутові',
    //     'Corners',
    //     'Künc zərbələri',
    //     'Burchakdan',

    //     'Угловые,1-й Тайм',
    //     'Кутові,1-й Тайм',
    //     'Corners,1 Half',
    //     'Künc zərbələri,1-ci Hissə ',
    //     'Künc zərbələri,1-ci Hissə',
    //     'Burchakdan,1 Taymi',

    //     'Угловые,2-й Тайм',
    //     'Кутові,2-й Тайм',
    //     'Corners,2 Half',
    //     'Künc zərbələri,2-ci Hissə ',
    //     'Künc zərbələri,2-ci Hissə',
    //     'Burchakdan,2 Taymi',

    //     'Желтые карточки',
    //     'Жовті картки',
    //     'Yellow Cards',
    //     'Sarı vərəqələr',
    //     'Sariq kartochkalar',

    //     'Желтые карточки,1-й Тайм',
    //     'Жовті картки,1-й Тайм',
    //     'Yellow Cards,1 Half',
    //     'Sarı vərəqələr,1-ci Hissə ',
    //     'Sarı vərəqələr,1-ci Hissə',
    //     'Sariq kartochkalar,1 Taymi',

    //     'Желтые карточки,2-й Тайм',
    //     'Жовті картки,2-й Тайм',
    //     'Yellow Cards,2 Half',
    //     'Sarı vərəqələr,2-ci Hissə ',
    //     'Sarı vərəqələr,2-ci Hissə',
    //     'Sariq kartochkalar,2 Taymi',

    //     'Карточки',
    //     'Картки',
    //     'Cards',
    //     'Vərəqələr',
    //     'Kartochkala',

    //     'Удары в створ ворот',
    //     'Удари у площину воріт',
    //     'Shots On Target',
    //     'Qapı çərçivəsinə edilən zərbələr',
    //     'Darvoza tomon aniq zarbalar',

    //     'Удары в створ ворот,1-й Тайм',
    //     'Удари у площину воріт,1-й Тайм',
    //     'Shots On Target,1 Half',
    //     'Qapı çərçivəsinə edilən zərbələr,1-ci Hissə ',
    //     'Qapı çərçivəsinə edilən zərbələr,1-ci Hissə',
    //     'Darvoza tomon aniq zarbalar,1 Taymi',

    //     'Удары в створ ворот,2-й Тайм',
    //     'Удари у площину воріт,2-й Тайм',
    //     'Shots On Target,2 Half',
    //     'Qapı çərçivəsinə edilən zərbələr,2-ci Hissə ',
    //     'Qapı çərçivəsinə edilən zərbələr,2-ci Hissə',
    //     'Darvoza tomon aniq zarbalar,2 Taymi',

    //     'Офсайды',
    //     'Офсайди',
    //     'Offsides',
    //     'Ofsaytlar',
    //     'Ofsaydlar',

    //     'Офсайды,1-й Тайм',
    //     'Офсайди,1-й Тайм',
    //     'Offsides,1 Half',
    //     'Ofsaytlar,1-ci Hissə ',
    //     'Ofsaytlar,1-ci Hissə',
    //     'Ofsaydlar,1 Taymi',

    //     'Офсайды,2-й Тайм',
    //     'Офсайди,2-й Тайм',
    //     'Offsides,2 Half',
    //     'Ofsaytlar,2-ci Hissə ',
    //     'Ofsaytlar,2-ci Hissə',
    //     'Ofsaydlar,2 Taymi',

    //     'Фолы',
    //     'Фоли',
    //     'Fouls',
    //     'Follar',
    //     'Follar',

    //     'Фолы,1-й Тайм',
    //     'Фоли,1-й Тайм',
    //     'Fouls,1 Half',
    //     'Follar,1-ci Hissə ',
    //     'Follar,1-ci Hissə',
    //     'Follar,1 Taymi',

    //     'Фолы,2-й Тайм',
    //     'Фоли,2-й Тайм',
    //     'Fouls,2 Half',
    //     'Follar,2-ci Hissə ',
    //     'Follar,2-ci Hissə',
    //     'Follar,2 Taymi',

    //     'Карточки. статистика',
    //     'Картки. статистика',
    //     'Cards. Stats',
    //     'Vərəqə. statistika',
    //     'Kartochkalar. Statistika',

    //     'Карточки. статистика,1-й Тайм',
    //     'Картки. статистика,1-й Тайм',
    //     'Cards. Stats,1 Half',
    //     'Vərəqə. statistika,1-ci Hissə ',
    //     'Vərəqə. statistika,1-ci Hissə',
    //     'Kartochkalar. Statistika,1 Taymi',

    //     'Карточки. статистика,2-й Тайм',
    //     'Картки. статистика,2-й Тайм',
    //     'Cards. Stats,2 Half',
    //     'Vərəqə. statistika,2-ci Hissə ',
    //     'Vərəqə. statistika,2-ci Hissə',
    //     'Kartochkalar. Statistika,2 Taymi',

    //     'Отборы',
    //     'Відбори',
    //     'Tackles',
    //     'Отборы',
    //     'To\'pni muvaffaqiyatli olib qo\'yish',

    //     'Видеопросмотры,1-й Тайм',
    //     'Відеоперегляд,1-й Тайм',
    //     'VAR Checks,1 Half',
    //     'Video baxışları,1-ci Hissə ',
    //     'Video baxışları,1-ci Hissə',
    //     'Video ko\'rib chiqish,1 Taymi',

    //     'Видеопросмотры,2-й Тайм',
    //     'Відеоперегляд,2-й Тайм',
    //     'VAR Checks,2 Half',
    //     'Video baxışları,2-ci Hissə ',
    //     'Video baxışları,2-ci Hissə',
    //     'Video ko\'rib chiqish,2 Taymi',

    //     'Удары по воротам',
    //     'Удари по воротам',
    //     'Shots Towards The Goal',
    //     'Qapıya zərbə',
    //     'Darvozaga zarbalar',

    //     'Удары по воротам,1-й Тайм',
    //     'Удари по воротам,1-й Тайм',
    //     'Shots Towards The Goal,1 Half',
    //     'Qapıya zərbə,1-ci Hissə ',
    //     'Qapıya zərbə,1-ci Hissə',
    //     'Darvozaga zarbalar,1 Taymi',

    //     'Удары по воротам,2-й Тайм',
    //     'Удари по воротам,2-й Тайм',
    //     'Shots Towards The Goal,2 Half',
    //     'Qapıya zərbə,2-ci Hissə ',
    //     'Qapıya zərbə,2-ci Hissə',
    //     'Darvozaga zarbalar,2 Taymi',

    //     'Удары от ворот',
    //     'Удари від воріт',
    //     'Goal kicks',
    //     'Qapıdan zərbələr',
    //     'Darvozadan zarbalar',

    //     'Удары от ворот,1-й Тайм',
    //     'Удари від воріт,1-й Тайм',
    //     'Goal kicks,1 Half',
    //     'Qapıdan zərbələr,1-ci Hissə ',
    //     'Darvozadan zarbalar,1 Taymi',

    //     'Удары от ворот,2-й Тайм',
    //     'Удари від воріт,2-й Тайм',
    //     'Goal kicks,2 Half',
    //     'Qapıdan zərbələr,2-ci Hissə ',
    //     'Qapıdan zərbələr,2-ci Hissə',
    //     'Darvozadan zarbalar,2 Taymi',

    //     '1-минутные события',
    //     '1-хвилинні події',
    //     '1-minute events',
    //     '1 dəqiqəlik hadisələr',
    //     '1 daqiqali voqealar',

    //     'Сейвы',
    //     'Сейви',
    //     'Saves',
    //     'Seyvlər',
    //     'Seyvlar',

    //     'Вброс аутов',
    //     'Вкидання аутів',
    //     'Throw-ins',
    //     'Aut atışı',
    //     'Autlardan to\'p tashlash',

    //     'Вброс аутов,1-й Тайм',
    //     'Вкидання аутів,1-й Тайм',
    //     'Throw-ins,1 Half',
    //     'Aut atışı,1-ci Hissə ',
    //     'Aut atışı,1-ci Hissə',
    //     'Autlardan to\'p tashlash,1 Taymi',

    //     'Вброс аутов,2-й Тайм',
    //     'Вкидання аутів,2-й Тайм',
    //     'Throw-ins,2 Half',
    //     'Aut atışı,2-ci Hissə ',
    //     'Aut atışı,2-ci Hissə',
    //     'Autlardan to\'p tashlash,2 Taymi',
    //   ],
    //   tournaments: [
    //     'Short Football 5x5 L1',
    //     'Short Football 4x4',
    //     'Sport Football 4x4',
    //     'Short Football 4x4 L2',
    //     'Short Football 3x3',
    //     'Short Football 3x3 L1',
    //     'Short Football 3x3 L2',
    //     'Short Football 2x2',
    //     'Short Football 2x2 L2',
    //     'Short Football D1',
    //     'USSR. Division 3x3',
    //     'Division 4x4',
    //     'BudnesLiga LFL 5x5',
    //   ]
    // },
    // 2: {
    //   sportId: 2,
    //   sportName: 'Хоккей',
    //   subgames: [
    //     'Броски в створ ворот',
    //     'Кидки у площину воріт',
    //     'Shots On Target',
    //     'Qapı zərbələri',
    //     'Darvoza tomon aniq otishlar',

    //     'Броски в створ ворот,1-й Период',
    //     'Кидки у площину воріт,1-й Період',
    //     'Shots On Target,1 Period',
    //     'Qapı zərbələri,1-ci Hissə',
    //     'Darvoza tomon aniq otishlar,1 Davri',

    //     'Видеопросмотры',
    //     'Відеоперегляд',
    //     'VAR Checks',
    //     'Video baxışları',
    //     'Video ko\'rib chiqish',

    //     'Выигранные вбрасывания',
    //     'Виграні вкидання',
    //     'Face-offs won',
    //     'Qazanmış atışlar',
    //     'Yutib olingan tashlashlar',

    //     'Голы в большинстве',
    //     'Голи в більшості',
    //     'Powerplay goals',
    //     'Голы в большинстве',
    //     'Ko\'pchilikda urilgan gollar',

    //     'Штрафное время',
    //     'Штрафний час',
    //     'Time Penalty',
    //     'Cərimə vaxtı',
    //     'Jarima vaqti',

    //     'Штрафное время,1-й Период',
    //     'Штрафний час,1-й Період',
    //     'Time Penalty,1 Period',
    //     'Cərimə vaxtı,1-ci Hissə',
    //     'Jarima vaqti,1 Davri',

    //     'Статистика игроков',
    //     'Статистика гравців',
    //     'Players\' stats',
    //     'Oyunçu statistikası',
    //     'O\'yinchilar statistikasi',

    //     'Статистика игроков,1-й Период',
    //     'Статистика гравців,1-й Період',
    //     'Players\' stats,1 Period',
    //     'Oyunçu statistikası,1-ci Hissə',
    //     'O\'yinchilar statistikasi,1 Davri',

    //     'Статистика игроков,3-й Период',
    //     'Статистика гравців,3-й Період',
    //     'Players\' stats,3 Period',
    //     'Oyunçu statistikası,3-ci Hissə',
    //     'O\'yinchilar statistikasi,3 Davri',

    //     'Статистика игроков,2-й Период',
    //     'Статистика гравців,2-й Період',
    //     'Players\' stats,2 Period',
    //     'Oyunçu statistikası,2-ci Hissə',
    //     'O\'yinchilar statistikasi,2 Davri',

    //     'Видеопросмотры,1-й Период',
    //     'Відеоперегляд,1-й Період',
    //     'VAR Checks,1 Period',
    //     'Video baxışları,1-ci Hissə',
    //     'Video ko\'rib chiqish,1 Davri',

    //     'Голы в большинстве,1-й Период',
    //     'Голи в більшості,1-й Період',
    //     'Powerplay goals,1 Period',
    //     'Голы в большинстве,1-ci Hissə',
    //     'Ko\'pchilikda urilgan gollar,1 Davri',

    //     'Выигранные вбрасывания,1-й Период',
    //     'Виграні вкидання,1-й Період',
    //     'Face-offs won,1 Period',
    //     'Qazanmış atışlar,1-ci Hissə',
    //     'Yutib olingan tashlashlar,1 Davri',
    //   ]
    // },
    // 3: {
    //   sportId: 3,
    //   sportName: 'Баскетбол',
    //   subgames: [
    //     'Статистика игроков,1-я Четверть',
    //     'Статистика гравців,1-а Чверть',
    //     'Players\' stats,1 Quarter',
    //     'Oyunçu statistikası,1-cü Dörddəbir',
    //     'O\'yinchilar statistikasi,1 Choragi',

    //     'Передачи,1-я Четверть',
    //     'Передачі,1-а Чверть',
    //     'Assists,1 Quarter',
    //     'Ötürmələr,1-cü Dörddəbir',
    //     'Uzatishlar,1 Choragi',

    //     'Подборы,1-я Четверть',
    //     'Підбирання,1-а Чверть',
    //     'Rebounds,1 Quarter',
    //     'Ribaundlar,1-cü Dörddəbir',
    //     'To\'pni ilib olishlar,1 Choragi',

    //     'Перехваты',
    //     'Перехоплення',
    //     'Steals',
    //     'Topu almaq',
    //     'Egallab olmoq',

    //     'Потери',
    //     'Втрати',
    //     'Turnovers',
    //     'İtkilər',
    //     'Yo\'qotishlar',

    //     'Блок-шоты',
    //     'Блок-шоти',
    //     'Blocks',
    //     'Blok-Şotlar',
    //     'Blok - tortishishlar',

    //     'Очки + Подборы + Передачи',
    //     'Очки + Підбори + Передачі',
    //     'Pts + Rebounds + Assists',
    //     'Очки + Подборы + Передачи',
    //     'Ochkolar + To\'pni ilib olishlar + Uzatishlar',

    //     'Фолы',
    //     'Фоли',
    //     'Fouls',
    //     'Follar',
    //     'Follar',

    //     'Забитые двухочковые броски',
    //     'Забиті двоочковs кидки',
    //     'Two-Point Field Goals Scored',
    //     'Vurulan 2-xallı atışları',
    //     'Urilgan ikki-ochkoli otishlar',

    //     'Забитые трехочковые броски',
    //     'Забиті триочкові кидки',
    //     'Three-Point Field Goals Scored',
    //     'Vurulan 3-xallı atışları',
    //     'Urilgan uch-ochkoli otishlar',

    //     'Забитые штрафные броски',
    //     'Забиті штрафні кидки',
    //     'Free Throws Scored',
    //     'Vurulan cərimə atışları',
    //     'Urilgan jarima otishlar',

    //     'Подборы',
    //     'Підбирання',
    //     'Rebounds',
    //     'Ribaundlar',
    //     'To\'pni ilib olishlar',

    //     'Статистика игроков,с ОТ',
    //     'Статистика гравців,з ОТ',
    //     'Players\' stats,Including Overtime',
    //     'Oyunçu statistikası,Overtaym daxil olmaqla',
    //     'O\'yinchilar statistikasi,OT bilan',

    //     'Передачи',
    //     'Передачі',
    //     'Assists',
    //     'Ötürmələr',
    //     'Uzatishlar',

    //     'Забитые трехочковые броски,1-я Четверть',
    //     'Забиті триочкові кидки,1-а Чверть',
    //     'Three-Point Field Goals Scored,1 Quarter',
    //     'Vurulan 3-xallı atışları,1-cü Dörddəbir',
    //     'Urilgan uch-ochkoli otishlar,1 Choragi',

    //     'Забитые трехочковые броски,2-я Четверть',
    //     'Забиті триочкові кидки,2-а Чверть',
    //     'Three-Point Field Goals Scored,2 Quarter',
    //     'Vurulan 3-xallı atışları,2-cü Dörddəbir',
    //     'Urilgan uch-ochkoli otishlar,2 Choragi'
    //   ]
    // },
  }
};
