import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';

import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useGetBasketQuery, useGetBetLimitsQuery, useGetSubgamesLimitsQuery, usePlaceBetsMutation } from 'store/basket/basketApi';
import { selectPrimaryWallet } from 'store/user/selectors';
import { getBetLimits } from 'store/basket/constants';
import { seletIsLineActive } from 'store/app/selectors';

const defaultCurrency = 'USD';

export const useBasket = () => {
  const { i18n } = useTranslation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const isLineActive = useAppSelector(seletIsLineActive);
  const [isBonusWallet, setIsBonusWallet] = useState(false);

  const {
    currency,
    maxBetAmount,
    amount: balance,
    bonusAmount: bonusBalance,
    subGamesLimits: walletSubgamesLimits
  } = useAppSelector(selectPrimaryWallet);
  const { data: subgamesLimits = {} } = useGetSubgamesLimitsQuery();
  const { data: systemBetLimits = {} } = useGetBetLimitsQuery();
  const { basketList } = useGetBasketQuery( undefined, {
    pollingInterval: 3000,
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketList: data?.items ?? []
    })
  });

  const [placeBets, { isLoading: isPlacing }] = usePlaceBetsMutation();

  const handlePlaceBet = async (amount: number, agreeWithCoef: boolean) => {
    if (amount) {
      await placeBets({
        amount,
        agreeWithCoef: agreeWithCoef,
        lang: i18n.resolvedLanguage,
        balanceType: isBonusWallet ? 'bonus' : 'real',
      });
    }
  };

  const handleChangeBonusWallet = (value: boolean) => setIsBonusWallet(value);

  const betLimits = useMemo(() => getBetLimits({
    currency: currency ?? defaultCurrency,
    currencyLimits: systemBetLimits,
    basketItems: basketList,
    subgamesLimits: subgamesLimits,
    walletMaxBet: maxBetAmount,
    walletSubgamesLimits,
  }), [currency, maxBetAmount, basketList, systemBetLimits, subgamesLimits, walletSubgamesLimits]);

  const isEmpty = basketList.length === 0;
  const isBlocked = basketList.some((bet) => !bet.available);

  return {
    currency,
    balance,
    bonusBalance,
    basketList,
    totalCoef: basketList.reduce((acc, bet) => acc * bet.rate, 1),
    handlePlaceBet,
    isPlacing,
    minBetAmount: 0,
    maxBetAmount: 0,
    isEmpty,
    isBlocked,
    isDisabled: isEmpty || !isLineActive || isBlocked,
    isOrdinar: basketList.length === 1,
    isExpress: basketList.length > 1,
    betLimits,
    isBonusWallet,
    handleChangeBonusWallet,
  };
};
