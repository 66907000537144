import { useMemo } from 'react';

import { useGetAppSportsQuery } from 'store/app/appApi';
import { useGetBetapiSportsQuery } from 'store/betapi/betapi';
import { GetBetapiSportsParams } from 'store/betapi/types';
import { SportItem } from 'types/line-data';

const isSportValid = (sport: SportItem, blacklist: string[]): boolean =>
  ![sport.name, sport.nameRu, sport.nameEn]
    .some((name) => blacklist.includes(name.toLowerCase()));

export const useFilteredSports = (
  arg: GetBetapiSportsParams,
  opt?: {
    skip?: boolean;
    pollingInterval?: number;
  },
) => {
  const { data } = useGetAppSportsQuery();
  const result = useGetBetapiSportsQuery(arg, opt);

  const sportsBlacklist = data?.sportsBlacklist ?? [];

  const filteredSports = useMemo(
    () => result.data?.filter((item) => isSportValid(item, sportsBlacklist)),
    [result.data, sportsBlacklist]
  );

  return {
    ...result,
    data: filteredSports,
  };
};

