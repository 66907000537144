import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import { useAppSelector } from 'hooks';
import { useFilteredEventsList } from 'hooks/useFilteredEventsList';
import { bemCn } from 'utils/bem-cn';
import { sortByTopEntries } from 'utils/common';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';

import EventCardDetailedMobile from '../EventCard/EventCardMobileDetailed/EventCardDetailedMobile';
import EventCardMobile from '../EventCard/EventCardMobile/EventCardMobile';

import type { LineType } from 'types/line-data';

import './Tournament.scss';

type Props = {
  lineType: LineType;
}

const b = bemCn('tournament');
const Tournament = ({ lineType }: Props) => {
  const { t, i18n } = useTranslation();
  const { sportId, tournamentId, tournamentName } = useParams();
  const navigate = useNavigate();

  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const favoriteEventsIds = favoriteEvents.map((event) => event.matchId);

  const { basketByGameId } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketByGameId: data?.itemsByGameId
    })
  });
  const isSportIdValid = sportId !== undefined;
  const isTournamentIdValid = tournamentId !== undefined;

  const { isFetching: isEventsFetching, data: events = [] } = useFilteredEventsList(
    {
      sportId: sportId,
      tournamentId: tournamentId,
      dataType: lineType,
      dataLang: i18n.resolvedLanguage,
    },
    { skip: !isSportIdValid || !isTournamentIdValid },
  );

  if (!isSportIdValid || !isTournamentIdValid) {
    navigate(-1);
    return <div />;
  }

  const handleStarClick = () => navigate('/profile/favorite-list');

  const sortedEvents = sortByTopEntries(events, favoriteEventsIds, ({ id }) => id);

  return (
    <div className={b()}>
      <Breadcrumbs link="/line" title={t('main.nav.Line', 'Линия')} />
      <PageHeader
        id="line"
        title={tournamentName ?? `Турнир ${tournamentId ?? ''}`}
        controlPanel={[{ type: 'star', onClick: handleStarClick }]}
      />
      <div className={b('content')}>
        <div className={b('events-list')}>
          {isEventsFetching
            ? <Loader />
            : sortedEvents.map((event) => {
              const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id;
              return (
                <EventCardMobile
                  className={b('event')}
                  key={event.id}
                  event={event}
                  basketItem={basketByGameId?.[event.id]?.basketId}
                  isFavorite={favoriteId !== undefined}
                  favoriteId={favoriteId ?? 'notFavoried'}
                  simple
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Tournament;
