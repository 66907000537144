import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Loader from 'components/shared/loader/Loader';

import './ProtectedRoute.scss';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  isAccountLoading: boolean;
  children: JSX.Element;
};

const ProtectedRoute = ({ isAuthenticated, children, isAccountLoading }: ProtectedRouteProps) => {
  const location = useLocation();
  if (isAccountLoading) {
    return (
      <div className="protected-route">
        <Loader />
      </div>
    );
  }
  return isAuthenticated ? children : <Navigate to="/home" state={{ from: location }} />;
};

export default ProtectedRoute;
