import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { toLocaleDateString, toLocaleTimeString } from 'utils/time';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardCountdown from 'components/line/EventCard/components/EventCardCountdown/EventCardCountdown';
import { useToggleFavoriteEvent } from 'hooks/useToggleFavoriteEvent';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';
import { bemCn } from 'utils/bem-cn';
import { AddFavoriteMatchBody as EvebtInfo } from 'types/user-data';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import type { FullEventItem, LineType } from 'types/line-data';

import './FullEventInfoDesktop.scss';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';

type Props = {
  eventDate: string;
  tournament: string;
  scoreFull: string;
  periodName: string;
  lineType: LineType;
  event: FullEventItem;
};

const b = bemCn('full-event-info-desktop');
const FullEventInfoDesktop = (props: Props) => {
  const { eventDate, tournament, lineType, scoreFull, periodName, event } = props;
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { t } = useTranslation();
  const { toggleFavoriteMatch, isLoading } = useToggleFavoriteEvent();
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const { countryId, countryName } = useParams();
  const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id ?? 'notFavorite';

  const isFavorite = favoriteId !== 'notFavorite';
  const timeFormatText = toLocaleTimeString(new Date(eventDate));
  const timeWithoutSeconds = `${timeFormatText.split(':')[0]}:${timeFormatText.split(':')[1]}`;

  const dateFormatText = lineType === 'line' ? toLocaleDateString(new Date(eventDate)) : `${t('event.today', 'Сегодня')}, ${timeWithoutSeconds}`;

  const eventInfo: EvebtInfo = {
    'matchId': event.id,
    'team1': event.team1,
    'team2': event.team2,
    'sportName': event.sportName,
    'sportId': event.sportId,
    'tournamentName': event.tournament,
    'tournamentId': event.tournamentId,
    'countryId': countryId ?? '1',
    'countryName': countryName ?? '1',
    'dataType': lineType,
  };

  const handleStarClick = () => {
    if (!isLoading) {
      toggleFavoriteMatch({ eventInfo, isFavorite, favoriteId });
    }
  };

  return (
    <div className={b()}>
      <div className={b('top-wrapper')}>
        {lineType === 'line' ? (
          <p className={b('date-time')}>{timeWithoutSeconds}</p>
        ) : (
          <div className={b('stats')}>
            <p className={b('stats-score')}>{scoreFull}</p>
            <div className="full-event-header__period-layout">
              <SvgIcon className="full-event-header__period-icon" name="clock" width={10} height={10} />
              <p className={b('stats-period', 'full-event-header__period')}>
                {periodName}
              </p>
            </div>
          </div>
        )}
        <p className={b('date')}>{dateFormatText}</p>
        <FavoriteBtn className={b('favorite-btn')} onClick={handleStarClick} active={isFavorite} />
      </div>
      <div className={b('content')}>
        <p>{tournament}</p>
        <EventCardCountdown
          className={b('countdown')}
          hide={lineType !== 'line'}
          date={eventDate}
        />
      </div>
    </div>
  );
};

export default FullEventInfoDesktop;
