import { useState, useEffect } from 'react';

import { useGetPartnerPromocodePlayersQuery } from 'store/partnerProgram/partnerApi';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import {
  defaultPartnerCurrency,
} from 'store/partnerProgram/constants';

import type { SubmitHandler } from 'react-hook-form';
import type {
  ManagerPartner,
  TTableProps,
  TFilterValues,
} from 'store/partnerProgram/types';

export const usePartnerPromocodePlayers = (promocodeId: string) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const userCurrency = useAppSelector(selectPrimaryWallet).currency ?? defaultPartnerCurrency;
  const initParams = {
    promocodeId: Number(promocodeId),
    currency: userCurrency,
    page: currentPage,
    pageSize,
  };
  const [params, setParams] = useState(initParams);

  const {
    data,
    isFetching,
    isError,
  } = useGetPartnerPromocodePlayersQuery(params, { skip: !Number(promocodeId) });

  const items = data?.items;
  const totalCount = data?.count ?? 0;
  const pageCount = Math.ceil(totalCount / pageSize);

  const pagination: TTableProps<ManagerPartner, keyof ManagerPartner>['pagination'] = {
    current: currentPage,
    total: pageCount,
    onChange: (value) => setCurrentPage(value),
  };

  const handleFilterChange: SubmitHandler<TFilterValues> = (filterData) => {
    setParams((prev) => ({
      ...prev,
      ...filterData,
    }));
  };

  const handleFilterReset = () => {
    setParams({
      ...initParams,
    });
  };

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      page: currentPage,
    }));
  }, [currentPage]);

  return {
    data,
    isFetching,
    isError,
    items,
    pagination,
    handleFilterChange,
    handleFilterReset,
  };
};
