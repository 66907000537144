
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountdown } from 'usehooks-ts';
import cn from 'classnames';

import { useGetBetgamesTokenQuery } from 'store/slots/slotsApi';
import { useAppSelector } from 'hooks';
import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import Loader from 'components/shared/loader/Loader';
import { useResponsive } from 'hooks/useResponsive';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import './BetgamesTwainSport.scss';

type AccessError = {
  data?: {
    detail?: string;
  };
  status?: number;
}

const clientUrl = 'https://web.twainsports.com';
const apiUrl = 'http://7ds0t9ij.twainsports.com';
const wsUrl = 'https://ws.twainsports.com';

const BetgamesTwainSport = () => {
  const { isMobile } = useResponsive();
  const { i18n, t } = useTranslation();
  const { gameId } = useParams();
  const [isPageLoading, setPageLoading] = useState(true);
  const [loadingTimer, { startCountdown }] = useCountdown({
    countStart: 3,
    intervalMs: 1000
  });
  const currency = useAppSelector(selectPrimaryWalletCurrency);
  const { data, isLoading, isFetching, isError, error, isSuccess } = useGetBetgamesTokenQuery({
    currency: currency as string
  }, { skip: !currency });

  const errorDetails = error as AccessError;

  let errorDesc = 'Ошибка авторизации игры';
  if (isError && errorDetails?.data?.detail) {
    if (errorDetails?.data?.detail === 'Slots not available for this account') {
      errorDesc = 'Игра не доступна для вашего акканта';
    } else {
      errorDesc = errorDetails?.data?.detail;
    }
  }

  const partnerCode = data?.partnerCodeTwain;
  const partnerToken = data?.token ?? '-';
  const defaultGame = gameId ?? '24';
  const language = i18n.resolvedLanguage ?? 'en';
  const mobile = isMobile ? 1 : 0;

  useEffect(() => {
    startCountdown();
  }, []);

  useEffect(() => {
    if (loadingTimer === 0 && !isLoading && !isFetching) {
      setPageLoading(false);
    }
  }, [isLoading, isFetching, loadingTimer]);

  useEffect(() => {
    if (data?.token && partnerCode && isSuccess) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = `${clientUrl}/public/ts-loader.js?${Date.now()}`;
      script.id = 'betgames_script';
      script.onload = () => {
        // @ts-ignore
        window.TwainSports.setup({
          containerElement: document.getElementById('betgames__container'),
          clientUrl: clientUrl,
          apiUrl: apiUrl,
          wsUrl: wsUrl,
          partnerCode: partnerCode,
          token: partnerToken,
          timezone: '3',
          locale: language,
          dynamicHeight: '1',
          platformID: `${mobile}`,
          gameId: defaultGame
        });
      };

      document.head.appendChild(script);
    }

    return () => {
      const betgames = document.getElementById('betgames_script');
      betgames?.remove();
      // @ts-ignore
      if (window.BetGames) {
        // @ts-ignore
        window.BetGames = undefined;
      }
    };
  }, [data]);

  return (
    <div className={cn('betgames', { 'betgames--loading': isPageLoading })}>
      <Breadcrumbs
        className='betgames__breadcrumbs'
        link="/games"
        title={t('games.list', 'Список игр')}
      />
      <h1 className='betgames__title'>Twain Sport</h1>
      <Loader wrapperClassName='betgames__spiner' />
      {isError && (
        <div className="betgames__error">
          <p className="betgames__error-title">
            {t('games.error.common', 'Произошла ошибка')}
          </p>
          <p>{errorDesc}</p>
        </div>
      )}
      {isSuccess && (
        <div className="betgames__container" id='betgames__container'></div>
      )}
      {/* <div className="betgames__container" id='betgames__container'>
        {isLoading || isFetching ? <Loader /> : (
          <iframe
            className="betgames__iframe"
            title='BetGames tv'
            frameBorder="0"
            allow={`autoplay; fullscreen; encrypted-media; clipboard-write self ${clientUrl}`}
            scrolling="no"
            src={newSrc}
            style={{ minWidth: '100%', height: '1410px' }}
          >
          </iframe>
        )}
      </div> */}
    </div>
  );
};

export default BetgamesTwainSport;
