import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import Button from 'components/shared/buttons/Button/Button';
import { FAV_ITEMS_PER_PAGE } from 'utils/constants';
import Pagination from 'components/shared/Pagination/Pagination';
import NavMenu from 'components/shared/NavMenu/NavMenu';
import { scrollToTop } from 'utils/scrollToTop';
import { bemCn } from 'utils/bem-cn';
import { useGetFavoriteItems } from 'hooks/useGetFavoriteItems';
import { useResponsive } from 'hooks/useResponsive';

import NoItems from '../components/NoItems/NoItems';
import './FavoriteList.scss';
import FavoriteTournamentCard from './components/FavoriteTournamentCard/FavoriteTournamentCard';
import FavoriteMatchCard from './components/FavoriteMatchCard/FavoriteMatchCard';

import type { FavoriteMatch } from 'types/user-data';


type filterButtonsType = {
  title: string;
  id: number;
};

const filterButtons: filterButtonsType[] = [
  {
    title: i18n.t('favorite.tournaments', 'Турниры'),
    id: 0
  },
  {
    title: i18n.t('favorite.matches', 'Матчи'),
    id: 1
  }
];

const b = bemCn('favorite-list');
const FavoriteList = () => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [filter, setFiler] = useState(0);
  const { Mobile, Desktop, isMobile } = useResponsive();

  const { data: favoriteList, isEmpty, isLoading, totalCout } = useGetFavoriteItems({
    type: filter,
    pageNumber: selectedPage
  });

  useEffect(() => {
    scrollToTop(true);
  }, [selectedPage]);

  useEffect(() => {
    setSelectedPage(1);
  }, [filter]);

  return (
    <div className={b()}>
      <Breadcrumbs link={isMobile ? '/profile' : '/'} title={t(isMobile ? 'main.nav.Profile' : 'main.nav.Home', 'Главная')} />
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <h1 className={b('top-title')}>
            {t('common.favorite', 'Список избранных')}
          </h1>
          <Desktop>
            <div className={b('filter')}>
              {filterButtons.map((item) => (
                <Button className={b('filter-btn')}
                  key={item.id}
                  variant={item.id !== filter ? 'shaded' : undefined}
                  onClick={() => setFiler(item.id)}
                >
                  {item.title}
                </Button>
              ))}
            </div>
          </Desktop>
        </div>
        <Mobile>
          <div style={{ marginTop: '10px' }} className={b('nav')}>
            <NavMenu
              menuItems={filterButtons}
              activeItemId={filter}
              onClick={(id) => setFiler(id)}
            />
          </div>
        </Mobile>
        <div className={b('list')}>
          {(isLoading || isEmpty)
            ? (
              <NoItems
                isLoading={isLoading}
                isEmpty={isEmpty}
                text={`${t('common.favorite')}`}
              />
            )
            : favoriteList.map((favoriteItem) => filter === 0
              ? <FavoriteTournamentCard key={favoriteItem.id} tournament={favoriteItem} />
              : <FavoriteMatchCard key={favoriteItem.id} match={favoriteItem as FavoriteMatch} />
            )}
        </div>
        <div>
          <Pagination
            selectPage={selectedPage}
            countPages={Math.ceil(totalCout / FAV_ITEMS_PER_PAGE)}
            setSelectPage={(value) => setSelectedPage(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default FavoriteList;
