import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { DateTime } from 'utils/time';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet, selectAdditionalWallets } from 'store/user/selectors';

import './PPFilter.scss';

import type { TFilterValues } from 'store/partnerProgram/types';

type Props = {
  onSubmit: SubmitHandler<TFilterValues>;
  onReset?: () => void;
  light?: boolean;
};

const transformDate = (date: string) => {
  const dateFrom = new DateTime(date).startOf().toISOString();
  const dateTo = new DateTime(date).endOf().toISOString();

  return {
    dateFrom,
    dateTo,
  };
};

const PPFilter = (props: Props) => {
  const {
    onSubmit,
    onReset,
    light,
  } = props;

  const className = light ? 'pp-filter-light' : 'pp-filter';
  const b = bemCn(className);
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program.filter' });

  const userCurrency = useAppSelector(selectPrimaryWallet).currency ?? 'RUB';
  const userCurrencyList = [userCurrency, ...useAppSelector(selectAdditionalWallets).map((wallet) => wallet.currency)];

  const { register, setValue, reset, handleSubmit } = useForm<TFilterValues>({
    defaultValues: {
      currency: userCurrency,
    }
  });

  const onFinish: SubmitHandler<TFilterValues> = (data) => {
    const dateFrom = data.createdFrom ? transformDate(data.createdFrom) : undefined;
    const dateTo = data.createdTo ? transformDate(data.createdTo) : undefined;
    setValue('currency', data.currency);

    const newData: TFilterValues = {
      currency: data.currency,
      createdFrom: dateFrom?.dateFrom,
      createdTo: dateTo?.dateTo,
    };

    onSubmit(newData);
  };

  const handleReset = () => {
    reset({
      currency: userCurrency,
      createdFrom: '',
      createdTo: '',
    });

    onReset?.();
  };

  return (
    <form className={b()} onSubmit={handleSubmit(onFinish)}>
      <p className={b('item')}>
        <label htmlFor="currency" className={b('label')}>
          {t('currency', 'Валюта')}
        </label>
        <select
          id="currency"
          className={b('select')}
          {...register('currency')}
        >
          {userCurrencyList.map((currency) => (
            <option
              className={b('option')}
              key={currency}
              value={currency}
            >
              {currency}
            </option>
          ))}
        </select>
      </p>

      <p className={b('item')}>
        <label htmlFor="createdFrom" className={b('label')}>
          {t('date-from', 'Дата (от)')}
        </label>
        <input
          type="date"
          id="createdFrom"
          placeholder="Select date"
          className={b('input')}
          {...register('createdFrom')}
        />
      </p>

      <p className={b('item')}>
        <label htmlFor="createdFrom" className={b('label')}>
          {t('date-to', 'Дата (to)')}
        </label>
        <input
          type="date"
          id="createdTo"
          placeholder="Select date"
          className={b('input')}
          {...register('createdTo')}
        />
      </p>

      <button
        type="submit"
        className={b('btn')}
      >
        {t('apply', 'Применить')}
      </button>

      <button
        type="button"
        className={b('btn')}
        onClick={handleReset}
      >
        {t('reset', 'Сбросить')}
      </button>
    </form>
  );
};

export default PPFilter;
