import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';
import { ppConverter } from 'store/partnerProgram/ppConverter';

import { useManagerPartnerPromocodes } from '../hooks/useManagerPartnerPromocodes';
import PPDropdown from '../../components/PPDropdown/PPDropdown';

import type { PartnerPromocode, TTableProps } from 'store/partnerProgram/types';

interface TableData extends Omit<PartnerPromocode, 'actions'> {
  actions: string;
}

type Props = {
  id: number;
};

const PPManagerPartnerPromocodes = (props: Props) => {
  const {
    id,
  } = props;

  const b = bemCn('pp-manager');
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const {
    items,
    isFetching,
    isError,
    pagination,
    actionList,
    handleActionsClick,
  } = useManagerPartnerPromocodes(id ?? 0);

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (title, item) => (
        <Link to={`promocodes/${item.id}`}>{item.id}</Link>
      ),
    },
    {
      title: 'Создана',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: `${t('name', 'Имя')}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t('script-id', 'ID скрипта')}`,
      dataIndex: 'scriptId',
      key: 'scriptId',
    },
    {
      title: `${t('description', 'Описание')}`,
      dataIndex: 'scriptDescription',
      key: 'scriptDescription',
    },
    {
      title: `${t('actions', 'Действия')}`,
      dataIndex: 'actions',
      key: 'actions',
      render: (title, item: TableData) => (
        <PPDropdown
          items={actionList}
          onClick={(key) => handleActionsClick(item.id, key)}
        />
      ),
    },
  ];

  return (
    <>
      {/* <h2 className={b('title')}>Промокоды партнёра с ID {id}</h2> */}

      <PPTable
        columns={columns}
        className={b('table')}
        isLoading={isFetching}
        isError={isError}
        sourceData={ppConverter.managerPartnerPromocodes(items ?? []) as unknown as TableData[]}
        pagination={pagination}
      />
    </>
  );
};

export default PPManagerPartnerPromocodes;
