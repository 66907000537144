import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';

import type { SubGame } from 'types/line-data';
import './EvetnCardTabs.scss';

type Props = {
  onTabChange: (id: number) => void;
  tabs: SubGame[];
  activeTab: number;
  onToggleClose: () => void;
  className?: string;
}

const b = bemCn('event-card-tabs');

const EvetnCardTabs = (props: Props) => {
  const { t } = useTranslation();
  const { tabs, onTabChange, activeTab, onToggleClose, className } = props;
  const containerRef = useHorizontalScroll<HTMLDivElement>();

  return (
    <div className={b(null, className)}>
      <BetsCloseToggler onClick={onToggleClose} />
      <div className={b('wrapper')} ref={containerRef}>
        <Tab
          key="0"
          onClick={() => onTabChange(0)}
          isActive={activeTab === 0}
          title={`${t('event.bets.main-tab', 'Основные')}`}
        />
        {tabs.map((tab) => (
          <Tab key={tab.gameId}
            onClick={() => onTabChange(tab.gameId)}
            isActive={activeTab === tab.gameId}
            title={tab.gameName}
          />
        ))}
      </div>
    </div>
  );
};

type TabProps = {
  onClick: () => void;
  title?: string;
  isActive: boolean;
}

const Tab = ({ onClick, title, isActive }: TabProps) => (
  <button className={b('item', { active: isActive })}
    type='button'
    onClick={onClick}
  >
    {title}
  </button>
);

type ToggleProps = {
  onClick: () => void;
}

const BetsCloseToggler = ({ onClick }: ToggleProps) => (
  <button className={b('toogle-close')}
    type='button'
    onClick={onClick}
  >
    <SvgIcon className={b('toogle-icon')}
      name='collapse-all'
      width={12}
      height={12}
    />
  </button>
);


export default EvetnCardTabs;
