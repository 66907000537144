import React, { ButtonHTMLAttributes } from 'react';

import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import './Button.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary' | 'outline-primary-inverted' | 'shaded' | 'login';
  size?: 'medium' | 'small';
  disabled?: boolean;
  animated?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
} & typeof defaultProps;

const defaultProps = {
  variant: 'primary',
  size: 'medium',
  disabled: false,
  animated: false,
  type: 'button'
};

const Button = (props: Props) => {
  const {
    children,
    variant,
    size,
    disabled,
    onClick,
    animated,
    isLoading,
    className,
    type = 'button'
  } = props;

  const b = bemCn('button');

  return (
    <button
      type={type}
      className={b(
        { size: size, 'flare-animated': animated, [`${variant}`]: !!variant }, className
      )}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {children}
      {isLoading && (
        <Loader className={b('loader', { [`${variant}`]: !!variant })}
          wrapperClassName={b('loader-wrapper')}
        />
      )}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default React.memo(Button);
