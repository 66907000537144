import { Route, Routes } from 'react-router-dom';

import BetgamesCasino from 'pages/GamesPage/BetgamesCasino/BetgamesCasino';
import BetgamesTwainSport from 'pages/GamesPage/BetgamesTwainSport/BetgamesTwainSport';
import { useAppSelector } from 'hooks';
import { useGetUserAccountQuery } from 'store/user/userApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import NotFounded from 'pages/NotFound/NotFound';
import ProtectedRoute from 'components/shared/ProtectedRoute/ProtectedRoute';
import {
  selectIsInbetActive,
  selectIsPragmaticActive,
  // selectIsVegasActive,
  seletIsBetgameActive,
  selectIsMascotActive,
  selectIsAgtActive,
  selectIsTvbetActive,
  selectIsAviatrixActive,
} from 'store/app/selectors';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';

import GamesList from './GamesList/GamesList';
import InbetGames from './InbetGames/InbetGames';
// import VegasClub from './VegasClub/VegasClub';
import Mascot from './Mascot/Mascot';
import AgtGame from './AgtGame/AgtGame';
import TVBet from './TVBet/TVBet';
import Fastsport from './Fastsport/Fastsport';
import PragmaticPlay from './PragmaticPlay/PragmaticPlay';
import GamesLayout from './GamesLayout/GamesLayout';
import Smartsoft from './Smartsoft/Smartsoft';
import Aviatrix from './Aviatrix/Aviatrix';

const GamesPage = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isBetgameActive = useAppSelector(seletIsBetgameActive);
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const isPragmaticActive = useAppSelector(selectIsPragmaticActive);
  // const isVegasActive = useAppSelector(selectIsVegasActive);
  const isMascotActive = useAppSelector(selectIsMascotActive);
  const isAgtActive = useAppSelector(selectIsAgtActive);
  const isTvbetActive = useAppSelector(selectIsTvbetActive);
  const isAviatrixActive = useAppSelector(selectIsAviatrixActive);

  const { isLoading: isAccountLoading } = useGetUserAccountQuery();

  return (
    <main>
      <GamesLayout>
        <Routes>
          <Route index element={<GamesList />} />
          <Route path="/betgames" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isBetgameActive
                ? <BetgamesCasino />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/tvbet" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isTvbetActive
                ? <TVBet />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/tvbet/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isTvbetActive
                ? <TVBet />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/fastsport" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isTvbetActive
                ? <Fastsport />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/fastsport/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isTvbetActive
                ? <Fastsport />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/betgames/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isBetgameActive
                ? <BetgamesCasino />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/twain-sport" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isBetgameActive
                ? <BetgamesTwainSport />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/twain-sport/:gameID" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isBetgameActive
                ? <BetgamesTwainSport />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />

          <Route path="/inbetgames/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isInbetActive
                ? <InbetGames />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          {/* <Route path="/vegas/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isVegasActive
                ? <VegasClub />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          /> */}
          <Route path="/pragmatic/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isPragmaticActive
                ? <PragmaticPlay />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/mascot/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isMascotActive
                ? <Mascot />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/agt/:gameId" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isAgtActive
                ? <AgtGame />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />
          <Route path="/smartsoft/:gameName/:gameCategory" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              <Smartsoft />
            </ProtectedRoute>
          }
          />
          <Route path="/aviatrix" element={
            <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
              {isAviatrixActive
                ? <Aviatrix />
                : <SiteMaintenance message={Maintenance.games} />}
            </ProtectedRoute>
          }
          />

          <Route path="*" element={<NotFounded />} />
        </Routes>
      </GamesLayout>
    </main>
  );
};

export default GamesPage;
