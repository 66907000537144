import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import './NoItems.scss';

interface Props {
  withButton?: boolean;
  isEmpty?: boolean;
  text?: string;
  isLoading: boolean;
  buttonText?: string;
}

const NoItems = (props: Props) => {
  const { withButton, isLoading, isEmpty, buttonText, text } = props;
  const { t } = useTranslation();
  const b = bemCn('no-items');

  return (
    <div className={b()}>
      {isLoading && <Loader />}
      {!isLoading && isEmpty && (
        <div className={b('wrapper')}>
          <p>{text ?? t('transactions.empty-list', 'Нет операций')}</p>
          {withButton && (
            <div className={b('button-wrapper')}>
              <Link to="/home">
                <Button variant="primary">{buttonText}</Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(NoItems);
