import { useGetPartnerPromocodeScriptsQuery } from 'store/partnerProgram/partnerApi';

export const usePromocodeScripts = () => {
  const {
    data,
    isFetching,
    isError,
  } = useGetPartnerPromocodeScriptsQuery();

  return {
    data,
    isFetching,
    isError,
  };
};
