import { type AppStatus, Status } from 'types/auth-data';
import { TBannerResBody, TBanner } from 'types/banners';

import { AppStatusResponse } from './appApi';

import type { Currency } from 'types/wallets-data';

export const transformAppStatus = (res: AppStatusResponse): AppStatus => ({
  system: res.system ? Status.Active : Status.NotActive,
  line: res.system && res.line ? Status.Active : Status.NotActive,
  betgames: res.system && res.betgames ? Status.Active : Status.NotActive,
  tgg: res.system && res.tgg ? Status.Active : Status.NotActive,
  inbet: res.system && res.inbet ? Status.Active : Status.NotActive,
  vegas: res.system && res.vegas ? Status.Active : Status.NotActive,
  mascot: res.system && res.mascot ? Status.Active : Status.NotActive,
  agt: res.system && res.agt ? Status.Active : Status.NotActive,
  withdrawals: res.system && res.withdrawals ? Status.Active : Status.NotActive,
  pragmatic: res.system && res.pragmatic ? Status.Active : Status.NotActive,
  smartsoft: res.system && res.smartsoft ? Status.Active : Status.NotActive,
  tvbet: res.system && res.tvbet ? Status.Active : Status.NotActive,
  aviatrix: res.system && res.aviatrix ? Status.Active : Status.NotActive,
  systemMessage: res.status_message,

  // system: Status.NotActive,
  // line: Status.NotActive,
  // betgames: Status.NotActive,
  // tgg: Status.NotActive,
  // inbet: Status.NotActive,
});

export const filterCurrencies = (currencies: Currency[] = []): Currency[] =>
  currencies.filter((currency) =>
    currency !== 'DEMO'
    && currency !== 'BTC'
    && currency !== 'BNB'
    && currency !== 'ETH'
    && currency !== 'FUN'
  );


export type AppsportServer = {
  'name': string;
  'blacklisted': boolean;
  'tournaments_priority': {
    [key: string]: string[];
  };
  'sub_games_black_list': string[];
  'tournaments_black_list': string[];
}

export type Appsport = {
  'name': string;
  'blacklisted': boolean;
  'tournamentsPriority': {
    [key: number]: string[];
  };
  'subGamesBlackList': string[];
  'tournamentsBlackList': string[];
}

const adapteAppSport = (sport: AppsportServer): Appsport => ({
  name: sport.name,
  blacklisted: sport.blacklisted,
  subGamesBlackList: sport.sub_games_black_list,
  tournamentsBlackList: sport.tournaments_black_list,
  tournamentsPriority: Object.fromEntries(
    Object.entries(sport.tournaments_priority)
      .map(([pLevel, pValue]) => ([Number(pLevel), pValue]))
  )
});

export type AppSports = {
  items: Appsport[];
  tournamentsPriority: string[];
  tournamentsBlackList: string[];
  sportsBlacklist: string[];
  subGamesBlackList: string[];
}

export const convertAppSports = (sports: AppsportServer[]): AppSports => {
  const tournamentsBlackList: string[] = [];
  const sportsBlacklist: string[] = [];
  const subGamesBlackList: string[] = [];

  let tournamentsPriority: string[] = [];
  const tournamentsByPriorityLevel: {
    [key: number]: string[];
  } = {};
  const items: Appsport[] = [];

  for (const sport of sports) {
    if (sport.blacklisted) {
      sportsBlacklist.push(sport.name.toLowerCase());
    }
    items.push(adapteAppSport(sport));

    subGamesBlackList.push(...sport.sub_games_black_list.map((item) => item.toLowerCase()));
    tournamentsBlackList.push(...sport.tournaments_black_list.map((item) => item.toLowerCase()));

    Object.entries(sport.tournaments_priority)
      .forEach(([pLevel, pValue]) => {
        const lvl = parseInt(pLevel, 10);

        if (!tournamentsByPriorityLevel[lvl]) {
          tournamentsByPriorityLevel[lvl] = [];
        }
        tournamentsByPriorityLevel[lvl] = [
          ...tournamentsByPriorityLevel[lvl],
          ...pValue,
        ];
      });
  }

  Object.entries(tournamentsByPriorityLevel)
    .sort(([lvlA], [lvlB]) => parseInt(lvlA, 10) - parseInt(lvlB, 10))
    .forEach(([pLevel, pValue]) => {
      tournamentsPriority = [
        ...tournamentsPriority,
        ...pValue,
      ];
    });

  return {
    items: items,
    tournamentsPriority: tournamentsPriority
      .map((item) => item.toLowerCase()),
    tournamentsBlackList: tournamentsBlackList,
    sportsBlacklist: sportsBlacklist,
    subGamesBlackList: subGamesBlackList,
  };
};

export const convertGetBannersRes = (res: TBannerResBody[]): TBanner[] => (
  res.map((banner) => ({
    id: banner.id,
    name: banner.name,
    isDesktop: banner.is_desktop,
    link: banner.link,
    currency: banner.currency,
    priority: banner.priority,
    url: banner.url
  }))
);
