import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useToggleFavoriteEvent } from 'hooks/useToggleFavoriteEvent';
import { monthList, toLocaleDateString, toLocaleTimeString } from 'utils/time';
import { getCoefTitle } from 'utils/line';
import Coefficient from 'components/line/components/Coefficient/Coefficient';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { bemCn } from 'utils/bem-cn';
import { useAddToBasketMutation } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import { AddFavoriteMatchBody as EventInfo } from 'types/user-data';

import type { Bet, EventItem } from 'types/line-data';

import './EventCardDesktop.scss';

const EmptyCoeffs = () => (
  <>
    <Coefficient title="-" onClick={() => undefined} />
    <Coefficient title="-" onClick={() => undefined} />
    <Coefficient title="-" onClick={() => undefined} />
  </>
);

type Props = {
  event: EventItem;
  basketItem?: string | null;
  className?: string;
  isFavorite: boolean;
  favoriteId: string;
};

const b = bemCn('event-card-desktop');
const EventCardDesktop = (props: Props) => {
  const {
    event,
    basketItem,
    className,
    isFavorite,
    favoriteId
  } = props;
  const { toggleFavoriteMatch, isLoading } = useToggleFavoriteEvent();
  const { i18n } = useTranslation();
  const date = toLocaleDateString(new Date(event.eventDate));
  const time = toLocaleTimeString(new Date(event.eventDate));
  const dateFormatText = `${date.split('.')[0]} ${monthList[Number(date.split('.')[1]) - 1]}`;
  const dateFormatDigit = `${date.split('.')[0]}.${date.split('.')[1]}`;
  const timeWithoutSeconds = `${time.split(':')[0]}:${time.split(':')[1]}`;
  const scorePeriodFormatSeparator = event.scorePeriod.split(';').join(' | ');
  const isLive = event.dataType === 'live';
  const isBetsStoped = event.group.name === 'stopped';
  const [addToBasket] = useAddToBasketMutation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const eventInfo: EventInfo = {
    'matchId': event.id,
    'team1': event.team1,
    'team2': event.team2,
    'sportName': event.sportName,
    'sportId': event.sportId,
    'tournamentName': event.tournament,
    'tournamentId': event.tournamentId,
    'countryId': event.countryId,
    'countryName': event.countryName,
    'dataType': event.dataType,
  };

  const handleStarClick = () => {
    if (!isLoading) {
      toggleFavoriteMatch({ eventInfo, isFavorite, favoriteId });
    }
  };

  const handleAddBetToBasket = (bet: Bet) => {
    if (isAuth) {
      addToBasket({
        dataType: event.dataType,
        basketId: bet.betId,
        lang: i18n.resolvedLanguage,
      });
    } else {
      dispatch(changeActiveModal('sign-in'));
    }
  };

  return (
    <div className={b(null, className)}>
      <FavoriteBtn size={12} active={isFavorite} onClick={handleStarClick} className={b('star-icon')} />
      <Link to={`/fullevent/${event.id}/${event.dataType}/${event.countryName}/${event.countryId}`} className={b('link')}>
        <p className={b('date')}>
          {/* <span>{dateFormatText}</span> */}
          <span>{dateFormatDigit}</span>
          <span className={b('date-time')}>{timeWithoutSeconds}</span>
        </p>
        <div className={b('info', { center: event.dataType === 'line' })}>
          <div className={b('teams')}>
            <p className={b('team')}>{event.team1}</p>
            <p className={b('team')}>{event.team2}</p>
          </div>
          {isLive && (
            <div className={b('stats')}>
              <p className={b('score')}>
                <span className={b('score-total')}>{event.scoreFull}</span>
                <span className={b('score-periods')}>{scorePeriodFormatSeparator}</span>
              </p>
              <p className={b('timer')}>
                <SvgIcon name='time-machine' width={11} height={11} />
                <span>{Math.floor(event.timer / 60)}′</span>
              </p>
            </div>
          )}
        </div>
      </Link>
      <div className={b('bets')}>
        {isBetsStoped
          ? <EmptyCoeffs />
          : event.group.bets.slice(0, 3).map((bet) => (
            <Coefficient
              className={b('coeff')}
              key={bet.betId}
              title={getCoefTitle(bet.caption, event.team1, event.team2)}
              value={bet.rate}
              isActive={basketItem === bet.betId}
              onClick={() => handleAddBetToBasket(bet)}
              isBlocked={bet.blocked}
            />
          ))}
      </div>
    </div>
  );
};

export default EventCardDesktop;
