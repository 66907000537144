import React, { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from 'store/user/userApi';

import ThemeContext, { AVAILABLE_THEMES, getTheme, saveTheme } from './ThemeContext';

type Props = {
  children: React.ReactNode;
};

const setDocumentTheme = (theme: string) => {
  document.documentElement.className = '';
  document.documentElement.classList.add(`theme--${theme}`);
};

const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState(getTheme());
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: userSettings } =
    useGetUserSettingsQuery(undefined, { skip: !isAuth });
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const userTheme = userSettings?.colorScheme;

  useEffect(() => {
    setDocumentTheme(theme);
    saveTheme(theme);
  }, [theme]);

  useEffect(() => {
    const isThemeValid = !!userTheme
      && AVAILABLE_THEMES.includes(userTheme)
      && userTheme !== theme;

    if (isThemeValid) {
      setTheme(userTheme);
    }
  }, [userTheme]);

  const changeTheme = useCallback((newTheme: string) => {
    if (AVAILABLE_THEMES.includes(newTheme)) {
      setTheme(newTheme);
      if (isAuth && userSettings) {
        updateUserSettings({
          ...userSettings,
          colorScheme: newTheme,
        });
      }
    }
  }, [isAuth, userSettings]);


  return (
    <ThemeContext.Provider value={{ theme, setTheme: changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
