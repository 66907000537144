import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import './EventCardStream.scss';

type Props = {
  streamUrl: string;
  className?: string;
}

const EventCardStream = ({ className, streamUrl }: Props) => {
  const { t } = useTranslation();
  const [isClosed, toggleClosed] = useToggle(true);
  const b = bemCn('event-card-stream');

  return (
    <div className={b({ closed: isClosed }, className)}>
      <button className={b('title')}
        type='button'
        onClick={toggleClosed}
      >
        {t('event.broadcast', 'Трансляция')}
        <SvgIcon className={b('toggle-icon')}
          name='chevron-down'
          width={10}
          height={10}
        />
      </button>
      {!isClosed && (
        <div className={b('stream-box')}>
          <iframe className={b('stream-iframe')} src={streamUrl} frameBorder="0" allowFullScreen scrolling='no'></iframe>
        </div>
      )}

    </div>
  );
};

export default EventCardStream;
