import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authApi } from 'store/auth/authApi';
import { NameSpace } from 'utils/constants';

import {
  deleteToken,
  handleLoginErrors,
  handleRegisterErrors,
  handleRegisterPhoneError,
  handleRegisterTGErrors,
  setToken,
} from './utils';
import { userApi } from '../user/userApi';

import type { BackendError, ShortError } from './utils';
import type { AuthState } from 'types/state';
import type { AuthModalType, LoginErrors, RegisterEmailErrors, RegisterPhoneErrors, RegisterTelegramErrors } from 'types/auth-data';

export const initialLoginError: LoginErrors = {
  email: null,
  password: null,
  common: null
};

export const initialRegisterError: RegisterEmailErrors = {
  email: null,
  password: null,
  name: null,
  common: null,
  promocode: null,
};

export const initialRegisterTelegramError: RegisterTelegramErrors = {
  'telegram_nickname': null,
  password: null,
  name: null,
  common: null,
  promocode: null,
};

export const initialPhoneRegError: RegisterPhoneErrors = {
  phone: null,
  password: null,
  confirmPassword: null,
  common: null,
  promocode: null,
};

const initialState: AuthState = {
  token: null,
  isAuthenticated: false,
  activeModal: null,
  loginError: initialLoginError,
  registerEmailError: initialRegisterError,
  registerTelegramError: initialRegisterTelegramError,
  registerPhoneError: initialPhoneRegError,
  phoneVerifyError: null,
  passwordResetId: null,
  verifyPhone: null,
  telegramVereficationUrl: null,
  isClickRegistration: false,
};

export const authData = createSlice({
  name: NameSpace.Auth,
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      deleteToken();
    },
    changeActiveModal: (state, action: PayloadAction<AuthModalType | null>) => {
      state.activeModal = action.payload;
    },
    clearTGVereficationUrl: (state) => {
      state.telegramVereficationUrl = null;
    },
    setClickRegistration: (state, action: PayloadAction<boolean>) => {
      state.isClickRegistration = action.payload;
    },
    setVerifyPhone: (state, action: PayloadAction<string>) => {
      state.verifyPhone = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      /* Login */
      .addMatcher(authApi.endpoints.login.matchPending, (state) => {
        state.loginError = initialLoginError;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const token = action.payload.access_token;
        state.activeModal = null;
        state.token = token;
        setToken(token);
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        state.loginError = handleLoginErrors(status, data);
      })

      /*  Registration */
      .addMatcher(authApi.endpoints.emailRegister.matchPending, (state) => {
        state.registerEmailError = initialRegisterError;
      })
      .addMatcher(authApi.endpoints.emailRegister.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        state.registerEmailError = handleRegisterErrors(status, data);
      })
      .addMatcher(authApi.endpoints.emailRegister.matchFulfilled, (state) => {
        state.activeModal = 'confirm-email';
      })
      .addMatcher(authApi.endpoints.registrationTelegram.matchPending, (state) => {
        state.registerTelegramError = initialRegisterTelegramError;
      })
      .addMatcher(authApi.endpoints.registrationTelegram.matchFulfilled, (state, action) => {
        state.telegramVereficationUrl = action.payload.telegram_bot_url;
      })
      .addMatcher(authApi.endpoints.registrationTelegram.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as BackendError;
        const status = action.payload.status as number;
        state.registerTelegramError = handleRegisterTGErrors(status, data);
      })
      .addMatcher(authApi.endpoints.phoneRegistration.matchPending,
        (state) => {
          state.registerPhoneError = initialPhoneRegError;
        })
      .addMatcher(authApi.endpoints.phoneRegistration.matchFulfilled,
        (state) => {
          state.activeModal = 'confirm-phone';
        })
      .addMatcher(authApi.endpoints.phoneRegistration.matchRejected,
        (state, action) => {
          if (!action.payload) { return; }
          const data = action.payload.data as BackendError;
          const status = action.payload.status as number;
          state.registerPhoneError = handleRegisterPhoneError(status, data);
        })
      /*  Email Verify */
      .addMatcher(authApi.endpoints.emailVerify.matchFulfilled, (state) => {
        state.activeModal = 'success';
      })
      .addMatcher(authApi.endpoints.emailVerify.matchPending, (state) => {
        state.activeModal = null;
      })
      .addMatcher(authApi.endpoints.emailVerify.matchRejected, (state) => {
        state.activeModal = 'error';
      })

      /*  Reset Password Request */
      .addMatcher(authApi.endpoints.passwordResetSms.matchFulfilled, (state) => {
        state.activeModal = 'confirm-reset-password-sms';
      })
      /*  Change Password */
      .addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state) => {
        state.activeModal = 'reset-success';
      })
      .addMatcher(authApi.endpoints.changePassword.matchPending, (state) => {
        state.activeModal = null;
      })
      .addMatcher(authApi.endpoints.changePassword.matchRejected, (state) => {
        state.activeModal = 'reset-error';
      })

      /*  Authentication test */
      .addMatcher(userApi.endpoints.getUserAccount.matchFulfilled, (state) => {
        state.isAuthenticated = true;
        state.isClickRegistration = false;
      })
      .addMatcher(userApi.endpoints.getUserAccount.matchRejected, (state, action) => {
        if (action.payload && action.payload.status === 401) {
          state.token = null;
          deleteToken();
          if (state.isClickRegistration) {
            state.activeModal = 'sign-up';
            state.isClickRegistration = false;
          }
        }
      })
      /* Phone verify */
      .addMatcher(authApi.endpoints.phoneVerify.matchPending, (state) => {
        state.phoneVerifyError = null;
      })
      .addMatcher(authApi.endpoints.phoneVerify.matchFulfilled, (state) => {
        state.activeModal = 'success';
      })
      .addMatcher(authApi.endpoints.phoneVerify.matchRejected, (state, action) => {
        if (!action.payload) { return; }
        const data = action.payload.data as ShortError;
        state.phoneVerifyError = data.detail;
      })
      /* Restore password sms verify */
      .addMatcher(authApi.endpoints.passwordPhoneVerify.matchFulfilled, (state, action) => {
        state.passwordResetId = action.payload;
        state.activeModal = 'change-password';
      });
  }
});

export const {
  logout,
  changeActiveModal,
  clearTGVereficationUrl,
  setClickRegistration,
  setVerifyPhone
} = authData.actions;

export { initialState as initialAuthState };
