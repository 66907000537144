import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';
import { mainPageLinks } from 'utils/constants/mainPageLinks';
import { useResponsive } from 'hooks/useResponsive';

import MainNavItem from './MainNavItem/MainNavItem';

import './MainNav.scss';

const MainNav = () => {
  const { pathname } = useLocation();
  const { isMobile } = useResponsive();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const containerRef = ref.current;
    if (containerRef === null || !isMobile) {
      return;
    }
    const leftShadowClass = 'main-nav--active-left-shadow';
    const rightShadowClass = 'main-nav--active-right-shadow';
    const scrollEvent = () => {
      const leftScrollDistance = containerRef.scrollLeft;
      const rightScrollDistance = containerRef.scrollWidth - containerRef.clientWidth - leftScrollDistance;
      if (leftScrollDistance > 0) {
        containerRef.classList.add(leftShadowClass);
      } else {
        containerRef.classList.remove(leftShadowClass);
      }
      if (rightScrollDistance > 0) {
        containerRef.classList.add(rightShadowClass);
      } else {
        containerRef.classList.remove(rightShadowClass);
      }
    };
    scrollEvent();
    containerRef.addEventListener('scroll', scrollEvent);
    return (() => containerRef.removeEventListener('scroll', scrollEvent));
  }, [ref, isMobile]);

  return (
    <div className='main-nav'>
      <div ref={ref} className='main-nav__container'>
        {
          mainPageLinks.map(({ id, title, link }) =>
            <MainNavItem key={id} id={id} title={title} link={link} isActive={pathname.startsWith(link)} />)
        }
      </div>
    </div>
  );
};

export default MainNav;
