import { Swiper } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import Loader from 'components/shared/loader/Loader';

import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  children?: React.ReactNode;
  isLoading?: boolean;
  itemsCount: number;
}

const GamesCardsWidgetSwiper = ({ children, isLoading, itemsCount }: Props) => {
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      speed={1500}
      loop={itemsCount > 3}
      loopedSlides={null}
      noSwiping
      spaceBetween={10}
      slidesPerView="auto"
      modules={[Pagination, Autoplay]}
      className="games-cards-widget-mobile__swiper"
    >
      {children}
    </Swiper>
  );
};

export default GamesCardsWidgetSwiper;
