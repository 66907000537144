import { useRef, useState, useEffect } from 'react';
import { useOnClickOutside, useToggle } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from 'hooks';
import { setUserTimezoneToState } from 'store/user/userSlice';
import { selectUserTimezone } from 'store/user/selectors';
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from 'store/user/userApi';
import { defaultTimezone, timezoneTitles } from 'utils/constants/timezone';
import { getUserTimezone, setUserTimezone } from 'utils/localStorage';

import type { UserSettings } from 'types/user-data';

const timezoneList: number[] = Object.keys(timezoneTitles).map((timezone) => Number(timezone)).sort((a, b) => a - b);

export const useTimezone = () => {
  const userTimezone = useAppSelector(selectUserTimezone);
  const [isSelectorOpened, toggleOpened, setSelectorOpened] = useToggle(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timezoneValue, setTimzoneValue] = useState(defaultTimezone);
  const timezoneBlockRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const {
    data: userSettingsData,
  } = useGetUserSettingsQuery();

  const [updateUserSettings, {
    isSuccess: isUpdateUserSettingsSuccess,
    // isError: isUpdateUserSettingsError,
  }] = useUpdateUserSettingsMutation();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const setTimezone = (timezoneNumber: number) => {
    setTimzoneValue(timezoneNumber);
    dispatch(setUserTimezoneToState({ timezone: timezoneNumber }));
  };

  const onChangeTimezone = async (selectedTimezone: number) => {
    setUserTimezone(selectedTimezone);
    setTimezone(selectedTimezone);

    if (userSettingsData) {
      const newUserSettings: UserSettings = {
        ...userSettingsData,
        timezone: selectedTimezone.toString(),
      };

      await updateUserSettings(newUserSettings);
    }

    setSelectorOpened(false);
  };

  useEffect(() => {
    const storageTimezone = getUserTimezone();
    setTimezone(storageTimezone);
  }, []);

  useEffect(() => {
    if (isUpdateUserSettingsSuccess) {
      setIsModalOpen(true);
    }
  }, [isUpdateUserSettingsSuccess]);

  useOnClickOutside(timezoneBlockRef, () => {
    if (isSelectorOpened) {
      setSelectorOpened(false);
    }
  });

  return {
    userTimezone,
    timezoneList,
    timezoneTitles,
    timezoneValue,
    isModalOpen,
    isSelectorOpened,
    timezoneBlockRef,
    toggleOpened,
    onChangeTimezone,
    handleModalClose,
  };
};
