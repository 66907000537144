import React from 'react';

import Adaptive from 'components/Layout/Adaptive/Adaptive';
import { seletIsLineActive } from 'store/app/selectors';
import { useAppSelector } from 'hooks';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';

import LinePageDesktop from './LinePageDesktop/LinePageDesktop';
import LinePageMobile from './LinePageMobile/LinePageMobile';

const LinePage = () => {
  const isLineActive = useAppSelector(seletIsLineActive);

  if (!isLineActive) {
    return <SiteMaintenance message={Maintenance.line} />;
  }

  return (
    <Adaptive
      desktop={<LinePageDesktop />}
      mobile={<LinePageMobile />}
    />

  );
};

export default LinePage;
