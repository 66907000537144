import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { useAppDispatch } from 'hooks';
import { useFavoriteTournamentToggle } from 'hooks/useFavoriteTournamentToggle';
import { setFilterTournament } from 'store/app/appSlice';
import { bemCn } from 'utils/bem-cn';
import { AddFavoriteTournamentBody } from 'types/user-data';

import './SideBarFilterDesktopTournament.scss';

import type { TournamentItem } from 'types/line-data';

interface newTournamentItem extends Omit<TournamentItem, 'nameRu' | 'nameEn'> {
  nameEn?: string;
  nameRu?: string;
}

type Props = {
  tournament: newTournamentItem;
  isActive: boolean;
  isFavorite: boolean;
  favoriteId?: string;
  tournamentInfo: AddFavoriteTournamentBody;
};

const b = bemCn('side-bar-filter-desktop-tournament');
function SideBarFilterDesktopTournament({ isActive, tournament, isFavorite, favoriteId = 'notFavorited', tournamentInfo }: Props) {
  const { toggleFavoriteTournament, isLoading } = useFavoriteTournamentToggle();
  const dispatch = useAppDispatch();
  const handleTournamentCLick = () => {
    dispatch(setFilterTournament(tournament.id));
  };
  const handleFavoriteClick = () => {
    if (!isLoading) {
      toggleFavoriteTournament({ isFavorite, favoriteId, tournamentInfo });
    }
  };

  return (
    <li className={b()}>
      <button
        className={b('button', { active: isActive })}
        onClick={handleTournamentCLick}
      >
        <span className={b('name')}>{tournament.name}</span>
      </button>
      <FavoriteBtn className={b('favorite-icon')} active={isFavorite} onClick={handleFavoriteClick} />
    </li>
  );
}

export default SideBarFilterDesktopTournament;
