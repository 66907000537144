import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside, useToggle } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from 'hooks';
import { selectPrimaryWallet, selectShowBalanceHeader } from 'store/user/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { useGetWalletsQuery } from 'store/user/userApi';
import WalletsManagement from 'components/profile/components/WalletsManagement/WalletsManagement';
// import Button from 'components/shared/buttons/Button/Button';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import './UserBalanceDesktop.scss';

const UserBalanceDesktop = () => {
  const [isOpened, toggleOpened, setIsOpened] = useToggle(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { amount, currency, cashbackLine, cashbackCasino, bonusAmount } = useAppSelector(selectPrimaryWallet);
  const isShowBalance = useAppSelector(selectShowBalanceHeader);
  const { isLoading: isWalletsLoading } = useGetWalletsQuery();
  const balanceBlockRef = useRef<HTMLDivElement>(null);
  const roundedBalance =
    currency === 'RUB' || currency === 'UAH' || currency === 'USD'
      ? Math.floor((amount * 100)) / 100
      : amount;
  const roundedBonusBalance =
    currency === 'RUB' || currency === 'UAH' || currency === 'USD'
      ? Math.floor((bonusAmount * 100)) / 100
      : bonusAmount;

  useOnClickOutside(balanceBlockRef, () => {
    if (isOpened) {
      setIsOpened(false);
    }
  });

  const handleHiddenClick = () => {
    dispatch(changeActiveModal('settings'));
  };

  // const handleWalletManageClick = () => {
  //   dispatch(changeActiveModal('wallet-manage'));
  // };

  const b = bemCn('user-balance-desktop');

  return (
    <div className={b()} ref={balanceBlockRef}>
      <button className={b('dropdow-btn')} type="button" onClick={toggleOpened}>
        {t('user-nav.balance', 'Баланс')}
        <SvgIcon className={b('dropdow-icon')} name='chevron-down' width={7} height={7} />
      </button>
      {isShowBalance
        ? (
          <div className={b('amounts')}>
            <p className={b('amount')}>{roundedBalance} {currency}</p>
            <p className={b('bonus-amount')}>
              <span className={b('bonus-amount-label')}>{t('user-nav.bonus', 'бонус')}</span>
              / {roundedBonusBalance} {currency}
            </p>
          </div>
        )
        : (
          <button className={b('amount')} type="button" onClick={handleHiddenClick}>
            {t('user-nav.balance-hide', 'Скрыто')}
          </button>
        )}
      {isOpened && (
        <div className={b('dropdow')}>
          <WalletsManagement
            balance={roundedBalance}
            bonusBalance={roundedBonusBalance}
            cashbackLine={cashbackLine}
            cashbackCasino={cashbackCasino}
            currency={currency}
            isWalletsLoading={isWalletsLoading}
          />
          {/* <OtherWallets /> */}
          {/* <Button className={b('all')} onClick={handleWalletManageClick}>
            {t('user-nav.wallets-manage', 'Управление счетами')}
          </Button> */}
        </div>
      )}
    </div>
  );
};

export default UserBalanceDesktop;
