import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import LinkContainer from './LinkContainer';
import LinkIcon from './LinkIcon';
import './ProfileLink.scss';

export type ProfileLinkType = {
  name: string;
  title: string;
  description?: string;
  disabled?: boolean;
  link?: string;
  href?: string;
  simple?: boolean;
  onClick?: (name: string) => void;
}

type Props = {
  title: string;
  description?: string;
  link?: string;
  href?: string;
  disabled?: boolean;
  simple?: boolean;
  name: string;
  onClick?: (name: string) => void;
}

const ProfileLink = (props: Props) => {
  const { title, description, link, href, disabled, onClick, name, simple } = props;

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick(name);
    }
  };

  const b = bemCn('profile-link');

  return (
    <LinkContainer className={b({ 'disabled': disabled, 'simple': simple })}
      href={href}
      link={link}
      onClick={handleClick} disabled={disabled}
    >
      <div className={b('wrapper')}>
        <div className={b('icon')}><LinkIcon name={name} /></div>
        <p className={b('title')}>{title}</p>
        <SvgIcon className={b('arrow')} name='chevron-right' width={8} height={8} />
      </div>
      {description && <p className={b('desc')}>{description}</p>}
    </LinkContainer>
  );
};

export default ProfileLink;
