import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { bemCn } from 'utils/bem-cn';
import CloseButton from 'components/shared/CloseButton/CloseButton';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import './PPDrawer.scss';

type Props = {
  title?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
};

const drawerRoot = document.getElementById('pp-drawer');

const PPDrawer = (props: Props) => {
  const {
    title,
    children,
    isOpen,
    onClose,
  } = props;

  const b = bemCn('pp-drawer');
  const classNames = isOpen ? b({ show: 'drawer' }) : b();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const drawerJSX = (
    <div className={classNames}>
      <div className={b('overlay')}
        onClick={onClose}
      />
      <div className={b('content')}>

        <div className={b('header')}>
          <CloseButton
            className={b('close-btn')}
            onClick={onClose}
          />
          <h2 className={b('title')}>{title}</h2>
        </div>

        <div className={b('body')}>
          {isOpen && children}
        </div>
      </div>
    </div>
  );

  return drawerRoot && createPortal(drawerJSX, drawerRoot);
};

export default PPDrawer;
