import React from 'react';
import { Link } from 'react-router-dom';

import type { BonusListType } from '../../BonusData';

import './BonusItem.scss';

type Props = {
  bonus: BonusListType;
};

const BonusItem = ({ bonus }: Props) => (
  <Link to={bonus.id} className="bonus-item__content">
    <div className="bonus-item__divider" />
    <div className="bonus-item__info">
      <h2 className='bonus-item__title'>{bonus.title}</h2>
      <p>{bonus.subTitle}</p>
    </div>
    {bonus.preview && (
      <img src={bonus.preview} alt="" className="bonus-item__img" />
    )}
    <div />
  </Link>
);

export default BonusItem;
