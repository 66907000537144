import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks';
import { useClickRefCodeMutation } from 'store/auth/authApi';
import { changeActiveModal } from 'store/auth/authSlice';
import { saveRefCode } from 'utils/localStorage';

type Props = {
  isAuthenticated: boolean;
};

const ReferralLogin = ({ isAuthenticated }: Props) => {
  const navigate = useNavigate();
  const { refCode, shortCode } = useParams();
  const [click] = useClickRefCodeMutation();
  const dispatch = useAppDispatch();
  const code = refCode ?? shortCode ?? '';

  useEffect(() => {
    const setCode = async () => {
      if (code && !isAuthenticated) {
        saveRefCode(code);
        await click(code);
      }
    };

    setCode().finally(() => {
      dispatch(changeActiveModal('sign-up'));
      navigate('/');
    });
  }, [code]);

  return null;
};

export default ReferralLogin;
