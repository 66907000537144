import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';


import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import { useGetBetapiCountriesQuery } from 'store/betapi/betapi';
import { routes } from 'utils/routes';
import SportIcon from 'components/shared/icons/SportIcon/SportIcon';
import { bemCn } from 'utils/bem-cn';
import { useFilteredTournaments } from 'hooks/useFilteredTournaments';
import { groupByValue } from 'utils/common';

import CountryItem from './CountryItem/CountryItem';

import './Sport.scss';

const type = 'line';

const Sport = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { id: sportId, name } = useParams();

  const {
    data: countriesList = [],
    isFetching: isCountriesFetching,
  } = useGetBetapiCountriesQuery(
    {
      sportId: sportId,
      dataType: type,
      dataLang: i18n.resolvedLanguage
    },
    { skip: !sportId }
  );

  const {
    data: tournamentsList = [],
    isFetching: isTournamentsFetching
  } = useFilteredTournaments(
    {
      sportId: sportId,
      dataType: type,
      dataLang: i18n.resolvedLanguage
    },
    { skip: !sportId }
  );


  if (!sportId) {
    navigate(-1);
    return (<div />);
  }

  const tournamentsByID = groupByValue(tournamentsList, (item) => item.countryId);

  const handleStarClick = () => navigate('/profile/favorite-list');
  const b = bemCn('sport');
  return (
    <div className={b()}>
      <Breadcrumbs link={routes[type].path} title={routes[type].title} />
      <PageHeader
        icon={<SportIcon sportId={sportId} />}
        id="line"
        title={name ?? sportId}
        controlPanel={[{ type: 'star', onClick: handleStarClick }]}
      />
      <div className={b('content')}>
        <div className={b('countries-list')}>
          {isCountriesFetching
            ? <Loader />
            : countriesList.map((country) => (
              <CountryItem
                key={country.id}
                country={country}
                sportId={sportId}
                sportName={name ?? sportId}
                tournaments={tournamentsByID[country.id] ?? []}
                isLoading={isTournamentsFetching}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sport;
