import AgtWidget from '../widgets/AgtWidget/AgtWidget';
import BetgamesWidget from '../widgets/BetgamesWidget/BetgamesWidget';
import CrashgamesWidget from '../widgets/CrashgamesWidget/CrashgamesWidget';
import FastsportWidget from '../widgets/FastsportWidget/FastsportWidget';
import InbetGamesWidget from '../widgets/InbetGamesWidget/InbetGamesWidget';
import MascotWidget from '../widgets/MascotWidget/MascotWidget';
import NewGamesWidget from '../widgets/NewGamesWidget/NewGamesWidget';
import PopularGamesWidget from '../widgets/PopularGamesWidget/PopularGamesWidget';
import PragmaticPlayWidget from '../widgets/PragmaticPlayWidget/PragmaticPlayWidget';
import SmartsoftWidget from '../widgets/SmartsoftWidget/SmartsoftWidget';
import TVBetWidget from '../widgets/TVBetWidget/TVBetWidget';
import VegasWidget from '../widgets/VegasWidget/VegasWidget';


const GamesWidgets = () => (
  <div>
    {/* <PopularGamesWidget />
    <NewGamesWidget />
    <PragmaticPlayWidget />
    <TVBetWidget />
    <BetgamesWidget />
    <FastsportWidget />
    <InbetGamesWidget /> */}
    <CrashgamesWidget />
    {/* <VegasWidget />
    <MascotWidget />
    <AgtWidget /> */}
    <SmartsoftWidget />
  </div>
);

export default GamesWidgets;
