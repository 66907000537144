import { Link } from 'react-router-dom';
import { MouseEventHandler, useState } from 'react';
import { ClassNameFormatter } from '@bem-react/classname';

import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { bemCn } from 'utils/bem-cn';
import ImgSmart from 'components/shared/ImgSmart/ImgSmart';
import amaticLogo from 'assets/provider-logos/amatic.svg';
import apolloLogo from 'assets/provider-logos/apollo.svg';
import aviatrixLogo from 'assets/provider-logos/aviatrix.svg';
import betsoftLogo from 'assets/provider-logos/betsoft.svg';
import cq9Logo from 'assets/provider-logos/cq9.svg';
import elbetLogo from 'assets/provider-logos/elbet.svg';
import evoplayLogo from 'assets/provider-logos/evoplay.svg';
import hacksawLogo from 'assets/provider-logos/hacksaw.svg';
import igrosoftLogo from 'assets/provider-logos/igrosoft.svg';
import wmgLogo from 'assets/provider-logos/logo_wmg.png';
import netentLogo from 'assets/provider-logos/netent.svg';
import nolimitLogo from 'assets/provider-logos/nolimit.svg';
import playsonLogo from 'assets/provider-logos/playson.svg';
import playtechLogo from 'assets/provider-logos/playtech.svg';
import quickspinLogo from 'assets/provider-logos/quickspin.svg';
import relaxLogo from 'assets/provider-logos/relax.svg';
import smartsoftLogo from 'assets/provider-logos/smartsoft.svg';
import spadegamingLogo from 'assets/provider-logos/spadegaming.svg';
import spribeLogo from 'assets/provider-logos/spribe.svg';
import wazdanLogo from 'assets/provider-logos/wazdan.svg';
import pragmaticLogo from 'assets/pragmatic-logo.svg';

import './GamesCard.scss';


export type GameCardItemType = {
  id?: number | string;
  name: string;
  preview: string | {
    src: string;
    webp?: string;
  };
  link: string;
  label?: string | React.ReactNode;
}

const labelIcons: Record<string, string> = {
  amatic: amaticLogo,
  apollo: apolloLogo,
  aviatrix: aviatrixLogo,
  betsoft: betsoftLogo,
  cqgaming: cq9Logo,
  elbet: elbetLogo,
  evoplay: evoplayLogo,
  hacksaw: hacksawLogo,
  igrosoft: igrosoftLogo,
  netent: netentLogo,
  nolimit: nolimitLogo,
  playson: playsonLogo,
  playtech: playtechLogo,
  quickspin: quickspinLogo,
  relaxgaming: relaxLogo,
  ssg: smartsoftLogo,
  smartsoft: smartsoftLogo,
  spribe: spribeLogo,
  spadegaming: spadegamingLogo,
  wazdan: wazdanLogo,
  wmg: wmgLogo,
  pragmatic: pragmaticLogo,
};

const getLabel = (b: ClassNameFormatter, label?: string | React.ReactNode) => {
  if (typeof label === 'string') {

    const icon = labelIcons[label];

    return (
      <p className={b('label')}>
        {icon && <img className={b('label-icon')} src={icon} alt={label} />}
        <span className={b('labet-text')}>{label}</span>
      </p>
    );
  }

  return label;
};

type Props = {
  className?: string;
  item: GameCardItemType;
}

const GamesCard = ({ className, item }: Props) => {
  const { name, preview, link } = item;
  const [isImgError, setImgError] = useState(false);
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const handleCardClick: MouseEventHandler<HTMLAnchorElement> = (evt) => {
    if (!isAuthenticated) {
      evt.preventDefault();
      dispatch(changeActiveModal('sign-in'));
    }
  };

  const b = bemCn('games-card');

  return (
    <div className={b(null, className)}>
      <Link className={b('link')} to={link} onClick={handleCardClick}>
        <div className={b('thumbnail')}>
          {getLabel(b, item.label)}
          {typeof preview === 'string'
            ? (
              <img
                className={b('image')}
                width={120}
                alt={name}
                loading='lazy'
                onError={() => setImgError(true)}
                src={isImgError
                  ? `${process.env.PUBLIC_URL}/vegas-club-preview/404.gif`
                  : preview}
              />
            )
            : (
              <ImgSmart className={b('image')}
                src={preview.src}
                webp={preview.webp}
                width={120}
                alt=""
                loading='lazy'
              />
            )}
        </div>
        <p className={b('name')}>{name}</p>
      </Link>
    </div>
  );
};

export default GamesCard;
