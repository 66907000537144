import { useParams } from 'react-router-dom';

import { toLocaleDateString, toLocaleTimeString } from 'utils/time';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardStats from 'components/line/EventCard/components/EventCardStats/EventCardStats';
import EventCardCountdown from 'components/line/EventCard/components/EventCardCountdown/EventCardCountdown';
import EventCardBoard from 'components/line/EventCard/components/EventCardBoard/EventCardBoard';
import EventCardStream from 'components/line/EventCard/components/EventCardStream/EventCardStream';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { useGetEventStreamQuery } from 'store/app/appApi';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';
import { useToggleFavoriteEvent } from 'hooks/useToggleFavoriteEvent';
import { bemCn } from 'utils/bem-cn';
import { AddFavoriteMatchBody as EventInfo } from 'types/user-data';
import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';

import type { FullEventItem, LineType } from 'types/line-data';

import './FullEventHeader.scss';

type Props = {
  event: FullEventItem;
  lineType: LineType;
};

const b = bemCn('full-event-header');
const FullEventHeader = ({ event, lineType }: Props) => {
  const {
    eventDate,
    tournament,
    periodName,
    gameDesk,
    statList,
  } = event;
  const isAuth = useAppSelector(selectIsAuthenticated);

  const { toggleFavoriteMatch, isLoading } = useToggleFavoriteEvent();
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const { countryId, countryName } = useParams();
  const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id ?? 'notFavorite';
  const isFavorite = favoriteId !== 'notFavorite';

  const {
    data: streamUrl = '',
    isFetching: isStreamLoading,
  } = useGetEventStreamQuery({
    sport: event.sportName,
    tournament: event.tournament,
    team1: event.team1,
    team2: event.team2,
    gameStart: event.eventDate,
  });

  const date = new Date(eventDate);
  const isLive = lineType === 'live';
  const eventInfo: EventInfo = {
    'matchId': event.id,
    'team1': event.team1,
    'team2': event.team2,
    'sportName': event.sportName,
    'sportId': event.sportId,
    'tournamentName': event.tournament,
    'tournamentId': event.tournamentId,
    'countryId': countryId ?? '1',
    'countryName': countryName ?? '1',
    'dataType': lineType,
  };

  const handleStarClick = () => {
    if (!isLoading) {
      toggleFavoriteMatch({ eventInfo, isFavorite, favoriteId });
    }
  };

  return (
    <div className={b()}>
      <div className={b('time')}>
        <div className={b('title-wrapper')}>
          <p className={b('time-title')}>
            {toLocaleTimeString(date)}
          </p>
          {(isLive && periodName) && (
            <div className={b('period-layout')}>
              <SvgIcon className={b('period-icon')}
                name="clock" width={10} height={10}
              />
              <p className={b('period')}>
                {gameDesk.length > 0 ? gameDesk : periodName}
              </p>
            </div>
          )}
        </div>
        <FavoriteBtn onClick={handleStarClick} active={isFavorite} />
      </div>
      <div className={b('time')}>
        <p className={b('date')}>{toLocaleDateString(date)}</p>
        <p>{tournament}</p>
      </div>
      <EventCardBoard
        className={b('board')}
        isLive={isLive}
        event={event}
      />
      {streamUrl && !isStreamLoading ? (
        <EventCardStream
          className={b('stream')}
          streamUrl={streamUrl}
        />
      ) : null}
      <EventCardStats
        className={b('stats')}
        stats={statList}
      />
      <EventCardCountdown
        className={b('start-time')}
        date={eventDate}
        hide={isLive}
      />
    </div>
  );
};

export default FullEventHeader;
