import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';

import AddButton from 'components/shared/buttons/AddButton/AddButton';
import TextInput from 'components/shared/inputs/TextInput/TextInput';
import { bemCn } from 'utils/bem-cn';
import './AddPromoCode.scss';

type Props<TFormValues extends FieldValues> = {
  disabled?: boolean;
} & UseControllerProps<TFormValues>;

const AddPromoCode = <TFormValues extends Record<string, unknown>>(props: Props<TFormValues>) => {
  const { disabled } = props;
  const { field, fieldState } = useController(props);
  const [isInputShown, setIsInputOpened] = useState<boolean>(false);
  const { t } = useTranslation();
  const showInput = () => {
    setIsInputOpened(true);
  };

  const hideInput = () => {
    setIsInputOpened(false);
    field.onChange('');
  };

  const b = bemCn('add-promo-code');

  return (
    <div className={b()}>
      {!isInputShown ? (
        <div className={b('button-wrapper')}>
          <p className={b('add-promo-title')}>
            {t('reg.modal.promo.title', 'Добавить промокод')}
          </p>
          <AddButton onClick={showInput} />
        </div>
      ) : (
        <div className={b('input-container')}>
          <div className={b('input-wrapper')}>
            <TextInput
              {...field}
              disabled={disabled}
              placeholder={`${t('reg.modal.promo.code', 'Промокод')}`}
              additionalButton="cancel"
              onAdditionalButtonClick={hideInput}
              error={fieldState.error}
              isTouched={fieldState.isTouched}
              value={field.value as string}
              showError
            />
          </div>
          {/* <button className={b('apply-promo-button')}
            type="button"
            aria-label="apply"
          >
            <div className={b('apply-promo-button-icon')} />
          </button> */}
        </div>
      )}
    </div>
  );
};

export default AddPromoCode;
