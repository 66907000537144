import React from 'react';
import cn from 'classnames';

import i18n from 'i18n/i18n';

import SportsFilterItem from './SportsFilterItem/SportsFilterItem';

import type { SportItem } from 'types/line-data';
import './SportsFilter.scss';

const commonSport: SportItem = {
  id: '0',
  name: i18n.t('all', 'Все'),
  nameEn: i18n.t('all', 'All'),
  nameRu: i18n.t('all', 'Все'),
  counter: 0,
  dataType: 'live'
};

type Props = {
  sports: SportItem[];
  onChange: (id: string) => void;
  activeSportId: string;
  isLoading?: boolean;
  className?: string;
};

const SportsFilter = (props: Props) => {
  const {
    sports,
    onChange,
    isLoading,
    className,
    activeSportId,
  } = props;

  if (isLoading) {
    return <div />;
  }

  return (
    <div className={cn('sports-filter', className)}>
      <SportsFilterItem
        key={commonSport.id}
        sport={commonSport}
        onClick={() => onChange(commonSport.id)}
        isActive={activeSportId === commonSport.id}
      />
      {sports.map((sport) => (
        <SportsFilterItem
          key={sport.id}
          sport={sport}
          onClick={() => onChange(sport.id)}
          isActive={activeSportId === sport.id}
          showCounter
        />
      ))}
    </div>
  );
};

export default React.memo(SportsFilter);
