import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/payment/paymentsSlice';
import {
  selectPaymentsActiveModal,
  selectWithdrawalMethodsList,
} from 'store/payment/selectors';
import Loader from 'components/shared/loader/Loader';
import { useGetWithdrawalMethodsQuery } from 'store/payment/paymentsApi';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';


import PaymentMethod from '../PaymentSystem/PaymentMethod';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import WithdrawalModal from '../WithdrawalModal/WithdrawalModal';

import type { TWithdrawalMethodsItem } from 'types/payments-data';

import './Withdrawals.scss';

const Withdrawals = () => {
  const { Mobile } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeMethod, setActiveMethod] = useState<TWithdrawalMethodsItem | null>(null);
  const methods = useAppSelector(selectWithdrawalMethodsList);
  const activeModal = useAppSelector(selectPaymentsActiveModal);
  const { isLoading } = useGetWithdrawalMethodsQuery();


  const handleMethodClick = (method: TWithdrawalMethodsItem) => {
    setActiveMethod(method);
    dispatch(changeActiveModal('withdrawal'));
  };

  const handleModalClose = () => dispatch(changeActiveModal(null));

  const b = bemCn('withdrawals');
  return (
    <div className={b()}>
      <Mobile>
        <ProfileHeader
          breadcrumbsLink="/profile"
          breadcrumbsTitle={t('Profile', 'Личный кабинет')}
          title={t('withdrawals.title', 'Вывод')}
          subtitle={`${t('withdrawals.description', 'Выберите метод для вывода')}`}
        />
      </Mobile>
      {isLoading ? (
        <div className={b('loader-wrapper')}>
          <Loader />
        </div>
      ) : (
        <div className={b('systems-list')}>
          {methods.map((method) => (
            <PaymentMethod
              key={method.id}
              id={method.name}
              title={method.title}
              onClick={() => handleMethodClick(method)}
            />
          ))}
        </div>
      )}
      <small className={b('delay')}>
        {t('withdrawals.delay', 'Вывод занимает от 15 минут до 24 часов.')}
      </small>
      <WithdrawalModal
        activeMethod={activeMethod}
        isOpen={activeModal === 'withdrawal'}
        onClose={handleModalClose}
      />
      <ResultModal
        title={t('result-modal.withdrawal.success.title', 'Заявка на вывод успешно создана')}
        status="success"
        isOpen={activeModal === 'success'}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default Withdrawals;
