import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { useGetWalletsQuery } from 'store/user/userApi';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import Button from 'components/shared/buttons/Button/Button';
import SupportIcon from 'components/shared/SupportIcon/SupportIcon';
import Loader from 'components/shared/loader/Loader';
import Logotype from 'components/shared/Logotype/Logotype';

import UserNav from '../UserblockDesktop/UserNav/UserNav';
import './HeaderAuthorized.scss';
import BurgerButton from '../BurgerButton/BurgerButton';

const b = bemCn('main-header-authorized');
const HeaderAuthorized = () => {
  const { t } = useTranslation();
  const { amount, currency, bonusAmount } = useAppSelector(selectPrimaryWallet);
  const { isLoading: isWalletsLoading } = useGetWalletsQuery();

  const roundedAmount = Math.floor((amount * 100)) / 100;
  const roundedBonusAmount = Math.floor((bonusAmount * 100)) / 100;

  return (
    <>
      <BurgerButton />

      <div className={b('logo')}>
        <Logotype />
      </div>

      <div className={b('right-container')}>
        {/* <div className={b('logo')}>
          <Logotype />
        </div> */}

        {/* <UserNav /> */}

        <div className={b('user-info')}>
          {isWalletsLoading
            ? <Loader />
            : (
              <>
                <div className={b('user-balance', { bonus: true })}>
                  {roundedBonusAmount} {currency ?? ''}
                </div>
                <div className={b('user-balance')}>
                  {roundedAmount} {currency ?? ''}
                </div>
              </>
            )}
        </div>

        <Link to="/profile/topups" className={b('top-up-btn-wrapper')}>
          <Button size="small" animated>
            {t('user-nav.topup', 'Пополнить')}
          </Button>
        </Link>

        <UserNav />
        {/* <SupportIcon className={b('support-icon')} /> */}
      </div>
    </>
  );
};

export default HeaderAuthorized;
