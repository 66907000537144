import { api } from 'store/api';

import {
  GetManagerPartners,
  GetManagerPartnerLinks,
  GetManagerPartnerPromocodes,
  GetPartnerLinkPlayers,
  GetPartnerLinkStats,
  GetPartnerPromocodePlayers,
  GetPartnerPromocodeStats,
  GetManagerPartnerStats,
  GetManagerPartnerPlayers,
  GetManagerStats,
  ManagerPartners,
  PartnerLinks,
  PartnerPlayers,
  PartnerPromocodes,
  PartnerStats,
  PartnerPlayersServer,
  PartnerLinksServer,
  PartnerPromocodesServer,
  ManagerPartnersServer
} from './types';
import { PPAdapter } from './utils';


export const managerApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({

    // TODO: Добавить типы данных
    getManagerLink: build.query<Record<string, string>, void>({
      query: () => '/v1/manager/link',
    }),

    getManagerPartners: build.query<ManagerPartners, GetManagerPartners>({
      query: (params) => ({
        url: '/v1/manager/partners',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: ManagerPartnersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.managerPartnerToClient),
      })
    }),

    getManagerPartnerLinks: build.query<PartnerLinks, GetManagerPartnerLinks>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/links`,
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerLinksServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.linkToClient),
      })
    }),
    getManagerPartnerPromocodes: build.query<PartnerPromocodes, GetManagerPartnerPromocodes>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/promocodes`,
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPromocodesServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.promocodeToClient),
      })
    }),

    getManagerPartnerLinkStats: build.query<PartnerStats, GetPartnerLinkStats>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/manager/links/${refCode}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),
    getManagerPartnerLinkPlayers: build.query<PartnerPlayers, GetPartnerLinkPlayers>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/manager/links/${refCode}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

    getManagerPartnerPromocodeStats: build.query<PartnerStats, GetPartnerPromocodeStats>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/manager/promocodes/${promocodeId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),
    getManagerPartnerPromocodePlayers: build.query<PartnerPlayers, GetPartnerPromocodePlayers>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/manager/promocodes/${promocodeId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

    getManagerPartnerStats: build.query<PartnerStats, GetManagerPartnerStats>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),
    getManagerPartnerPlayers: build.query<PartnerPlayers, GetManagerPartnerPlayers>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

    getManagertStats: build.query<PartnerStats, GetManagerStats>({
      query: (params) => ({
        url: '/v1/manager/stats',
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),

  })
});

export const {
  useGetManagerLinkQuery,
  useGetManagerPartnersQuery,
  useGetManagerPartnerLinksQuery,
  useGetManagerPartnerPromocodesQuery,
  useGetManagerPartnerLinkStatsQuery,
  useGetManagerPartnerLinkPlayersQuery,
  useGetManagerPartnerPromocodeStatsQuery,
  useGetManagerPartnerPromocodePlayersQuery,
  useGetManagerPartnerStatsQuery,
  useGetManagerPartnerPlayersQuery,
  useGetManagertStatsQuery,
} = managerApi;
