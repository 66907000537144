import { useMediaQuery } from 'react-responsive';

interface Props {
  children: JSX.Element;
}

export const useResponsive = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1280px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' });

  const Mobile = ({ children }: Props) => isMobile ? children : null;
  const Desktop = ({ children }: Props) => isDesktop ? children : null;

  return {
    isDesktop,
    isTablet,
    isMobile,
    Mobile,
    Desktop
  };
};
