import { Link } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';
import SectionIcon from 'components/shared/SectionIcon/SectionIcon';
import './MainNavItem.scss';
import { SiteSection as SiteSectionType } from 'types/siteSections';

type Props = {
  link: string;
  title: string;
  isActive: boolean;
  id: SiteSectionType;
}

const b = bemCn('main-nav-item');
function MainNavItem({ link, title, isActive, id }: Props) {
  if (id === 'download-app') {
    return (
      <div className={b('', { 'active': isActive })}>
        <a href={link} className={b('link')}>
          <SectionIcon
            className={b('section-icon')}
            id={id}
            variant="rounded-shadow"
            isBackgroundShowing={false}
          />
          {title}
        </a>
      </div>
    );
  }

  return (
    <div className={b('', { 'active': isActive })}>
      <Link to={link} className={b('link')}>
        <SectionIcon
          className={b('section-icon')}
          id={id}
          variant="rounded-shadow"
          isBackgroundShowing={false}
        />
        {title}
      </Link>
    </div>
  );
}

export default MainNavItem;
