import speedy7 from './bet-games-speedy7.jpg';
import warOfBets from './bet-games-war-of-bets.jpg';
import baccarat from './bet-games-baccarat.jpg';
import wheelRNG from './bet-games-wheel-rng.jpg';
import sattaMatka from './bet-games-satta-matka.jpg';
import lucky5 from './bet-games-lucky5.jpg';
import lucky6 from './bet-games-lucky6.jpg';
import lucky7 from './bet-games-lucky7.jpg';
import diceDuel from './bet-games-dice-duel.jpg';
import anderBaha from './bet-games-andar-baha.jpg';
import poker6 from './bet-games-6-poker.jpg';
import wheel from './bet-games-wheel.jpg';
import football from './bet-games-football.jpg';
import poker from './bet-games-poker.jpg';
import speedy7Webp from './bet-games-speedy7.webp';
import warOfBetsWebp from './bet-games-war-of-bets.webp';
import baccaratWebp from './bet-games-baccarat.webp';
import wheelRNGWebp from './bet-games-wheel-rng.webp';
import sattaMatkaWebp from './bet-games-satta-matka.webp';
import lucky5Webp from './bet-games-lucky5.webp';
import lucky6Webp from './bet-games-lucky6.webp';
import lucky7Webp from './bet-games-lucky7.webp';
import diceDuelWebp from './bet-games-dice-duel.webp';
import anderBahaWebp from './bet-games-andar-baha.webp';
import poker6Webp from './bet-games-6-poker.webp';
import wheelWebp from './bet-games-wheel.webp';
import footballWebp from './bet-games-football.webp';
import pokerWebp from './bet-games-poker.webp';
import instantLucky7 from './card-rng-lucky7.jpg';
import instantLucky7Webp from './card-rng-lucky7.webp';

export default {
  speedy7: {
    src: speedy7,
    webp: speedy7Webp
  },
  warOfBets: {
    src: warOfBets,
    webp: warOfBetsWebp
  },
  baccarat: {
    src: baccarat,
    webp: baccaratWebp
  },
  wheelRNG: {
    src: wheelRNG,
    webp: wheelRNGWebp
  },
  sattaMatka: {
    src: sattaMatka,
    webp: sattaMatkaWebp
  },
  lucky5: {
    src: lucky5,
    webp: lucky5Webp
  },
  lucky6: {
    src: lucky6,
    webp: lucky6Webp
  },
  lucky7: {
    src: lucky7,
    webp: lucky7Webp
  },
  diceDuel: {
    src: diceDuel,
    webp: diceDuelWebp
  },
  anderBaha: {
    src: anderBaha,
    webp: anderBahaWebp
  },
  poker6: {
    src: poker6,
    webp: poker6Webp
  },
  wheel: {
    src: wheel,
    webp: wheelWebp
  },
  football: {
    src: football,
    webp: footballWebp
  },
  poker: {
    src: poker,
    webp: pokerWebp
  },
  instantLucky7: {
    src: instantLucky7,
    webp: instantLucky7Webp,
  }
};
