import { TournamenWithEvents, filterByConditionWithTransform, groupByValueWithTransform, sortByTopEntries } from 'utils/common';
import { topCountriesId } from 'utils/constants';

import { blacklist } from './blacklist';

import type { Bet, CountryItem, EventItem, FullEventItem, Group, SportItem, SubGame, TournamentItem } from 'types/line-data';
import type { CountryServer, DataType, EventDetailedServer, EventOcGroup, EventOcItemServer, EventShortServer, EventSubGameServer, GetBetapiCountriesResponse, GetBetapiEventsByTourneyResponse, GetBetapiEventsListResponse, GetBetapiSportsResponse, GetBetapiTournamentsResponse, SportServer, TournamentEventsServer, TournamentServer } from './types';

const empryFullEvent: FullEventItem = {
  id: '',
  eventDate: '',
  team1: '',
  team2: '',
  scoreFull: '',
  scorePeriod: '',
  periodName: '',
  team1Icon: '',
  team1Id: 0,
  team2Icon: '',
  team2Id: 0,
  tournament: '',
  tournamentId: '',
  groups: [],
  subGames: [],
  statList: [],
  timer: 0,
  gameDesk: 'The game is over',
  finale: true,
  sportId: '',
  sportName: '',
  subGameName: '',
  scoreExtra: '',
  pitch: null,
};

const isBodyEmpty = (body: unknown) => {
  if (body === undefined || body === null) {
    return true;
  }

  if (Array.isArray(body) && body.length <= 0) {
    return true;
  }

  if (typeof body === 'object' && Object.entries(body).length <= 0) {
    return true;
  }

  return !body;
};

// const isSportValid = (sport: SportServer): boolean =>
//   ![sport.name, sport.name_ru, sport.name_en]
//     .some((name) => blacklist.sports.includes(name));

// const isTourneyValid = (tourney: TournamentServer): boolean =>
//   !blacklist.tournaments.includes(tourney.name_ru)
//   && !blacklist.tournaments.includes(tourney.name_en);

// const isEventValid = (event: EventShortServer): boolean =>
//   !blacklist.subgames.includes(event.game_dop_name)
//   && !blacklist.sports.includes(event.sport_name_ru)
//   && !blacklist.sports.includes(event.sport_name_en)
//   && !blacklist.tournaments.some((tourney) => event.tournament_name.includes(tourney))
//   && !blacklist.tournaments.some((tourney) => event.tournament_name_ru.includes(tourney))
//   && !blacklist.tournaments.some((tourney) => event.tournament_name_en.includes(tourney));

const isOcGroupValid = (group: EventOcGroup): boolean =>
  !blacklist.outcomes.some((ocB) =>
    group.group_name.toLowerCase().includes(ocB.toLowerCase()));

const isOcItemValid = (oc: EventOcItemServer): boolean =>
  !blacklist.outcomes.some((ocB) =>
    oc.oc_name.toLowerCase().includes(ocB.toLowerCase()));

// const isSubgameValid = (subgame: EventSubGameServer, sportId: number): boolean => {
//   const blacklistSubgames = blacklist.bySport[sportId]?.subgames ?? [];
//   return !blacklistSubgames.includes(subgame.game_name)
//     && !blacklist.subgames.includes(subgame.game_name);
// };

const getIconLink = (iconName: string) => {
  if (iconName) {
    const name = iconName.includes('.png') ? iconName : `${iconName}.png`;
    return `https://cdn.incub.space/v1/opp/icon/${name}`;
  }
  return '';
};

const selectGroup = (groups: Group[]): Group => {
  const group = groups.find((g) => g.name === '1x2') ?? groups[0];

  return group ?? {
    bets: [],
    name: 'stopped',
  };
};

export const AdapterBetapi = {
  sport: (res: SportServer, dataType: DataType): SportItem => ({
    id: `${res.id}`,
    name: res.name,
    nameRu: res.name_ru,
    nameEn: res.name_en,
    counter: res.counter,
    dataType: dataType,
  }),

  sportsList: (res: GetBetapiSportsResponse, dataType: DataType): SportItem[] => {
    const items = res?.body;
    if (!Array.isArray(items)) {
      return [];
    }

    return items.map((sport) => AdapterBetapi.sport(sport, dataType));
  },

  country: (res: CountryServer): CountryItem => ({
    id: `${res.id}`,
    name: res.name,
    nameEn: res.name_en,
    nameRu: res.name_ru,
    sportId: res.sport_id,
    counter: res.counter,
  }),

  countriesList: (res: GetBetapiCountriesResponse): CountryItem[] => {
    const items = res?.body;
    if (!Array.isArray(items)) {
      return [];
    }

    return sortByTopEntries(
      items.map(AdapterBetapi.country),
      topCountriesId,
      (country) => country.id
    );
  },

  tournament: (res: TournamentServer): TournamentItem => ({
    id: `${res.id}`,
    name: res.name,
    nameRu: res.name_ru,
    nameEn: res.name_en,
    sportId: res.sport_id,
    countryId: `${res.country_id}`,
    counter: res.counter,
  }),

  tournamentsList: (res: GetBetapiTournamentsResponse): TournamentItem[] => {
    const items = res?.body;
    if (!Array.isArray(items)) {
      return [];
    }

    return items.map(AdapterBetapi.tournament);
  },

  gameOCGpoutItem: (res: EventOcItemServer): Bet => ({
    betId: res.oc_pointer,
    caption: res.oc_name,
    rate: res.oc_rate,
    blocked: res.oc_block,
  }),

  betsGroups: (res: EventOcItemServer[]): Group[] => {
    const betsByGroupName = groupByValueWithTransform(
      res.filter(isOcItemValid),
      (item) => item.oc_group_name,
      AdapterBetapi.gameOCGpoutItem
    );

    return Object.entries(betsByGroupName)
      .map(([groupName, groupBets]) => ({
        name: groupName,
        bets: groupBets
      }));
  },

  gameOCGroup: (res: EventOcGroup): Group => ({
    name: res.group_name,
    bets: res.oc_list.map(AdapterBetapi.gameOCGpoutItem)
  }),

  eventsListItem: (res: EventShortServer, dataType: DataType): EventItem => {
    const allGroups = AdapterBetapi.betsGroups(res.game_oc_list);
    return {
      id: `${res.game_id}`,
      eventDate: new Date(res.game_start * 1000).toISOString(),
      team1: res.opp_1_name,
      team2: res.opp_2_name,
      team1Icon: getIconLink(res.opp_1_icon),
      team1Id: res.opp_1_id,
      team2Icon: getIconLink(res.opp_2_icon),
      team2Id: res.opp_2_id,
      tournament: res.tournament_name,
      tournamentRu: res.tournament_name_ru,
      tournamentEn: res.tournament_name_en,
      sportId: `${res.sport_id}`,
      sportName: res.sport_name,
      sportNameRu: res.sport_name_ru,
      sportNameEn: res.sport_name_en,
      group: selectGroup(allGroups),
      timer: res.timer,
      scorePeriod: res.score_period,
      scoreFull: res.score_full,
      allGroups: allGroups,
      gameDesk: res.game_desk,
      periodName: res.period_name,
      tournamentId: `${res.tournament_id}`,
      subGameName: res.game_dop_name,
      scoreExtra: res.score_extra,
      pitch: res.pitch,
      countryId: String(res.country_id),
      countryName: res.country_name,
      dataType: dataType,
    };
  },

  eventsList: (res: GetBetapiEventsListResponse, dataType: DataType): EventItem[] => {
    const items = res?.body;
    if (!Array.isArray(items)) {
      return [];
    }

    return items.map((item) => AdapterBetapi.eventsListItem(item, dataType));
  },

  eventSubGames: (res: EventSubGameServer): SubGame => ({
    gameId: res.game_id,
    gameName: res.game_name,
    groups: []
  }),

  eventDetailed: (res: EventDetailedServer): FullEventItem | undefined => {
    if (isBodyEmpty(res) || typeof res === 'string') {
      return empryFullEvent;
    }

    return {
      id: `${res.game_id}`,
      eventDate: new Date(res.game_start * 1000).toISOString(),
      team1: res.opp_1_name,
      team2: res.opp_2_name,
      scoreFull: res.score_full,
      scorePeriod: res.score_period,
      periodName: res.period_name,
      team1Icon: getIconLink(res.opp_1_icon),
      team1Id: res.opp_1_id,
      team2Id: res.opp_2_id,
      team2Icon: getIconLink(res.opp_2_icon),
      tournament: res.tournament_name,
      tournamentId: String(res.tournament_id),
      groups: filterByConditionWithTransform(
        res.game_oc_list,
        isOcGroupValid,
        AdapterBetapi.gameOCGroup
      ),
      subGames: res.sub_games.map(AdapterBetapi.eventSubGames),
      statList: res.stat_list.map((item) => ({ ...item, name: item.name ?? '' })),
      timer: res.timer,
      gameDesk: res.game_desk,
      finale: res.finale,
      sportName: res.sport_name,
      sportId: String(res.sport_id),
      subGameName: res.game_dop_name,
      scoreExtra: res.score_extra,
      pitch: res.pitch,
    };
  },

  tournamentEvents: (res: TournamentEventsServer, index: number, dataType: DataType): TournamenWithEvents => ({
    id: `${res.tournament_id}`,
    name: res.tournament_name,
    nameRu: res.events_list[0]?.tournament_name_ru ?? '',
    nameEn: res.events_list[0]?.tournament_name_en ?? '',
    index: index,
    sportName: res.events_list[0]?.sport_name ?? '',
    sportNameRu: res.events_list[0]?.sport_name_ru ?? '',
    sportNameEn: res.events_list[0]?.sport_name_en ?? '',
    sportId: `${res.events_list[0]?.sport_id ?? ''}`,
    events: res.events_list.map((item) => AdapterBetapi.eventsListItem(item, dataType))
  }),

  eventsByTournament: (res: GetBetapiEventsByTourneyResponse, dataType: DataType): TournamenWithEvents[] => {
    const items = res?.body;
    if (!Array.isArray(items)) {
      return [];
    }

    return items.map((item, i) => AdapterBetapi.tournamentEvents(item, i, dataType));
  },
};
