import React from 'react';
import cn from 'classnames';

import './ErrorBlock.scss';

export type Props = {
  isDisplayed?: boolean;
  message?: string | null;
  align?: 'left' | 'right' | 'center';
  className?: string;
};

const ErrorBlock = ({ isDisplayed, message, align = 'right', className }: Props) => (
  <div className={cn('error-message', `error-message--align-${align}`, className)}>
    {isDisplayed && message}
  </div>
);

export default ErrorBlock;
