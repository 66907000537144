import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from 'i18n/i18n';
import {
  useGetManagerPartnerLinksQuery,
} from 'store/partnerProgram/managerApi';

import type { ManagerPartner, TTableProps, TDropdownItem } from 'store/partnerProgram/types';

const actionList: TDropdownItem[] = [
  {
    key: 'link-stats',
    title: i18n.t('partner-program.stats', 'Статистика'),
  },
  {
    key: 'link-players',
    title: i18n.t('partner-program.players-stats', 'Статистика рефералов'),
  },
];

export const useManagerPartnerLinks = (id: number) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const {
    data: partnerLinksData,
    isFetching: isPartnerLinksFetching,
    isError: isPartnerLinksError,
  } = useGetManagerPartnerLinksQuery({ partnerId: id, page: currentPage, pageSize });

  const partnerLinksItems = partnerLinksData?.items;
  const partnerLinksTotalCount = partnerLinksData?.count ?? 0;
  const pageCount = Math.ceil(partnerLinksTotalCount / pageSize);

  const pagination: TTableProps<ManagerPartner, keyof ManagerPartner>['pagination'] = {
    current: currentPage,
    total: pageCount,
    onChange: (value) => setCurrentPage(value),
  };

  const handleActionsClick = (refCode: string, key: string) => {
    switch (key) {
      case 'link-stats':
        navigate(`links/${refCode}`);
        break;
      case 'link-players':
        navigate(`links/${refCode}/players`);
        break;
      default:
    }
  };

  return {
    partnerLinksData,
    isPartnerLinksFetching,
    isPartnerLinksError,
    partnerLinksItems,
    pagination,
    actionList,
    handleActionsClick,
  };
};
