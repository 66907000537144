import { useEffect, useRef } from 'react';

const useHorizontalScroll = <T extends HTMLElement>() => {
  const containerRef = useRef<T>(null);

  const handleWheel = (evt: WheelEvent) => {
    evt.preventDefault();
    const container = containerRef.current;
    if (container) {
      container.scrollLeft += evt.deltaY;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  });

  return containerRef;
};


export {useHorizontalScroll};
