import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import { selectFilterCountry } from 'store/app/selectors';
import { useGetBetapiCountriesQuery } from 'store/betapi/betapi';
import { useFilteredTournaments } from 'hooks/useFilteredTournaments';
import { groupByValue } from 'utils/common';
import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';

import SideBarFilterDesktopCountry from '../SideBarFilterDesktopCountry/SideBarFilterDesktopCountry';

import type { LineType, TournamentRegion } from 'types/line-data';


import './SideBarFilterDesktopCountries.scss';

type Props = {
  lineType: LineType;
  sportId: string;
  sportName: string;
};

const b = bemCn('side-bar-filter-desktop-countries');

const SideBarFilterDesktopCountries = ({ lineType, sportId, sportName }: Props) => {
  const activeCountry = useAppSelector(selectFilterCountry);
  const { t, i18n: { resolvedLanguage: lang } } = useTranslation();

  const { data: countries = [], isFetching: isCountriesFetching } = useGetBetapiCountriesQuery({
    sportId: sportId,
    dataType: lineType,
    dataLang: lang
  });

  const {
    data: tournamentsList = [],
    isFetching: isTournamentsLoading
  } = useFilteredTournaments({
    sportId: sportId,
    dataType: lineType,
    dataLang: lang
  });

  const tournamentsByID = groupByValue(tournamentsList, (item) => item.countryId);
  return (
    <div className={b()}>
      <p className={b('title')}>{t('bets-page.countries', 'Страны')}</p>
      <div className={b('list')}>
        {isCountriesFetching
          ? <Loader />
          : countries.map((country) => {
            const tournamentRegion:TournamentRegion = {
              sportName,
              sportId,
              countryName: country.name,
              countryId: country.id
            };
            return (
              <SideBarFilterDesktopCountry
                key={country.id}
                country={country}
                isActive={activeCountry === country.id}
                tournaments={tournamentsByID[country.id] ?? []}
                isTournamentsLoading={isTournamentsLoading}
                tournamentRegion={tournamentRegion}
              />
            );
          })}
      </div>
    </div>
  );
};


export default SideBarFilterDesktopCountries;
