import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import 'swiper/css';
import 'swiper/css/pagination';

import { useNavigate } from 'react-router-dom';

import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import { useFilteredSports } from 'hooks/useFilteredSports';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import ButtonToTop from 'components/shared/ButtonToTop/ButtonToTop';
import SportsFilter from 'components/line/SportsFilter/SportsFilter';
import SportGroup from 'components/line/SportGroup/SportGroup';
import { filterTournamentEvents } from 'utils/common';
import { groupTournamentEvents } from 'utils/line';
import './LivePageMobile.scss';
import { useFilteredEventsByTourney } from 'hooks/useFilteredEventsByTourney';

const lineType = 'live';

const LivePageMobile = () => {
  const navigate = useNavigate();
  const [activeSportId, setActiveSportId] = useState('1');
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [isRefetch, setIsRefetch] = useState(true);
  const { t, i18n } = useTranslation();

  const {
    data: sports = [],
    isLoading: isSportsLoading
  } = useFilteredSports({
    dataType: lineType,
    dataLang: i18n.resolvedLanguage
  });

  const {
    data: tournamentswithEvents = [],
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
  } = useFilteredEventsByTourney(
    {
      sportId: activeSportId,
      dataType: lineType,
      dataLang: i18n.resolvedLanguage,
    },
    { pollingInterval: 4000 }
  );


  useEffect(() => {
    if (!isEventsFetching) {
      setIsRefetch(false);
    }
  }, [isEventsFetching]);

  useEffect(() => {
    setIsRefetch(true);
  }, [activeSportId]);

  const filteredTournaments = filterTournamentEvents(tournamentswithEvents, searchFilterValue);
  const groupedTournaments = groupTournamentEvents(filteredTournaments);

  const handleSportChange = useCallback((id: string) => {
    if (activeSportId !== id) {
      setActiveSportId(id);
    }
  }, [activeSportId]);

  const handleSearchChange = useCallback((newSearchValue: string) => {
    setSearchFilterValue(newSearchValue);
  }, []);

  const handleSearchCancel = useCallback(() => {
    setOpenSearch(false);
  }, []);

  const handleOpenSearch = () => setOpenSearch(true);

  const handleStarClick = () => navigate('/profile/favorite-list');
  const isListLoading = isEventsFetching && isRefetch;


  return (
    <div className="tournament">
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      {!openSearch && (
        <PageHeader
          id="live"
          title="Live"
          controlPanel={[
            { type: 'star', onClick: handleStarClick },
            { type: 'search', onClick: handleOpenSearch }
          ]}
        />
      )}
      <SearchFilter
        openSearch={openSearch}
        onCancel={handleSearchCancel}
        onChange={handleSearchChange}
      />
      <div className="tournament__content">
        <SportsFilter
          sports={sports}
          activeSportId={activeSportId}
          isLoading={isSportsLoading}
          onChange={handleSportChange}
        />
        <div className="tournament__events-list">
          {isListLoading
            ? <Loader />
            : groupedTournaments.map((sportGroup) => (
              <SportGroup
                key={sportGroup.id}
                sportGroup={sportGroup}
              />
            ))}
        </div>
      </div>
      <ButtonToTop />
    </div>
  );
};

export default React.memo(LivePageMobile);
