import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { useAppDispatch } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import Button from 'components/shared/buttons/Button/Button';

import './BurgerAuth.scss';

const b = bemCn('burger-auth');
function BurgerAuth() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLoginClick = () => dispatch(changeActiveModal('sign-in'));
  const handleRegClick = () => dispatch(changeActiveModal('sign-up'));

  return (
    <div className={b()}>
      <Button className={b('login')}
        onClick={handleLoginClick}
        variant="login"
      >
        {t('user-nav.login', 'Вход')}
      </Button>
      <Button className={b('reg')}
        onClick={handleRegClick}
        animated
      >
        {t('user-nav.registration', 'Регистрация')}
      </Button>
    </div>
  );
}

export default BurgerAuth;
