import { bemCn } from 'utils/bem-cn';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import './PPStats.scss';

type Props = {
  title: string;
  value: string | number;
  small?: boolean;
};

const PPStatsItem = (props: Props) => {
  const {
    title,
    value,
    small,
  } = props;

  const b = bemCn('pp-stats');

  const classNames = small ? 'item-small' : 'item';

  return (
    <div className={b(classNames)}>
      <h3 className={b('item-title')}>{title}</h3>
      <p className={b('item-value')}>{value}</p>
    </div>
  );
};

export default PPStatsItem;
