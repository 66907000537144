const localStorageKey = 'phoneHistory';
const maxCallCount = 2;
const smsResendPause = 600000;
const getDefaultPhoneInfo = (): PhoneInfo => ({
  timeStamp: new Date().getTime(),
  callCount: 0
});

const getPhoneHistory = () => {
  const data = localStorage.getItem(localStorageKey);
  return (
    data === null ? {} : JSON.parse(data) as PhoneHistory
  );
};

const setPhoneHistory = (phoneHistory: PhoneHistory): void =>
  localStorage.setItem(localStorageKey, JSON.stringify(phoneHistory));

export const getSmsResendPermission = (phone: string) => {
  const phoneHistory = getPhoneHistory();
  if (phoneHistory[phone] === undefined) {
    phoneHistory[phone] = getDefaultPhoneInfo();
  }

  const phoneInfo: PhoneInfo = phoneHistory[phone];
  const currentTime = new Date().getTime();
  const timeSinceLastCall = phoneInfo.timeStamp - currentTime;

  if (timeSinceLastCall >= smsResendPause || phoneInfo.callCount < maxCallCount) {
    phoneHistory[phone] = timeSinceLastCall >= smsResendPause
      ? getDefaultPhoneInfo()
      : phoneInfo;
    phoneHistory[phone].callCount += 1;
    setPhoneHistory(phoneHistory);
    return true;
  }
  return false;
};

interface PhoneInfo {
  timeStamp: number;
  callCount: number;
}

interface PhoneHistory {
  [key: string]: PhoneInfo;
}
