import { useEffect, useRef, useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import ErrorBlock from '../ErrorBlock/ErrorBlock';
import './BankSelector.scss';

type Option = {
  id: string;
  bankName: string;
  logoURL: string;
}

type Props<TFormValues extends FieldValues, T> = {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  options?: Option[];
  defaultSelectedOption?: Option;
} & UseControllerProps<TFormValues>

const BankSelector = <TFormValues extends Record<string, unknown>, T>(props: Props<TFormValues, T>) => {
  const {
    className,
    disabled,
    placeholder,
    options,
    defaultSelectedOption,
  } = props;

  const { field, fieldState } = useController(props);
  const [selectedOption, setSelectedOption] = useState<Option | null>(defaultSelectedOption ?? null);
  const [isOpened, setOpened] = useState(false);
  const selectorRef = useRef<HTMLDivElement>(null);
  const selectedOptionRef = useRef<HTMLLIElement>(null);


  useOnClickOutside(selectorRef, () => {
    if (isOpened) {
      setOpened(false);
    }
  });

  useEffect(() => {
    if (isOpened) {
      selectedOptionRef.current?.scrollIntoView();
    }
  }, [isOpened]);

  useEffect(() => {
    field.onChange(selectedOption?.id);
  }, [selectedOption?.id]);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setOpened(false);
  };

  const handleSelectedClick = () => {
    setOpened(!isOpened);
  };

  useEffect(() => {
    if (isOpened) {
      selectedOptionRef.current?.scrollIntoView();
    }
  }, [isOpened]);

  const invalid = !!fieldState.error;
  const valid = !fieldState.error;
  const isTouched = fieldState.isTouched;

  const b = bemCn('bank-selector');
  return (
    <div className={b({ invalid: invalid, valid: isTouched && valid, opened: isOpened }, className)}>
      <ErrorBlock isDisplayed={invalid} message={fieldState.error?.message} />
      <div className={b('wrapper')}>
        <div className={b('selector-wrapper')} ref={selectorRef}>
          <button className={b('selected-option')} type='button' onClick={handleSelectedClick} disabled={disabled}>
            {selectedOption ? (
              <>
                <img className={b('country-icon')} src={selectedOption.logoURL} alt="" />
                {selectedOption?.bankName}
              </>
            ) : placeholder}
          </button>

          <ul className={b('optios-list', { opened: isOpened })}>
            {options?.map((option) => {
              const isSelected = option.id === selectedOption?.id;
              return (
                <li className={b('option', { selected: isSelected })}
                  key={option.id}
                  ref={isSelected ? selectedOptionRef : null}
                  onClick={() => handleOptionClick(option)}
                >
                  <img className={b('country-icon')} src={option.logoURL} alt="" />
                  {option.bankName}
                </li>
              );
            }
            )}
          </ul>

          <SvgIcon name='chevron-down' width={7} height={7}
            className={b('arrow')}
          />
        </div>
      </div>

    </div>
  );
};

export default BankSelector;
