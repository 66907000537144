import React, { forwardRef, useState } from 'react';

import TextInput, { InputProps } from 'components/shared/inputs/TextInput/TextInput';

interface Props extends InputProps {
  value?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

// eslint-disable-next-line react/display-name
const PasswordInput = forwardRef<HTMLInputElement, Props>((
  {
    placeholder = 'Ваш пароль',
    ...props
  }, ref) => {
  const [isPasswordShown, setIsPasswordShowed] = useState<boolean>(false);

  const showPassword = () => {
    setIsPasswordShowed(true);
  };

  const hidePassword = () => {
    setIsPasswordShowed(false);
  };

  return (
    <TextInput
      {...props}
      additionalButton={isPasswordShown ? 'hide-password' : 'show-password'}
      onAdditionalButtonClick={isPasswordShown ? hidePassword : showPassword}
      type={isPasswordShown ? 'text' : 'password'}
      ref={ref}
      placeholder={placeholder}
    />
  );
});
export default PasswordInput;
