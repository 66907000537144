import { ImgHTMLAttributes } from 'react';

import './ImgSmart.scss';

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  webp?: string | string[];
};

const ImgSmart = ({ webp, ...props }: Props) => (
  <picture className='img-smart'>
    {webp && (
      <source type="image/webp" srcSet={typeof webp === 'string' ? webp : webp.join(', ')} />
    )}
    <img {...props} />
  </picture>
);

export default ImgSmart;
