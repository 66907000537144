import { bemCn } from 'utils/bem-cn';
import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import Logotype from 'components/shared/Logotype/Logotype';

import BurgerPersonalInfo from './components/BurgerPersonalInfo/BurgerPersonalInfo';
import BurgerAuth from './components/BurgerAuth/BurgerAuth';
import BurgerNav from './components/BurgerNav/BurgerNav';
import BurgerFooter from './components/BurgerFooter/BurgerFooter';

import './BurgerMenuContent.scss';

const b = bemCn('burger-menu-content');
function BurgerMenuContent() {
  const isAuth = useAppSelector(selectIsAuthenticated);

  return (
    <div className={b()}>
      <div>
        <Logotype />
        <hr className={b('seperator')} />
        {
          isAuth
            ? <BurgerPersonalInfo />
            : <BurgerAuth />
        }
        <hr className={b('seperator')} />
        <BurgerNav />
      </div>

      <BurgerFooter />
    </div>
  );
}

export default BurgerMenuContent;
