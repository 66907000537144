import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';

// import BonusItem from './components/BonusItem/BonusItem';
import BonusList from './components/BonusList/BonusList';
import BonusInfo from './components/BonusInfo/BonusInfo';

import './BonusPage.scss';


const BonusPage = () => (
  <Routes>
    <Route path="/" element={<BonusList />} />
    <Route path="/:id" element={<BonusInfo />} />
  </Routes>
);

export default BonusPage;
