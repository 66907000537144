import React, { useEffect, useState } from 'react';

import Pagination from 'components/shared/Pagination/Pagination';
import { useGetTransactionsQuery } from 'store/user/userApi';
import { useGetWithdrawalsQuery } from 'store/user/userApi';

import { scrollToTop } from 'utils/scrollToTop';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';

import TransactionItem from '../TransactionItem/TransactionItem';
import NoItems from '../../components/NoItems/NoItems';

const TransactionsPage = () => {
  const [selectPage, setSelectPage] = useState<number>(1);
  const { data: transactions, isLoading: isTransactionsLoading } = useGetTransactionsQuery(selectPage);

  const { data: transactionsWidthrawal, isLoading: isTransactionsWidthrawalLoading } = useGetWithdrawalsQuery(selectPage);

  useEffect(() => {
    scrollToTop(true);
  }, [selectPage]);

  const allItemsCount = transactions?.count || 0;
  const countPages = Math.ceil(allItemsCount / TRANSACTIONS_ITEMS_PER_PAGE);
  const isEmptyList = !isTransactionsLoading && !transactions?.items?.length;

  if (isTransactionsLoading || isEmptyList) {
    return (
      <NoItems isLoading={isTransactionsLoading} isEmpty={isEmptyList} />
    );
  }

  return (
    <>
      {transactions?.items.map((transaction) => (
        <TransactionItem
          transaction={transaction}
          transactionsWithdrawal={transactionsWidthrawal?.items}
          key={transaction.id}
        />
      ))}

      <Pagination
        selectPage={selectPage}
        countPages={countPages}
        setSelectPage={(value) => setSelectPage(value)}
      />
    </>
  );
};

export default TransactionsPage;
