import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';
import { useUpdateWalletMutation } from 'store/user/userApi';
import { getCurrencySymbol, getCurrencyTitle } from 'utils/currency';
import Button from 'components/shared/buttons/Button/Button';

import type { Wallet } from 'types/wallets-data';

import './WalletOperationModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  wallet: Wallet;
}

const WalletOperationModal = ({ wallet, isOpen, onClose }: Props) => {
  const [addWallet] = useUpdateWalletMutation();
  const { t } = useTranslation();

  const updateWallet = async () => {
    await addWallet(wallet.currency);
  };

  const b = bemCn('wallet-operation-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <h2 className={b('title')}>
        {t('wallets.actions.title', 'Операции со счетом')}
      </h2>
      <p className={b('description')}>
        {t('wallets.actions.descriptions', 'Вы можете сделать')}
      </p>
      <div className={b('main')}>
        <div className={b('symbol')}>
          <p>{getCurrencySymbol(wallet.currency)}</p>
        </div>
        <div>
          <p className={b('name')}>
            {getCurrencyTitle(wallet.currency)}
          </p>
          <p className={b('amount')}>{wallet.amount}</p>
        </div>
      </div>
      <Button onClick={updateWallet}>
        {t('wallets.actions.set-main', 'Сделать основным')}
      </Button>
    </AppModal>
  );
};

export default WalletOperationModal;
