import { useState } from 'react';

import { useGetPartnerProjectsQuery, useCreatePartnerProjectMutation } from 'store/partnerProgram/partnerApi';

import type { ManagerPartner, TTableProps } from 'store/partnerProgram/types';
import type { ModalParams } from '../widgets/PPPartnerModals';

const initModalParams = {
  id: 0,
  key: '',
  title: '',
  created: '',
  requestParam: '',
};

export const usePartnerProjects = (projectsCount?: number) => {
  const [modalParams, setModalParams] = useState<ModalParams>(initModalParams);
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = projectsCount ? projectsCount : 20;

  const [createPartnerProject] = useCreatePartnerProjectMutation();

  const {
    data,
    isFetching,
    isError,
  } = useGetPartnerProjectsQuery({ page: currentPage, pageSize });

  const items = data?.items;
  const totalCount = data?.count ?? 0;
  const pageCount = Math.ceil(totalCount / pageSize);

  const pagination: TTableProps<ManagerPartner, keyof ManagerPartner>['pagination'] = {
    current: currentPage,
    total: pageCount,
    onChange: (value) => setCurrentPage(value),
  };

  const handleActionsClick = (options: ModalParams) => {
    setModalParams(options);
    setActiveModal(options.key);
  };

  const onModalClose = () => setActiveModal(null);

  return {
    data,
    isFetching,
    isError,
    totalCount,
    items,
    pagination,
    modalParams,
    activeModal,
    onModalClose,
    handleActionsClick,
    createPartnerProject,
  };
};
