import { api } from 'store/api';

import { AppSports, AppsportServer, convertAppSports, convertGetBannersRes, transformAppStatus } from './convert';

import type { AppStatus } from 'types/auth-data';
import type { TBanner, TBannerMessage } from 'types/banners';

export type AppStatusResponse = {
  system: boolean;
  line: boolean;
  betgames: boolean;
  tgg: boolean;
  inbet: boolean;
  vegas: boolean;
  mascot: boolean;
  agt: boolean;
  withdrawals: boolean;
  pragmatic: boolean;
  smartsoft: boolean;
  tvbet: boolean;
  aviatrix: boolean;
  status_message: {
    enable: boolean;
    message: string;
  };
}

type BankServer = {
  'bankName': string;
  'logoURL': string;
  'schema': string;
}

type Bank = {
  'id': string;
  'bankName': string;
  'logoURL': string;
}

type BanksResponse = {
  dictionary: BankServer[];
}

type GetStreamParams = {
  sport: string;
  tournament: string;
  team1: string;
  team2: string;
  gameStart: string;
}

export const appApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAppStatus: build.query<AppStatus, void>({
      query: () => '/v1/status',
      transformResponse: transformAppStatus
    }),
    getBanks: build.query<Bank[], void>({
      query: () => '/v1/banks',
      transformResponse: (res: BanksResponse) =>
        res.dictionary.map((item) => ({ ...item, id: item.schema }))
    }),

    getBannerMessages: build.query<TBannerMessage[], void>({
      query: () => '/v1/banner_messages',
    }),

    getBanners: build.query<TBanner[], void>({
      query: () => '/v1/banners',
      transformResponse: convertGetBannersRes
    }),

    getEventStream: build.query<string, GetStreamParams>({
      query: (params) => ({
        url: '/stream',
        params: {
          sport: params.sport,
          tournament: params.tournament,
          team1: params.team1,
          team2: params.team2,
          'game_start': new Date(params.gameStart).getTime() / 1000,
        }
      })
    }),

    getAppSports: build.query<AppSports, void>({
      query: () => 'v1/sports',
      transformResponse: (res: AppsportServer[]) => convertAppSports(res)
    })
  }),
  overrideExisting: false,
});

export const {
  useGetBannersQuery,
  useGetAppStatusQuery,
  useGetBanksQuery,
  useGetBannerMessagesQuery,
  useGetEventStreamQuery,
  useGetAppSportsQuery,
} = appApi;
