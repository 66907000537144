import { bemCn } from 'utils/bem-cn';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import './PPError.scss';

type Props = {
  message?: string;
};

const PPError = (props: Props) => {
  const {
    message = 'Данные не доступны',
  } = props;

  const b = bemCn('pp-error');

  return (
    <div className={b()}>
      <p className={b('message')}>
        {message}
      </p>
    </div>
  );
};

export default PPError;
