import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import { useFilteredSports } from 'hooks/useFilteredSports';
import { selectFilterSport, seletIsLineActive } from 'store/app/selectors';
import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';

import SideBarFilterDesktopSport from './components/SideBarFilterDesktopSport/SideBarFilterDesktopSport';
import SideBarFilterDesktopFavorites from './components/SideBarFilterDesktopFavorites/SideBarFilterDesktopFavorites';

import type { LineType } from 'types/line-data';

import './SideBarFilterDesktop.scss';

type Props = {
  lineType: LineType;
};

const b = bemCn('side-bar-filter-desktop');

const SideBarFilterDesktop = ({ lineType }: Props) => {
  const activeSport = useAppSelector(selectFilterSport);
  const isLineActive = useAppSelector(seletIsLineActive);
  const { i18n } = useTranslation();
  const { data: sports = [], isLoading } = useFilteredSports(
    {
      dataType: lineType,
      dataLang: i18n.resolvedLanguage
    },
    { skip: !isLineActive }
  );

  return (
    <div className={b()}>
      <ul className={b('sports')}>
        {isLoading
          ? <Loader />
          :
          <>
            <SideBarFilterDesktopFavorites lineType={lineType} isActive={activeSport === 'favorites'} />
            {
              sports.map((sport) => (
                <SideBarFilterDesktopSport
                  key={sport.id}
                  sport={sport}
                  isActive={activeSport === sport.id}
                  lineType={lineType}
                />
              ))
            }
          </>}
      </ul>
    </div >
  );
};

export default memo(SideBarFilterDesktop);
