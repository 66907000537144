import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { useStartAviatrixSessionMutation } from 'store/slots/slotsApi';
import { bemCn } from 'utils/bem-cn';
import ScrollToTop from 'components/Layout/ScrollToTop/ScrollToTop';
import Loader from 'components/shared/loader/Loader';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import './Aviatrix.scss';

const Aviatrix = () => {
  const { gameId } = useParams();
  const { t, i18n: { resolvedLanguage: lang } } = useTranslation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const [startSession, { isLoading, data: aviatrixGameSessinon }] = useStartAviatrixSessionMutation();
  const isLoaded = aviatrixGameSessinon && !isLoading;

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    const tabBar = document.querySelector('.tab-bar');
    if (tabBar) {
      tabBar.classList.add('visually-hidden');
    }
    return () => {
      document.body.style.overflowY = '';
      if (tabBar) {
        tabBar.classList.remove('visually-hidden');
      }
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      startSession({
        productId: 'nft-aviatrix',
        lang,
        lobbyUrl: `${window.location.origin}/games`,
      });
    }
  }, [isAuthenticated, gameId]);

  const src = aviatrixGameSessinon ?? '';

  const b = bemCn('aviatrix');

  return (
    <div className={b()}>
      <ScrollToTop />
      <div className={b('container')}>
        <Loader className={b('loader')}
          wrapperClassName={b('loader-wrapper')}
        />
        {isLoaded && (
          <iframe className={b('content')}
            title='Inbet Games'
            scrolling='no'
            allow="fullscreen"
            frameBorder="0"
            src={src}
          />
        )}
        <Link className={b('back')}
          to='/games'
        >
          <SvgIcon name='chevron-right' className={b('back-icon')} />
          {t('games.list', 'Список игр')}
        </Link>
      </div>
    </div>
  );
};

export default Aviatrix;
