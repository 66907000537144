import React, { useEffect, } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import Loader from 'components/shared/loader/Loader';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import ScrollToTop from 'components/Layout/ScrollToTop/ScrollToTop';
import { bemCn } from 'utils/bem-cn';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { selectIsInbetActive } from 'store/app/selectors';
import { useCreateInbetSessionMutation } from 'store/slots/slotsApi';
import './InbetGames.scss';

const InbetGames = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const [createSession, inbet] = useCreateInbetSessionMutation();
  const { gameId } = useParams();
  const { t, i18n } = useTranslation();
  const isLoaded = gameId && inbet.data && !inbet.isLoading;

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    const tabBar = document.querySelector('.tab-bar');
    if (tabBar) {
      tabBar.classList.add('visually-hidden');
    }
    return () => {
      document.body.style.overflowY = '';
      if (tabBar) {
        tabBar.classList.remove('visually-hidden');
      }
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && isInbetActive) {
      createSession();
    }
  }, [isAuthenticated, isInbetActive]);

  const src = `/inbetgames.html?customer_id=${inbet.data?.customerId ?? ''}&session=${inbet.data?.session ?? ''}&game_id=${gameId ?? ''}&currency=${inbet.data?.currency ?? ''}&lang=${i18n.resolvedLanguage ?? 'en'} `;

  const b = bemCn('inbet-games');

  return (
    <div className={b()}>
      <ScrollToTop />
      <div className={b('container')}>
        <Loader className={b('loader')}
          wrapperClassName={b('loader-wrapper')}
        />
        {isLoaded && (
          <iframe className='inbet-games__content'
            title='Inbet Games'
            scrolling='no'
            allow="fullscreen"
            frameBorder="0"
            src={src}
          />
        )}
        <Link className={b('back')}
          to='/games?filter=Inbet+Games+Slots'
        >
          <SvgIcon name='chevron-right' className={b('back-icon')} />
          {t('games.list', 'Список игр')}
        </Link>
      </div>
    </div>
  );
};

export default InbetGames;
