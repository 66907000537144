import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useLoginMutation, useResendPhoneRegSmsMutation } from 'store/auth/authApi';
import Button from 'components/shared/buttons/Button/Button';
import { changeActiveModal, setVerifyPhone } from 'store/auth/authSlice';
import { selectLoginError } from 'store/auth/selectors';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';
import PhoneSelector from 'components/shared/inputs/PhoneSelector/PhoneSelector';


import type { Entries } from 'types/common';
import type { LoginErrors } from 'types/auth-data';

import './SignIn.scss';

const phoneRegExp = /^\+?\d{6,24}$/;

type FormFields = {
  phone: string;
  password: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = yup.object({
  password: yup.string()
    .min(4, `${i18n.t('auth.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('auth.modal.errors.empty-pass', 'Введите пароль')}`),
  phone: yup.string()
    .matches(phoneRegExp, `${i18n.t('reg.modal.errors.wrong-phone', 'Введите правильный номер')}`)
    .nullable(true)
    .required(`${i18n.t('reg.modal.errors.empty-phone', 'Введите номер')}`),
}).required();

const b = bemCn('login-modal');
const SignIn = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();
  const [sendSms] = useResendPhoneRegSmsMutation();
  const loginErrors = useAppSelector(selectLoginError);
  const dispatch = useAppDispatch();

  const { handleSubmit, setError, control, getValues } = useForm<FormFields>({
    defaultValues: { phone: '', password: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<LoginErrors> = { ...loginErrors };
    if (fieldsErrors.common === 'Phone verification needed') {
      sendSms(getValues().phone);
      dispatch(changeActiveModal('confirm-phone'));
    }
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<FormFields>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [loginErrors]);

  const onSubmit = async (data: FormFields) => {
    await login({
      login: data.phone.slice(1),
      password: data.password,
    });
    setVerifyPhone(data.phone);
  };

  const handleRegisterClick = () => {
    dispatch(changeActiveModal('sign-up'));
  };

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <form className={b('form')} onSubmit={handleSubmit(onSubmit)}>
        <div className={b('header')}>
          <h2 className={b('title')}>
            {t('auth.modal.title', 'Вход')}
          </h2>
          <p className={b('description')}>
            {t('auth.modal.description', 'Введите номер и пароль, чтобы войти')}
          </p>
        </div>

        <div className={b('wrapper')}>
          {!!loginErrors.common && (
            <ErrorBlock
              isDisplayed={!!loginErrors.common}
              message={loginErrors.common}
              align="center"
            />
          )}
          <div className={b('fields')}>
            <PhoneSelector<FormFields, unknown>
              name="phone"
              placeholder={t('withdrawals.modal.phone-number') ?? ''}
              disabled={isLoading}
              control={control}
            />
            <FormInputWrapper<FormFields>
              name="password"
              type="password"
              control={control}
              disabled={isLoading}
              autoComplete="current-password"
              placeholder={`${t('auth.modal.password', 'Пароль')}`}
              showError
            />
          </div>
          <button className={b('remember-password')}
            type='button'
            onClick={() => dispatch(changeActiveModal('reset-password'))}
          >
            {t('auth.modal.forget-pass', 'Забыли пароль?')}
          </button>
          <Button className={b('button')}
            isLoading={isLoading}
            type='submit'
          >
            {t('auth.modal.sign-in', 'Войти')}
          </Button>
          <Button className={b('button')}
            type='button'
            variant="outline-primary-inverted"
            onClick={handleRegisterClick}
          >
            {t('auth.modal.registration', 'Зарегистрироваться')}
          </Button>
        </div>
      </form>
    </AppModal>
  );
};

export default SignIn;
