import TextContent from 'components/shared/TextContent/TextContent';
import { logoText } from 'utils/constants';


const TermsOfBetCanceling = () => (
  <TextContent as="article">
    <h1>Условия отмены</h1>

    <p>Подтверждение ставки онлайн Клиентом в ходе игрового процесса онлайн на {logoText} считается окончательным и не может быть внезапно изменено или отменено. Возможно сделать ставку на вероятность исхода, противоположную стартовому прогнозу. В некоторых случаях Это поможет снизить возможные потери, однако подобная ставка также не может быть отменена.</p>
    <p>Все выплаты по ставкам на {logoText} рассчитываются, согласно коэффициентам, действующим на момент размещения ставки. Любые последующие изменения коэффициентов не влияют на уже размещенные ставки. Во избежание ошибок, недоразумений, настоятельно рекомендуем Клиенту тщательно проверять все совершённые им ставки в купонах перед их подтверждением онлайн.</p>
  </TextContent>
);

export default TermsOfBetCanceling;
