export const timezoneStorageKey = 'bettesy-user-timezone';
export const defaultTimezone = 3;
export const timezoneTitles: Record<string, string> = {
  '-12': 'UTC-12:00',
  '-11': 'UTC-11:00',
  '-10': 'UTC-10:00',
  '-9': 'UTC-09:00',
  '-8': 'UTC-08:00',
  '-7': 'UTC-07:00',
  '-6': 'UTC-06:00',
  '-5': 'UTC-05:00',
  '-4': 'UTC-04:00',
  '-3': 'UTC-03:00',
  '-2': 'UTC-02:00',
  '-1': 'UTC-01:00',
  0: 'UTC+00:00',
  1: 'UTC+01:00',
  2: 'UTC+02:00',
  3: 'UTC+03:00',
  4: 'UTC+04:00',
  5: 'UTC+05:00',
  6: 'UTC+06:00',
  7: 'UTC+07:00',
  8: 'UTC+08:00',
  9: 'UTC+09:00',
  10: 'UTC+10:00',
  11: 'UTC+11:00',
  12: 'UTC+12:00',
  13: 'UTC+13:00',
  14: 'UTC+14:00',
};
