import React from 'react';
import cn from 'classnames';

import flagsSprite from 'assets/flags/sprite.svg';
import { getCountryIcon, getCountryIconByCode } from 'utils/flag-icon';

import './Flag.scss';

type Props = {
  countryId?: string;
  countryCode?: string;
  className?: string;
  width?: number;
  height?: number;
};

const Flag = (props: Props) => {
  const {
    countryId,
    countryCode,
    className,
    width = 18,
    height = 18,
  } = props;
  let icon = '';
  if (countryId) {
    icon = getCountryIcon(countryId);
  } else if (countryCode) {
    icon = getCountryIconByCode(countryCode);
  } else {
    icon = 'world';
  }

  return (
    <svg className={cn('flag', className)} width={width} height={height}>
      <use xlinkHref={`${flagsSprite}#flag-${icon}`}></use>
    </svg>
  );
};

export default Flag;
