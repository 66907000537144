import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { useClickLogin } from 'hooks/useClickLogin';

const ClickLogin = () => {
  const { clickId, webId } = useParams();
  useClickLogin(clickId, webId);

  return <Navigate to='/' replace />;
};

export default ClickLogin;
