import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import ModalsHead from '../ModalsHead/ModalsHead';
import Withdrawals from '../Withdrawals/Withdrawals';

import './WithdrawalsDesktop.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const WithdrawalsDesktop = (props: Props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  const b = bemCn('withdrawals-desktop');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <ModalsHead
        title={t('withdrawals.title', 'Вывод')}
        descr={`${t('withdrawals.description', 'Выберите метод для вывода')}`}
      />
      <Withdrawals />
    </AppModal>
  );
};

export default WithdrawalsDesktop;
