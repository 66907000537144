import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { ppConverter } from 'store/partnerProgram/ppConverter';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import { useManagerPartners } from '../hooks/useManagerPartners';
import PPTable from '../../components/PPTable/PPTable';
import PPDropdown from '../../components/PPDropdown/PPDropdown';
import PPMangerModals from './PPManagerModals';

import '../PPManager.scss';

import type { ManagerPartner, TTableProps } from 'store/partnerProgram/types';

interface TableData extends Omit<ManagerPartner, 'actions'> {
  actions: string;
}

const PPManagerPartners = () => {
  const b = bemCn('pp-manager');
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const {
    isManagerPartnersFetching,
    isManagerPartnerError,
    items,
    pagination,
    selectedPartnerId,
    partnerActionList,
    activeModal,
    onModalClose,
    handlePartnerActionsClick,
  } = useManagerPartners();

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: `${t('name', 'Имя')}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t('registration-date', 'Дата регистрации')}`,
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: `${t('login', 'Логин')}`,
      dataIndex: 'login',
      key: 'login',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: `${t('phone', 'Телефон')}`,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: `${t('role', 'Роль')}`,
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: `${t('actions', 'Действия')}`,
      dataIndex: 'actions',
      key: 'actions',
      render: (title, item: TableData) => (
        <PPDropdown
          items={partnerActionList}
          onClick={(key) => handlePartnerActionsClick(item.id, key)}
        />
      ),
    },
  ];

  return (
    <>
      <PPTable
        title={`${t('your-partners', 'Ваши партнёры')}`}
        columns={columns}
        className={b('table')}
        isLoading={isManagerPartnersFetching}
        isError={isManagerPartnerError}
        sourceData={ppConverter.managerPartnersToTable(items ?? []) as unknown as TableData[]}
        pagination={pagination}
      />

      <PPMangerModals
        activeModal={activeModal}
        onModalClose={onModalClose}
        id={selectedPartnerId}
      />
    </>
  );
};

export default PPManagerPartners;
