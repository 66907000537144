import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import i18n from 'i18n/i18n';
// import { bemCn } from 'utils/bem-cn';
import { mainPageLinksMobile } from 'utils/constants/mainPageLinks';
import { useResponsive } from 'hooks/useResponsive';
import { useFilteredSports } from 'hooks/useFilteredSports';
import Loader from 'components/shared/loader/Loader';

import MainNavItem from './MainNavItem/MainNavItem';

import './MainNav.scss';

import type { PageData } from 'utils/constants/mainPageLinks';

const lineType = 'line';

const MainNavMobile = () => {
  const { pathname } = useLocation();
  const { isMobile } = useResponsive();
  const ref = useRef<HTMLDivElement>(null);

  const {
    data: sportsList = [],
    isLoading: isListLoading
  } = useFilteredSports({
    dataType: lineType,
    dataLang: i18n.resolvedLanguage
  });

  const sportListWithLink = sportsList.map((sport) => ({
    ...sport,
    title: sport.name,
    link: `/line/sport/${sport.id}/${sport.name}`,
  })).filter((sport) => sport.id !== '1');

  const allNavItems: PageData[] = [...mainPageLinksMobile, ...sportListWithLink];

  useEffect(() => {
    const containerRef = ref.current;
    if (containerRef === null || !isMobile) {
      return;
    }
    const leftShadowClass = 'main-nav--active-left-shadow';
    const rightShadowClass = 'main-nav--active-right-shadow';
    const scrollEvent = () => {
      const leftScrollDistance = containerRef.scrollLeft;
      const rightScrollDistance = containerRef.scrollWidth - containerRef.clientWidth - leftScrollDistance;
      if (leftScrollDistance > 0) {
        containerRef.classList.add(leftShadowClass);
      } else {
        containerRef.classList.remove(leftShadowClass);
      }
      if (rightScrollDistance > 0) {
        containerRef.classList.add(rightShadowClass);
      } else {
        containerRef.classList.remove(rightShadowClass);
      }
    };
    scrollEvent();
    containerRef.addEventListener('scroll', scrollEvent);
    return (() => containerRef.removeEventListener('scroll', scrollEvent));
  }, [ref, isMobile]);

  if (isListLoading) {
    return <Loader />;
  }

  return (
    <div className='main-nav'>
      <div ref={ref} className='main-nav__container'>
        {
          allNavItems.map(({ id, title, link }) =>
            // <MainNavItem key={id} id={id} title={title} link={link} isActive={pathname.startsWith(link)} />)
            <MainNavItem key={id} id={id} title={title} link={link} isActive={pathname === link} />)
        }
      </div>
    </div>
  );
};

export default MainNavMobile;
