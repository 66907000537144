
type savedUserGeo = {
  country: string | null;
  countryName: string | null;
  countryPhoneCode: string | null;
}

export const saveUserGeo = (userGeo: savedUserGeo) => {
  localStorage.setItem('rysebet-user-geo', JSON.stringify(userGeo));
};

export const getSavedUserGeo = () => {
  const userGeo = localStorage.getItem('rysebet-user-geo');
  if (userGeo) {
    return JSON.parse(userGeo) as savedUserGeo;
  }
  return {
    country: null,
    countryName: null,
    countryPhoneCode: null,
  };
};
