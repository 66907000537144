const keyToSnake = (str: string): string => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

const keyToCamel = (s: string): string => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

export const camelToSnakeCase = <T extends object, R>(obj: T) :R => {
  const r = Object.keys(obj).reduce((acc, key) => ({
    ...acc,
    [keyToSnake(key) as keyof T]: obj[key as keyof T],
  }), {});
  return (r as R);
};

export const snakeToCamelCase = <T extends object, R>(obj: T) :R => {
  const r = Object.keys(obj).reduce((acc, key) => ({
    ...acc,
    [keyToCamel(key) as keyof T]: obj[key as keyof T],
  }), {});
  return (r as R);
};
