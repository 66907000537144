import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveBasketModal } from 'store/basket/basketSlice';
import { changeActiveModal as changeAuthModal } from 'store/auth/authSlice';
import { selectIsAuthenticated } from 'store/auth/selectors';
import SectionIcon from 'components/shared/SectionIcon/SectionIcon';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { bemCn } from 'utils/bem-cn';

type Props = {
  isActive: boolean;
};

const b = bemCn('tab-bar-item');
const BasketTabItem = ({ isActive }: Props) => {
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { basketCount } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketCount: data?.count ?? 0
    })
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleBasketClick = () => {
    if (isAuth) {
      dispatch(changeActiveBasketModal('basket'));
    } else {
      dispatch(changeAuthModal('sign-in'));
    }
  };

  return (
    <div className={b()}
      onClick={handleBasketClick}
      key="basket"
    >
      <SectionIcon className={b('section-icon')} id="basket" isBackgroundShowing={isActive} variant="rounded-shadow" />
      <p className={b('title', { 'active': isActive })}>
        {t('main.nav.Cart', 'Корзина')}
      </p>
      <p className={b('basket-count')}>{basketCount}</p>
    </div>

  );
};

export default React.memo(BasketTabItem);
