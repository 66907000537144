import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';

import { changeActiveModal } from 'store/auth/authSlice';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useAppDispatch } from 'hooks';
import { bemCn } from 'utils/bem-cn';

import './DropdownWallet.scss';


const DropdownWallet = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownBlockRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useOnClickOutside(dropdownBlockRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleWithdrawalClick = () => {
    dispatch(changeActiveModal('withdrawals-modal'));
  };

  const handleTopupClick = () => {
    dispatch(changeActiveModal('topups'));
  };

  const handleTransferClick = () => {
    dispatch(changeActiveModal('transfer-to-user'));
  };

  const b = bemCn('dropdown-wallet');

  return (
    <div className={b()} ref={dropdownBlockRef}>
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        <SvgIcon className={b('icon')} name="dots-menu" width={11} height={11} />
      </button>
      {isOpen && (
        <div className={b('dropdown')}>
          <button type="button" onClick={handleWithdrawalClick}>
            {t('wallets.withdrawal', 'Вывод')}
          </button>
          <button type="button" onClick={handleTopupClick}>
            {t('wallets.topup', 'Пополнить')}
          </button>
          {/* <button type="button" onClick={handleTransferClick}>
            {t('wallets.transfer', 'Перевод')}
          </button> */}
        </div>
      )}
    </div>
  );
};

export default DropdownWallet;
