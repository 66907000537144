import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { statsTitleList } from 'store/partnerProgram/constants';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';
import PPFilter from 'pages/PartnerProgram/components/PPFilter/PPFilter';
import PPStats from 'pages/PartnerProgram/components/PPStats/PPStats';

import { usePartnerPromocodeStats } from '../hooks/usePartnerPromocodeStats';
import { usePartnerPromocodePlayers } from '../hooks/usePartnerPromocodePlayers';

import type { PlayerStats, TTableProps, TFilterValues } from 'store/partnerProgram/types';

import '../PPPartner.scss';

interface TableData extends Omit<PlayerStats, 'actions'> {
  actions: string;
}

type Props = {
  id: number;
  key: string;
  title: string;
  created: string;
  requestParam: string;
};

const PPPartnerPromocodeStats = (props: Props) => {
  const {
    id,
    key,
    title,
    created,
    requestParam,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    isFetching: isStatsFetching,
    isError: isStatsError,
    statsList,
    handleFilterChange: statsFilterChange,
    handleFilterReset: statsFilterReset,
  } = usePartnerPromocodeStats(requestParam);

  const {
    items: playerItems,
    isFetching: isPlayersFetching,
    isError: isPlayersError,
    pagination,
    handleFilterChange: playersFilterChange,
    handleFilterReset: playersFilterReset,
  } = usePartnerPromocodePlayers(requestParam);

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: statsTitleList.playerId,
      dataIndex: 'playerId',
      key: 'playerId',
    },
    {
      title: statsTitleList.playerName,
      dataIndex: 'playerName',
      key: 'playerName',
    },
    {
      title: statsTitleList.deposits,
      dataIndex: 'deposits',
      key: 'deposits',
    },
    {
      title: statsTitleList.betsIncome,
      dataIndex: 'betsIncome',
      key: 'betsIncome',
    },
    {
      title: statsTitleList.income,
      dataIndex: 'income',
      key: 'income',
    },
  ];

  const changeFilter = (filterData: TFilterValues) => {
    statsFilterChange(filterData);
    playersFilterChange(filterData);
  };

  const resetFilter = () => {
    statsFilterReset();
    playersFilterReset();
  };

  return (
    <div className={b('content-section')}>
      <div>
        {/* <h2>{t('promocode-stats', 'Статистика по промокоду')}</h2> */}
        <p>{t('title', 'Название')}: {title}</p>
        <p>{t('created', 'Создан')}: {created}</p>
      </div>

      <PPFilter
        onSubmit={changeFilter}
        onReset={resetFilter}
        light
      />

      <PPStats
        title={t('general-stats', 'Общая статистика') ?? 'Общая статистика'}
        items={statsList}
        isLoading={isStatsFetching}
        isError={isStatsError}
      />

      <PPTable
        title={t('users', 'Пользователи') ?? 'Пользователи'}
        columns={columns}
        className={b('table')}
        isLoading={isPlayersFetching}
        isError={isPlayersError}
        sourceData={playerItems as unknown as TableData[]}
        pagination={pagination}
      />
    </div>
  );
};

export default PPPartnerPromocodeStats;
