import { ElementType, HTMLAttributes } from 'react';

import { bemCn } from 'utils/bem-cn';
import './TextContent.scss';

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
  className?: string;
}

const TextContent = (props: Props) => {
  const {
    as: Tag = 'div',
    className,
    ...otherProps
  } = props;

  const b = bemCn('text-content');
  return (
    <Tag {...otherProps} className={b(null, className)} />
  );
};

export default TextContent;
