import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';

import i18n from 'i18n/i18n';
import FormInputWrapper from 'components/shared/form/FormInput';
import Button from 'components/shared/buttons/Button/Button';
import FormSelect from 'components/shared/form/FormSelect';
import FormCheckbox from 'components/shared/form/FormCheckbox';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { getClickId, getRefCode, getWebId } from 'utils/localStorage';
import { usePhoneRegistrationMutation } from 'store/auth/authApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal, setVerifyPhone } from 'store/auth/authSlice';
import { selectRegisterPhoneError } from 'store/auth/selectors';
import PhoneSelector from 'components/shared/inputs/PhoneSelector/PhoneSelector';

import AddPromoCode from '../AddPromocode/AddPromoCode';

import type { Currency } from 'types/wallets-data';
import type { RegisterPhoneErrors } from 'types/auth-data';
import type { Entries } from 'types/common';

export type FormFields = {
  confirmPassword: string;
  password: string;
  currency: Currency | null;
  checked: boolean;
  phone: string;
  promocode: string | undefined;
}

const phoneRegExp = /^\+?\d{6,24}$/;

const schema = yup.object({
  password: yup.string()
    .min(4, `${i18n.t('reg.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reg.modal.errors.empty-pass', 'Введите пароль')}`),
  checked: yup.bool()
    .oneOf([true], `${i18n.t('reg.modal.errors.check-agreement', 'Подтвердите согласие')}`),
  // TODO: Проверить typeError, зачем он тут нужен и как использовать
  currency: yup.string()
    .required(`${i18n.t('reg.modal.errors.empty-currency', 'Выберите валюту')}`)
    .typeError('Выберите валюту'),
  phone: yup.string()
    .matches(phoneRegExp, `${i18n.t('reg.modal.errors.wrong-phone', 'Введите правильный номер')}`)
    .nullable(true)
    .required(`${i18n.t('reg.modal.errors.empty-phone', 'Введите номер')}`),
  promocode: yup.string(),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(4, `${i18n.t('reg.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reg.modal.errors.empty-pass', 'Введите пароль')}`),
}).required();

type Props = {
  id: number;
  userCurrency: string | null;
  availableCurrencies: Currency[];
}

const PhoneTab = (props: Props) => {
  const { id, userCurrency, availableCurrencies } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [registration, { isLoading, isSuccess }] =
    usePhoneRegistrationMutation();
  const registerErrors = useAppSelector(selectRegisterPhoneError);

  const {
    handleSubmit,
    reset,
    control,
    setError,
  } = useForm<FormFields>({
    defaultValues: {
      confirmPassword: '',
      password: '',
      checked: false,
      currency: userCurrency,
      phone: '',
      promocode: '',
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    const fieldsErrors: Partial<RegisterPhoneErrors> = { ...registerErrors };
    delete fieldsErrors.common;
    const formEntries = Object.entries(fieldsErrors) as Entries<Omit<RegisterPhoneErrors, 'common'>>;

    formEntries.forEach(([key, value]) => {
      if (!value) { return; };
      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [registerErrors]);

  const onSubmit = handleSubmit(async (formData: FormFields) => {
    const { phone, password, currency, checked, promocode } = formData;
    const cleanPhone = phone.replace('+', '');
    if (currency && checked) {
      await registration({
        password,
        currency,
        phone: cleanPhone,
        'ref_code': getRefCode(),
        'click_id': getClickId(),
        'web_id': getWebId(),
        'phone_country': 'KE',
        promocode: promocode ? promocode : undefined,
      });
      dispatch(setVerifyPhone(phone));
    }
  });

  return (
    <form className="registration-modal__form" onSubmit={onSubmit} key={id}>
      <ErrorBlock isDisplayed={!!registerErrors.common} message={registerErrors.common} />
      <FormSelect<FormFields, Currency>
        name="currency"
        type="currency"
        options={availableCurrencies}
        disabled={true}
        isLoading={false}
        control={control}
        showError
      />
      <PhoneSelector<FormFields, unknown>
        name="phone"
        placeholder={t('withdrawals.modal.phone-number') ?? ''}
        disabled={isLoading}
        control={control}
      />
      <FormInputWrapper<FormFields>
        name="password"
        type="password"
        placeholder={`${t('reg.modal.password', 'Пароль')}`}
        disabled={isLoading}
        autoComplete="new-password"
        control={control}
        showError
      />
      <FormInputWrapper<FormFields>
        name="confirmPassword"
        type="password"
        placeholder={`${t('reg.modal.confirm-password', 'Подтвердите Пароль')}`}
        disabled={isLoading}
        autoComplete="new-password"
        control={control}
        showError
      />
      <AddPromoCode<FormFields>
        name="promocode"
        disabled={isLoading}
        control={control}
      />
      <FormCheckbox<FormFields>
        className="registration-modal__accept-rules"
        name="checked"
        disabled={isLoading}
        control={control}
        showError
      >
        {t('reg.modal.agreement', 'Я согласен на обработку персональных данных и согласен с')}{' '}
        <Link to="/privacy-policy" className="registration-modal__privacy-policy-link">
          {t('reg.modal.agreement-privacy', 'политикой конфиденциальности')}
        </Link>
      </FormCheckbox>
      <Button className='registration-modal__btn'
        type='submit'
        isLoading={isLoading}
      >
        {t('reg.modal.submit-btn', 'Зарегистрироваться')}
      </Button>
      <Button className='registration-modal__btn'
        onClick={() => dispatch(changeActiveModal('sign-in'))}
        variant="outline-primary-inverted"
      >
        {t('reg.modal.sign-in', 'Войти')}
      </Button>
    </form >
  );
};

export default PhoneTab;
