import React from 'react';

import { getMethodIcons } from 'components/shared/icons/PaymentMethodIcons';
import { bemCn } from 'utils/bem-cn';

import './PaymentMethod.scss';

type Props = {
  className?: string;
  onClick?: () => void;
  title: string;
  id: string;
  variant?: 'default' | 'inverted';
  active?: boolean;
};

const b = bemCn('payment-method');

const PaymentMethod = (props: Props) => {
  const { className, onClick, title, variant = 'default', id, active } = props;
  const layout = getMethodIcons(id);

  return (
    <button className={b({ active, [`${variant}`]: true }, className)}
      type="button"
      onClick={onClick}
    >
      <span className={b('title')}>{title}</span>
      <span className={b('icons')}>{layout}</span>
    </button>
  );
};

export default React.memo(PaymentMethod);
