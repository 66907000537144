import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import i18n from 'i18n/i18n';
import { NameSpace } from 'utils/constants';

import { paymentsApi } from './paymentsApi';
import { BackendError } from '../auth/utils';
import { handleWithdrawalError } from './utils';

import type {
  InitError,
  PaymentsModalType,
  InitResponse,
} from 'types/payments-data';
import type { PaymentsState } from 'types/state';

const paymantInitErrorTitle: Record<string, string> = {
  'Payment timeout': i18n.t('backend-errors.payment.payment-timeout', 'Попробуйте через минуту')
};


const initialState: PaymentsState = {
  initError: null,
  activeModal: null,
};

export const paymentsData = createSlice({
  name: NameSpace.Payments,
  initialState,
  reducers: {
    changeActiveModal: (state, action: PayloadAction<PaymentsModalType | null>) => {
      state.activeModal = action.payload;
      state.initError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        paymentsApi.endpoints.initTopUp.matchFulfilled,
        (state, action: PayloadAction<InitResponse>) => {
          state.activeModal = null;
          state.initError = null;
          const paymentUrl = action.payload.paymentUrl;
          if (paymentUrl) {
            window.open(paymentUrl, '_self');
          }
        },
      )
      .addMatcher(
        paymentsApi.endpoints.initTopUp.matchRejected,
        (state, action) => {
          if (!action.payload) { return; }
          const data = action.payload.data as InitError;
          if (typeof data.detail === 'string') {
            state.initError = paymantInitErrorTitle[data.detail] ?? data.detail;
          }

          if (typeof data === 'string') {
            state.initError = data;
          }
        },
      )
      .addMatcher(
        paymentsApi.endpoints.initWithdrawal.matchFulfilled,
        (state) => {
          state.activeModal = 'success';
          state.initError = null;
        },
      )
      .addMatcher(
        paymentsApi.endpoints.initWithdrawal.matchRejected,
        (state, action) => {
          if (!action.payload || !action.payload.data) { return; }
          const data = action.payload.data as BackendError;
          const status = action.payload.status as number;
          state.initError = handleWithdrawalError(status, data);
        },
      );
  },
});

export const {
  changeActiveModal,
} = paymentsData.actions;
