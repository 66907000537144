import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import TextContent from 'components/shared/TextContent/TextContent';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';

import './TermsPage.scss';

function TermsPage() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const { tittle } = useParams();
  // useEffect(() => {
  //   if (tittle !== 'main' && tittle !== undefined) {
  //     navigate(tittle);
  //   }
  // }, [tittle]);

  return (
    <div className="terms-page">
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <TextContent className="terms-page__content">
        <h1>TERMS AND CONDITIONS</h1>
      </TextContent>
    </div>
  );
}

export default TermsPage;
