import React from 'react';

import { bemCn } from 'utils/bem-cn';
import './CoefDisplay.scss';

type Props = {
  value: string | number;
  variant?: 'primary' | 'secondary' | 'shaded' | 'shaded-highlighted';
  className?: string;
  status?: 'increased' | 'decreased' | 'same';
};

const CoefDisplay = ({ value, variant = 'primary', status, className }: Props) => {
  const b = bemCn('coef-display');

  return (
    <div className={b({ [`${variant}`]: Boolean(variant), status }, className)}>
      {value}
    </div>
  );
};

export default CoefDisplay;
