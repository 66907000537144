import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import * as React from 'react';

import CurrencySelector from 'components/shared/inputs/Selector/Selector';

import type { SelectType } from 'components/shared/inputs/Selector/Selector';

type Props<TFormValues extends FieldValues, T> = {
  options: T[];
  disabled?: boolean;
  type: SelectType;
  isLoading: boolean;
  showError?: boolean;
} & UseControllerProps<TFormValues>;

const FormSelect = <TFormValues extends Record<string, unknown>, T>({
  options,
  showError,
  disabled,
  isLoading,
  type,
  ...props
}: Props<TFormValues, T>) => {
  const { field, fieldState } = useController(props);
  const { value } = field;

  return (
    <div>
      <CurrencySelector<T>
        {...field}
        type={type}
        options={options as unknown as T[]}
        value={value as T | null}
        showError={showError}
        error={fieldState.error}
        isTouched={fieldState.isTouched}
        disabled={disabled}
        isLoading={isLoading}
      />
    </div>
  );
};

export default FormSelect;
