import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useEmailVerifyQuery } from 'store/auth/authApi';
import Loader from 'components/shared/loader/Loader';

import './VerifyEmail.scss';

const VerifyEmail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate('/home');
    }
  }, [navigate, id]);

  const { isSuccess, isError } = useEmailVerifyQuery(id as string);

  useEffect(() => {
    if (isSuccess || isError) {
      navigate('/home');
    }
  }, [navigate, isSuccess, isError]);

  return (
    <div className="verify-page">
      <Loader />
    </div>
  );
};

export default VerifyEmail;
