import { createSelector } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import type { RootState } from 'types/state';

export const selectAccountData = (state: RootState) => state[NameSpace.User].account;
export const selectAccountPhone = (state:RootState) => state[NameSpace.User].account.phone;

export const selectUserTimezone = (state: RootState) => state[NameSpace.User].userTimezone;

export const selectPrimaryWallet = (state: RootState) => state[NameSpace.User].primaryWallet;

export const selectAdditionalWallets = (state: RootState) => state[NameSpace.User].additionalWallets;

export const selectPrimaryWalletCurrency = createSelector(
  selectPrimaryWallet,
  (primaryWallet) => primaryWallet.currency
);

export const selectChangeUserPasswordErrors = (state: RootState) => state[NameSpace.User].changeUserPasswordErrors;

export const selectChangeUserPasswordStatus = (state: RootState) => state[NameSpace.User].changeUserPasswordStatus;

export const selectShowBalanceHeader = (state: RootState) => state[NameSpace.User].showBalanceHeader;

export const selectUserGeo = (state: RootState) => state[NameSpace.User].geo;
export const selectAccountLoading = (state: RootState) => state[NameSpace.User].isAccaountLoading;
