import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { getCurrencySymbol } from 'utils/currency';
import Button from 'components/shared/buttons/Button/Button';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { selectInitError } from 'store/payment/selectors';
import SumSuggestButton from 'components/shared/buttons/SumSuggestButton/SumSuggestButton';
import { useInitWithdrawalMutation } from 'store/payment/paymentsApi';
import BankSelector from 'components/shared/inputs/BankSelector/BankSelector';
import { selectAccountPhone } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';
import { useGetBanksQuery } from 'store/app/appApi';

import { suggestValues } from '../constants';

import type { TWithdrawalMethodsItem } from 'types/payments-data';

import './WithdrawalForm.scss';

const getSuggestions = (currency: string): number[] =>
  suggestValues[currency] ?? suggestValues.USD;

type FormFields = {
  amount: number | '';
  phone: string | null;
  credentials: string;
  bankId: string | null;
};

type Props = {
  method: TWithdrawalMethodsItem;
  balance: number;
};

const credentialsInputTitle: Record<string, string> = {
  'card': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_byn': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_azn': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'uzcard': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_uzs': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_kzt': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_uah': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'card_uah_1000': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'cardmir': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'mir': i18n.t('withdrawals.modal.card-number', 'Номер карты'),
  'mobile': i18n.t('withdrawals.modal.phone-number', 'Номер телефона'),
  'qiwi': i18n.t('withdrawals.modal.wallet-number', 'Номер кошелька'),
  'sbp': i18n.t('withdrawals.modal.phone-number', 'Номер телефона'),
};

const getCredentialsInputTitle = (method: string) =>
  credentialsInputTitle[method.toLocaleLowerCase()]
  ?? i18n.t('withdrawals.modal.account-number', 'Номер счета');

const phoneRegExp = /^\+?\d{9,15}$/;

type SchemaParams = {
  min: number;
  max: number;
  balance: number;
  useBankId: boolean;
}

const schema = ({ min, max, balance, useBankId }: SchemaParams) => yup.object({
  amount: yup
    .number()
    .min(min, `${i18n.t('withdrawals.modal.errors.min-amount', 'Минимальная сумма вывода')} ${min}`)
    .max(max, `${i18n.t('withdrawals.modal.errors.max-amount', 'Максимальная сумма вывода')} ${max}`)
    .test('balanceTest', `${i18n.t('withdrawals.modal.errors.balance', 'Недостаточно средств')}`, (value) => (value ? value <= balance : false))
    .required('Введите сумму пополнения')
    .typeError('Введите сумму'),
  credentials: yup
    .string()
    .required(`${i18n.t('withdrawals.modal.errors.empty-credentials', 'Введите реквизиты')}`),
  phone: yup
    .string()
    .matches(phoneRegExp, `${i18n.t('withdrawals.modal.errors.phone', 'Введите номер телефона')}`)
    .nullable(true),
  ...(useBankId
    ? {
      bankId: yup
        .string()
        .required('Выберите банк')
    } : {
      bankId: yup
        .string()
        .nullable(true)
    })
}).required();

const b = bemCn('withdrawal-form');

const WithdrawalForm = ({ method, balance }: Props) => {
  const { currency, minAmount, maxAmount } = method;
  const { t } = useTranslation();
  const { data: bankslist = [] } = useGetBanksQuery();
  const [init, { isLoading }] = useInitWithdrawalMutation();
  const initError = useAppSelector(selectInitError);
  const userPhone = useAppSelector(selectAccountPhone);
  const isCard = method.name.toLocaleLowerCase() === 'card';
  const isSBP = method.name.toLocaleLowerCase() === 'sbp';

  const { handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: {
      amount: '',
      credentials: `+${userPhone}`,
      phone: `+${userPhone}`,
      bankId: isSBP ? '' : null
    },
    mode: 'all',
    resolver: yupResolver(schema({
      min: minAmount,
      max: maxAmount,
      balance,
      useBankId: isSBP,
    })),
  });

  const handleSumClick = (amount: number) => {
    if (!isLoading) {
      setValue('amount', amount, { shouldValidate: true, shouldTouch: true });
    }
  };

  const handleFormSubmit = async (formData: FormFields) => {
    const { amount, credentials, phone, bankId } = formData;
    const creds: string[] = [];
    creds.push(credentials.replace('+', ''));
    if (phone) {
      creds.push(phone.replace('+', ''));
    }
    if (bankId) {
      creds.push(bankId);
    }

    if (amount) {
      await init({
        credentials: creds,
        amount: amount,
        method: method.name,
      });
    }
  };

  return (
    <form className={b()} onSubmit={handleSubmit(handleFormSubmit)}>
      <ErrorBlock isDisplayed message={initError} align="center" />
      <FormInputWrapper<FormFields>
        name="credentials"
        type="text"
        value={userPhone}
        control={control}
        disabled
        placeholder={getCredentialsInputTitle(method.name)}
        currency={currency}
        showError
      />
      {isSBP ? (
        <BankSelector
          control={control}
          name="bankId"
          placeholder='Выберите банк'
          options={bankslist}
        />
      ) : null}
      <FormInputWrapper<FormFields>
        name="amount"
        type="money"
        control={control}
        disabled={isLoading}
        placeholder={`${t('withdrawals.modal.amount', 'Сумма')}`}
        currency={currency}
        showError
      />
      <div className={b('input-info')}>
        <p>{t('withdrawals.modal.limit-description', 'Сумма одного депозита')}</p>
        <p>
          {t('from', 'from')} {minAmount} {getCurrencySymbol(currency)} {t('to', 'to')} {maxAmount} {getCurrencySymbol(currency)}
        </p>
      </div>
      {isCard && (
        <>
          <FormInputWrapper<FormFields>
            name="phone"
            type="phone"
            control={control}
            placeholder={`${t('withdrawals.modal.phone-number', 'Номер телефона')}`}
            currency={currency}
            showError
          />
          <div className={b('input-info')}>
            <p>{t('withdrawals.modal.card-description', 'Введите номер телефона, привязанный к карте')}</p>
          </div>
        </>
      )}

      <div className={b('suggestions')}>
        {getSuggestions(currency)
          .filter((suggest) => suggest <= balance)
          .map((suggestAmount) => (
            <SumSuggestButton
              key={suggestAmount}
              onClick={handleSumClick}
              amount={suggestAmount}
              currency={currency}
            />
          ))}
      </div>

      <Button className={b('button')} type='submit' isLoading={isLoading} >
        {t('withdrawals.modal.submit-btn', 'Вывести')}
      </Button>
    </form>
  );
};

export default WithdrawalForm;
