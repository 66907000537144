import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsSmartsoftActive } from 'store/app/selectors';
import { useGetSmartsoftGamesQuery } from 'store/slots/slotsApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';

const SmartsoftWidget = () => {
  const isSmartsoftActive = useAppSelector(selectIsSmartsoftActive);
  const {
    data: smartsoftGames = [],
    isLoading: isSmartsoftLoading,
  } = useGetSmartsoftGamesQuery(
    undefined,
    { skip: !isSmartsoftActive }
  );

  if (!isSmartsoftActive) {
    return null;
  }

  const link = '/games?filter=Smartsoft';
  const gamesList = smartsoftGames.slice(0, 20);

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Smartsoft"
          iconColor="purple"
          iconName='coin'
          itemsCounter={smartsoftGames.length}
          items={gamesList}
          linkToAll={link}
          isLoading={isSmartsoftLoading}
          isAvailable={isSmartsoftActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Smartsoft"
          items={gamesList}
          linkToAll={link}
          isAvailable={isSmartsoftActive}
        />
      )}
    />
  );
};

export default SmartsoftWidget;
