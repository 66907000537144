import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import { useManagerPartnerPromocodeStats } from '../hooks/useManagerPartnerPromocodeStats';
import PPFilter from '../../components/PPFilter/PPFilter';
import PPStats from '../../components/PPStats/PPStats';

import '../PPManager.scss';

const PPManagerPartnerPromocodeStats = () => {
  const b = bemCn('pp-manager');

  const {
    statsList,
    isFetching,
    isError,
    promocodeId,
    handleFilterChange,
    handleFilterReset,
  } = useManagerPartnerPromocodeStats();

  return (
    <>
      <PPFilter
        onSubmit={handleFilterChange}
        onReset={handleFilterReset}
      />

      <PPStats
        title={`Статистика промокода по ID ${promocodeId}`}
        items={statsList}
        isLoading={isFetching}
        isError={isError}
      />
    </>
  );
};

export default PPManagerPartnerPromocodeStats;
