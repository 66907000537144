import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
// import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';


import { useAddWalletMutation } from 'store/user/userApi';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import FormSelect from 'components/shared/form/FormSelect';
import { currencies } from 'utils/constants';

import './AddWalletForm.scss';

type FormFields = {
  currency: string;
};

// const schema = ({ currency }: { currency: string }) => yup
//   .object({
//     send_to: yup
//       .string().required('Выберите валюту')
//   })
//   .required();

const AddWalletForm = () => {
  const { t } = useTranslation();
  const [isCanAdd, setIsCanAdd] = useState<boolean>(false);
  const [addWallet, { isError: isSendError }] = useAddWalletMutation();
  const [addWalletSuccess, setAddWalletSuccess] = useState<boolean>(false);

  const { handleSubmit, watch, control, resetField } =
    useForm<FormFields>({
      defaultValues: { currency: '' },
      mode: 'all',
    });

  useEffect(() => {
    watch((value, { name, type }) => setIsCanAdd(true));
  }, [watch]);

  const handleWalletAdd = async ({ currency }: FormFields) => {
    if (currency.length > 0) {
      resetField('currency');
      await addWallet(currency);
      setAddWalletSuccess(true);
    }
  };
  return (
    <div>
      <h3 className='add-wallet__title'>
        {t('wallets.add-wallet', 'Добавить новый счет')}
      </h3>
      <div className='add-wallet__form'>
        <div className='add-wallet__fields'>
          <FormSelect
            options={currencies}
            type="currency"
            isLoading={false}
            name="currency"
            control={control}
          />
        </div>
        <div className='add-wallet__add'>
          <button
            type="button"
            className={cn({ 'active': isCanAdd })}
            onClick={handleSubmit(handleWalletAdd)}
          >
            <p>+</p>
          </button>
        </div>
      </div>
      <ResultModal
        title={
          isSendError
            ? t('result-modal.wallet.error.title', 'Произошла ошибка во время добавления валюты')
            : t('result-modal.wallet.success.title', 'Новая валюта добавлена в ваш аккаунт')
        }
        status={isSendError ? 'error' : 'success'}
        isOpen={addWalletSuccess}
        onClose={() => setAddWalletSuccess(false)}
      />
    </div>
  );
};

export default AddWalletForm;
