import { useCallback, useEffect, useState } from 'react';

import { scrollToTop } from 'utils/scrollToTop';
import { bemCn } from 'utils/bem-cn';

import SvgIcon from '../SvgIcon/SvgIcon';
import './ButtonToTop.scss';


const ButtonToTop = () => {
  const [isShowButton, setShowButton] = useState(false);

  const handleWindowScroll = useCallback(() => {
    if (window.pageYOffset > 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  const handleOnClick = useCallback(() => {
    scrollToTop(true);
  }, []);

  const b = bemCn('button-to-top');

  return isShowButton
    ? (
      <button className={b()} onClick={handleOnClick}>
        <SvgIcon className={b('icon')} name="chevron-down" />
      </button>
    )
    : null;
};

export default ButtonToTop;
