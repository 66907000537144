import { Link } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';
import { checkMajorTournament } from 'utils/common';
import { TournamentItem as TournamentItemType } from 'types/line-data';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { useFavoriteTournamentToggle } from 'hooks/useFavoriteTournamentToggle';
import { AddFavoriteTournamentBody } from 'types/user-data';

import './TournamentItem.scss';

interface props {
  tournament: TournamentItemType;
  isFavorite: boolean;
  favoriteId: string;
  sportId: string;
  tournamentInfo: AddFavoriteTournamentBody;
}

const b = bemCn('tournament-item');
function TournamentItem({ tournament, tournamentInfo, isFavorite, favoriteId, sportId }: props) {
  const { toggleFavoriteTournament, isLoading } =
    useFavoriteTournamentToggle();
  const major = checkMajorTournament(tournament.id);
  const handleFavoriteClick = () => {
    if (!isLoading) {
      toggleFavoriteTournament({ isFavorite, favoriteId, tournamentInfo });
    }
  };
  return (
    <div className={b('')}>
      <Link className={b('link')}
        to={`/line/tournament/${sportId}/${tournament.id}/${tournament.name}`}
        key={tournament.id}
      >
        <p className={b('title', { major })}>{tournament.name}</p>
      </Link>
      <FavoriteBtn onClick={handleFavoriteClick} active={isFavorite} className={b('favorite-button')} />
    </div>
  );
}

export default TournamentItem;
