import React from 'react';

import { bemCn } from 'utils/bem-cn';

import './BetTypeButton.scss';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
};

const BetTypeButton = ({ children, disabled, onClick, isActive }: Props) => {
  const b = bemCn('bet-type-button');

  return (
    <button className={b({ active: isActive })}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default React.memo(BetTypeButton);
