import { useResponsive } from 'hooks/useResponsive';

type Props = {
  desktop?: JSX.Element;
  mobile: JSX.Element;
}

const Adaptive = ({ desktop, mobile }: Props) => {
  const { isDesktop } = useResponsive();

  return isDesktop && desktop
    ? desktop
    : mobile;
};

export default Adaptive;
