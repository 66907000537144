import { useAppSelector } from 'hooks';
import { selectFilterTournament } from 'store/app/selectors';
import { sortByTopEntries } from 'utils/common';
import { bemCn } from 'utils/bem-cn';
import { useGetFavoriteTournamentsQuery } from 'store/user/userApi';
import Loader from 'components/shared/loader/Loader';
import { AddFavoriteTournamentBody } from 'types/user-data';
import { selectIsAuthenticated } from 'store/auth/selectors';

import SideBarFilterDesktopTournament from '../SideBarFilterDesktopTournament/SideBarFilterDesktopTournament';

import './SideBarFilterDesktopTournaments.scss';

import type { TournamentItem, TournamentRegion } from 'types/line-data';

type Props = {
  tournaments: Extract<TournamentItem, { nameRu?: string; nameEn?: string }>[];
  isLoading?: boolean;
  tournamentRegion: TournamentRegion;
};

const b = bemCn('side-bar-filter-desktop-tournaments');

const SideBarFilterDesktopTournaments = ({ tournaments, isLoading, tournamentRegion }: Props) => {
  const activeTournament = useAppSelector(selectFilterTournament);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteTournamentList = [], isLoading: isFavoriteLoading } = useGetFavoriteTournamentsQuery(undefined, { skip: !isAuth });
  const favoriteTournamentsId = favoriteTournamentList.map((tournament) => tournament.tournamentId);
  const sortedTournaments = sortByTopEntries(tournaments, favoriteTournamentsId, (item) => item.id);
  return (
    <ul className={b()}>
      {isLoading || isFavoriteLoading
        ? <Loader />
        : sortedTournaments.map((tournament) => {
          const isFavorite = favoriteTournamentsId.includes(tournament.id);
          const tournamentInfo: AddFavoriteTournamentBody = { tournamentId: tournament.id, tournamentName: tournament.name, ...tournamentRegion };
          return (
            <SideBarFilterDesktopTournament
              key={tournament.id}
              isActive={tournament.id === activeTournament}
              tournament={tournament}
              isFavorite={isFavorite}
              favoriteId={favoriteTournamentList.find((favTour) => favTour.tournamentId === tournament.id)?.id}
              tournamentInfo={tournamentInfo}
            />
          );
        })}
    </ul>
  );
};

export default SideBarFilterDesktopTournaments;
