import { bemCn } from 'utils/bem-cn';

import Loader from '../loader/Loader';
import './LoadingScreen.scss';

type Props = {
  isLoading: boolean;
}

const LoadingScreen = ({ isLoading }: Props) => {
  if (!isLoading) {
    return null;
  }

  const b = bemCn('loading-screen');
  return (
    <div className={b()}>
      <Loader
        wrapperClassName={b('loader-wrapper')}
        className={b('loader')}
      />
    </div>
  );
};

export default LoadingScreen;
