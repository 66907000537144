import { useTranslation } from 'react-i18next';
import { bemCn } from 'utils/bem-cn';
import { statsTitleList } from 'store/partnerProgram/constants';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';
import PPFilter from 'pages/PartnerProgram/components/PPFilter/PPFilter';

import { usePartnerPlayers } from '../hooks/usePartnerPlayers';

import type { PlayerStats, TTableProps } from 'store/partnerProgram/types';

import '../PPPartner.scss';

interface TableData extends Omit<PlayerStats, 'actions'> {
  actions: string;
}

const PPPartnerPlayers = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    items,
    isFetching,
    isError,
    pagination,
    handleFilterChange,
    handleFilterReset,
  } = usePartnerPlayers();

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: statsTitleList.playerId,
      dataIndex: 'playerId',
      key: 'playerId',
    },
    {
      title: statsTitleList.playerName,
      dataIndex: 'playerName',
      key: 'playerName',
    },
    {
      title: statsTitleList.deposits,
      dataIndex: 'deposits',
      key: 'deposits',
    },
    {
      title: statsTitleList.betsIncome,
      dataIndex: 'betsIncome',
      key: 'betsIncome',
    },
    {
      title: statsTitleList.income,
      dataIndex: 'income',
      key: 'income',
    },
  ];

  return (
    <>
      <PPFilter
        onSubmit={handleFilterChange}
        onReset={handleFilterReset}
      />

      <PPTable
        title={t('players-stats', 'Статистика рефералов') ?? 'Статистика рефералов'}
        columns={columns}
        className={b('table')}
        isLoading={isFetching}
        isError={isError}
        sourceData={items as unknown as TableData[]}
        pagination={pagination}
      />
    </>
  );
};

export default PPPartnerPlayers;
