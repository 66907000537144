import React, { ReactNode } from 'react';

import { bemCn } from 'utils/bem-cn';


import AppModal from '../AppModal/AppModal';
import './ResultModal.scss';

export type Status = 'confirmation' | 'success' | 'error';

type Props = {
  title: string;
  status: Status;
  contentLabel?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  error?: string | null;
  children?: ReactNode;
};

const ResultModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    contentLabel,
    title,
    description,
    status,
    error,
    children
  } = props;

  const b = bemCn('result-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} contentLabel={contentLabel}>
      <div className={b('icon', { [`${status}`]: !!status })} />
      <div className={b('title')}>{title}</div>
      <p className={b('description')}>{error ?? description}</p>
      {children}
    </AppModal>
  );
};

export default ResultModal;
