import React from 'react';
import { useToggle } from 'usehooks-ts';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import { rulesListType } from '../../RulesPage';

import './RulesItem.scss';

const b = bemCn('rules-item');

type Props = {
  rule: rulesListType;
  id: number;
};

const RulesItem = ({ rule, id }: Props) => {
  const [isCollapsed, toggleCollapsed] = useToggle(true);
  return (
    <div className={b({ collapsed: isCollapsed })}>
      <div className={b('header')} onClick={toggleCollapsed}>
        <h2 className={b('title')}>{rule.title}</h2>
        <SvgIcon className={b('toggle-icon')}
          name='chevron-down'
          width={11}
          height={11}
        />
      </div>
      {!isCollapsed && <List items={rule.items} id={id} />}
    </div>
  );
};

type ListProps = {
  id: number;
  items?: {
    text: string;
    items?: { text: string }[];
  }[];
}

const List = ({ items, id }: ListProps) => !items
  ? null
  : (
    <ul className={b('list')}>
      {items.map((item, index) => {
        const number = `${id}.${index + 1}.`;
        return (
          <li className={b('item')} key={number}>
            {item.text}
            <SubList items={item.items} number={number} />
          </li>
        );
      })}
    </ul>
  );

type SubListProps = {
  number: string | number;
  items?: { text: string }[];
}

const SubList = ({ number, items }: SubListProps) => !items
  ? null
  : (
    <ul className={b('sub-list')}>
      {items.map((subItem, subIndex) => {
        const subNumber = `${number}${subIndex + 1}.`;
        return (
          <li className={b('sub-item')} key={subNumber}>
            {subItem.text}
          </li>
        );
      })}
    </ul>
  );


export default RulesItem;
