import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'hooks';
import { changeShowBalance } from 'store/user/userSlice';
import { selectShowBalanceHeader } from 'store/user/selectors';

import './SwapShowBalance.scss';

type Props = {
  classTitle: string;
};

const SwapShowBalance = ({ classTitle }: Props) => {
  const [changeBalance, setChanegBalance] = useState(false);
  const showBalanceHeader = useAppSelector(selectShowBalanceHeader);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const onButtonClick = () => {
    dispatch(changeShowBalance(!showBalanceHeader));
    setChanegBalance(!changeBalance);
  };

  return (
    <button type="button" className={cn(classTitle, 'swap-show-balance', { 'active': !showBalanceHeader })} onClick={onButtonClick}>
      {t('settings.hide-balance', 'Скрыть баланс из хедера')}
      <span />
    </button>
  );
};

export default SwapShowBalance;
