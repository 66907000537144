import React from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';

import { useNavigate } from 'react-router-dom';

import i18n from 'i18n/i18n';
import Loader from 'components/shared/loader/Loader';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/shared/PageHeader/PageHeader';
import { useAppSelector } from 'hooks';
import { useFilteredSports } from 'hooks/useFilteredSports';
import { seletIsLineActive } from 'store/app/selectors';
import { bemCn } from 'utils/bem-cn';

import SportListItem from './SportListItem/SportListItem';

import type { LineType } from 'types/line-data';

import './SportsList.scss';

const pageTitle: Record<LineType, string> = {
  line: i18n.t('main.nav.Line', 'Линия'),
  live: i18n.t('main.nav.Live', 'Live'),
};

const lineType = 'line';

const SportsList = () => {
  const { t } = useTranslation();
  const isLineActive = useAppSelector(seletIsLineActive);
  const navigate = useNavigate();

  const {
    data: sportsList = [],
    isLoading: isListLoading
  } = useFilteredSports({
    dataType: lineType,
    dataLang: i18n.resolvedLanguage
  }, { skip: !isLineActive });

  const b = bemCn('sports');

  const handleStarClick = () => navigate('/profile/favorite-list');

  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <PageHeader
        id={lineType}
        title={pageTitle[lineType]}
        controlPanel={[{ type: 'star',onClick:handleStarClick }]}
      />
      <div className={b('content')}>
        {isListLoading
          ? <Loader />
          : (
            <div className={b('list')}>
              {sportsList.map((sportItem) => (
                <SportListItem
                  key={sportItem.id}
                  sport={sportItem}
                  link={`/line/sport/${sportItem.id}/${sportItem.name}`}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default SportsList;
