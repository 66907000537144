import TextContent from 'components/shared/TextContent/TextContent';
import { logoText } from 'utils/constants';


const TermsOfUse = () => (
  <TextContent as="article">
    <h1>Условия использования</h1>

    <p>Настоящие положения и условия, а также правила, применимые ко всем играм на сайте {logoText} и любым другим документам, упомянутым в них, являются соглашением и пониманием между сторонами и регулируют договорные отношения между компанией {logoText} и владельцем учетной записи сервиса («Клиент»).</p>
    <p>Пожалуйста, ознакомьтесь детально с данным соглашением перед его принятием. Если вы не согласны с содержанием, пожалуйста, воздержитесь от использования сервиса. При использовании услуг {logoText} вы автоматически даёте согласие на нижеследующие условия, положения:</p>
    <ol>
      <li>Клиент добровольно вступает в соглашение с Компанией, принимает изложенные ниже условия. Настоящие положения и условия применяются независимо от того, осуществляется ли доступ к сервису через телефон, настольный браузер или мобильный браузер.</li>
      <li>Вы автоматически даёте согласие на перечень остальных условий, правил, которые опубликованы на игровом сайте Компании. Данные положения и условия настоящей версии действуют до даты, указанной как ___ года. Они остаются в силе до тех пор, пока не будет опубликована новая версия на веб-сайте и принята владельцем учетной записи в соответствии с настоящими условиями.</li>
      <li>Клиент подтверждает факт достижения им минимального возраста, с момента наступления которого может беспрепятственно пользоваться игровыми услугами, согласно законодательству страны вашего проживания.</li>
      <li>Клиент не пребывает на сегодняшний момент в странах, попадающих под ограничения.</li>
      <li>Возраст Клиента составляет 18 и более лет.</li>
      <li>Клиент играет на сайте добровольно, исходя из собственных интересов; использует для ставок свои собственные средства.</li>
      <li>Регистрация и создания аккаунта на игровом сайте является единственным.</li>
      <li>Клиент по собственному желанию не производил самоисключение с игровых сайтов на протяжении предыдущих 12 месяцев.</li>
      <li>Клиент внимательно прочитал, изучил и полностью соглашается со всеми приведёнными условиями правилами.</li>
      <li>Клиент добровольно подтверждает: предоставленная им персональная информация верна. Вы осознаёте, что она может быть в любой момент проверена. Клиент знает, что предоставление ложных или искажённых данных приведет к автоматической аннуляции выигрышей.</li>
      <li>Клиент осведомлен и признаете: {logoText} устанавливает определённые лимиты на максимальную сумму выигрыша, доступной для получения и вывода за день/неделю/месяц.</li>
      <li>Мы ожидаем от пользователя проявления ответственности в период игры. Решение о пользовании услугами {logoText} полностью зависит от вас. Клиент принимает решение о ведении игры исключительно по своему выбору, осознавая вероятные риски. Принимая решение использовать сервисы {logoText}, вы даёте автоматическое подтверждение об осознании возможности утраты вложенных финансовых средств в ходе  игры, несёте самостоятельную ответственность за любые потери. Также Клиент подтверждает отсутствие у него любых претензий к {logoText}/партнерам Компании, директорам, сотрудникам в отношении результатов игрового процесса или проигрышей.</li>
      <li>Посещая разделы, вкладки нашего сайта/создавая аккаунт для игры, Клиент дает согласие с предоставленным перечнем пунктов соглашения, промо, политикой конфиденциальности, нюансами совершения ставок, спецпредложениями, акциями, политикой конфиденциальности, предлагаемыми на официальном сайте {logoText}.
      </li>
    </ol>
    <p>Ещё раз перечитайте, изучите пункты, представленные выше, перед тем, как согласится с данным соглашением, принимая его условия. При несогласии с выше представленными пунктами, Клиенту следует прекратить использование нашего игрового сайта. Любое последующее использование сайта {logoText} автоматически рассматривается как согласие Клиента с пунктами Соглашения.</p>
  </TextContent>
);

export default TermsOfUse;
