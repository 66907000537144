import React from 'react';
import { useTranslation } from 'react-i18next';

import AppModal from 'components/shared/AppModal/AppModal';

import ModalsHead from '../ModalsHead/ModalsHead';
import Topups from '../Topups/Topups';
import './TopUpsDesktop.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TopUpsDesktop = (props: Props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      className='topups-modal'
    >
      <ModalsHead
        title={t('topups.modal.title', 'Пополнить')}
        descr={`${t('topups.description', 'Выберите метод оплаты')}`}
      />
      <Topups />
    </AppModal>
  );
};

export default TopUpsDesktop;
