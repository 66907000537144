import { bemCn } from 'utils/bem-cn';
import PPTable from 'pages/PartnerProgram/components/PPTable/PPTable';
import { statsTitleList } from 'store/partnerProgram/constants';

import { useManagerPartnerPlayers } from '../hooks/useManagerPartnerPlayers';
import PPFilter from '../../components/PPFilter/PPFilter';

import type { PlayerStats, TTableProps } from 'store/partnerProgram/types';

interface TableData extends Omit<PlayerStats, 'actions'> {
  actions: string;
}

const PPManagerPartnerPlayers = () => {
  const b = bemCn('pp-manager');

  const {
    items,
    isFetching,
    isError,
    pagination,
    partnerId,
    handleFilterChange,
    handleFilterReset,
  } = useManagerPartnerPlayers();

  const columns: TTableProps<TableData, keyof TableData>['columns'] = [
    {
      title: statsTitleList.playerId,
      dataIndex: 'playerId',
      key: 'playerId',
    },
    {
      title: statsTitleList.playerName,
      dataIndex: 'playerName',
      key: 'playerName',
    },
    {
      title: statsTitleList.betsPlaced,
      dataIndex: 'betsPlaced',
      key: 'betsPlaced',
    },
    {
      title: statsTitleList.betsWinnings,
      dataIndex: 'betsWinnings',
      key: 'betsWinnings',
    },
    {
      title: statsTitleList.income,
      dataIndex: 'income',
      key: 'income',
    },
  ];

  return (
    <>
      <PPFilter
        onSubmit={handleFilterChange}
        onReset={handleFilterReset}
      />

      <PPTable
        title={`Статистика рефералов партнёра с ID ${partnerId}`}
        columns={columns}
        className={b('table')}
        isLoading={isFetching}
        isError={isError}
        sourceData={items as unknown as TableData[]}
        pagination={pagination}
      />
    </>
  );
};

export default PPManagerPartnerPlayers;
