import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { bemCn } from 'utils/bem-cn';
import './NotFound.scss';

const NotFounded = () => {
  const { t } = useTranslation();
  const b = bemCn('not-founded');
  return (
    <div className={b()}>
      <div className={b('icon')} />
      <div className={b('info')}>
        <h1 className={b('title')}>
          {t('not-found.title', 'Страница не найдена')}
        </h1>
        <p>{t('not-found.text-1', 'Упс...Что-то пошло не так.')}</p>
        <p>{t('not-found.text-2', 'Страница, которую вы искали, не найдена')}</p>
      </div>
      <Link className={b('to-home')} to="/home">
        <Button>
          {t('main.nav.Home', 'На главную')}
        </Button>
      </Link>
    </div>
  );
};

export default NotFounded;
