import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useDeleteFromBasketMutation } from 'store/basket/basketApi';
import { changeActiveBasketModal } from 'store/basket/basketSlice';
import { useAppDispatch } from 'hooks';

import CoefDisplay from '../../../line/components/CoefDisplay/CoefDisplay';

import type { BasketItem as BasketItemType } from 'types/basket-data';

import './BasketItem.scss';
export type DiffType = 'increased' | 'decreased' | 'same';

type Props = {
  item: BasketItemType;
  deleteBlock?: boolean;
};

const BasketItem = ({ item, deleteBlock }: Props) => {
  const {
    team1,
    team2,
    rate: coef,
    tournament,
    dataType: lineType,
    available: isError,
    groupName,
    outcomeName,
    basketId,
    subGameName,
    gameMid,
    sportName,
  } = item;
  const dispatch = useAppDispatch();
  const [diff, setDiff] = useState<DiffType>('same');
  const [rate, setRate] = useState(coef);
  const [deleteFromBasket, { isSuccess: isSuccessDeleted }] = useDeleteFromBasketMutation();


  useEffect(() => {
    setRate((prevRate) => {
      if (prevRate !== undefined && coef !== undefined) {
        if (coef > prevRate) {
          setDiff('increased');
        } else if (coef < prevRate) {
          setDiff('decreased');
        }
      }
      return coef;
    });
  }, [coef]);

  useEffect(() => {
    if (diff !== 'same') {
      const timer = setTimeout(() => {
        setDiff('same');
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [diff]);

  if (isSuccessDeleted) {
    return null;
  }

  const handleDeleteClick = () => {
    if (!deleteBlock) {
      deleteFromBasket({
        dataType: lineType,
        basketId,
      });
    }
  };

  const closeBasket = () => dispatch(changeActiveBasketModal(null));

  const gameUrl = `/fullevent/${gameMid}/${lineType}`;

  const b = bemCn('basket-item');
  return (
    <div className={b({ error: !isError })}>
      <div className={b('content')}>

        <div className={b('row')}>
          <div className={b('title-wrapper')}>
            <CoefDisplay
              className={b('rate')}
              value={rate}
              status={diff}
            />
            <p className={b('title')}>
              {subGameName && `${subGameName} • `}
              {groupName} • {outcomeName}
            </p>
          </div>
          {!deleteBlock && (
            <button className={b('delete-btn')}
              type='button'
              onClick={handleDeleteClick}
            >
              <SvgIcon name="trash" width={15} height={15} />
            </button>
          )}
        </div>

        <div className={b('row')}>
          <Link className={b('teams')} to={gameUrl} onClick={closeBasket}>
            <p className={b('team-name')}>{team1}</p>
            <p className={b('team-name')}>{team2}</p>
          </Link>
        </div>

        <div className={b('row', b('coefficients'))}>
          <p>{sportName} • {tournament}</p>
          {lineType === 'live' && (
            <p className={b('live')}>
              <span className={b('bullet')}></span>
              Live
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasketItem;
