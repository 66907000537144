import TextContent from 'components/shared/TextContent/TextContent';
import { logoText } from 'utils/constants';


const KycAndAml = () => (
  <TextContent as="article">
    <h1>KYC & AML</h1>
    <p>Компания {logoText} прилагает все усилия по борьбе с отмыванием денег и международным терроризмом (AML-политика). Мы строго придерживаемся принципов, которые предотвращают незаконную деятельность и соблюдаем соответствующее законодательство, о чём уведомляем наших Клиентов заранее.</p>
    <p>При регистрации на сайте {logoText} Клиент автоматически соглашается с политикой AML и дальнейшим использованием сайта подтверждает свое согласие. При необходимости проверки финансовых средств, используемых для игры, Клиент соглашается предоставить все необходимые данные. Если деятельность клиента или операции на его счетах будут считаться подозрительными, аккаунт будет заблокирован.</p>
    <p>В случае подозрений в отношении честного происхождения ваших средств и их возможной связи с легализацией незаконно полученных доходов или финансированием терроризма, мы обязаны сообщить об этом официальным органам. В соответствии с правилами AML-политики, мы заблокируем такие средства и предпримем другие соответствующие меры.</p>
    <p>Отмывание денег подразумевает скрытие информации о происхождении, источнике, местонахождении, правах собственности и других правах на незаконно полученное имущество, а также его конвертацию, перемещение и использование с целью сокрытия незаконного происхождения или содействия преступным действиям.</p>
    <p>В целях предотвращения проникновения криминально-инфицированного капитала и распространения преступности, компания {logoText} активно сотрудничает с государственными и международными организациями по борьбе с отмыванием денег и финансированием терроризма.</p>
    <p>При регистрации на сайте {logoText} в качестве Клиента, вы обязуетесь соблюдать применимые законы и нормативные акты в области противодействия отмыванию средств и финансированию терроризма. Клиент также подтверждает, что у него нет информации или подозрений о незаконном происхождении средств, использованных для пополнения счета.</p>
    <p>Клиент соглашается добровольно предоставить компании {logoText} необходимую информацию для соблюдения действующего законодательства. Мы проводим проверки личности пользователей в соответствии с рисками; собираем и безопасно храним их данные, документы и отчеты.</p>
    <p>Мы также проверяем личные данные на совпадение с списками лиц, подозреваемых в терроризме. Компания {logoText} проведет проверку личных данных пользователей с целью обнаружения совпадений со списком лиц, подозреваемых в террористической деятельности, сформированным государственными и независимыми органами.</p>
    <p>Для проведения такой проверки необходимы следующие идентификационные данные:</p>
    <ul>
      <li>полное имя пользователя, дата рождения (для физических лиц);</li>
      <li>адрес проживания или адрес регистрации пользователя;</li>
      <li>данные об источнике происхождения средств, которые планируется депонировать на счете на веб-сайте компании {logoText}.</li>
    </ul>
    <p>Для подтверждения достоверности предоставленных данных компания {logoText} может запросить следующие документы:</p>
    <ul>
      <li>паспорт, идентификационную карту или другой документ, заменяющий их, который содержит полное имя пользователя, дату рождения и фотографию владельца документа, выданный национальными государственными органами;</li>
      <li>документ-подтверждение фактического адреса проживания (счет за коммунальные платежи, полученный не ранее, чем за 3 месяца от текущей проверки).</li>
    </ul>
    <p>Компания {logoText} оставляет за собой право запросить иные документы, необходимые для идентификации личности клиента.</p>
    <p>Пожалуйста, предоставьте нам минимальные данные для подтверждения вашей личности. Мы будем признательны за сотрудничество.</p>
  </TextContent>
);

export default KycAndAml;
