import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useLocalizedBonuses } from 'hooks/useLocalizedBonuses';

import { bonusListEN } from '../../BonusData';
import BonusItem from '../BonusItem/BonusItem';

const BonusList = () => {
  const { t } = useTranslation();
  const bonusList = useLocalizedBonuses();

  return (
    <div>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <div className="bonus-page__wrapper">
        <h1 className='bonus-page__title'>
          {t('footer.nav.item.Bonus and actions', 'Бонусы и Акции')}
        </h1>
        <div className="bonus-page__list">
          {bonusListEN.map((item) => (
            <BonusItem key={item.id} bonus={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BonusList;
