import { MouseEventHandler, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';
import { getCurrencySymbol } from 'utils/currency';
import { betingTaxInfo } from 'utils/getBetingTaxInfo';
import './BasketTaxInfo.scss';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

interface Props extends betingTaxInfo {
  currency: string;
  type?: 'win' | 'loss' | 'refund' | 'placed' | 'default';
}

const statusItemTitles: Record<string, string> = {
  win: i18n.t('bet-history.prize', 'Выигрыш'),
  loss: i18n.t('bet-history.loss', 'Выигрыш'),
  placed: i18n.t('bet-history.possible-prize', 'Возможный выигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат'),
  'default': 'Final pay with tax'
};

const b = bemCn('basket-tax-info');
function BasketTaxInfo({ exciseTax, posibleWin, winnings, withHoldingTax, finalPay, currency, stakeAfterTax, type = 'default' }: Props) {
  const [isTaxInfoOpen, setIsTaxInfoOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsTaxInfoOpen(false));
  const handleClick: MouseEventHandler = (e) => {
    setIsTaxInfoOpen(!isTaxInfoOpen);
    e.preventDefault();
  };

  return (
    <div className={b()}>
      <button onClick={handleClick} disabled={type === 'loss' || type === 'refund'} type='button' className={b('warning-button')}>
        <SvgIcon name='rounded-warning' className={b('warning-icon', { type })} width={15} height={15} />
        <span className={b('tittle', { type })}>{statusItemTitles[type]}</span>
      </button >
      {
        isTaxInfoOpen && (
          <div className={b('container')} ref={ref}>
            <div className={b('bar')}>
              <span>Excise tax: {exciseTax.toFixed(2)} {getCurrencySymbol(currency)}</span>
              <span>Stake after excise tax: {stakeAfterTax.toFixed(2)} {getCurrencySymbol(currency)}</span>
              <span>Possible win: {posibleWin.toFixed(2)} {getCurrencySymbol(currency)}</span>
              <span>Prize: {winnings.toFixed(2)} {getCurrencySymbol(currency)}</span>
              <span>Withholding tax: {withHoldingTax.toFixed(2)} {getCurrencySymbol(currency)}</span>
              <span>Final pay: {finalPay.toFixed(2)} {getCurrencySymbol(currency)}</span>
            </div>
          </div>
        )
      }
    </div >
  );
}

export default BasketTaxInfo;
