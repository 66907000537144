import { bemCn } from 'utils/bem-cn';
import DownloadAppButton from 'components/shared/DownloadAppButton/DownloadAppButton';
import { downloadApp } from 'utils/constants';

const BurgerFooter = () => {
  const b = bemCn('burger-menu-content');

  return (
    <div className={b('footer')}>
      <hr className={b('seperator')} />
      <DownloadAppButton
        type='android'
        link={downloadApp.android}
        className='burger-menu-content__download-btn'
      />
    </div>
  );
};

export default BurgerFooter;
