import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import { useAppSelector } from 'hooks';
import { selectSystemMessage } from 'store/app/selectors';
import './SystemNotification.scss';


const SystemNotification = () => {
  const systemMessage = useAppSelector(selectSystemMessage);

  if (!systemMessage.enable) {
    return null;
  }

  const b = bemCn('system-notification');
  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <p className={b('message')}>{systemMessage.message}</p>
        <SvgIcon className={b('warning-icon')} name='warning' />
      </div>
    </div>
  );
};

export default SystemNotification;
