import { Link, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';
import ButtonToTop from 'components/shared/ButtonToTop/ButtonToTop';

import TermsOfUse from './components/TermsOfUse';
import RiskNotification from './components/RiskNotification';
import TermsOfRefund from './components/TermsOfRefund';
import TermsOfBetCanceling from './components/TermsOfBetCanceling';
import KycAndAml from './components/KycAndAml';


import './UserAgreement.scss';

const UserAgreement = () => {
  const { t } = useTranslation();
  const b = bemCn('user-agreement-page');
  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <h1 className={b('title')}>Пользовательское соглашение</h1>
      <ul className={b('links-list')}>
        <li className={b('links-item')}>
          <Link className={b('link')} to="terms-of-use">
            Условия использования
          </Link>
        </li>
        <li>
          <Link className={b('link')} to="risk-notification">
            Уведомление о рисках
          </Link>
        </li>
        <li>
          <Link className={b('link')} to="terms-of-refund">
            Условия возврата финансовых средств
          </Link>
        </li>
        <li>
          <Link className={b('link')} to="terms-of-bet-canceling">
            Условия отмены
          </Link>
        </li>
        <li>
          <Link className={b('link')} to="kyc-and-aml">
            KYC & AML
          </Link>
        </li>
      </ul>
      <Routes>
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/risk-notification" element={<RiskNotification />} />
        <Route path="/terms-of-refund" element={<TermsOfRefund />} />
        <Route path="/terms-of-bet-canceling" element={<TermsOfBetCanceling />} />
        <Route path="/kyc-and-aml" element={<KycAndAml />} />
      </Routes>
      <ButtonToTop />
    </div>
  );
};

export default UserAgreement;
