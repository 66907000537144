import { useTranslation } from 'react-i18next';

import { useLazyGetUserBonusEventsQuery } from 'store/user/userApi';
import Button from 'components/shared/buttons/Button/Button';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import cashbackSportPreview from './assets/cashback-sport-preview.jpg';
import cashbackSportBanner from './assets/cashback-sport-banner.jpg';
import cashbackCasinoPreview from './assets/cashback-casino-preview.jpg';
import cashbackCasinoBanner from './assets/cashback-casino-banner.jpg';
import freebetBanner from './assets/banner-freebet.jpg';
import bonusBanner from './assets/banner-bonus.jpg';
import cashbackBanner from './assets/banner-cashback-en.jpg';

export type BonusListType = {
  title: string;
  subTitle: string;
  preview: string;
  banner: string;
  body: JSX.Element;
  id: string;
};

type CheckBonusEventsProps = {
  eventName: string;
}

const CheckBonusEvents = ({ eventName }: CheckBonusEventsProps) => {
  const { t } = useTranslation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const [check, { data: bonusEvents = [], isSuccess }] = useLazyGetUserBonusEventsQuery();


  const currentEvetn = bonusEvents.find((item) => item.eventName === eventName);
  const isEmpty = !currentEvetn && isSuccess;

  const handleCheckClick = async () => {
    if (isAuth) {
      await check();
    }
  };

  if (!isAuth) {
    return null;
  }

  return (
    <div className="">
      <div>
        {currentEvetn && (
          <div>
            <p><b>{t('bonus-events.negative-message')}</b></p>
            <p>{t('bonus-events.coupons-count')}: <b>{currentEvetn.couponCount}</b></p>
          </div>
        )}
        {isEmpty && <p><b>{t('bonus-events.negative-message')}...</b></p>}
      </div>
      <Button type="button" onClick={handleCheckClick}>
        {t('bonus-events.check-btn')}
      </Button>
    </div>
  );
};

export const bonusListRu: BonusListType[] = [];

export const bonusListUA: BonusListType[] = [];

export const bonusListEN: BonusListType[] = [];

export const bonusListKZ: BonusListType[] = [];

export const bonusListAZ: BonusListType[] = [];

export const bonusListUZ: BonusListType[] = [];
