import { bemCn } from 'utils/bem-cn';
import './JackpotCard.scss';

const tierTitle: Record<number | string, string> = {
  0: 'minor',
  1: 'major',
  2: 'mega',
  3: 'grand'
};

type JackpotCardProps = {
  label?: string;
  amount: number | string;
  tier: number | string;
  className?: string;
}

const JackpotCard = (props: JackpotCardProps) => {
  const {
    label,
    amount,
    tier,
    className,
  } = props;
  const b = bemCn('jackpot-card');

  return (
    <p className={b({ tier: tierTitle[tier] }, className)}>
      <span className={b('label')}>
        {label ?? tierTitle[tier]}
      </span>
      <span className={b('amount')}>
        {amount}
      </span>
    </p>
  );
};

export default JackpotCard;
