import i18n from 'i18n/i18n';

import { TournamenWithEvents } from './common';

import type { CompareBets, EventItem, SportGroups, TSportGroup, TournamentCollection, TournamentItem } from 'types/line-data';
import type { DiffType } from 'components/line/components/Coefficient/Coefficient';

export const compareBets = ({ prevBet, bet, prevGroupName, groupName }: CompareBets): DiffType => {

  if (prevGroupName === 'stopped' && groupName !== 'stopped') {
    return 'increased';
  }

  if (!prevBet) {
    return 'same';
  }

  const { rate: rate1 } = prevBet;
  const { rate: rate2 } = bet;
  if (rate1 > rate2) {
    return 'decreased';
  } if (rate1 < rate2) {
    return 'increased';
  }
  return 'same';
};

export const getCoefTitle = (caption: string, team1: string, team2: string) => {
  if (caption === team1) {
    return i18n.t('event.team1-win-short', 'П1');
  }
  if (caption === team2) {
    return i18n.t('event.team2-win-short', 'П2');
  }
  if (caption === 'Ничья') {
    return 'X';
  }
  return caption;
};

export const getCoefTitleFull = (caption: string, team1: string, team2: string) => {
  if (caption === team1) {
    return i18n.t('event.team1-win', 'Победа 1 команды');
  }
  if (caption === team2) {
    return i18n.t('event.team2-win', 'Победа 2 команды');
  }
  return caption;
};

export const groupTournamentEvents = (items: TournamenWithEvents[] = []): TSportGroup[] => {
  const groupedEvents: SportGroups = {};

  for (const tourney of items) {
    const { sportId, sportName, id: tournamentId, name: tournament, events, index } = tourney;

    if (!groupedEvents[sportId]) {
      groupedEvents[sportId] = {
        id: sportId,
        name: sportName,
        tournamentsIds: [],
        tournaments: [],
      };
    }

    const sportGroup = groupedEvents[sportId];
    sportGroup.tournamentsIds.push(tournamentId);
    sportGroup.tournaments.push({
      id: tournamentId,
      name: tournament,
      sportName: sportName,
      events: events,
      index: index,
    });
  }

  return Object.values(groupedEvents);
};
