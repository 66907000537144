import { combineReducers } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import { api } from './api';
import { betapi } from './betapi/betapi';
import { authData } from './auth/authSlice';
import { basketData } from './basket/basketSlice';
import { paymentsData } from './payment/paymentsSlice';
import { slotsData } from './slots/slotsSlice';
import { userData } from './user/userSlice';
import { partnerProgramData } from './partnerProgram/partnerProgramSlice';
import { appData } from './app/appSlice';

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [betapi.reducerPath]: betapi.reducer,
  [NameSpace.App]: appData.reducer,
  [NameSpace.Auth]: authData.reducer,
  [NameSpace.Basket]: basketData.reducer,
  [NameSpace.Payments]: paymentsData.reducer,
  [NameSpace.Slots]: slotsData.reducer,
  [NameSpace.User]: userData.reducer,
  [NameSpace.PartnerProgram]: partnerProgramData.reducer,
});
