import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addToBasketErrors, basketErrors, NameSpace } from 'utils/constants';

import { basketApi } from './basketApi';

import type { BackendError } from 'store/auth/utils';
import type { BasketState } from 'types/state';
import type { BasketModalType } from 'types/basket-data';


const initialState: BasketState = {
  activeModal: null,
  errorMesage: addToBasketErrors.default,
  betsCount: 0,
};

export const basketData = createSlice({
  name: NameSpace.Basket,
  initialState,
  reducers: {
    changeActiveBasketModal: (state, action: PayloadAction<BasketModalType | null>) => {
      if (state.activeModal === 'place-error' && action.payload === null) {
        state.activeModal = 'basket';
      } else {
        state.activeModal = action.payload;
      }
    },
    clearBasketErrorMessage: (state) => {
      state.errorMesage = addToBasketErrors.default;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        basketApi.endpoints.placeBets.matchFulfilled,
        (state) => {
          state.activeModal = 'success';
          state.errorMesage = basketErrors.default;
        },
      )
      .addMatcher(
        basketApi.endpoints.placeBets.matchRejected,
        (state, action) => {
          state.errorMesage = basketErrors.default;
          state.activeModal = 'place-error';
          if (!action.payload || !action.payload.data || !action.payload.status) {
            return;
          }
          const data = action.payload.data as BackendError;
          const status = action.payload.status as number;
          const { detail } = data;

          if (typeof detail === 'string') {
            switch (status) {
              case 403:
                state.errorMesage = basketErrors[detail] ?? basketErrors.default;
                break;
              case 404:
                state.errorMesage = basketErrors[detail] ?? basketErrors.eventBlocked;
                break;
              case 409:
                state.errorMesage = basketErrors[detail] ?? basketErrors.coefHasChanged;
                break;
              default:
                state.errorMesage = basketErrors[detail] ?? basketErrors.default;
            }
          }
        },
      )
      .addMatcher(
        basketApi.endpoints.addToBasket.matchFulfilled,
        (state, action) => {
          if (state.betsCount < 1) {
            state.activeModal = 'basket';
          }
        }
      )
      .addMatcher(
        basketApi.endpoints.getBasket.matchFulfilled,
        (state, action) => {
          state.betsCount = action.payload.count;
        }
      )
      .addMatcher(
        basketApi.endpoints.addToBasket.matchRejected,
        (state, action) => {
          state.errorMesage = addToBasketErrors.default;
          state.activeModal = 'error';
          if (!action.payload || !action.payload.data || !action.payload.status) {
            return;
          }
          const { detail } = action.payload?.data as { detail: string };
          if (typeof detail === 'string') {
            switch (action.payload?.status) {
              case 403:
              case 404:
                state.errorMesage = addToBasketErrors[detail] ?? detail;
                break;
              default:
                state.errorMesage = addToBasketErrors.default;
            }
          }
        }
      );
  },
});

export const {
  changeActiveBasketModal,
} = basketData.actions;

export {initialState as initialBasketState};
