import { separateNumberIntoUnits } from './common';

type GrammaticalCases = {
  nominative: string;
  genitive: string;
  genitivePlural: string;
};

export const getWidgetSubtitle = (gamesCount : number, grammaticalCases: GrammaticalCases) => {
  /* [1] событие, [2,3,4] события, [5,6,7,8,9,0] событий  */
  /* [1] игра, [2,3,4] игры, [5,6,7,8,9,0] игр  */
  const digitsArray = separateNumberIntoUnits(gamesCount);
  const [tens, ones] = digitsArray.length > 1 ? digitsArray.slice(-2) : [0, digitsArray[0]];

  const isOnesEqualOne = ones === 1;
  const isOnesEqualTwoFour = [2, 3, 4].some((element) => ones === element);
  const isOnesEqualOther = !isOnesEqualOne && !isOnesEqualTwoFour;
  const isTensEqualOne = tens === 1;

  if (isOnesEqualOne && !isTensEqualOne) {
    return `${gamesCount} ${grammaticalCases.nominative}`;
  }

  if (isOnesEqualTwoFour && !isTensEqualOne) {
    return `${gamesCount} ${grammaticalCases.genitive}`;
  }

  if (isTensEqualOne || isOnesEqualOther) {
    return `${gamesCount} ${grammaticalCases.genitivePlural}`;
  }

  return '';
};
