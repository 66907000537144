import { bemCn } from 'utils/bem-cn';

import './PPRefLinks.scss';

type Props = {
  title?: string;
  links?: string[];
};

const PPRefLinks = (props: Props) => {
  const {
    title,
    links,
  } = props;

  const b = bemCn('pp-ref-links');

  return (
    <div className={b()}>
      {title && (
        <h3 className={b('title')}>{title}</h3>
      )}

      {links && links.map((link) => (
        <p className={b('ref-link')} key={link}>{link}</p>
      ))}
    </div>
  );
};

export default PPRefLinks;
