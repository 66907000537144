import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

import Button from 'components/shared/buttons/Button/Button';
import { bemCn } from 'utils/bem-cn';
import { useLocalizedBanners } from 'hooks/useLocalizedBanners';
import Loader from 'components/shared/loader/Loader';

import banner1 from './assets/banner.png';

import './DesktopLiveBanner.scss';

const DesktopLiveBanners = () => {
  const { isLoading, bannersDesktop } = useLocalizedBanners();
  const { big } = bannersDesktop;
  const b = bemCn('desktop-line-banner');
  return (
    <div className={b()}>
      {isLoading
        ? <Loader />
        : (
          <Swiper
            pagination
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            speed={1500}
            slidesPerView={1}
            spaceBetween={30}
            modules={[Pagination, Autoplay]}
            className={b('swiper', 'mySwiper')}
            loop={big.length > 1}
          >

            {big.map((banner) => (
              <SwiperSlide className={b('item')} key={banner.img}>
                {banner.href && (
                  <a href={banner.href} className={b('banner')} target='_blank' rel="noreferrer">
                    <img src={banner.img} alt="" className={b('bg')} />
                  </a>
                )}
                {banner.link && (
                  <Link to={banner.link} className={b('banner')}>
                    <img src={banner.img} alt="" className={b('bg')} />
                  </Link>
                )}
                {(!banner.href && !banner.link) && (
                  <div className={b('banner')} >
                    <img src={banner.img} alt="" className={b('bg')} />
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
    </div>
  );
};

export default DesktopLiveBanners;
