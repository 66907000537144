import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import i18n from 'i18n/i18n';
import { useGetUserAccountQuery, useUpdateUserPhoneMutation } from 'store/user/userApi';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import type { SerializedError } from '@reduxjs/toolkit';
import type { BackendError } from 'store/auth/utils';

export type FormFields = {
  phone: string;
};

export const phoneRegExp = /^\+?\d{6,24}$/;

export const schema = yup.object({
  phone: yup.string()
    .matches(phoneRegExp, `${i18n.t('reg.modal.errors.wrong-phone', 'Введите правильный номер')}`)
    .nullable(true)
    .required(`${i18n.t('reg.modal.errors.empty-phone', 'Введите номер')}`),
}).required();

export const handleResponseError = (error?: FetchBaseQueryError | SerializedError) => {
  if (!!error && 'status' in error && error.data) {
    const { detail } = error.data as BackendError;
    let message = '';

    if (typeof detail === 'string') {
      if (detail === 'Phone number already exists') {
        return `${i18n.t('reg.modal.errors.exist-phone', 'Такой номер телефона уже существует')}`;
      }
      return detail;
    } else if (detail[0].type === 'value_error') {
      message = `${i18n.t('reg.modal.errors.wrong-phone', 'Введите правильный номер')}`;
    }

    return message;
  }

  return error && `${i18n.t('reg.modal.errors.smth-wrong', 'Что-то пошло не так!')}`;
};

export function usePhoneChange() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoading, data: userData } = useGetUserAccountQuery();
  const phoneNumber: (string | null) = userData?.phone ? userData.phone : null;
  const visiblePhoneNumber: string = phoneNumber ? `+${phoneNumber}` : '';
  const [updateUserPhone, { isLoading: isUpdatePhoneLoading, isSuccess, error: responseError }] = useUpdateUserPhoneMutation();

  const { handleSubmit, setError, formState: { errors }, control, reset } = useForm<FormFields>({
    defaultValues: { phone: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (formData: FormFields) => {
    const { phone } = formData;
    const cleanPhone = phone.replace('+', '');
    const response = await updateUserPhone(cleanPhone);
  });

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(true);
      reset();
    } else {
      const responseErrorMessage = handleResponseError(responseError);

      setError('phone', {
        type: 'server',
        message: responseErrorMessage
      })
    }
  }, [isSuccess, responseError]);

  return {
    handleSubmit,
    setError,
    control,
    reset,
    isLoading,
    isUpdatePhoneLoading,
    isSuccess,
    responseError,
    onSubmit,
    phoneNumber,
    visiblePhoneNumber,
    isModalOpen,
    setIsModalOpen,
    handleModalClose,
  };
}


