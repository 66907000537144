import { useGetAppSportsQuery } from 'store/app/appApi';
import { useGetBetapiEventQuery } from 'store/betapi/betapi';
import { GetBetapiEventParams } from 'store/betapi/types';


const useFilteredEvent = (
  arg: GetBetapiEventParams,
  opt?: {
    skip?: boolean;
    pollingInterval?: number;
  },
) => {
  const { data } = useGetAppSportsQuery();
  const result = useGetBetapiEventQuery(arg, opt);
  const subGamesBlackList = data?.subGamesBlackList ?? [];
  const eventSubgames = result.data?.subGames ?? [];

  const filteredSubgames = eventSubgames.filter((item) =>
    !subGamesBlackList.includes(item.gameName.toLowerCase()));


  return {
    ...result,
    data: result.data && {
      ...result.data,
      subGames: filteredSubgames,
    }
  };
};

export { useFilteredEvent };
