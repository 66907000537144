import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';
import { getSavedUserGeo, saveUserGeo } from 'utils/userGeo';
import i18n from 'i18n/i18n';
import { defaultTimezone } from 'utils/constants/timezone';
import { setUserTimezone, getUserTimezone } from 'utils/localStorage';

import { userApi } from './userApi';
import { getShowBalanceHeader, handleChangeUserPasswordErrors, setShowBalanceHeader } from './utils';

import type { BackendError } from 'store/auth/utils';
import type { Wallet, AccountData } from 'types/user-data';
import type { UserState } from 'types/state';

const initialUserGeo = getSavedUserGeo();

export const initialChangeUserPasswordErrors = {
  oldPassword: null,
  newPassword: null,
  common: null,
};

const initialState: UserState = {
  isAccaountLoading: true,
  account: {
    id: '',
    email: '',
    role: null,
    name: '',
    phone: '',
  },
  userTimezone: defaultTimezone,
  additionalWallets: [],
  primaryWallet: {
    currency: null,
    amount: 0,
    bonusAmount: 0,
    cashbackLine: 0,
    cashbackCasino: 0,
    maxBetAmount: 0,
    subGamesLimits: null,
  },
  showBalanceHeader: getShowBalanceHeader(),
  changeUserPasswordErrors: initialChangeUserPasswordErrors,
  changeUserPasswordStatus: 'default',
  geo: {
    country: initialUserGeo.country,
    countryName: initialUserGeo.countryName,
    countryPhoneCode: initialUserGeo.countryPhoneCode,
    isLoading: true,
  }
};

export const userData = createSlice({
  name: NameSpace.User,
  initialState,
  reducers: {
    resetchangeUserPasswordErrors: (state) => {
      state.changeUserPasswordErrors = initialChangeUserPasswordErrors;
    },
    resetChangeUserPasswordStatus: (state) => {
      state.changeUserPasswordStatus = 'default';
    },
    changeShowBalance: (state, action: PayloadAction<boolean>) => {
      setShowBalanceHeader(action.payload);
      state.showBalanceHeader = action.payload;
    },
    setUserTimezoneToState: (state, action: PayloadAction<{ timezone: number }>) => {
      state.userTimezone = action.payload.timezone;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.getUserAccount.matchFulfilled,
        (state, action: PayloadAction<AccountData>) => {
          state.account = action.payload;
          state.isAccaountLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.getUserAccount.matchRejected,
        (state) => {
          state.isAccaountLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.getWallets.matchFulfilled,
        (state, action: PayloadAction<Wallet[]>) => {
          const primary = action.payload.find((wallet) => wallet.isPrimary);
          if (primary) {
            state.primaryWallet = {
              amount: primary.amount,
              bonusAmount: primary.bonusAmount,
              currency: primary.currency,
              cashbackLine: primary.cashbackLine,
              cashbackCasino: primary.cashbackCasino,
              maxBetAmount: primary.maxBetAmount,
              subGamesLimits: primary.subGamesLimits,
            };
          }
          state.additionalWallets = action.payload.filter((wallet) => !wallet.isPrimary);
        },
      )
      .addMatcher(
        userApi.endpoints.changeUserPassword.matchPending,
        (state: UserState,) => {
          state.changeUserPasswordErrors = initialChangeUserPasswordErrors;
          state.changeUserPasswordStatus = 'default';
        },
      )
      .addMatcher(
        userApi.endpoints.changeUserPassword.matchFulfilled,
        (state: UserState,) => {
          state.changeUserPasswordStatus = 'success';
        },
      )
      .addMatcher(
        userApi.endpoints.getUserCountry.matchPending,
        (state) => {
          state.geo.isLoading = true;
        },
      )
      .addMatcher(
        userApi.endpoints.getUserCountry.matchFulfilled,
        (state, action) => {
          saveUserGeo({
            country: action.payload.country,
            countryName: action.payload.countryName,
            countryPhoneCode: action.payload.countryPhoneCode,
          });
          state.geo.country = action.payload.country;
          state.geo.countryName = action.payload.countryName;
          state.geo.isLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.getUserCountry.matchRejected,
        (state) => {
          const savedUserGeo = getSavedUserGeo();
          state.geo.country = savedUserGeo.country;
          state.geo.countryName = savedUserGeo.countryName;
          state.geo.isLoading = false;
        },
      )
      .addMatcher(
        userApi.endpoints.changeUserPassword.matchRejected,
        (state: UserState, action) => {
          if (!action.payload || !action.payload.data) { return; }
          const data = action.payload.data as BackendError;
          const status = action.payload.status as number;
          state.changeUserPasswordErrors = handleChangeUserPasswordErrors(status, data);
          state.changeUserPasswordStatus = 'error';
        },
      )
      .addMatcher(
        userApi.endpoints.getUserSettings.matchFulfilled,
        (state, action) => {
          const { lang, timezone } = action.payload;
          if (lang && i18n.resolvedLanguage !== lang) {
            i18n.changeLanguage(lang);
          }

          if (timezone && Number(timezone) !== getUserTimezone()) {
            setUserTimezone(timezone);
          }
        }
      );
  },
});

export const {
  resetchangeUserPasswordErrors,
  resetChangeUserPasswordStatus,
  changeShowBalance,
  setUserTimezoneToState,
} = userData.actions;
