import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import NavMenu, { MenuItem } from 'components/shared/NavMenu/NavMenu';
import Pagination from 'components/shared/Pagination/Pagination';
import { scrollToTop } from 'utils/scrollToTop';
import { COUPON_ITEMS_PER_PAGE } from 'utils/constants';
import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';
import CouponCardMobile from 'components/profile/BetHistory/CouponCard/CouponCardMobile/CouponCardMobile';
import { useGetCouponsQuery } from 'store/user/userApi';

import NoItems from '../../../components/profile/components/NoItems/NoItems';

import type { PlaceType } from 'types/user-data';
import './BetHistoryMobile.scss';

const getMenuItems = (): MenuItem[] => [
  {
    title: i18n.t('bet-history.all-bets', 'Все ставки'),
    id: 0,
  },
  {
    title: i18n.t('bet-history.single', 'Ординар'),
    id: 1,
  },
  {
    title: i18n.t('bet-history.express', 'Экспресс'),
    id: 2,
  },
];

const getCouponType = (typeId: number): PlaceType | undefined => {
  switch (typeId) {
    case 1:
      return 'ordinar';
    case 2:
      return 'express';
    default:
      return undefined;
  }
};

const BetHistoryMobile = () => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filter, setFiler] = useState<number>(0);

  const {
    coupons,
    totalCout,
    isLoading,
    isEmpty
  } = useGetCouponsQuery({
    pageSize: COUPON_ITEMS_PER_PAGE,
    pageNumber: pageNumber,
    type: getCouponType(filter),
    lang: i18n.resolvedLanguage,
  }, {
    selectFromResult: (result) => ({
      totalCout: result.data?.totalCount ?? 0,
      coupons: result.data?.items ?? [],
      isLoading: result.isFetching,
      isEmpty: !result.isLoading && result.data?.items.length === 0,
    })
  });

  useEffect(() => {
    scrollToTop(true);
  }, [pageNumber, filter]);


  const handleNavClick = (id: number) => {
    setPageNumber(1);
    setFiler(id);
  };

  const b = bemCn('bet-history');

  return (
    <div className={b()}>
      <Breadcrumbs link="/profile" title={t('Profile', 'Личный кабинет')} />
      <h1 className={b('title')}>
        {t('bet-history.title', 'История ставок')}
      </h1>
      <div className={b('nav')}>
        <NavMenu
          menuItems={getMenuItems()}
          activeItemId={filter}
          onClick={handleNavClick}
        />
      </div>
      <div className={b('content')}>
        {(isLoading || isEmpty)
          ? (
            <NoItems
              isLoading={isLoading}
              isEmpty={isEmpty}
              text={`${t('bet-history.bets-empty', 'Список ставок пуст')}`}
              buttonText={`${t('bet-history.make-bet', 'Сделать ставку')}`}
              withButton
            />
          )
          : coupons.map((coupon) => (
            <CouponCardMobile
              key={coupon.id}
              coupon={coupon}
            />
          ))}
        <Pagination
          selectPage={pageNumber}
          countPages={Math.ceil(totalCout / COUPON_ITEMS_PER_PAGE)}
          setSelectPage={(value) => setPageNumber(value)}
        />
      </div>
    </div>
  );
};

export default BetHistoryMobile;
