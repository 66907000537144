import { useTranslation } from 'react-i18next';

import PPModal from 'pages/PartnerProgram/components/PPModal/PPModal';
import PPDrawer from 'pages/PartnerProgram/components/PPDrawer/PPDrawer';

import PPManagerPartnerLinks from './PPManagerPartnerLinks';
import PPManagerPartnerPromocodes from './PPManagerPartnerPromocodes';
import PPManagerPartnerStats from './PPManagerPartnerStats';
// import PPManagerPartnerPlayers from './PPManagerPartnerPlayers';

type Props = {
  activeModal: string | null;
  onModalClose: () => void;
  id: number | null;
  children?: React.ReactNode;
};

const PPMangerModals = (props: Props) => {
  const {
    activeModal,
    onModalClose,
    id,
    children,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <>
      <PPDrawer
        isOpen={activeModal === 'links'}
        onClose={onModalClose}
        title={`${t('ref-partner-links', 'Реферальные ссылки партнёра с ID')} ${id ?? 0}`}
      >
        <PPManagerPartnerLinks id={id ?? 0} />
      </PPDrawer>

      <PPDrawer
        isOpen={activeModal === 'promocodes'}
        onClose={onModalClose}
        title={`${t('partner-promocodes', 'Промокоды партнёра с ID')} ${id ?? 0}`}
      >
        <PPManagerPartnerPromocodes id={id ?? 0} />
      </PPDrawer>

      <PPDrawer
        isOpen={activeModal === 'partner-stats'}
        onClose={onModalClose}
        title={`${t('partner-stats', 'Статистика партнёра с ID')} ${id ?? 0}`}
      >
        <PPManagerPartnerStats partnerId={id ?? 0} />
      </PPDrawer>

      {/* <PPModal
        isOpen={activeModal === 'links'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPManagerPartnerLinks id={id ?? 0} />
      </PPModal>

      <PPModal
        isOpen={activeModal === 'promocodes'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPManagerPartnerPromocodes id={id ?? 0} />
      </PPModal>

      <PPModal
        isOpen={activeModal === 'partner-stats'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPManagerPartnerStats partnerId={id ?? 0} />
      </PPModal> */}

      {/* <PPModal
        isOpen={activeModal === 'partner-ref-stats'}
        onClose={onModalClose}
        title={activeModal ?? ''}
      >
        <PPManagerPartnerPlayers id={id ?? 0} />
      </PPModal> */}
    </>

  );
};

export default PPMangerModals;
