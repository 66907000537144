import { bemCn } from 'utils/bem-cn';
import SportIcon from 'components/shared/icons/SportIcon/SportIcon';
import { setFilterSport } from 'store/app/appSlice';
import { useAppDispatch } from 'hooks';

import SideBarFilterDesktopCountries from '../SideBarFilterDesktopCountries/SideBarFilterDesktopCountries';

import type { LineType, SportItem } from 'types/line-data';

import './SideBarFilterDesktopSport.scss';

type Props = {
  sport: SportItem;
  isActive: boolean;
  lineType: LineType;
}

const b = bemCn('side-bar-filter-desktop-sport');
const SideBarFilterDesktopSport = ({ sport, isActive, lineType }: Props) => {
  const dispatch = useAppDispatch();
  const handleSportClick = () => {
    dispatch(setFilterSport(sport.id));
  };

  return (
    <li className={b('button-wrapper')}>
      <button className={b('button', { active: isActive })}
        type="button"
        onClick={handleSportClick}
      >
        <SportIcon sportId={sport.id} />
        <span className={b('name')}>{sport.name}</span>
        {sport.counter && (
          <span className={b('counter')}>
            {sport.counter}
          </span>
        )}
      </button>
      {isActive && (
        <SideBarFilterDesktopCountries
          lineType={lineType}
          sportId={sport.id}
          sportName={sport.name}
        />
      )}
    </li>
  );
};

export default SideBarFilterDesktopSport;
