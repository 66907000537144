import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import { useAddFavoriteMatchMutation, useDeleteFavoriteMatchMutation } from 'store/user/userApi';
import { AddFavoriteMatchBody as EventInfo } from 'types/user-data';

import { selectIsAuthenticated } from './../store/auth/selectors';

export interface ToggleFavoriteMatchProps {
  isFavorite: boolean;
  favoriteId: string;
  eventInfo: EventInfo;
}

type UseToggleFavoriteEventRes = {
  toggleFavoriteMatch: (arg: ToggleFavoriteMatchProps) => void;
  isLoading: boolean;
};

export const useToggleFavoriteEvent = (): UseToggleFavoriteEventRes => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [addFavoriteMatch, { isLoading: isAddLoading }] = useAddFavoriteMatchMutation();
  const [deleteFavoriteMatch, { isLoading: isDeleteLoading }] = useDeleteFavoriteMatchMutation();

  const toggleFavoriteMatch = useCallback(
    ({ isFavorite, favoriteId, eventInfo }: ToggleFavoriteMatchProps) => {
      if (isAuthenticated) {
        if (!isFavorite) {
          addFavoriteMatch(eventInfo);
        } else {
          deleteFavoriteMatch(favoriteId);
        }
      } else {
        dispatch(changeActiveModal('sign-in'));
      }
    }, [addFavoriteMatch, deleteFavoriteMatch, isAuthenticated, dispatch]);

  return { toggleFavoriteMatch, isLoading: isAddLoading || isDeleteLoading };
};

