import React from 'react';

import { useResponsive } from 'hooks/useResponsive';
import SystemNotification from 'components/SystemNotification/SystemNotification';
import BasketPopupButton from 'components/basket/BusketPopupButton/BusketPopupButton';

import ScrollToTop from './ScrollToTop/ScrollToTop';
import Container from './Container/Container';
import Header from './Header/Header';
import PageFooter from './PageFooter/PageFooter';
import TabBar from './TabBar/TabBar';
import AppModals from './AppModals/AppModals';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import MainNav from './MainNav/MainNav';
import MainNavMobile from './MainNav/MainNavMobile';

type Props = {
  children: React.ReactNode;
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

export const Layout = (props: Props) => {
  const { Mobile } = useResponsive();

  return (
    <>
      <ScrollToTop />
      <Header
        isAccountLoading={props.isAccountLoading}
        isAuthenticated={props.isAuthenticated}
      />
      {/* <Mobile><MainNav /></Mobile> */}
      <Mobile><MainNavMobile /></Mobile>
      <BurgerMenu />
      <SystemNotification />
      <Container>
        {props.children}
      </Container>
      <PageFooter
        isAccountLoading={props.isAccountLoading}
        isAuthenticated={props.isAuthenticated}
      />
      <TabBar />
      <BasketPopupButton />
      <AppModals />
    </>
  );
};

export default React.memo(Layout);
