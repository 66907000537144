import { Link } from 'react-router-dom';

type Props = {
  link?: string;
  href?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

const LinkContainer = (props: Props) => {
  const { link, href, children, disabled, onClick, className } = props;
  if (!disabled && link && link !== '') {
    return (
      <Link className={className} to={link} onClick={onClick}>
        {children}
      </Link>
    );
  }
  if (!disabled && href && href !== '') {
    return (
      <a className={className} href={href} target='__blank' onClick={onClick}>
        {children}
      </a>
    );
  }
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default LinkContainer;
