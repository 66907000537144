import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';
import PPError from 'pages/PartnerProgram/components/PPError/PPError';

import { usePromocodeScripts } from '../hooks/usePromocodeScripts';
import { usePartnerPromocodes } from '../hooks/usePartnerPromocodes';

import '../PPPartner.scss';

type TFormData = {
  scriptId: number;
  name: string;
};

type Props = {
  onModalClose: () => void;
};

const PPPartnerNewPromocode = (props: Props) => {
  const {
    onModalClose,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    data: promocodeScripts,
    isFetching,
    isError,
  } = usePromocodeScripts();

  const {
    createPartnerPromocode,
  } = usePartnerPromocodes();

  const { register, handleSubmit } = useForm<TFormData>();

  const onSubmit: SubmitHandler<TFormData> = (data) => {
    createPartnerPromocode(data);
    onModalClose();
  };

  if (isFetching || !promocodeScripts) {
    return <Loader />;
  }

  if (promocodeScripts.length < 1) {
    return (
      <PPError
        message={t('errors.no-scripts', 'Опция не доступна, у вас нет ни одного скрипта промокода') ?? 'Error'}
      />
    );
  }

  return (
    <div className={b('add-modal')}>
      <h3>{t('create-promocode', 'Создать промокод')}</h3>
      <form
        className={b('add-modal-form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className={b('add-modal-item')}>
          <label htmlFor="scriptId" className={b('add-modal-label')}>
            {t('select-promocode-script', 'Выберите скрипт промокода')}:
          </label>
          <select
            className={b('add-modal-select')}
            id="projectId"
            {...register('scriptId')}
            required
          >
            {promocodeScripts.map((script) => (
              <option
                className={b('add-modal-option')}
                key={script.id}
                value={script.id}
              >
                {script.description}
              </option>
            ))}
          </select>
        </p>

        <p className={b('add-modal-item')}>
          <label htmlFor="name" className={b('add-modal-label')}>
            {t('enter-promocode', 'Введите промокод')}:
          </label>
          <input
            type="text"
            id="name"
            className={b('add-modal-input')}
            required
            {...register('name')}
          />
        </p>

        <button
          type="submit"
          className={b('add-modal-btn')}
        >
          {t('add', 'Добавить')}
        </button>
      </form>
    </div>
  );
};

export default PPPartnerNewPromocode;
