import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import PhoneSelector from 'components/shared/inputs/PhoneSelector/PhoneSelector';
// import Button from 'components/shared/buttons/Button/Button';
// import ResultModal from 'components/shared/ResultModal/ResultModal';

import {
  FormFields,
  usePhoneChange
} from './usePhoneChange';

import './PhoneChange.scss';

const PhoneChangeDesktop = () => {
  const { t } = useTranslation();
  const b = bemCn('phone-change');
  const {
    phoneNumber,
    control,
    // onSubmit,
    // isLoading,
    // isUpdatePhoneLoading,
    // isModalOpen,
    // handleModalClose,
    // visiblePhoneNumber
  } = usePhoneChange();

  return (
    <div>
      <div className='desktop-title'>
        <span className={b('title-text')}>{t('change-phone.title', 'Номер телефона')}</span>
        {/* <span className={b('title-phone')}>{visiblePhoneNumber}</span> */}
      </div>

      <form className={b('form')} action="#" method="POST">
        <PhoneSelector<FormFields, unknown>
          control={control}
          name="phone"
          value={phoneNumber?.slice(3)}
          disabled={true}
          placeholder={t('withdrawals.modal.phone-number') ?? ''}
        />

        {/* <div className={b('button-wrapper')}>
          <Button
            type="submit"
            variant="outline-primary-inverted"
            isLoading={isLoading || isUpdatePhoneLoading}
          >
            {phoneNumber
              ? t('change-phone.change', 'Изменить телефон')
              : t('change-phone.add', 'Добавить телефон')}
          </Button>
        </div> */}
      </form>

      {/* <ResultModal
        title={phoneNumber
          ? t('result-modal.change-phone.title', 'Телефон изменён')
          : t('result-modal.add-phone.title', 'Телефон добавлен')}
        status="success"
        contentLabel="Change phone modal"
        isOpen={isModalOpen}
        description={`${phoneNumber
          ? t('result-modal.change-phone.description', 'Ваш телефон успешно изменён!')
          : t('result-modal.add-phone.description', 'Ваш телефон успешно добавлен!')
          }`}
        onClose={handleModalClose}
      /> */}
    </div>
  );
};

export default PhoneChangeDesktop;
