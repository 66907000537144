import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import CoefDisplay from '../CoefDisplay/CoefDisplay';
import './TotalCoef.scss';

type Props = {
  coef: number;
  isActive?: boolean;
};

const TotalCoef = ({ coef, isActive }: Props) => {
  const { t } = useTranslation();
  const b = bemCn('total-coef');

  return (
    <div className={b({ active: isActive })}>
      <div className={b('content')}>
        <CoefDisplay value={coef.toFixed(2)} />
        <p className={b('title')}>
          {t('cart.total-coef', 'Итоговый коэффициент')}
        </p>
      </div>
    </div>
  );
};

export default React.memo(TotalCoef);
