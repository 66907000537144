import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'usehooks-ts';
import { MouseEventHandler } from 'react';

import { bemCn } from 'utils/bem-cn';
import { domainName } from 'utils/constants';
import i18n from 'i18n/i18n';
import { getCurrencySymbol } from 'utils/currency';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { getDateTimeSlashFormat } from 'utils/time';
import StatusIcon from 'components/shared/StatusIcon/StatusIcon';
import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';

import CouponBet from './CouponBet/CouponBet';

import type { Coupon, PlaceType } from 'types/user-data';

import './CouponCardBig.scss';
import BasketTaxInfo from 'components/basket/Basket/BasketForm/BasketTaxInfo/BasketTaxInfo';
import { getBetingTaxInfo } from 'utils/getBetingTaxInfo';

const typeTitles: Record<PlaceType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

export const statusTitles = {
  placed: i18n.t('bet-history.not-calculated', 'Не рассчитано'),
  win: i18n.t('bet-history.win', 'Выигрыш'),
  loss: i18n.t('bet-history.loss', 'Проигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат')
};

export const rewardTitle = {
  win: i18n.t('bet-history.prize', 'Выигрыш'),
  loss: i18n.t('bet-history.prize', 'Выигрыш'),
  placed: i18n.t('bet-history.possible-prize', 'Возможный выигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат'),
};

type Props = {
  coupon: Coupon;
  className?: string;
}

const CouponCardBig = ({ coupon, className }: Props) => {
  const {
    id,
    type,
    winAmount,
    created,
    status,
    betAmount,
    currency,
    coef,
    bets,
    balanceType,
  } = coupon;

  const [, copyBarCode] = useCopyToClipboard();
  const { t } = useTranslation();

  const isWin = status === 'win';
  const isPlaced = status === 'placed';
  const isLoss = status === 'loss';
  const isBonusWallet = balanceType === 'bonus';
  const taxInfo = getBetingTaxInfo(betAmount, coef);

  const rewardAmount =
    isPlaced
      ? taxInfo.finalPay.toFixed(2)
      : isWin || isLoss
        ? winAmount?.toFixed(2) ?? 0
        : betAmount;

  const handleCopyClick: MouseEventHandler = (evt) => {
    evt.preventDefault();
    copyBarCode(`${id}`);
  };

  const b = bemCn('coupon-card-big');

  return (
    <div className={b(null, className)}>
      <div className={b('wrap')}>
        <p className={b('status', { [`${status}`]: true })}>
          {statusTitles[status]}
          <StatusIcon className={b('status-icon')} status={status} />
        </p>
        <div className={b('content', { bonus: isBonusWallet })}>
          <div className={b('header')}>
            <div className={b('header-wrap')}>
              <p className={b('type')}>{typeTitles[type]}</p>
              {coef && (
                <CoefDisplay className={b('ratio')}
                  value={coef.toFixed(2)}
                  variant="secondary"
                />
              )}
            </div>
            <div className={b('header-wrap')}>
              <p className={b('date')}>{getDateTimeSlashFormat(created)}</p>
              <button className={b('number')}
                type="button"
                onClick={handleCopyClick}
              >
                №: {id}
                <SvgIcon className={b('number-copy-icon')}
                  name='copy'
                  width={11} height={11}
                />
              </button>
            </div>
            <p className={b('label')}>{domainName}</p>
          </div>
          <div className={b('amount-info')}>
            <p className={b('bet-amount')}>
              <span>{t('bet-history.bet', 'Ставка')}:</span>
              <span className={b('bet-amount-value')}>
                {betAmount} {getCurrencySymbol(currency)}
              </span>
            </p>
            <BasketTaxInfo type={status} currency={currency} {...taxInfo} />
            <p className={b('reward-amount', { [`${status}`]: true })}>
              <span className={b('reward-amount-value')}>
                {rewardAmount} {getCurrencySymbol(currency)}
              </span>
            </p>
          </div>
          <div className={b('bets')}>
            {bets?.map((bet) => (
              <CouponBet className={b('bet')}
                key={bet.barcode}
                bet={bet}
              />
            ))}
          </div>
          {isBonusWallet ? (
            <p className={b('bonus-wallet-mark')}>
              {t('wallets.bonus-wallet', 'Бонусный счет')}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CouponCardBig;
