import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import { setFilterSport } from 'store/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import GradientIcon from 'components/shared/icons/GradientIcon/GradientIcon';
import { useGetFavoriteTournamentsQuery } from 'store/user/userApi';
import Loader from 'components/shared/loader/Loader';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { changeActiveModal } from 'store/auth/authSlice';

import SideBarFilterDesktopFavoriteTournaments from '../SideBarFilterDesktopFavoriteTournaments/SideBarFilterDesktopFavoriteTournaments';

import type { LineType } from 'types/line-data';


type Props = {
  isActive: boolean;
  lineType: LineType;
}

const b = bemCn('side-bar-filter-desktop-sport');
const SideBarFilterDesktopFavorites = ({ isActive, lineType }: Props) => {
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favorites = [], isLoading } = useGetFavoriteTournamentsQuery(undefined, { skip: !isAuth });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSportClick = () => {
    if (isAuth) {
      dispatch(setFilterSport('favorites'));
    } else {
      dispatch(changeActiveModal('sign-in'));
    }
  };

  return (
    <li className={b('button-wrapper')}>
      <button className={b('button', { active: isActive })}
        type="button"
        onClick={handleSportClick}
      >
        <GradientIcon iconName='star' color='yellow' />
        <span className={b('name')}>{t('common.favorite')}</span>
        <span className={b('counter')}>
          {favorites.length}
        </span>
      </button>
      {isActive && (isLoading ?
        <Loader /> :
        <SideBarFilterDesktopFavoriteTournaments tournaments={favorites} />
      )}
    </li>
  );
};

export default SideBarFilterDesktopFavorites;
