import React, { useEffect, useState } from 'react';

import Pagination from 'components/shared/Pagination/Pagination';
import { useGetDepositsQuery } from 'store/user/userApi';
import { scrollToTop } from 'utils/scrollToTop';
import { TRANSACTIONS_ITEMS_PER_PAGE } from 'utils/constants';

import TransactionItem from '../TransactionItem/TransactionItem';
import NoItems from '../../components/NoItems/NoItems';

const DepositsPage = () => {
  const [selectPage, setSelectPage] = useState<number>(1);
  const { data: deposits, isLoading } = useGetDepositsQuery(selectPage);

  useEffect(() => {
    scrollToTop(true);
  }, [selectPage]);

  const allItemsCount = deposits?.count || 0;
  const countPages = Math.ceil(allItemsCount / TRANSACTIONS_ITEMS_PER_PAGE);
  const isEmptyList = !isLoading && !deposits?.items?.length;

  if (isLoading || isEmptyList) {
    return (
      <NoItems isLoading={isLoading} isEmpty={isEmptyList} />
    );
  }

  return (
    <>
      {deposits?.items.map((transaction) => (
        <TransactionItem
          key={transaction.id}
          transaction={transaction}
        />
      ))}

      <Pagination
        selectPage={selectPage}
        countPages={countPages}
        setSelectPage={(value) => setSelectPage(value)}
      />
    </>
  );
};

export default DepositsPage;
