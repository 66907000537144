import { useTranslation } from 'react-i18next';

import StatusIcon from 'components/shared/StatusIcon/StatusIcon';
import { getCoefTitleFull } from 'utils/line';
import { bemCn } from 'utils/bem-cn';
import { getDateTimeCommaFormat } from 'utils/time';
import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';

import type { BetInfo } from 'types/user-data';
import './CouponBet.scss';

type Props = {
  bet: BetInfo;
  className?: string;
}

const CouponBet = ({ bet, className }: Props) => {
  const { t, i18n } = useTranslation();

  const b = bemCn('coupon-bet');
  return (
    <div className={b(null, className)}>
      <div className={b('head')}>
        <p className={b('outcome')}>
          {bet.subGameName && `${bet.subGameName}: `}
          {getCoefTitleFull(bet.outcomeName, bet.team1, bet.team2)}
        </p>
        <StatusIcon className={b('status')} status={bet.status} />
        <CoefDisplay className={b('ratio')} value={bet.coef.toFixed(2)} />
      </div>
      <div className={b('teams')}>
        <p className={b('tournament')}>
          <span>
            {bet.sportName && `${bet.sportName} • `}
            {bet.tournament}:
          </span>
          <span className={b('date')}>
            {getDateTimeCommaFormat(bet.gameDatetime, undefined, i18n.language)}
          </span>
        </p>
        <p className={b('team')}>{bet.team1}</p>
        <p className={b('team')}>{bet.team2}</p>
      </div>
      {bet.gameScore && (
        <p className={b('score')}>
          <span>{t('bet-history.final-score', 'Итоговый счёт')}:</span>
          <span className={b('score-value')}>{bet.gameScore}</span>
        </p>
      )}
      {bet.betScore && (
        <p className={b('score')}>
          <span>{t('bet-history.bet-score', 'Поставлено при счете')}:</span>
          <span className={b('score-value')}>{bet.betScore || '0:0'}</span>
        </p>
      )}
    </div>
  );
};

export default CouponBet;
