import { bemCn } from 'utils/bem-cn';
import './EventCardScore.scss';

type Props = {
  score: string;
  className?: string;
}

const EventCardScore = (props: Props) => {
  const score = props.score.split(':');

  const b = bemCn('event-card-score');

  return (
    <p className={b(null, props.className)}>
      <span className={b('value')}>{score[0]}</span>
      :
      <span className={b('value')}>{score[1]}</span>
    </p>
  );
};

export default EventCardScore;
