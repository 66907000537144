import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api } from 'store/api';
import { betapi } from 'store/betapi/betapi';
import { initialAuthState } from 'store/auth/authSlice';
import { getToken } from 'store/auth/utils';
import { NameSpace } from 'utils/constants';

import { rootReducer } from './root-reducer';

const preloadedState = {
  [NameSpace.Auth]: { ...initialAuthState, token: getToken() },
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(betapi.middleware),
  preloadedState,
});

setupListeners(store.dispatch);
