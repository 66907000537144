import React from 'react';
import cn from 'classnames';

import { useThemeContext } from 'contexts/ThemeContext';
import { getSportIconName } from 'utils/common';

import SvgIcon from '../SvgIcon/SvgIcon';

import type { SiteSection } from 'types/siteSections';
import './SectionIcon.scss';

type Props = {
  id: SiteSection;
  isBackgroundShowing: boolean;
  variant?: 'rounded-shadow';
  className?: string;
};

const iconName: Record<SiteSection, string> = {
  home: 'home',
  live: 'live',
  basket: 'cart',
  line: 'desktop',
  'top-line': 'fire',
  casino: 'coin',
  crashGames: 'crashGames',
  highlights: 'highlights',
  promotions: 'promotions',
  betHistory: 'desktop',
  profile: 'user',
  'aviator_spribe': 'aviator',
  'download-app': 'iphone',
};

const defaultIcon = 'home';

const SectionIcon = ({ id, isBackgroundShowing, variant, className }: Props) => {
  const { theme } = useThemeContext();
  const bgVariant = theme === 'light-blue' ? 'blue' : id;

  const icon = iconName[id] || getSportIconName(id as string) || defaultIcon;
  const icon20Class = icon === 'aviator' ? 'section-icon-20' : '';

  return (
    <div className={cn(
      'section-icon__wrapper',
      variant && `section-icon__wrapper--${variant}`,
      isBackgroundShowing && `section-icon__wrapper--${bgVariant}`,
      className,
    )}
    >
      {/* <SvgIcon className={cn('section-icon__icon', { 'section-icon__icon--active': isBackgroundShowing })} */}
      <SvgIcon className={cn(`section-icon__icon ${icon20Class}`, { 'section-icon__icon--active': isBackgroundShowing })}
        name={icon}
        width={15} height={15}
      />
    </div>
  );
};

export default SectionIcon;
