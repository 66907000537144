import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { getWidgetSubtitle } from 'utils/widgetHeader';
import { bemCn } from 'utils/bem-cn';
import i18n from 'i18n/i18n';
import JackpotCard from 'components/casino/components/JackpotCard/JackpotCard';
import { getCurrencySymbol } from 'utils/currency';
import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import { useAppSelector } from 'hooks';

import GradientIcon from '../../icons/GradientIcon/GradientIcon';
import SvgIcon from '../../SvgIcon/SvgIcon';
import GamesCard, { GameCardItemType } from '../GamesCard/GamesCard';
import GamesCardsWidgetSwiper from './GamesCardsWidgetSwiper/GamesCardsWidgetSwiper';

import './GamesCardsWidgetMobile.scss';

const grammaticalCases = {
  nominative: i18n.t('games.nominative', 'игра'),
  genitive: i18n.t('games.genitive', 'игры'),
  genitivePlural: i18n.t('games.genitivePlural', 'игр'),
};

type Props = {
  className?: string;
  title: string;
  icon?: React.ReactNode;
  iconColor?: string;
  iconName?: string;
  items: GameCardItemType[];
  itemsCounter?: number;
  linkToAll?: string;
  isLoading?: boolean;
  isAvailable?: boolean;
  notAvailableMessage?: string;
  jackpots?: {
    tier: number;
    amount: number;
  }[];
}

const GamesCardsWidgetMobile = (props: Props) => {
  const { t } = useTranslation();
  const { notAvailableMessage = t('maintenance.gamesNotAvailable') } = props;
  const currency = useAppSelector(selectPrimaryWalletCurrency);
  const b = bemCn('games-cards-widget-mobile');
  return (
    <div className={b(null, props.className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          {props.icon ? (
            <div className={b('icon')}>
              {props.icon}
            </div>
          ) : (
            <GradientIcon className={b('icon')} color={props.iconColor} iconName={props.iconName} />
          )}
          <p className={b('title')}>
            {props.title}
          </p>
          <div className={b('meta')}>
            <Link to={props.linkToAll ?? '/games'} className={b('all')}>
              {t('common.all', 'Все')}
              <SvgIcon className={b('all-icon')} name="arrow-right" width={9} height={9} />
            </Link>
            <div className={b('count')}>
              {getWidgetSubtitle(
                props.itemsCounter ?? props.items.length,
                grammaticalCases
              )}
            </div>
          </div>
        </div>
        {props.isAvailable && props.jackpots && props.jackpots.length ? (
          <div className={b('jackpots')}>
            {props.jackpots?.slice(0, 4).map((item, i) => (
              <JackpotCard className={b('jackpot-item')}
                key={`${item.tier}-${item.amount}`}
                tier={item.tier}
                amount={`${getCurrencySymbol(currency)} ${item.amount}`}
              />
            ))}
          </div>
        ) : null}
        <div className={b('games')}>
          {props.isAvailable !== undefined && !props.isAvailable
            ? <p>{notAvailableMessage}</p>
            : (
              <GamesCardsWidgetSwiper isLoading={props.isLoading} itemsCount={props.items.length}>
                {props.items.map((game) => (
                  <SwiperSlide className={b('swiper-item')}
                    key={`${game.id ?? ''}-${game.name}`}
                  >
                    <GamesCard className={b('item')}
                      item={game}
                    />
                  </SwiperSlide>
                ))}
              </GamesCardsWidgetSwiper>
            )}
        </div>
      </div>
    </div>
  );
};

export default GamesCardsWidgetMobile;
