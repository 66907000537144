import React, { useEffect } from 'react';
import { useToggle } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import { getCoefTitle } from 'utils/line';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import { useAddToBasketMutation } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';

import Coefficient from '../../components/Coefficient/Coefficient';

import type { LineType, Group, Bet } from 'types/line-data';
import './GroupItem.scss';

type Props = {
  group: Group;
  eventId: string;
  team1: string;
  team2: string;
  tournament: string;
  lineType: LineType;
  basketItem?: string | null;
  tab: number;
  groupName: string;
  isCollapsedAll: boolean;
};

const GroupItem = (props: Props) => {
  const {
    group,
    eventId,
    team1,
    team2,
    lineType,
    tournament,
    basketItem,
    tab,
    groupName,
    isCollapsedAll,
  } = props;
  const { i18n } = useTranslation();
  const [isCollapsed, toggleCollapsed, setCollapsed] = useToggle(isCollapsedAll);
  const [addToBasket] = useAddToBasketMutation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setCollapsed(isCollapsedAll);
  }, [isCollapsedAll]);

  useEffect(() => {
    setCollapsed(true);
  }, [tab]);

  const handleAddBetToBasket = (bet: Bet) => {
    if (isAuth) {
      addToBasket({
        dataType: lineType,
        basketId: bet.betId,
        lang: i18n.resolvedLanguage,
      });
    } else {
      dispatch(changeActiveModal('sign-in'));
    }
  };

  const b = bemCn('group-item');

  return (
    <div className={b()}>
      <div className={b('title-wrapper')} onClick={toggleCollapsed}>
        <p className={b('title')}>
          {group.name}
        </p>
        <SvgIcon className={b('collapse-icon', { active: !isCollapsed })}
          name='chevron-down'
          width={7}
          height={7}
        />
      </div>
      {!isCollapsed && (
        <div>
          {group.bets.map((bet) => (
            <Coefficient
              key={bet.betId}
              title={getCoefTitle(bet.caption, team1, team2)}
              value={bet.rate}
              isActive={bet.betId === basketItem}
              variant="fullwidth"
              onClick={() => handleAddBetToBasket(bet)}
              isBlocked={bet.blocked}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupItem;
