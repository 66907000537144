import { useGetFavoriteMatchesQuery, useGetFavoriteTournamentsQuery } from 'store/user/userApi';
import { FavoriteMatch, FavoriteTournament } from 'types/user-data';
import { FAV_ITEMS_PER_PAGE } from 'utils/constants';


type Props = {
  type: number;
  pageNumber: number;
};

type Result = {
  data: FavoriteTournament[] | FavoriteMatch[];
  isLoading: boolean;
  isEmpty: boolean;
  totalCout: number;
}

export const useGetFavoriteItems = ({ type, pageNumber }: Props): Result => {
  const { data: favoriteTournaments = [], isLoading: tournamentLoading } = useGetFavoriteTournamentsQuery();
  const { data: favoriteMatches = [], isLoading: matchesLoading } = useGetFavoriteMatchesQuery();

  if (type === 0) {
    const data = favoriteTournaments.slice((pageNumber - 1) * FAV_ITEMS_PER_PAGE, pageNumber * FAV_ITEMS_PER_PAGE);
    const totalCout = favoriteTournaments.length;
    return ({ data, isLoading: tournamentLoading, isEmpty: totalCout === 0, totalCout });
  } else {
    const data = favoriteMatches.slice((pageNumber - 1) * FAV_ITEMS_PER_PAGE, pageNumber * FAV_ITEMS_PER_PAGE);
    const totalCout = favoriteMatches.length;
    return ({ data, isLoading: matchesLoading, isEmpty: totalCout === 0, totalCout });
  }
};
