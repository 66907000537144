import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import bannerDesk2 from 'assets/banners/bannerDesk2.png';
import bannerDesk3 from 'assets/banners/bannerDesk3.png';
import bannerDesk2Webp from 'assets/banners/bannerDesk2.webp';
import bannerDesk3Webp from 'assets/banners/bannerDesk3.webp';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { selectAccountLoading, selectPrimaryWalletCurrency, selectUserGeo } from 'store/user/selectors';
import { useGetWalletsQuery } from 'store/user/userApi';
import { changeActiveModal } from 'store/auth/authSlice';
import { useGetBannersQuery } from 'store/app/appApi';
import { transformBanner } from 'utils/tranfromBanner';

export type BannerType = {
  img: string;
  webp?: string;
  link?: string;
  href?: string;
}

type desktopList = {
  big: BannerType[];
  small: BannerType[];
}

const Banner: Record<string, BannerType> = {
  casinoDeskSmall2: {
    img: bannerDesk2,
    webp: bannerDesk2Webp,
    link: '/games'
  },
  casinoDeskSmall3: {
    img: bannerDesk3,
    webp: bannerDesk3Webp,
    link: '/games'
  },
};

export const useLocalizedBanners = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAccaountLoading = useAppSelector(selectAccountLoading);
  const userGeo = useAppSelector(selectUserGeo);
  const userCurrency = useAppSelector(selectPrimaryWalletCurrency);
  const wallets = useGetWalletsQuery(undefined, { skip: !isAuthenticated || isAccaountLoading });
  const { data: banners = [], isLoading: bannersLoading } = useGetBannersQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSlideClick = (link: string) => {
    if (isAuthenticated) {
      navigate(link);
      return;
    }
    dispatch(changeActiveModal('sign-up'));
  };

  const sortedBanners = [...banners].sort((a, b) => b.priority - a.priority);

  const banersListMobile: BannerType[] = useMemo(() =>
    transformBanner(
      sortedBanners.filter((banner) => (
        !banner.isDesktop &&
        (
          banner.currency === null ||
          banner.currency === userCurrency
        )
      ))
    ), [sortedBanners, userCurrency, userGeo.country]);

  const banersListDesktop: desktopList = useMemo(() => ({
    big: transformBanner(
      sortedBanners.filter((banner) => (
        banner.isDesktop &&
        (
          banner.currency === null ||
          banner.currency === userCurrency
        )
      ))
    ),
    small: [Banner.casinoDeskSmall2, Banner.casinoDeskSmall3]
  }), [sortedBanners, userCurrency, userGeo.country]);


  return {
    isLoading: isAccaountLoading || userGeo.isLoading || wallets.isLoading || bannersLoading,
    bannersMobile: banersListMobile,
    bannersDesktop: banersListDesktop,
    onSlideClick
  };
};
