import { api } from 'store/api';
import { APIRoute } from 'utils/routes';
import { getLineLang } from 'utils/common';
import { LineType } from 'types/line-data';

import { adapteBasket } from './utils';

import type { PlaceBetsParams, TBasket } from 'types/basket-data';
import type { BetLimitsByCurrency, SubgamesLimits } from 'types/bet-limits';

export const basketApi = api.injectEndpoints({
  endpoints: (build) => ({
    placeBets: build.mutation<unknown, PlaceBetsParams>({
      query: ({ amount, lang = 'en', agreeWithCoef, balanceType = 'real' }) => ({
        url: APIRoute.PlaceBet,
        method: 'POST',
        params: {
          lang: getLineLang(lang),
        },
        body: {
          amount,
          'agree_with_coef': agreeWithCoef,
          'balance_type': balanceType,
        },
      }),
      invalidatesTags: ['Transactions', 'BetsHistory', 'Wallets', 'Basket']
    }),

    getBasket: build.query<TBasket, void>({
      query: () => 'v1/line/basket',
      transformResponse: adapteBasket,
      providesTags: ['Basket'],
      keepUnusedDataFor: 0,
    }),

    addToBasket: build.mutation<void, { dataType: LineType; basketId: string; lang?: string }>({
      query: ({dataType, basketId, lang}) => ({
        url: 'v1/line/basket/add',
        method: 'POST',
        params: {
          lang: getLineLang(lang),
        },
        body: {
          'data_type': dataType,
          'basket_id': basketId,
        },
      }),
      onQueryStarted() {
        basketApi.endpoints.getBasket.initiate();
      },
      invalidatesTags: ['Basket'],
    }),

    deleteFromBasket: build.mutation<void, { dataType: LineType; basketId: string }>({
      query: ({dataType, basketId}) => ({
        url: 'v1/line/basket/delete',
        method: 'DELETE',
        body: {
          'data_type': dataType,
          'basket_id': basketId,
        }
      }),
      invalidatesTags: ['Basket'],
    }),

    getBetLimits: build.query<BetLimitsByCurrency, void>({
      query: () => 'v1/bet_limits',
    }),

    getSubgamesLimits: build.query<SubgamesLimits, void>({
      query: () => '/v1/sub_games_limits',
    })
  }),
  overrideExisting: false,
});

export const {
  usePlaceBetsMutation,
  useGetBasketQuery,
  useLazyGetBasketQuery,
  useAddToBasketMutation,
  useDeleteFromBasketMutation,
  useGetBetLimitsQuery,
  useGetSubgamesLimitsQuery,
} = basketApi;
