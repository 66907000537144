import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import PPHeader from './PPHeader/PPHeader';
import PPSidebar from './PPSidebar/PPSidebar';
import PPMenu from './PPMenu/PPMenu';

import type { TMenuItem } from 'store/partnerProgram/types';

import './PPLayout.scss';

type Props = {
  title?: string;
  role: string;
  menuItems: TMenuItem[];
  children?: React.ReactNode;
};

const PPLayout = (props: Props) => {
  const { t } = useTranslation();

  const {
    title = 'Партнёрская программа',
    role,
    menuItems,
    children,
  } = props;

  const b = bemCn('pp-layout');

  return (
    <div className={b()}>
      <PPHeader title={title} role={role} />
      <div className={b('main')}>
        {/* <PPSidebar sideMenu={sideMenu} /> */}
        <PPMenu menuItems={menuItems} />
        <div className={b('content')}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PPLayout;
