
import { useTranslation } from 'react-i18next';

import EventCardMobile from 'components/line/EventCard/EventCardMobile/EventCardMobile';
import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';
import { useGetHotEventsList } from 'hooks/useGetHotEventsList';
import { useResponsive } from 'hooks/useResponsive';
import EventCardDesktop from 'components/line/EventCard/EventCardDesktop/EventCardDesktop';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import './HotEventsPage.scss';

const b = bemCn('hote-events-page');

const HotEventsPage = () => {
  const { t } = useTranslation();
  const { events, isFetching } = useGetHotEventsList();
  const { isMobile } = useResponsive();
  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
        <div className={b('list')}>
          {(events === null || isFetching) && <Loader />}
          {events?.map((event) => (
            isMobile
              ? (
                <EventCardMobile className={b('item')}
                  key={event.id}
                  event={event}
                  isFavorite={false}
                  favoriteId={'notFavoried'}
                />
              )
              : (
                <EventCardDesktop className={b('item')}
                  key={event.id}
                  event={event}
                  isFavorite={false}
                  favoriteId={'notFavoried'}
                />
              )
          ))}
        </div>
      </div>

    </div>
  );
};

export default HotEventsPage;
