import { getDateCommaFormat } from 'utils/time';

import type {
  ManagerPartner,
  PartnerLink,
  PartnerPromocode,
  PartnerProject,
} from './types';

export const ppConverter = {
  managerPartnersToTable: (items: ManagerPartner[]) => items.map((item) => ({
    ...item,
    key: item.id,
    created: getDateCommaFormat(item.created),
  })),

  managerPartnerLinks: (items: PartnerLink[]) => items.map((item) => ({
    ...item,
    key: item.refCode,
    created: getDateCommaFormat(item.created),
  })),

  managerPartnerPromocodes: (items: PartnerPromocode[]) => items.map((item) => ({
    ...item,
    key: item.id,
    created: getDateCommaFormat(item.created),
  })),

  partnerProjects: (items: PartnerProject[]) => items.map((item) => ({
    ...item,
    key: item.id,
    created: getDateCommaFormat(item.created),
  })),

  partnerLinks: (items: PartnerLink[]) => items.map((item) => ({
    ...item,
    key: item.refCode,
    created: getDateCommaFormat(item.created),
  })),

  partnerPromocodes: (items: PartnerPromocode[]) => items.map((item) => ({
    ...item,
    key: item.id,
    created: getDateCommaFormat(item.created),
  })),

};
