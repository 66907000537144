import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { changeActiveModal } from 'store/auth/authSlice';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useAppDispatch } from 'hooks';
import TextContent from 'components/shared/TextContent/TextContent';
import { bemCn } from 'utils/bem-cn';
import './PrivacyPolicy.scss';

const b = bemCn('privacy-policy-page');
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeActiveModal(null));
  }, []);

  return (
    <div className={b()}>
      <Breadcrumbs link="/home" title={t('main.nav.Home', 'Главная')} />
      <TextContent className={b('content')}>
        <h2>
          Privacy and Cookies
        </h2>
        <ul>
          <li>
            1. Your personal information is processed in accordance with our Privacy Policy, a
            copy of which is available on our website.
          </li>
          <li>
            2. Our Cookies Policy – a copy of which is available on our website – explains what
            cookies are, how they are used on the Services, and how to manage their use.
          </li>
        </ul>
      </TextContent>
    </div>
  );
};

export default PrivacyPolicy;
