
import ProfileLink, { ProfileLinkType } from '../ProfileLink/ProfileLink';

type Props = {
  items: ProfileLinkType[];
}

const ProfileSectionLinks = ({ items }: Props) => (
  <div className="profile__sections-links">
    {items.map((section) => (
      <ProfileLink
        key={section.name}
        name={section.name}
        title={section.title}
        description={section.description}
        disabled={section.disabled}
        link={section.link}
        href={section.href}
      />
    ))}
  </div>
);


export default ProfileSectionLinks;
