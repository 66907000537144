import React from 'react';

import LivePageMobile from 'pages/LivePage/LivePageMobile/LivePageMobile';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import { seletIsLineActive } from 'store/app/selectors';
import { useAppSelector } from 'hooks';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';

import LivePageDesktop from './LivePageDesktop/LivePageDesktop';

const LivePage = () => {
  const isLineActive = useAppSelector(seletIsLineActive);

  if (!isLineActive) {
    return <SiteMaintenance message={Maintenance.line} />;
  }

  return (
    <Adaptive
      desktop={<LivePageDesktop />}
      mobile={<LivePageMobile />}
    />
  );
};

export default LivePage;
