import React from 'react';

import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';
import './ModalsHead.scss';

type Props = {
  title: string;
  descr?: string;
};

const ModalsHead = ({ title, descr }: Props) => {
  const accountData = useAppSelector(selectAccountData);
  const { id: userId } = accountData;

  const b = bemCn('modals-head');

  return (
    <div className={b('top')}>
      <div className={b('title')}>{title}</div>
      <div className={b('id')}>ID: {userId}</div>
      {descr && <div className={b('descr')}>{descr}</div>}
    </div>
  );
};

export default ModalsHead;
