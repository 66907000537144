import React, { forwardRef } from 'react';
import cn from 'classnames';

import { bemCn } from 'utils/bem-cn';

import ErrorBlock from '../ErrorBlock/ErrorBlock';

import './TextInput.scss';

type TextInputType = 'text' | 'password' | 'number';

interface Props extends InputProps {
  value?: string | number;
  onChange?(e: React.ChangeEvent<HTMLInputElement> | string): void;
}

export type InputProps = {
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  additionalButton?: 'cancel' | 'copy' | 'show-password' | 'hide-password';
  onAdditionalButtonClick?: () => void;
  type?: TextInputType;
  variant?: 'default' | 'inverted';
  error?: { message?: string };
  showError?: boolean;
  isTouched?: boolean;
  autoComplete?: string;
  pattern?: string;
  // removeTrims?: boolean;
};

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, Props>((
  {
    value,
    placeholder,
    additionalButton,
    onAdditionalButtonClick,
    type = 'text',
    name,
    onChange,
    onBlur,
    disabled,
    error,
    showError = false,
    isTouched,
    autoComplete,
    pattern,
    variant,
  },
  ref,
) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = e.target;
    onChange?.(newValue);
  };

  const handleBlur = () => {
    if (value && typeof value === 'string' && type !== 'password') {
      onChange?.(value.trim());
    }
    onBlur?.();
  };

  const b = bemCn('text-input');

  return (
    <div className={b()}>
      {showError && <ErrorBlock isDisplayed={isTouched} message={error?.message} />}
      <input
        className={
          b('input', {
            invalid: showError && isTouched && !!error,
            valid: showError && isTouched && !error,
            [`${variant as string}`]: !!variant,
          })
        }
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
        name={name}
        ref={ref}
        disabled={disabled}
        autoComplete={autoComplete}
        pattern={pattern}
      />
      {additionalButton && (
        <button
          type="button"
          aria-label="cancel"
          className={cn(`text-input__additional-button--${additionalButton}`)}
          onClick={onAdditionalButtonClick}
        />
      )}
    </div>
  );
});

export default TextInput;
