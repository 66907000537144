import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTimezone } from 'hooks/useTimezone';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import { bemCn } from 'utils/bem-cn';

import './UserTimezone.scss';

type Props = {
  className?: string;
};

const UserTimezone = ({ className }: Props) => {
  const { t } = useTranslation();
  const b = bemCn('user-timezone');

  const {
    timezoneList,
    timezoneTitles,
    timezoneValue,
    isModalOpen,
    isSelectorOpened,
    timezoneBlockRef,
    toggleOpened,
    onChangeTimezone,
    handleModalClose,
  } = useTimezone();


  return (
    <div className={b(null, className)} ref={timezoneBlockRef}>
      <button className={b('selected')}
        type="button"
        onClick={toggleOpened}
      >
        {timezoneTitles[timezoneValue]}
        <SvgIcon name='chevron-down' className={b('arrow')} width={7} height={7} />
      </button>

      {isSelectorOpened && (
        <ul className={b('list')}>
          {timezoneList.map((timezone) => (
            <li className={b('option')}
              key={timezone}
              onClick={() => onChangeTimezone(timezone)}
            >
              {timezoneTitles[timezone.toString()]}
            </li>
          ))}
        </ul>
      )}

      <ResultModal
        title={`${t('result-modal.user-timezone.success.title', 'Часовой пояс изменён')}`}
        status="success"
        contentLabel="Change user timezone"
        isOpen={isModalOpen}
        description={`${t('result-modal.user-timezone.success.description', 'Часовой пояс изменён')}`}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default UserTimezone;
