import { topTournamentsID } from './constants';

import type { CountryItem, EventItem, SportItem } from 'types/line-data';

export const separateNumberIntoUnits = (number: number) =>
  number.toString()
    .split('')
    .map((digit) => parseInt(digit, 10));

export const moveToStart = <T,>(array: Array<T>, value: T | null | undefined) => {
  if (!value) {
    return array;
  }
  const items = array.filter((el) => el !== value);
  items.unshift(value);
  return items;
};

const sportIconNames: Record<string, string> = {
  0: 'cup',
  1: 'football',
  2: 'hockey',
  3: 'basketball',
  4: 'tennis',
  5: 'baseball',
  6: 'volleyball',
  7: 'rugby',
  8: 'handball',
  9: 'boxing',
  10: 'table-tennis',
  11: 'chess',
  13: 'rugby',
  14: 'futsal',
  16: 'badminton',
  19: 'biathlon',
  21: 'darts',
  22: 'alpine-skiing',
  24: 'skiing',
  26: 'f1',
  29: 'beach-volleyball',
  31: 'motosport',
  36: 'bicycle-racing',
  40: 'games',
  41: 'golf-1',
  56: 'mma',
  57: 'athletics',
  62: 'rowing',
  66: 'cricket',
  138: 'surfing',
  189: 'mma'
};

export const getSportIconName = (sportId: string): string =>
  sportIconNames[sportId] || 'cup';

export const sortByTopEntries = <T, K>(items: T[], entries: K[], cb: (item: T) => K): T[] => {
  if (entries.length <= 0) {
    return items;
  }
  const temp1: T[] = [];
  const temp2: T[] = [];

  items.forEach((item) => {
    if (entries.includes(cb(item))) {
      temp1.push(item);
    } else {
      temp2.push(item);
    }
  });

  return temp1.sort((a, b) => entries.indexOf(cb(a)) - entries.indexOf(cb(b))).concat(temp2);
};

export const filterByEntries = <T, K>(items: T[], entries: K[], cb: (item: T) => K): T[] =>
  items.filter((item) => entries.includes(cb(item)));

export const checkMajorTournament = (tournamentId: string): boolean =>
  topTournamentsID.includes(tournamentId);

export type TournamenWithEvents = {
  name: string;
  nameRu: string;
  nameEn: string;
  events: EventItem[];
  id: string;
  sportName: string;
  sportNameRu: string;
  sportNameEn: string;
  sportId: string;
  index: number;
}

export type EventGroupById = Record<string, TournamenWithEvents>

export const reduceEventsByTournamentId = (events: EventItem[]): EventGroupById => events.reduce<EventGroupById>((acc, event, index) => {
  acc[event.tournamentId] = acc[event.tournamentId] || {
    id: event.tournamentId,
    name: event.tournament,
    events: [],
    index,
  };
  acc[event.tournamentId].events.push(event);
  return acc;
}, {});

const lineLang: Record<string, string> = {
  'ru': 'ru',
  'uk': 'ua',
  'en': 'en',
  'az': 'az',
  'uz': 'uz',
};

export const getLineLang = (siteLang?: string) => {
  if (siteLang) {
    return lineLang[siteLang] ?? 'en';
  }
  return 'en';
};

export const filterTournamentEvents = (tournament: TournamenWithEvents[] = [], search: string): TournamenWithEvents[] => {
  if (!search || search.length <= 0) {
    return tournament;
  }

  const filteredTournaments: TournamenWithEvents[] = [];

  for (const tourny of tournament) {
    const filteredEvents = tourny.events.filter((event) =>
      event.team1.toLowerCase().includes(search.toLowerCase())
      || event.team2.toLowerCase().includes(search.toLowerCase())
      || event.tournament.toLowerCase().includes(search.toLowerCase()));

    if (filteredEvents && filteredEvents.length > 0) {
      filteredTournaments.push({
        ...tourny,
        events: filteredEvents,
      });
    }
  }

  return filteredTournaments;
};


export const filterEventsBySearch = (events: EventItem[] = [], search: string) => search.length > 0
  ? events.filter((event) =>
    event.team1.toLowerCase().includes(search.toLowerCase())
    || event.team2.toLowerCase().includes(search.toLowerCase())
    || event.tournament.toLowerCase().includes(search.toLowerCase()))
  : events;

export const filterSportsBySearch = (sports: SportItem[] = [], search: string) =>
  search.length > 0
    ? sports.filter((sport) => sport.name.toLowerCase().includes(search.toLowerCase()))
    : sports;

export const filterCountriesBySearch = (countries: CountryItem[], search: string) => (
  search.length > 0
    ? countries.filter((country) =>
      country.name.toLowerCase().includes(search.toLowerCase()))
    : countries
);

export const roundAmount = (amount: number) => Math.floor((amount * 100)) / 100;

export const groupByValue = <T>(
  arr: T[],
  getValue: (item: T) => string | number
) => arr.reduce<Record<string | number, T[]>>((acc, item) => {
  if (!acc[getValue(item)]) {
    acc[getValue(item)] = [];
  }
  acc[getValue(item)].push(item);

  return acc;
}, {});

export const groupByValueWithTransform = <T, K>(
  arr: T[],
  getValue: (item: T) => string | number,
  transform: (item: T) => K,
) => arr.reduce<Record<string | number, K[]>>((acc, item) => {

  if (!acc[getValue(item)]) {
    acc[getValue(item)] = [];
  }
  acc[getValue(item)].push(transform(item));

  return acc;
}, {});

export const filterByBlacklist = <T extends object, K>(
  arr: T[],
  blacklist: K[],
  valuesToCheck: (item: T) => K[],
): T[] => arr.filter((item) => !valuesToCheck(item).some((value) => blacklist.includes(value)));

export const filterByConditionWithTransform = <T, R>(
  arr: T[],
  isValid: (item: T) => boolean,
  transform: (item: T) => R
): R[] => {
  const res: R[] = [];
  for (const item of arr) {
    if (isValid(item)) {
      res.push(transform(item));
    }
  }
  return res;
};
