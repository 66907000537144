export type betingTaxInfo = {
  exciseTax: number;
  posibleWin: number;
  winnings: number;
  withHoldingTax: number;
  finalPay: number;
  stakeAfterTax: number;
}

export const getBetingTaxInfo = (value: number, coef: number): betingTaxInfo => {
  const exciseTax = value * 11.11 / 100;
  const stakeAfterTax = value * 88.89 / 100;
  const posibleWin = stakeAfterTax * coef;
  const winnings = posibleWin - stakeAfterTax;
  const withHoldingTax = winnings * 20 / 100;
  const finalPay = posibleWin - withHoldingTax;
  return ({
    exciseTax,
    stakeAfterTax,
    posibleWin,
    winnings,
    withHoldingTax,
    finalPay
  });
};
