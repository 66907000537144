import { useGetAppSportsQuery } from 'store/app/appApi';
import { useGetBetapiTournamentsQuery } from 'store/betapi/betapi';
import { GetBetapiTournamensParams } from 'store/betapi/types';
import { TournamentItem } from 'types/line-data';
import { sortByTopEntries } from 'utils/common';


const tournamentSortName: keyof TournamentItem = 'nameRu';

const isValid = (tourney: TournamentItem, blacklist: string[]): boolean =>
  ![tourney.name, tourney.nameRu, tourney.nameEn]
    .some((name) => blacklist.includes(name.toLowerCase()));

const useFilteredTournaments = (
  arg: GetBetapiTournamensParams,
  opt?: {
    skip?: boolean;
    pollingInterval?: number;
  },
) => {
  const { data } = useGetAppSportsQuery();
  const result = useGetBetapiTournamentsQuery(arg, opt);

  const tournamentsBlackList = data?.tournamentsBlackList ?? [];
  const validTournaments = result.data?.filter((item) => isValid(item, tournamentsBlackList));

  const sortedData = sortByTopEntries(
    validTournaments ?? [],
    data?.tournamentsPriority ?? [],
    (item) => item[tournamentSortName].toLowerCase()
  );

  return {
    ...result,
    data: sortedData,
  };
};

export { useFilteredTournaments };
