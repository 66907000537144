import { useAppDispatch } from 'hooks';
import { bemCn } from 'utils/bem-cn';
import { setFilterCountry } from 'store/app/appSlice';
import Flag from 'components/shared/Flag/Flag';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import SideBarFilterDesktopTournaments from '../SideBarFilterDesktopTournaments/SideBarFilterDesktopTournaments';

import './SideBarFilterDesktopCountry.scss';

import type { CountryItem as CountryItemType, TournamentItem, TournamentRegion } from 'types/line-data';

type Props = {
  country: CountryItemType;
  isActive: boolean;
  tournaments: TournamentItem[];
  isTournamentsLoading?: boolean;
  tournamentRegion: TournamentRegion;
}

const b = bemCn('side-bar-filter-desktop-country');

const SideBarFilterDesktopCountry = ({ country, isActive, tournaments, isTournamentsLoading ,tournamentRegion}: Props) => {
  const dispatch = useAppDispatch();
  const handleCountryCLick = () => {
    dispatch(setFilterCountry(country.id));
  };

  return (
    <div>
      <button className={b('button', { active: isActive })}
        type="button"
        onClick={handleCountryCLick}
      >
        <Flag countryId={country.id} />
        <span className={b('name')}>{country.name}</span>
        <SvgIcon className={b('button-arrow', { active: isActive })} name="chevron-right" width={10} height={10} />
      </button>
      {isActive && (
        <SideBarFilterDesktopTournaments
          tournaments={tournaments}
          tournamentRegion={tournamentRegion}
          isLoading={isTournamentsLoading}
        />
      )}
    </div>
  );
};

export default SideBarFilterDesktopCountry;
