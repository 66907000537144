import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { usePasswordResetSmsMutation } from 'store/auth/authApi';
import Button from 'components/shared/buttons/Button/Button';
import PhoneSelector from 'components/shared/inputs/PhoneSelector/PhoneSelector';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';
import { FetchErorr } from 'types/auth-data';
import { useAppDispatch } from 'hooks';
import { setVerifyPhone } from 'store/auth/authSlice';

type FormFields = {
  phone: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const phoneRegExp = /^\+?\d{6,24}$/;

const schema = yup.object({
  phone: yup.string()
    .matches(phoneRegExp, `${i18n.t('reset-pass.errors.wrong-phone', 'Введите корректный номер')}`)
    .required(`${i18n.t('reset-pass.errors.empty-phone', 'Введите номер')}`),
}).required();

const b = bemCn('login-modal');
const ResetPassword = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const [sendSms, { isLoading, error }] = usePasswordResetSmsMutation();

  const { handleSubmit, setError, control } = useForm<FormFields>({
    defaultValues: { phone: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (error !== undefined) {
      const fetchError = error as FetchErorr;
      const errorMesage = fetchError.data.detail;

      setError('phone', {
        type: 'server',
        message: errorMesage ?? '',
      });
    }
  }, [error]);

  const handleSend = async (data: FormFields) => {
    await sendSms(data.phone);
    dispatch(setVerifyPhone(data.phone));
  };

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <form onSubmit={handleSubmit(handleSend)}>
        <div className={b('header')}>
          <h2 className={b('title')}>
            {t('reset-pass.title', 'Восстановление пароля')}
          </h2>
          <p className={b('description')}>
            {t('reset-pass.phone-description', '')}
          </p>
        </div>

        <div className={b('wrapper')}>
          <div className={b('fields')}>
            <PhoneSelector<FormFields, unknown>
              name="phone"
              placeholder={t('withdrawals.modal.phone-number') ?? ''}
              disabled={isLoading}
              control={control}
            />
          </div>
          <Button className={b('button')}
            isLoading={isLoading}
            type='submit'
          >
            {t('reset-pass.send-request', 'Отправить')}
          </Button>
        </div>
      </form >
    </AppModal>
  );
};

export default ResetPassword;
