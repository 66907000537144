import { api } from 'store/api';

import {
  GetPartnerLinkPlayers,
  GetPartnerLinkStats,
  GetPartnerLinks,
  GetPartnerPlayers,
  GetPartnerProjectPlayers,
  GetPartnerProjectStats,
  GetPartnerPromocodePlayers,
  GetPartnerPromocodeStats,
  GetPartnerPromocodes,
  GetPartnerStats,
  GetPratnerProjects,
  CreatePartnerLink,
  CreatePartnerProject,
  CreatePartnerPromocode,
  PartnerLinks,
  PartnerPlayers,
  PartnerPromocodes,
  PartnerStats,
  PartnerProjects,
  PromocodeScript,
  PartnerPlayersServer,
  PartnerLinksServer,
  PromocodeScriptServer,
  PartnerPromocodesServer,
} from './types';
import { PPAdapter } from './utils';


export const partnerApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({

    getPartnerProjects: build.query<PartnerProjects, GetPratnerProjects>({
      query: (params) => ({
        url: '/v1/partner/projects',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      providesTags: ['PartnerProjects'],
    }),

    createPartnerProject: build.mutation<unknown, CreatePartnerProject>({
      query: (params) => ({
        url: '/v1/partner/projects',
        method: 'POST',
        params: {
          name: params.name,
        }
      }),
      invalidatesTags: ['PartnerProjects'],
    }),

    getPartnerProjectStats: build.query<PartnerStats, GetPartnerProjectStats>({
      query: ({ projectId, ...params }) => ({
        url: `/v1/partner/projects/${projectId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        },
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),

    getPartnerProjectPlayers: build.query<PartnerPlayers, GetPartnerProjectPlayers>({
      query: ({ projectId, ...params }) => ({
        url: `/v1/partner/projects/${projectId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

    getPartnerLinks: build.query<PartnerLinks, GetPartnerLinks>({
      query: (params) => ({
        url: '/v1/partner/links',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      providesTags: ['PartnerLinks'],
      transformResponse: (res: PartnerLinksServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.linkToClient),
      })
    }),
    createPartnerLink: build.mutation<unknown, CreatePartnerLink>({
      query: (params) => ({
        url: '/v1/partner/links',
        method: 'POST',
        params: {
          'project_id': params.projectId,
        }
      }),
      invalidatesTags: ['PartnerLinks'],
    }),

    getPartnerLinkStats: build.query<PartnerStats, GetPartnerLinkStats>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/partner/links/${refCode}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),
    getPartnerLinkPlayers: build.query<PartnerPlayers, GetPartnerLinkPlayers>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/partner/links/${refCode}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

    getPartnerPromocodeScripts: build.query<PromocodeScript[], void>({
      query: () => '/v1/partner/promocode_scripts',
      transformResponse: (res: PromocodeScriptServer[]) =>
        res.map(PPAdapter.promocodeScriptToClient),
    }),

    getPartnerPromocodes: build.query<PartnerPromocodes, GetPartnerPromocodes>({
      query: (params) => ({
        url: '/v1/partner/promocodes',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      providesTags: ['PartnerPromocodes'],
      transformResponse: (res: PartnerPromocodesServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.promocodeToClient),
      })
    }),

    createPartnerPromocode: build.mutation<unknown, CreatePartnerPromocode>({
      query: (params) => ({
        url: '/v1/partner/promocodes',
        method: 'POST',
        params: {
          'script_id': params.scriptId,
          'name': params.name,
        }
      }),
      invalidatesTags: ['PartnerPromocodes'],
    }),

    getPartnerPromocodeStats: build.query<PartnerStats, GetPartnerPromocodeStats>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/partner/promocodes/${promocodeId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),
    getPartnerPromocodePlayers: build.query<PartnerPlayers, GetPartnerPromocodePlayers>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/partner/promocodes/${promocodeId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

    getPartnerStats: build.query<PartnerStats, GetPartnerStats>({
      query: (params) => ({
        url: '/v1/partner/stats',
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: PPAdapter.partnerStatsToClient,
    }),
    getPartnerPlayers: build.query<PartnerPlayers, GetPartnerPlayers>({
      query: (params) => ({
        url: '/v1/partner/players',
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(PPAdapter.playerStatsToClient),
      })
    }),

  })
});

export const {
  useGetPartnerProjectsQuery,
  useCreatePartnerProjectMutation,
  useGetPartnerProjectStatsQuery,
  useGetPartnerProjectPlayersQuery,
  useGetPartnerLinksQuery,
  useCreatePartnerLinkMutation,
  useGetPartnerLinkStatsQuery,
  useGetPartnerLinkPlayersQuery,
  useGetPartnerPromocodeScriptsQuery,
  useGetPartnerPromocodesQuery,
  useCreatePartnerPromocodeMutation,
  useGetPartnerPromocodeStatsQuery,
  useGetPartnerPromocodePlayersQuery,
  useGetPartnerStatsQuery,
  useGetPartnerPlayersQuery,
} = partnerApi;
