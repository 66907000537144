import { BackendError } from 'store/auth/utils';
import { toLocaleDateString } from 'utils/time';

type LimitError = {
  detail: {
    'message': string;
    'next_available_withdrawal'?: string;
    'available_ammount'?: number;
  };
}

const withdrawalErrors: Record<string, string> = {
  'Not enough money': 'Недостаточно средств.',
  'Withdrawal method not supported': 'Метод вывода не поддерживается.',
  'Withdrawal method not active': 'Метод вывода не активен.',
  'Currency not supported': 'Валюта не поддерживается.',
  'Broken limits': 'Превышен лимит вывода.',
  'Withdrawal limits count exceeded for the current period': 'Вы исчерпали лимит на кол-во заявок.',
  'Withdrawal limits ammount exceeded for the current period': 'Вы исчерпали лимит на вывод средств.',
  'Withdrawal limits are set as a ban on any withdrawal': 'Вы превысили лимит на вывод средств, попробуйте позднее.'
};

export const handleWithdrawalError = (status: number, data: BackendError | LimitError): string => {
  if (!data || !data.detail) {
    return 'Неизвестная ошибка';
  }
  const { detail } = data;
  if (typeof detail === 'string') {
    return withdrawalErrors[detail] ?? detail;
  }

  if (Array.isArray(detail)) {
    return detail[0].msg ?? 'Ошибка';
  }
  if (status === 403) {
    if (typeof detail === 'object' && detail !== null) {
      const message = detail.message ?
        withdrawalErrors[detail.message] ?? detail.message
        : '';
      const date = detail['next_available_withdrawal'] && detail['next_available_withdrawal'] !== 'Never'
        ? toLocaleDateString(new Date(detail['next_available_withdrawal']), { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
        : '';
      const dateMessage = date ? ` Лимит обновится: ${date}.` : '';
      const availableLimitAmount = detail['available_ammount']
        ? ` Доступно для вывода ещё: ${detail['available_ammount']}.`
        : '';

      return message + availableLimitAmount + dateMessage;
    }
  }


  return 'Неизвестная ошибка';
};
