import { useGetAppSportsQuery } from 'store/app/appApi';
import { useGetBetapiEventsListQuery } from 'store/betapi/betapi';
import { GetBetapiEventsListParams } from 'store/betapi/types';


const useFilteredEventsList = (
  arg: GetBetapiEventsListParams,
  opt?: {
    skip?: boolean;
    pollingInterval?: number;
  },
) => {
  const { data } = useGetAppSportsQuery();
  const result = useGetBetapiEventsListQuery(arg, opt);

  const tournamentsBlackList = data?.tournamentsBlackList ?? [];
  const sportsBlacklist = data?.sportsBlacklist ?? [];

  const filteredEventsList = result.data?.filter((event) => {
    const {
      sportName,
      sportNameRu,
      sportNameEn,
      tournament,
      tournamentEn,
      tournamentRu,
    } = event;

    return ![sportName, sportNameEn, sportNameRu].some((item) => sportsBlacklist.includes(item.toLowerCase()))
      && ![tournament, tournamentRu, tournamentEn].some((item) =>
        tournamentsBlackList.includes(item.toLowerCase()));
  });


  return {
    ...result,
    data: filteredEventsList,
  };
};

export { useFilteredEventsList } ;
