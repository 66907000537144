import { useState } from 'react';

import {
  useGetPartnerLinkStatsQuery,
} from 'store/partnerProgram/partnerApi';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import {
  statsTitleList,
  defaultPartnerCurrency,
} from 'store/partnerProgram/constants';

import type { PartnerStats, TStatsItem, TFilterValues } from 'store/partnerProgram/types';
import type { SubmitHandler } from 'react-hook-form';

const statsKeys: (keyof PartnerStats)[] = [
  'numClicks',
  'numRegistrations',
  'deposits',
  // 'adminDeposits',
  'firstDeposits',
  // 'betsPlaced',
  'betsWinnings',
  // 'betsIngame',
  // 'betsLosses',
  'betsIncome',

  // 'inbetPlaced',
  // 'inbetWinnings',
  // 'inbetIncome',
  // 'betgamesPlaced',
  // 'betgamesWinnings',
  // 'betgamesIncome',
  // 'vegasPlaced',
  // 'vegasWinnings',
  // 'vegasIncome',
  // 'pragmaticPlaced',
  // 'pragmaticWinnings',
  // 'pragmaticIncome',
  // 'tvbetPlaced',
  // 'tvbetWinnings',
  // 'tvbetIncome',
  // 'mascotPlaced',
  // 'mascotWinnings',
  // 'mascotIncome',
  // 'agtPlaced',
  // 'agtWinnings',
  // 'agtIncome',
  'income',
  'revshare',
  // 'currentIncome',
];

const getStatsList = (stats?: PartnerStats) => {
  const statsList: TStatsItem[] = statsKeys.map((key) => {
    const val = stats ? stats[key] : 0;

    const statsItem = {
      title: statsTitleList[key],
      value: val ? val : 0,
      key: statsTitleList[key],
    };

    return statsItem;
  });

  return statsList;
};

export const usePartnerLinkStats = (refCode: string) => {
  const userCurrency = useAppSelector(selectPrimaryWallet).currency ?? defaultPartnerCurrency;
  const initParams = {
    refCode,
    currency: userCurrency,
  };
  const [params, setParams] = useState(initParams);

  const {
    data,
    isFetching,
    isError,
  } = useGetPartnerLinkStatsQuery(params, { skip: !refCode });

  const statsList = data && getStatsList(data);

  const handleFilterChange: SubmitHandler<TFilterValues> = (filterData) => {
    setParams((prev) => ({
      ...prev,
      ...filterData,
    }));
  };

  const handleFilterReset = () => {
    setParams({
      ...initParams,
    });
  };

  return {
    data,
    isFetching,
    isError,
    statsList,
    handleFilterChange,
    handleFilterReset,
  };
};
