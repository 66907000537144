import React from 'react';

import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { bemCn } from 'utils/bem-cn';
import './GamesFilter.scss';

type Props = {
  className?: string;
  items: string[];
  activeItem: string;
  onChange?: (filter: string) => void;
}

const GamesFilter = ({ className, items, onChange, activeItem }: Props) => {
  const containerRef = useHorizontalScroll<HTMLDivElement>();
  const handleTabChange = (newTab: string) => {
    if (newTab !== activeItem) {
      onChange?.(newTab);
    }
  };

  const b = bemCn('games-filter');

  return (
    <div className={b(null, className)}>
      <div className={b('list')} ref={containerRef}>
        {items.map((item) => (
          <button className={b('item', { active: activeItem === item })}
            key={`game-filter-${item}`}
            type="button"
            onClick={() => handleTabChange(item)}
          >
            {item}
          </button>
        ))}

      </div>
    </div>
  );
};

export default GamesFilter;
