import React from 'react';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import './CloseButton.scss';

type Props = {
  onClick?: () => void;
  className?: string;
};

const CloseButton = ({ onClick, className }: Props) => {
  const b = bemCn('close-button');

  return (
    <button className={b(null, className)} type="button" aria-label="Close" onClick={onClick}>
      <SvgIcon className={b('icon')} name="close" width={10} height={10} />
    </button>
  );
};
export default CloseButton;
