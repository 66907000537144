import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import { adapteTupupMethods, adapteWithdrawalMethods } from './converters';

import type {
  InitResponse,
  InitTopupParams,
  InitWithdrawalParams,
  TTopupMethodsByCurrency,
  TWithdrawalMethodsServer,
  TWithdrawalMethodsByCurrency,
  TTopupMethodsServer
} from 'types/payments-data';

export const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getTopupMethods: builder.query<TTopupMethodsByCurrency, void>({
      query: () => APIRoute.PaymentSystems,
      transformResponse: (response: TTopupMethodsServer) =>
        adapteTupupMethods(response)
    }),

    getWithdrawalMethods: builder.query<TWithdrawalMethodsByCurrency, void>({
      query: () => APIRoute.WithdrawalMethods,
      transformResponse: (response: TWithdrawalMethodsServer) =>
        adapteWithdrawalMethods(response)
    }),

    initTopUp: builder.mutation<InitResponse, InitTopupParams>({
      query: ({ system, amount, method }) => ({
        url: `${APIRoute.Payment}/${system}/init`,
        method: 'POST',
        body: {
          'amount': amount,
          'pay_with': method,
          'method': method,
        }
      }),
      transformResponse: (response: { payment_url: string }) => ({
        paymentUrl: response.payment_url,
      })
    }),

    initWithdrawal: builder.mutation<unknown, InitWithdrawalParams>({
      query: (data: InitWithdrawalParams) => ({
        url: APIRoute.WithdrawalInit,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Transactions', 'Wallets'],
    }),
  }),
});

export const {
  useInitTopUpMutation,
  useInitWithdrawalMutation,
  useGetWithdrawalMethodsQuery,
  useGetTopupMethodsQuery,
} = paymentsApi;
