import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useOnClickOutside } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import './PPDropdown.scss';

import type { TDropdownItem } from 'store/partnerProgram/types';

type Props = {
  icon?: React.ReactNode;
  items: TDropdownItem[];
  onClick?: (key: string) => void;
};

type TDropdownCoords = {
  top: number;
  left: number;
};

const dropdownRoot = document.getElementById('pp-dropdown');
const dropdownWidth = 250;

const PPDropdown = (props: Props) => {
  const {
    icon = <SvgIcon name="dots-menu" />,
    items,
    onClick,
  } = props;

  const b = bemCn('pp-dropdown');
  const [open, setOpen] = useState(false);
  const dropdownBlockRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);
  const [dropdownCoords, setDropdownCoords] = useState<TDropdownCoords>({ top: 0, left: 0 });

  const getDropdownCoords = () => {
    const btnRect = btnRef.current && btnRef.current.getBoundingClientRect();
    const top = btnRect ? btnRect.top + btnRect?.height + 10 : 0;
    const left = btnRect ? btnRect.x + btnRect.width - dropdownWidth : 0;

    return { top, left };
  };

  const handleBtnClick = () => {
    const { top, left } = getDropdownCoords();
    setDropdownCoords({ top, left });

    setOpen(!open);
  };

  const handleItemClick = (item: TDropdownItem) => {
    onClick?.(item.key);
  };

  useOnClickOutside(dropdownBlockRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEffect(() => {
    const { top, left } = getDropdownCoords();
    setDropdownCoords({ top, left });
  }, []);

  const dropdownItemsJSX = (
    <div
      style={{
        top: `${dropdownCoords.top}px`,
        left: `${dropdownCoords.left}px`,
        width: `${dropdownWidth}px`,
      }}
      className={open ? 'pp-dropdown__items pp-dropdown__items--active' : 'pp-dropdown__items'}
    >
      {items.map((item) => (
        <button
          type="button"
          key={item.key}
          className={b('item')}
          onClick={() => handleItemClick(item)}
        >
          {item.title}
        </button>
      ))}
    </div>
  );


  return (
    <div className={b()} ref={dropdownBlockRef}>
      <div
        className={b('btn')}
        ref={btnRef}
        onClick={handleBtnClick}
      >
        {icon}
      </div>

      {dropdownRoot && createPortal(dropdownItemsJSX, dropdownRoot)}

    </div>
  );
};

export default PPDropdown;
