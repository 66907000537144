import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsBurgerMenuOpen } from 'store/app/selectors';
import { useResponsive } from 'hooks/useResponsive';
import { setBurgerMenu } from 'store/app/appSlice';
import { bemCn } from 'utils/bem-cn';

import BurgerMenuContent from './BurgerMenuContent/BurgerMenuContent';

import './BurgerMenu.scss';

const b = bemCn('burger-menu');
function BurgerMenu() {
  const dispatch = useAppDispatch();
  const { isMobile } = useResponsive();
  const [burgerMenuDisplayState, setBurgerMenuDisplayState] = useState(false);
  const isBurgerMenuOpen = useAppSelector(selectIsBurgerMenuOpen);
  const BurgerRef = useRef(null);

  useOnClickOutside(BurgerRef, () => {
    dispatch(setBurgerMenu(false));
  });

  useEffect(() => {
    if (isBurgerMenuOpen) {
      setBurgerMenuDisplayState(true);
    }
  }, [isBurgerMenuOpen]);

  const onAnimationEnd = () => {
    if (!isBurgerMenuOpen) {
      setBurgerMenuDisplayState(false);
    }
  };

  return (
    <div className={b('', { 'active': isBurgerMenuOpen })}
      ref={BurgerRef}
      onTransitionEnd={onAnimationEnd}
    >
      {
        burgerMenuDisplayState && isMobile && <BurgerMenuContent />
      }
    </div>
  );
}

export default BurgerMenu;
