import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';

import type { PartnerProgramState } from 'types/state';
import type { Currency } from 'types/wallets-data';

const initialState: PartnerProgramState = {
  partnerSelectedCurrency: 'RUB',
};

export const partnerProgramData = createSlice({
  name: NameSpace.PartnerProgram,
  initialState,
  reducers: {
    setPartnerSelectedCurrency: (state, action: PayloadAction<{ currency: Currency }>) => {
      state.partnerSelectedCurrency = action.payload.currency;
    },
  },

});

export const {
  setPartnerSelectedCurrency,
} = partnerProgramData.actions;
