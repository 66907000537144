import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import Button from 'components/shared/buttons/Button/Button';
import { BETS_ITEMS_PER_PAGE } from 'utils/constants';
import Pagination from 'components/shared/Pagination/Pagination';
import { scrollToTop } from 'utils/scrollToTop';
import NoItems from 'components/profile/components/NoItems/NoItems';
import { bemCn } from 'utils/bem-cn';
import { useGetCouponsQuery } from 'store/user/userApi';

import CouponCardDesktop from '../../../components/profile/BetHistory/CouponCard/CouponCardDesktop/CouponCardDesktop';

import type { PlaceType } from 'types/user-data';

import './BetHistoryDesktop.scss';

type filterButtonsType = {
  title: string;
  id: number;
  filter?: PlaceType;
};

const filterButtons: filterButtonsType[] = [
  {
    title: i18n.t('bet-history.all-bets', 'Все ставки'),
    id: 0,
  },
  {
    title: i18n.t('bet-history.single', 'Ординар'),
    id: 1,
    filter: 'ordinar'
  },
  {
    title: i18n.t('bet-history.express', 'Экспресс'),
    id: 2,
    filter: 'express'
  }
];

const BetHistoryDesktop = () => {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [filter, setFiler] = useState<PlaceType | undefined>(undefined);

  const {
    coupons,
    totalCout,
    isLoading,
    isEmpty
  } = useGetCouponsQuery({
    pageSize: BETS_ITEMS_PER_PAGE,
    pageNumber: selectedPage,
    type: filter,
    lang: i18n.resolvedLanguage,
  }, {
    selectFromResult: (result) => ({
      totalCout: result.data?.totalCount ?? 0,
      coupons: result.data?.items ?? [],
      isLoading: result.isFetching,
      isEmpty: !result.isLoading && result.data?.items.length === 0,
    })
  });

  useEffect(() => {
    scrollToTop(true);
  }, [selectedPage]);

  useEffect(() => {
    setSelectedPage(1);
  }, [filter]);

  const b = bemCn('bet-history-desktop');
  return (
    <div className={b()}>
      <Breadcrumbs link="/" title={t('main.nav.Home', 'Главная')} />
      <div className={b('wrapper')}>
        <div className={b('top')}>
          <h1 className={b('top-title')}>
            {t('bet-history.title', 'История ставок')}
          </h1>
          <div className={b('filter')}>
            {filterButtons.map((item) => (
              <Button className={b('filter-btn')}
                key={item.id}
                variant={item.filter !== filter ? 'shaded' : undefined}
                onClick={() => setFiler(item.filter)}
              >
                {item.title}
              </Button>
            ))}
          </div>
        </div>
        <div className={b('list')}>
          {(isLoading || isEmpty)
            ? (
              <NoItems
                isLoading={isLoading}
                isEmpty={isEmpty}
                text={`${t('bet-history.bets-empty', 'Список ставок пуст')}`}
                buttonText={`${t('bet-history.make-bet', 'Сделать ставку')}`}
                withButton
              />
            )
            : coupons.map((coupon) => (
              <CouponCardDesktop
                key={coupon.id}
                coupon={coupon}
              />
            ))}
        </div>
        <div>
          <Pagination
            selectPage={selectedPage}
            countPages={Math.ceil(totalCout / BETS_ITEMS_PER_PAGE)}
            setSelectPage={(value) => setSelectedPage(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default BetHistoryDesktop;
