import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { useDeleteFavoriteMatchMutation } from 'store/user/userApi';
import { useResponsive } from 'hooks/useResponsive';
import { FavoriteMatch } from 'types/user-data';
import { bemCn } from 'utils/bem-cn';

import './FavoriteMatchCard.scss';

type Props = {
  match: FavoriteMatch;
}
const b = bemCn('favorite-match-card');
function FavoriteMatchCard({ match }: Props) {
  const [deleteFavorite] = useDeleteFavoriteMatchMutation();
  const { isMobile } = useResponsive();
  const handleFavClick: MouseEventHandler = (e) => {
    e.preventDefault();
    deleteFavorite(match.id);
  };

  return (
    <Link to={`/fullevent/${match.matchId}/${match.dataType}/${match.countryName}/${match.countryId}`} className={b()}>
      <p>{match.sportName}</p>
      <p>{match.dataType}</p>
      <p>{match.countryName}</p>
      <p>{match.tournamentName}</p>
      <p><b>{`${match.team1} vs ${match.team2}`}</b></p>
      <FavoriteBtn size={isMobile ? 14 : 18} onClick={handleFavClick} className={b('fav-btn')} active />
    </Link>
  );
}

export default FavoriteMatchCard;
