import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { changeActiveBasketModal } from 'store/basket/basketSlice';
import { changeActiveModal as changeAuthModal } from 'store/auth/authSlice';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import { useResponsive } from 'hooks/useResponsive';
import { useGetBasketQuery } from 'store/basket/basketApi';
import './BusketPopupButton.scss';

const BasketPopupButton = () => {
  const { isMobile } = useResponsive();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { basketCount } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketCount: data?.count ?? 0
    })
  });

  if (isMobile) {
    return null;
  }

  const handleBasketClick = () => {
    if (isAuth) {
      dispatch(changeActiveBasketModal('basket'));
    } else {
      dispatch(changeAuthModal('sign-in'));
    }
  };

  const b = bemCn('basket-popup-button');
  return (
    <button className={b({ hidden: basketCount <= 0, active: basketCount > 0 })} type='button' onClick={handleBasketClick}>
      <SvgIcon className={b('icon')}
        name="cart"
        width={20}
        height={20}
      />
      <span className={b('count')}>{basketCount}</span>
    </button>
  );
};

export default BasketPopupButton;
