import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { useChangePasswordMutation } from 'store/auth/authApi';
import Button from 'components/shared/buttons/Button/Button';
import { selectPasswordResetId } from 'store/auth/selectors';
import FormInputWrapper from 'components/shared/form/FormInput';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';


type FormFields = {
  password: string;
  confirmPassword: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = yup.object({
  password: yup.string()
    .min(4, `${i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')}`),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(4, `${i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')}`)
}).required();

const b = bemCn('login-modal');
const ChangePassword = (props: Props) => {
  const { isOpen, onClose } = props;
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const passwordResetId = useAppSelector(selectPasswordResetId);
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<FormFields>({
    defaultValues: { password: '', confirmPassword: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const handleSend = async (data: FormFields) => {
    if (passwordResetId) {
      await changePassword({ password: data.password, passwordResetId });
    }
  };

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSend)}>
        <div className={b('header')}>
          <h2 className={b('title')}>
            {t('reset-pass.title', 'Восстановление пароля')}
          </h2>
          <p className={b('description')}>
            {t('reset-pass.description', 'Введите новый пароль')}
          </p>
        </div>

        <div className={b('wrapper')}>
          <div className={b('fields')}>
            <FormInputWrapper<FormFields>
              name="password"
              type="password"
              control={control}
              disabled={isLoading}
              autoComplete="current-password"
              placeholder={`${i18n.t('reset-pass.password', 'Пароль')}`}
              showError
            />
            <FormInputWrapper<FormFields>
              name="confirmPassword"
              type="password"
              placeholder={`${t('reg.modal.confirm-password', 'Подтвердите Пароль')}`}
              disabled={isLoading}
              autoComplete="new-password"
              control={control}
              showError
            />
          </div>
          <Button className={b('button')}
            isLoading={isLoading}
            type='submit'
          >
            {t('reset-pass.submit-btn', 'Изменить пароль')}
          </Button>
        </div>
      </form>
    </AppModal>
  );
};

export default ChangePassword;
