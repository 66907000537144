import React, { useCallback, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import NavMenu, { MenuItem } from 'components/shared/NavMenu/NavMenu';
import { TransactionsRoute } from 'utils/routes';
import i18n from 'i18n/i18n';
import { bemCn } from 'utils/bem-cn';

import TransactionsPage from './TransactionsPage/TransactionsPage';
import DepositsPage from './DepositsPage/DepositsPage';
import WidthrawalPage from './WidthrawalPage/WidthrawalPage';
import BetsPage from './BetsPage/BetsPage';
import TransferPage from './TransferPage/TransferPage';
import SlotsPage from './SlotsPage/SlotsPage';


import './Transactions.scss';

const menuItems: MenuItem[] = [
  {
    link: '/profile/transactions',
    title: i18n.t('transactions.all', 'Все'),
    id: 0,
  },
  {
    link: 'deposits',
    title: i18n.t('transactions.deposits', 'Пополнения'),
    id: 1,
  },
  {
    link: 'widthrawal',
    title: i18n.t('transactions.withdrawal', 'Вывод'),
    id: 2,
  },
  {
    link: 'bets',
    title: i18n.t('transactions.bets', 'Ставки'),
    id: 3,
  },
  {
    link: 'transfer',
    title: i18n.t('transactions.transfer', 'Переводы'),
    id: 4
  },
  // {
  //   link: 'slots',
  //   title: i18n.t('transactions.slots', 'Слоты'),
  //   id: 5
  // }
];

const Transactions = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // TODO: Поменять этот нелепый роутинг
  const getRouteId = useCallback(() => {
    const activeItem = Object.values(menuItems)
      .find((item) => pathname === `/profile/transactions/${item.link}`);
    return activeItem?.id ?? 0;
  }, []);

  const [filter, setFiler] = useState<number>(getRouteId);

  const b = bemCn('transactions');

  return (
    <div className={b()}>
      <Breadcrumbs link="/profile" title={t('Profile', 'Личный кабинет')} />
      <h2 className={b('title')}>
        {t('transactions.title', 'Детализация')}
      </h2>
      <div className={b('nav')}>
        <NavMenu
          menuItems={menuItems}
          activeItemId={filter}
          onClickLink={(id) => setFiler(id)}
        />
      </div>
      <div className={b('content')}>
        <Routes>
          <Route index
            element={<TransactionsPage />}
          />
          <Route path={TransactionsRoute.Deposits}
            element={<DepositsPage />}
          />
          <Route path={TransactionsRoute.Widthrawal}
            element={<WidthrawalPage />}
          />
          <Route path={TransactionsRoute.Bets}
            element={<BetsPage />}
          />
          <Route path={TransactionsRoute.Transfer}
            element={<TransferPage />}
          />
          {/* <Route path={TransactionsRoute.Slots}
            element={<SlotsPage />}
          /> */}
        </Routes>
      </div>
    </div>
  );
};

export default Transactions;
