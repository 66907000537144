import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';

import { usePartnerProjects } from '../hooks/usePartnerProjects';

import '../PPPartner.scss';

type TFormData = {
  name: string;
};

type Props = {
  onModalClose: () => void;
};

const PPPartnerNewProject = (props: Props) => {
  const {
    onModalClose,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    createPartnerProject,
  } = usePartnerProjects();

  const { register, handleSubmit } = useForm<TFormData>();

  const onSubmit: SubmitHandler<TFormData> = (data) => {
    createPartnerProject(data);
    onModalClose();
  };

  return (
    <div className={b('add-modal')}>
      <h3>{t('enter-project-name', 'Задайте имя нового проекта')}</h3>
      <form
        className={b('add-modal-form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className={b('add-modal-item')}>
          <label htmlFor="name" className={b('add-modal-label')}>
            {t('project-name', 'Имя проекта')}:
          </label>
          <input
            type="text"
            id="name"
            className={b('add-modal-input')}
            required
            {...register('name')}
          />
        </p>

        <button
          type="submit"
          className={b('add-modal-btn')}
        >
          {t('add', 'Добавить')}
        </button>
      </form>
    </div>
  );
};

export default PPPartnerNewProject;
