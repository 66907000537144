import type {
  TTopupMethodsByCurrency,
  TTopupMethodsServer,
  TWithdrawalMethodsByCurrency,
  TWithdrawalMethodsServer
} from 'types/payments-data';

export const adapteTupupMethods = (res: TTopupMethodsServer): TTopupMethodsByCurrency => {
  if (!res) {
    return {};
  }

  const methods: TTopupMethodsByCurrency = {};

  Object.entries(res)
    .forEach(([system, systemMethods]) => ({
      id: system,
      methods: Object.entries(systemMethods)
        .forEach(([methodName, methodData]) => {
          Object.entries(methodData.limits)
            .forEach(([currency, [minAmount, maxAmount]]) => {
              methods[currency] = methods[currency] ?? [];
              if (methodData.is_active) {
                methods[currency].push({
                  id: `${system}:${methodName}`,
                  name: methodName,
                  title: methodData.displayed_name,
                  system: system,
                  currency,
                  isActive: methodData.is_active,
                  minAmount,
                  maxAmount,
                  priority: methodData.priority ?? 0,
                });
              }
            });
        })
    }));

  Object.keys(methods).forEach((currency) => {
    methods[currency] = methods[currency]
      .sort((a, b) => b.priority - a.priority);
  });

  return methods;
};

export const adapteWithdrawalMethods = (res: TWithdrawalMethodsServer): TWithdrawalMethodsByCurrency => {
  if (!res) {
    return {};
  }

  const methods: TWithdrawalMethodsByCurrency = {};

  Object.entries(res)
    .forEach(([methodName, methodData]) => {
      Object.entries(methodData.limits)
        .forEach(([currency, [minAmount, maxAmount]]) => {
          methods[currency] = methods[currency] ?? [];
          if (methodData.is_active) {
            methods[currency].push({
              id: methodName,
              name: methodName,
              title: methodData.displayed_name,
              isActive: methodData.is_active,
              currency,
              minAmount,
              maxAmount,
              priority: methodData.priority ?? 0,
            });
          }
        });
    });

  Object.keys(methods).forEach((currency) => {
    methods[currency] = methods[currency]
      .sort((a, b) => b.priority - a.priority);
  });

  return methods;
};
