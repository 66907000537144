import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import ScrollToTop from 'components/Layout/ScrollToTop/ScrollToTop';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import Loader from 'components/shared/loader/Loader';
import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useCreatePragmaticSessionMutation, useGetPragmaticJackpotsQuery, useGetPragmaticLobbyGamesQuery } from 'store/slots/slotsApi';
import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/buttons/Button/Button';
import ImgSmart from 'components/shared/ImgSmart/ImgSmart';
import { selectPrimaryWallet, selectPrimaryWalletCurrency } from 'store/user/selectors';
import { getCurrencySymbol } from 'utils/currency';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { domainName } from 'utils/constants';
import JackpotCard from 'components/casino/components/JackpotCard/JackpotCard';
import './PlayGame.scss';

const PragmaticPlay = () => {
  const currency = useAppSelector(selectPrimaryWalletCurrency);
  const wallet = useAppSelector(selectPrimaryWallet);
  const [isGameSelected, setGameSelected] = useState(false);
  const { gameId = '' } = useParams();
  const { t, i18n } = useTranslation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [createSession, { data: url, isLoading }] = useCreatePragmaticSessionMutation();
  const { gameData } = useGetPragmaticLobbyGamesQuery({
    category: 'all',
    gameId
  }, {
    skip: !gameId,
    selectFromResult: (res) => ({
      gameData: res.data?.find((item) => item.id === gameId) ?? null,
      isGameDataLoading: res.isLoading,
      isGameDataFetching: res.isFetching,
      isGameDataSuccess: res.isSuccess,
      isGameDataError: res.isError,
    }),
  });

  const { data: jackpots = [] } = useGetPragmaticJackpotsQuery();
  const isLoaded = gameId && url && !isLoading && isGameSelected;
  const jackpot = jackpots?.[0]?.tiers ?? [];

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    const tabBar = document.querySelector('.tab-bar');
    if (tabBar) {
      tabBar.classList.add('visually-hidden');
    }
    return () => {
      document.body.style.overflowY = '';
      if (tabBar) {
        tabBar.classList.remove('visually-hidden');
      }
    };
  }, []);

  const startDemoGame = () => {
    if (isAuthenticated && gameId) {
      setGameSelected(true);
      createSession({
        gameId,
        lang: i18n.resolvedLanguage,
        balanceType: 'real',
        isDemo: true,
        lobbyUrl: `${domainName}/games?filter=Pragmatic+Play`
      });
    }
  };

  const startRealGame = () => {
    if (isAuthenticated && gameId) {
      setGameSelected(true);
      createSession({
        gameId,
        lang: i18n.resolvedLanguage,
        balanceType: 'real',
        lobbyUrl: `${domainName}/games?filter=Pragmatic+Play`
      });
    }
  };

  // const startBonusGame = () => {
  //   if (isAuthenticated && gameId) {
  //     setGameSelected(true);
  //     createSession({
  //       gameId,
  //       lang: i18n.resolvedLanguage,
  //       balanceType: 'bonus',
  //       lobbyUrl: `${domainName}/games?filter=Pragmatic+Play`
  //     });
  //   }
  // };

  const renderPreview = () => typeof gameData?.preview === 'string' ? (
    <img className={b('select-game-preview')}
      src={gameData.preview} alt=""
    />
  ) : (
    <img className={b('select-game-preview')}
      src={gameData?.preview?.src} alt=""
    />
  );


  const b = bemCn('play-game');

  return (
    <div className={b()}>
      {/* <Breadcrumbs
        className='betgames__breadcrumbs'
        link="/games"
        title={t('games.list', 'Список игр')}
      />
      <h1 className='betgames__title'>{gameData?.name}</h1> */}
      <ScrollToTop />

      <div className={b('container')}>
        <div className={b('jackpots')}>
          {jackpot.slice(0, 4).map((item, i) => (
            <JackpotCard className={b('jackpot-item')}
              key={`${item.tier}-${item.amount}`}
              amount={`${getCurrencySymbol(currency)} ${item.amount}`}
              tier={item.tier}
            />
          ))}
        </div>
        {isGameSelected ? (
          <Loader className={b('loader')}
            wrapperClassName={b('loader-wrapper')}
          />
        ) : null}

        {isLoaded && (
          <iframe className={b('content')}
            title='PargmaticPlay'
            scrolling='no'
            allow="fullscreen"
            frameBorder="0"
            src={url}
          />
        )}
        <Link className={b('back')} to='/games?filter=Pragmatic+Play' >
          <SvgIcon name='chevron-right' className={b('back-icon')} />
          {t('games.list', 'Список игр')}
        </Link>

        {!isGameSelected ? (
          <div className={b('modal', b('select-game'))}>
            {renderPreview()}
            <Button type="button" className={b('select-game-btn')} onClick={startRealGame}>
              {t('games.play-for-money', 'Играйте на деньги')} {getCurrencySymbol(wallet.currency)}
            </Button>
            {/* {wallet.bonusAmount > 0 ? (
              <Button type="button" className={b('select-game-btn')} onClick={startBonusGame} variant="secondary">
                {t('games.play-for-bonuses', 'Играйте на бонусы')}  {getCurrencySymbol(wallet.currency)}
              </Button>
            ) : null} */}
            <Button type="button" className={b('select-game-btn')}
              onClick={startDemoGame} variant="shaded"
            >
              {t('games.play-the-demo', 'Играйте в демо')}
            </Button>
          </div>
        ) : null}

      </div>
    </div>
  );
};

export default PragmaticPlay;
