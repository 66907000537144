import lucky6 from './bet6.jpg';
import lucky6Webp from './bet6.webp';
import blackjack from './blackjack.jpg';
import blackjackWebp from './blackjack.webp';
import fastkeno from './fastkeno.jpg';
import fastkenoWebp from './fastkeno.webp';
import keno from './keno.jpg';
import kenoWebp from './keno.webp';
import bet5 from './lucky5.jpg';
import bet5Webp from './lucky5.webp';
import bet7 from './lucky7.jpg';
import bet7Webp from './lucky7.webp';
import onebet from './onebet.jpg';
import onebetWebp from './onebet.webp';
import poker from './poker.jpg';
import pokerWebp from './poker.webp';
import warofElements from './warofbets.jpg';
import warofElementsWebp from './warofbets.webp';
import wheel from './wheel.jpg';
import wheelWebp from './wheel.webp';
import roulette from './el-casino.jpg';

export default {
  lucky6: {
    src: lucky6,
    webp: lucky6Webp,
  },
  blackjack: {
    src: blackjack,
    webp: blackjackWebp,
  },
  fastkeno: {
    src: fastkeno,
    webp: fastkenoWebp,
  },
  keno: {
    src: keno,
    webp: kenoWebp,
  },
  bet5: {
    src: bet5,
    webp: bet5Webp,
  },
  bet7: {
    src: bet7,
    webp: bet7Webp,
  },
  onebet: {
    src: onebet,
    webp: onebetWebp,
  },
  poker: {
    src: poker,
    webp: pokerWebp,
  },
  warofElements: {
    src: warofElements,
    webp: warofElementsWebp,
  },
  wheel: {
    src: wheel,
    webp: wheelWebp,
  },
  roulette: {
    src:roulette,
  }
};
