import React, { useState } from 'react';

import { getCurrencySymbol, getCurrencyTitle } from 'utils/currency';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { useResponsive } from 'hooks/useResponsive';

import WalletOperationModal from './WalletOperationModal/WalletOperationModal';

import type { Wallet } from 'types/wallets-data';

import './AddedWalletItem.scss';

type Props = {
  wallet: Wallet;
};

const AddedWalletItem = ({ wallet }: Props) => {
  const { Desktop } = useResponsive();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="added-wallet__item profile__section">
      <div className="added-wallet__symbol">
        <p>{getCurrencySymbol(wallet.currency)}</p>
      </div>
      <div className="added-wallet__info">
        <p className='added-wallet__name'>
          {getCurrencyTitle(wallet.currency)}
        </p>
        <p className='added-wallet__amount'>
          {wallet.amount}
          <Desktop>
            <span>{getCurrencySymbol(wallet.currency)}</span>
          </Desktop>
        </p>
      </div>
      <button className="added-wallet__more" type="button"
        onClick={() => setIsOpen(true)}
      >
        <SvgIcon className="added-wallet__more-icon"
          name="dots-menu" width={10} height={10}
        />
      </button>
      <div />

      <WalletOperationModal
        isOpen={isOpen}
        onClose={closeModal}
        wallet={wallet}
      />
    </div>
  );
};

export default AddedWalletItem;
