import React from 'react';
import cn from 'classnames';

import Loader from 'components/shared/loader/Loader';
import { getCurrencyTitle } from 'utils/currency';

import type { Currency } from 'types/wallets-data';

import './CurrencyOption.scss';

type Props = {
  value: Currency | null;
  isActive?: boolean;
  isLoading?: boolean;
};

const CurrencyOption = ({ value, isActive, isLoading }: Props) => {
  const isLoaderDisplayed = isActive && isLoading;
  const isFlagDisplayed = (isActive && !isLoading && value) || !isActive;

  return (
    <>
      {isLoaderDisplayed && <Loader className="currency-selector__flag" />}
      {isFlagDisplayed && (
        <div className={cn(
          'currency-selector__flag',
          value && `currency-selector__flag--${value}`,
        )}
        />
      )}
      <span
        className={cn('currency-selector__value', { 'currency-selector__value--default': !value })}
      >
        {getCurrencyTitle(value)}
      </span>
    </>
  );
};

export default React.memo(CurrencyOption);
