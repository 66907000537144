import type { Currency } from 'types/wallets-data';

export type AuthModalType = 'sign-in' | 'sign-up' | 'confirm-email' | 'confirm-phone' | 'success' | 'error' | 'reset-password' | 'confirm-reset-password-sms'
  | 'change-password' | 'reset-error' | 'reset-success' | 'success-send-reset-link'
  | 'detailing' | 'settings' | 'topups' | 'withdrawals-modal' | 'wallet-manage' | 'transfer-to-user' | 'promocodes';

export type LoginErrors = {
  email: string | null;
  password: string | null;
  common: string | null;
};

export type RegisterEmailErrors = {
  email: string | null;
  password: string | null;
  name: string | null;
  common: string | null;
  promocode: string | null;
};
export type RegisterTelegramErrors = {
  name: string | null;
  password: string | null;
  telegram_nickname: string | null;
  common: string | null;
  promocode: string | null;
}

export type RegisterPhoneErrors = {
  phone: string | null;
  password: string | null;
  common: string | null;
  promocode: string | null;
  confirmPassword: string | null;
}
export interface LoginRequest {
  login: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
  token_type: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
  name: string;
  currency: Currency;
  ref_code?: string | null;
  click_id?: string | null;
  web_id?: string | null;
  promocode?: string | null;
}

export interface RegisterResponse {
  success: boolean;
  message?: string;
  data?: unknown;
}

export interface ChangePasswordParams {
  password: string;
  passwordResetId: string;
}

export enum Status {
  Unknown = 'UNKNOWN',
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE',
}

export type AppStatus = {
  system: Status;
  line: Status;
  betgames: Status;
  tgg: Status;
  inbet: Status;
  vegas: Status;
  mascot: Status;
  agt: Status;
  withdrawals: Status;
  pragmatic: Status;
  smartsoft: Status;
  tvbet: Status;
  aviatrix: Status;
  systemMessage: {
    enable: boolean;
    message: string;
  };
}

export type PhoneRegParams = {
  'phone': string;
  'name'?: string;
  'password': string;
  'currency': string;
  'ref_code'?: string | null;
  'click_id'?: string | null;
  'phone_country': string;
  'web_id'?: string | null;
  promocode?: string | null;
}

export type FetchErorr = {
  status: string;
  data: { detail: string };
}

export type PasswordResetVerifyRespons = {
  data: {
    password_reset_id: string;
  };
};
