import { bemCn } from 'utils/bem-cn';

import FooterNavList from './FooterNavList';

import type { FooterListItem } from './FooterNavItem';

import './FooterNav.scss';

type GroupListItems = {
  groupTitle: string;
  items: FooterListItem[];
}

type Props = {
  className?: string;
  navItems: GroupListItems[];
}

const FooterNav = ({ className, navItems }: Props) => {
  const b = bemCn('footer-nav');

  return (
    <div className={b(null, className)}>
      {navItems.map((group, index) => {
        const keyVal = `${group.groupTitle}-${index}`;
        return (
          <div className={b('group')} key={keyVal}>
            <p className={b('group-title')}>{group.groupTitle}</p>
            <FooterNavList items={group.items} b={b} />
          </div>
        );
      })}
    </div>
  );
};

export default FooterNav;
