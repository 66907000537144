import React, { useState } from 'react';

import Loader from 'components/shared/loader/Loader';
import Flag from 'components/shared/Flag/Flag';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import { useGetFavoriteTournamentsQuery } from 'store/user/userApi';
import { sortByTopEntries } from 'utils/common';
import { AddFavoriteTournamentBody } from 'types/user-data';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import TournamentItem from './TournamentItem/TournamentItem';

import type { CountryItem as ICountryItem, TournamentItem as TournamentItemType } from 'types/line-data';

import './CountryItem.scss';

type Props = {
  country: ICountryItem;
  tournaments: TournamentItemType[];
  isLoading: boolean;
  sportId: string;
  sportName: string;
};

const b = bemCn('country-item');
const CountryItem = ({ country, tournaments = [], isLoading, sportId, sportName }: Props) => {
  const { id, name } = country;
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteTournamentList = [], isLoading: isFavoriteLoading } = useGetFavoriteTournamentsQuery(undefined, { skip: !isAuth });
  const favoriteTournamentsId = favoriteTournamentList.map((tournament) => tournament.tournamentId);
  const sortedTournaments = sortByTopEntries(tournaments, favoriteTournamentsId, (item) => item.id);

  const onItemClick = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div className={b()}>
      <div className={b('wrapper')} onClick={onItemClick}>
        <div className={b('title-wrapper')}>
          <Flag countryId={id} />
          <p className={b('title')}>{name}</p>
        </div>
        <SvgIcon className={b('icon')} name='chevron-right' />
      </div>
      {!isCollapsed && (
        <div className={b('tournaments')}>
          {isLoading || isFavoriteLoading
            ? <Loader />
            : sortedTournaments.map((tournament) => {
              const isFavorite = favoriteTournamentsId.includes(tournament.id);
              const tournamentInfo: AddFavoriteTournamentBody = {
                tournamentId: tournament.id,
                tournamentName: tournament.name,
                countryId: id,
                countryName: name,
                sportId: sportId,
                sportName: sportName
              };

              return (
                <TournamentItem
                  key={tournament.id}
                  tournament={tournament}
                  sportId={sportId}
                  isFavorite={isFavorite}
                  tournamentInfo={tournamentInfo}
                  favoriteId={favoriteTournamentList.find((favTour) => favTour.tournamentId === tournament.id)?.id ?? 'notFavorite'}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default CountryItem;
