import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';

import PromocodeActivate from './PromocodeActivate/PromocodeActivate';

import './Promocodes.scss';

const Promocodes = () => {
  const { t } = useTranslation();
  const b = bemCn('profile-promocodes');
  return (
    <div className={b()}>
      <Breadcrumbs link="/profile" title={t('main.nav.Profile', 'Личный кабинет')} />
      <h3 className={b('header')}>
        {t('promocodes.activate.title', 'Активация промокода')}
      </h3>
      <hr className={b('separator')} />

      <div className={b('section')}>
        <PromocodeActivate />
      </div>
    </div>
  );
};

export default Promocodes;
