import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';
import CloseButton from 'components/shared/CloseButton/CloseButton';

import './PPModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
};

const PPModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    children,
    title,
  } = props;

  const b = bemCn('pp-modal');

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onClose}
      className={b()}
      contentLabel={title}
      closeButton={
        <CloseButton className={b('close-btn')} onClick={onClose} />
      }
    >
      {children}
    </AppModal>
  );
};

export default PPModal;
