import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';
import { ReactComponent as NavIcon } from 'assets/icons/icon-nav.svg';

import './BurgerPersonalInfo.scss';

const b = bemCn('burger-personal-info');
function BurgerPersonalInfo() {
  const { t } = useTranslation();
  const { name } = useAppSelector(selectAccountData);

  return (
    <Link to={'/profile'} className={b()}>
      <div className={b('left-container')}>
        <div className={b('avatar')}>
          <p className={b('avatar-literals')}>
            {name.substring(0, 1)}
          </p>
        </div>
        <div className={b('info-wrapper')}>
          <span className={b('info-title')}>
            {t('burger.profile-info.title')}
          </span>
          <span className={b('personal-name')}>
            {name}
          </span>
        </div>
      </div>
      <NavIcon />
    </Link>
  );
}

export default BurgerPersonalInfo;
