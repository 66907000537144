import React from 'react';

import Adaptive from 'components/Layout/Adaptive/Adaptive';

import HomeMobile from './HomeMobile/HomeMobile';
import HomeDesktop from './HomeDesktop/HomeDesktop';

type Props = {
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

const Home = (props: Props) => (
  <Adaptive
    desktop={<HomeDesktop />}
    mobile={<HomeMobile />}
  />
);

export default Home;
