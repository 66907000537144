import { bemCn } from 'utils/bem-cn';
import './StatusIcon.scss';

type Props = {
  status: string;
  className?: string;
}

const StatusIcon = ({ status, className }: Props) => {
  const b = bemCn('status-icon');

  return (
    <span className={b({ status }, className)} />
  );
};


export default StatusIcon;
