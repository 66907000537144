import { bemCn } from 'utils/bem-cn';
// import { useGetCurrenciesQuery } from 'store/app/appApi';
// import { useAppDispatch, useAppSelector } from 'hooks';
// import { selectPartnerCurrency } from 'store/partnerProgram/selectors';
// import { setPartnerSelectedCurrency } from 'store/partnerProgram/partnerProgramSlice';
// import Loader from 'components/shared/loader/Loader';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import './PPHeader.scss';

// import type { Currency } from 'types/wallets-data';
// import type { ChangeEventHandler } from 'react';

type Props = {
  title: string;
  role: string;
};

const PPHeader = (props: Props) => {
  const {
    title,
    role
  } = props;

  const b = bemCn('pp-header');
  // const dispatch = useAppDispatch();
  // const selectedCurrency = useAppSelector(selectPartnerCurrency);

  // const {
  //   data: availableCurrenciesData = [],
  //   isLoading: isCurrenciesLoading,
  // } = useGetCurrenciesQuery();

  // const handleCurrencySubmit: ChangeEventHandler<HTMLSelectElement> = (event) => {
  //   // console.log('data:', event.target.value);
  //   dispatch(setPartnerSelectedCurrency({ currency: event.target.value }));
  // };

  // if (isCurrenciesLoading || !availableCurrenciesData) {
  //   return (
  //     <Loader />
  //   );
  // }

  return (
    <div className={b()}>
      <h1 className={b('title')}>
        {title}
      </h1>

      {/* <div className={b('currency')}>
        <select
          className={b('select')}
          onChange={handleCurrencySubmit}
          value={selectedCurrency}
        >
          {availableCurrenciesData.map((currency) => (
            <option
              className={b('option')}
              key={currency}
              value={currency}
            >
              {currency}
            </option>
          ))}
        </select>

      </div> */}

      <p className={b('role')}>{role}</p>
    </div>
  );
};

export default PPHeader;
