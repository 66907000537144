import { api } from 'store/api';
import { APIRoute } from 'utils/routes';

import { detectPhoneZero, transformPasswordSmsVerify } from './utils';

import type {
  LoginResponse,
  LoginRequest,
  RegisterResponse,
  RegisterRequest,
  ChangePasswordParams,
  PhoneRegParams
} from 'types/auth-data';

type RegTelegramParams = {
  'telegram_nickname': string;
  'name': string;
  'password': string;
  'currency': string;
  'ref_code'?: string | null;
  'click_id'?: string | null;
  'web_id'?: string | null;
  'promocode'?: string | null;
}

type RegTelegramResp = {
  'telegram_bot_url': string;
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (params: LoginRequest) => ({
        url: APIRoute.Login,
        method: 'POST',
        body: {
          ...params,
          login: detectPhoneZero(params.login)
        },
      }),
      invalidatesTags: (result) => result ? ['Auth'] : [],
    }),

    logout: build.mutation<void, void>({
      query: () => ({
        url: 'v1/auth/logout',
        method: 'POST'
      })
    }),

    emailRegister: build.mutation<RegisterResponse, RegisterRequest>({
      query: (data) => ({
        url: APIRoute.Register,
        method: 'POST',
        body: data,
      }),
    }),

    registrationTelegram: build.mutation<RegTelegramResp, RegTelegramParams>({
      query: (params) => ({
        url: 'v1/auth/register/telegram',
        method: 'POST',
        body: params
      })
    }),
    phoneRegistration: build.mutation<void, PhoneRegParams>({
      query: (params) => ({
        url: 'v1/auth/register/phone',
        method: 'POST',
        body: {
          ...params,
          phone: detectPhoneZero(params.phone)
        }
      })
    }),

    emailVerify: build.query<unknown, string>({
      query: (id) => ({
        url: `${APIRoute.VerifyEmail}/${id}`,
        method: 'POST',
      }),
    }),

    phoneVerify: build.mutation<unknown, string>({
      query: (verifyCode) => ({
        url: `v1/auth/login/phone/verify/${verifyCode}`,
        method: 'POST'
      })
    }),

    resendPhoneRegSms: build.mutation<unknown, string>({
      query: (phone) => ({
        url: 'v1/auth/login/phone/verify/repeat_sms',
        method: 'POST',
        params: { 'phone': detectPhoneZero(phone) }
      })
    }),

    passwordPhoneVerify: build.mutation<string, string>({
      query: (verifyCode) => ({
        url: `v1/auth/login/password/reset/sms/${verifyCode}`,
        method: 'GET',
      }),
      transformResponse: transformPasswordSmsVerify
    }),

    passwordResetSms: build.mutation<unknown, string>({
      query: (phone) => ({
        url: 'v1/auth/login/password/reset_phone',
        method: 'GET',
        params: { 'phone': detectPhoneZero(phone) }
      }),
    }),

    changePassword: build.mutation<unknown, ChangePasswordParams>({
      query: (params) => ({
        url: `${APIRoute.ResetPass}/${params.passwordResetId}`,
        method: 'POST',
        params: {
          'new_password': params.password,
        }
      }),
    }),

    clickRefCode: build.mutation<unknown, string>({
      query: (refCode) => ({
        url: `${APIRoute.ReferCode}/${refCode}`,
        method: 'POST'
      }),
    })
  }),
});

export const {
  useLoginMutation,
  useEmailRegisterMutation,
  useEmailVerifyQuery,
  useRegistrationTelegramMutation,
  usePasswordResetSmsMutation,
  useChangePasswordMutation,
  useClickRefCodeMutation,
  useLogoutMutation,
  usePhoneRegistrationMutation,
  usePhoneVerifyMutation,
  useResendPhoneRegSmsMutation,
  usePasswordPhoneVerifyMutation
} = authApi;
