import { useGetAppSportsQuery } from 'store/app/appApi';
import { useGetBetapiEventsByTourneyQuery } from 'store/betapi/betapi';
import { GetBetapiEventsListParams } from 'store/betapi/types';
import { TournamenWithEvents, sortByTopEntries } from 'utils/common';

const tournamentSortName: keyof TournamenWithEvents = 'nameRu';

const useFilteredEventsByTourney = (
  arg: GetBetapiEventsListParams,
  opt?: {
    skip?: boolean;
    pollingInterval?: number;
  },
) => {
  const { data } = useGetAppSportsQuery();
  const result = useGetBetapiEventsByTourneyQuery(arg, opt);

  const tournamentsBlacklist = data?.tournamentsBlackList ?? [];
  const sportsBlacklist = data?.sportsBlacklist ?? [];

  const filteredTournaments = result.data?.filter((tournament) =>
    tournament.events.length > 0
    && ![tournament.name, tournament.nameRu, tournament.nameEn]
      .some((item) => tournamentsBlacklist.includes(item.toLowerCase()))
    && ![tournament.sportName, tournament.sportNameRu, tournament.sportNameEn]
      .some((item) => sportsBlacklist.includes(item.toLowerCase()))
  );

  const sortedData = sortByTopEntries(
    filteredTournaments ?? [],
    data?.tournamentsPriority ?? [],
    (item) => item[tournamentSortName].toLowerCase()
  );

  return {
    ...result,
    data: sortedData,
  };
};

export { useFilteredEventsByTourney };
