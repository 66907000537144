import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import TransferForm from './TransferForm/TransferForm';

import './TransferModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TransferModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { amount: balance, currency } = useAppSelector(selectPrimaryWallet);

  const b = bemCn('transfer-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <h2 className={b('title')}>
        {t('transfer.modal.title', 'Перевод')}
      </h2>
      <p className={b('description')}>
        {t('transfer.modal.description', 'Перевод по ID')}
      </p>
      <TransferForm balance={balance} currency={currency} />
    </AppModal>
  );
};

export default TransferModal;
