import { whatsappLink } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/buttons/Button/Button';
import SiteSettings from 'components/Layout/PageFooter/components/SiteSettings/SiteSettings';
import MainNav from 'components/Layout/MainNav/MainNav';
import Logotype from 'components/shared/Logotype/Logotype';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import UserblockDesktop from '../UserblockDesktop/UserblockDesktop';

import './DesktopHeader.scss';

type Props = {
  isAuthenticated: boolean;
};

const DesktopHeader = ({ isAuthenticated }: Props) => {
  const b = bemCn('main-header-desktop');
  return (
    <div className={b()}>
      <div className={b('top')}>
        <div className={b('left')}>
          <Logotype className={b('logo')} />
          {/* <MainNav /> */}
        </div>
        <div className={b('buttons-group')}>
          <Button className={b('support')} variant="shaded" >
            <a href={whatsappLink} target='_blank' rel="noreferrer">
              <SvgIcon name='support-tg' width={24} height={24} />
            </a>
          </Button>
          <UserblockDesktop className={b('user-block')}
            isAuthenticated={isAuthenticated}
          />
          {/* <SiteSettings below /> */}
        </div>
      </div>

      <MainNav />
    </div>
  );
};

export default DesktopHeader;
