import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavMenu, { MenuItem } from 'components/shared/NavMenu/NavMenu';
import i18n from 'i18n/i18n';
import AppModal from 'components/shared/AppModal/AppModal';
import { bemCn } from 'utils/bem-cn';

import TransactionsPage from '../Transactions/TransactionsPage/TransactionsPage';
import DepositsPage from '../Transactions/DepositsPage/DepositsPage';
import WidthrawalPage from '../Transactions/WidthrawalPage/WidthrawalPage';
import BetsPage from '../Transactions/BetsPage/BetsPage';
import TransferPage from '../Transactions/TransferPage/TransferPage';
import SlotsPage from '../Transactions/SlotsPage/SlotsPage';
import ModalsHead from '../ModalsHead/ModalsHead';


import './DetailingModal.scss';

const menuItems: (MenuItem & { component: JSX.Element })[] = [
  {
    link: '',
    title: i18n.t('transactions.all', 'Все'),
    id: 0,
    component: <TransactionsPage />
  },
  {
    link: '',
    title: i18n.t('transactions.deposits', 'Пополнения'),
    id: 1,
    component: <DepositsPage />
  },
  {
    link: '',
    title: i18n.t('transactions.withdrawal', 'Вывод'),
    id: 2,
    component: <WidthrawalPage />
  },
  {
    link: '',
    title: i18n.t('transactions.bets', 'Ставки'),
    id: 3,
    component: <BetsPage />
  },
  {
    link: '',
    title: i18n.t('transactions.transfer', 'Переводы'),
    id: 4,
    component: <TransferPage />
  },
  // {
  //   link: '',
  //   title: i18n.t('transactions.slots', 'Слоты'),
  //   id: 5,
  //   component: <SlotsPage />
  // }
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const b = bemCn('detailing-modal');
const DetailingModal = (props: Props) => {
  const { isOpen, onClose, } = props;
  const [filter, setFiler] = useState<number>(0);
  const { t } = useTranslation();

  return (
    <AppModal isOpen={isOpen} onClose={onClose} className={b()} >
      <ModalsHead title={t('transactions.title', 'Детализация')} />
      <div className={b('menu')}>
        <NavMenu menuItems={menuItems} activeItemId={filter} onClick={(id) => setFiler(id)} />
      </div>
      <div className={b('list')}>
        {menuItems.find((item) => item.id === filter)?.component}
      </div>
    </AppModal>
  );
};

export default DetailingModal;
