import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { moveToStart } from 'utils/common';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import CurrencyOption from './CurrencyOption/CurrencyOption';

import type { MouseEvent, KeyboardEvent } from 'react';
import type { Currency } from 'types/wallets-data';

import './Selector.scss';

declare module 'react' {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type SelectType = 'currency' | 'default';

type Props<T> = {
  value?: T | null;
  options: T[];
  onChange?: (value: T) => void;
  onBlur?: () => void;
  error?: { message?: string };
  showError?: boolean;
  isTouched?: boolean;
  name?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type: SelectType;
};

const Selector = <T,>(props: Props<T>, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    value,
    options,
    onChange,
    onBlur,
    error,
    showError,
    isTouched,
    disabled,
    name,
    isLoading,
    type,
  } = props;

  const [selectedOption, setSelectedOption] = useState<T | null>(null);
  const [isOptionsDisplay, setOptionsDisplay] = useState<boolean>(false);

  const optionsList: T[] = useMemo(() => moveToStart<T>(options, value), [value, options]);
  const isSingleItem = value && optionsList.length < 2;

  const handleBlur = () => {
    if (!disabled) { onBlur?.(); }
  };

  const handleListDisplay = () => {
    if (disabled || isSingleItem) { return; }
    setOptionsDisplay(!isOptionsDisplay);
  };

  const handleListDisplayKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleListDisplay();
    }
  };

  const handleOptionClick = (event: MouseEvent<HTMLLIElement | SVGSVGElement | HTMLDivElement> | KeyboardEvent<HTMLLIElement>) => {
    const target = event.currentTarget;
    const selected = target.getAttribute('data-name');
    setSelectedOption(selected as unknown as T);
    setOptionsDisplay(!isOptionsDisplay);
  };

  const handleOptionKeyDown = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      handleOptionClick(e);
    }
  };

  const handleClickOutside = () => {
    setOptionsDisplay(false);
  };

  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    if (selectedOption) {
      onChange?.(selectedOption);
    }
  }, [selectedOption]);

  const b = bemCn('selector');

  const optionsLayout = optionsList.map((option) => (
    <li className={b('option-item')}
      data-name={option}
      key={option as unknown as string}
      onClick={handleOptionClick}
      onKeyDown={handleOptionKeyDown}
      tabIndex={isOptionsDisplay ? 0 : 1}
      role="button"
    >
      {type === 'currency' && (
        <CurrencyOption isLoading={false} value={option as unknown as Currency} />
      )}
    </li>
  ));

  return (
    <>
      {showError && <ErrorBlock isDisplayed={isTouched && !isOptionsDisplay} message={error?.message} />}
      <div ref={wrapperRef} className={b({ [`${type}`]: !!type })}>
        <div
          className={b('selected-option', {
            single: !!isSingleItem,
            active: isOptionsDisplay,
            invalid: showError && isTouched && !!error,
            valid: showError && isTouched && !error,
            disabled: disabled,
          })}
          ref={ref}
          onClick={handleListDisplay}
          onKeyDown={handleListDisplayKeyDown}
          role="button"
          onBlur={handleBlur}
          tabIndex={0}
          key={name}
        >
          {type === 'currency' && (
            <CurrencyOption isLoading={isLoading} value={value as unknown as Currency} isActive />
          )}
        </div>
        {isOptionsDisplay && (
          <ul className={b('options-list')}>
            {optionsLayout}
          </ul>
        )}
        {!isSingleItem && !disabled && (
          <SvgIcon name='chevron-down' width={7} height={7}
            className={cn(
              'currency-selector__caret-icon',
              { 'currency-selector__caret-icon--rotated': isOptionsDisplay },
            )}
          />
        )}
      </div>
    </>
  );
};

const SelectorWithRef = React.forwardRef(Selector);
export default SelectorWithRef;
