import { useEffect } from 'react';

import { useAppDispatch } from 'hooks';
import { ClickId, WebId , saveClickId, saveWebId } from 'utils/localStorage';
import { setClickRegistration } from 'store/auth/authSlice';

type UseClickLogin = (clickId: ClickId, webId: WebId) => void;


export const useClickLogin: UseClickLogin = (clickId, webId) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (clickId) {
      saveClickId(clickId);
      saveWebId(webId);
      dispatch(setClickRegistration(true));
    }
  }, []);
};
