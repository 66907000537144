import React from 'react';
import cn from 'classnames';
import './Container.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const Container = ({ children, className }: Props) => (
  <div className={cn('app-container', className)}>
    {children}
  </div>
);

export default Container;
