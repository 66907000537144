import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';
import Flag from 'components/shared/Flag/Flag';

import ErrorBlock from '../ErrorBlock/ErrorBlock';
import './PhoneSelector.scss';

type Option = {
  code: string;
  country: string;
}

const availableInput = /^\d{0,20}$/;

type Props<TFormValues extends FieldValues, T> = {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  variant?: 'inverted';
  value?: string | null;
} & UseControllerProps<TFormValues>

const selectedOption: Option = {
  code: '+254',
  country: 'KE'
};

const b = bemCn('phone-selector');
const PhoneSelector = <TFormValues extends Record<string, unknown>, T>(props: Props<TFormValues, T>) => {
  const {
    className,
    disabled,
    placeholder,
    variant,
    value
  } = props;

  const { field, fieldState } = useController(props);
  const [isOpened, setOpened] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const selectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const value = field.value as string;
    const valueInput = value.replace(selectedOption.code, '');
    if (valueInput !== inputValue) {
      setInputValue(valueInput);
    }
  }, [field.value]);

  useEffect(() => {
    field.onChange(selectedOption.code + inputValue);
  }, [selectedOption.code]);

  useOnClickOutside(selectorRef, () => {
    if (isOpened) {
      setOpened(false);
    }
  });

  const handleInput: ChangeEventHandler<HTMLInputElement> = (evt) => {
    if (availableInput.test(evt.target.value)) {
      field.onChange(selectedOption.code + evt.target.value);
    }
  };

  const invalid = !!fieldState.error;
  const valid = !fieldState.error;
  const isTouched = fieldState.isTouched;

  return (
    <div className={b({
      invalid: isTouched && invalid,
      valid: isTouched && valid,
      inverted: variant === 'inverted'
    }, className)}
    >
      <ErrorBlock isDisplayed={invalid && isTouched} message={fieldState.error?.message} />
      <div className={b('wrapper')}>
        <div className={b('selector-wrapper')} ref={selectorRef}>
          <button className={b('selected-option')} type='button'>
            <Flag className={b('country-icon')} countryCode={selectedOption.country} />
            {selectedOption.code}
          </button>
        </div>
        <div className={b('input-wrapper')}>
          <input className={b('input')}
            type="tel"
            placeholder={placeholder ?? '9999999999'}
            value={value ?? inputValue}
            onChange={handleInput}
            onBlur={field.onBlur}
            disabled={disabled}
          />
        </div>
      </div>

    </div>
  );
};

export default PhoneSelector;
