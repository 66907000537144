
import SocialList from 'components/shared/SocialList/SocialList';
import { socialLinks } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import { Maintenance, type MaintenanceMessage } from 'utils/constants/maintenanceMessage';

import './SiteMaintenance.scss';

type Props = {
  message?: MaintenanceMessage;
}

const SiteMaintenance = ({ message = Maintenance.default }: Props) => {
  const b = bemCn('site-maintenance');
  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <h1 className={b('title')}>
          {message.title}
        </h1>
        <p className={b('info')}>
          {message.description}
        </p>
        <SocialList className={b('social')} items={socialLinks} />
      </div>
    </div>
  );
};

export default SiteMaintenance;
