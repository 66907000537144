import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import JackpotCard from 'components/casino/components/JackpotCard/JackpotCard';
import { getCurrencySymbol } from 'utils/currency';
import { selectPrimaryWalletCurrency } from 'store/user/selectors';
import { useAppSelector } from 'hooks';

import GamesCard, { GameCardItemType } from '../GamesCard/GamesCard';

import './GamesCardsWidgetDesktop.scss';

type Props = {
  className?: string;
  title: string;
  items: GameCardItemType[];
  linkToAll?: string;
  isAvailable?: boolean;
  notAvailableMessage?: string;
  jackpots?: {
    tier: number;
    amount: number;
  }[];
}

const GamesCardsWidgetDesktop = (props: Props) => {
  const { t } = useTranslation();
  const { notAvailableMessage = t('maintenance.gamesNotAvailable') } = props;
  const currency = useAppSelector(selectPrimaryWalletCurrency);
  const b = bemCn('games-cards-widget-desktop');
  return (
    <div className={b(null, props.className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <p className={b('title')}>{props.title}</p>
          <Link className={b('all')} to={props.linkToAll ?? '/games'}>
            {t('common.all', 'Все')}
            <SvgIcon className={b('all-icon')} name="arrow-right" width={9} height={9} />
          </Link>
        </div>
        {props.isAvailable && props.jackpots && props.jackpots.length ? (
          <div className={b('jackpots')}>
            {props.jackpots?.slice(0, 4).map((item, i) => (
              <JackpotCard className={b('jackpot-item')}
                key={`${item.tier}-${item.amount}`}
                tier={item.tier}
                amount={`${getCurrencySymbol(currency)} ${item.amount}`}
              />
            ))}
          </div>
        ) : null}
        {props.isAvailable !== undefined && !props.isAvailable
          ? <p>{notAvailableMessage ?? t('maintenance.gamesNotAvailable')}</p>
          : (
            <div className={b('list')}>
              {props.items.map((game) => (
                <GamesCard className={b('item')}
                  key={`${game.id ?? ''}-${game.name}`}
                  item={game}
                />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default GamesCardsWidgetDesktop;
