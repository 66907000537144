import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside, useToggle } from 'usehooks-ts';

import Button from 'components/shared/buttons/Button/Button';
import { changeActiveModal, logout } from 'store/auth/authSlice';
import { useLogoutMutation } from 'store/auth/authApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { useResponsive } from 'hooks/useResponsive';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import './UserNav.scss';


const UserNav = () => {
  const { Mobile, Desktop } = useResponsive();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [serverLogout] = useLogoutMutation();
  const accountData = useAppSelector(selectAccountData);
  const { t } = useTranslation();
  const [isOpened, toggleOpened, setIsOpened] = useToggle(false);
  const userBLockRef = useRef<HTMLDivElement>(null);

  const isUserPartner = accountData.role === 'manager' || accountData.role === 'partner';

  const handleLogoutClick = () => {
    serverLogout()
      .then(() => {
        dispatch(logout());
      });
  };

  useOnClickOutside(userBLockRef, () => {
    if (isOpened) {
      setIsOpened(false);
    }
  });

  const b = bemCn('user-nav');

  return (
    <div className={b()} ref={userBLockRef}>
      <Button className={b('menu-btn')} variant="shaded" onClick={toggleOpened}>
        <SvgIcon name="user" width={15} height={15} />
      </Button>
      {isOpened && (
        <div className={b('menu-down')}>
          <Desktop>
            <>
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => dispatch(changeActiveModal('withdrawals-modal'))}
              >
                {t('wallets.withdrawal', 'Вывод')}
              </button>
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => { navigate('/bet-history'); setIsOpened(false); }}
              >
                {t('profile.nav.bet-history', 'История ставок')}
              </button>
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => dispatch(changeActiveModal('detailing'))}
              >
                {t('profile.nav.transactions', 'Детализация')}
              </button>
              <button
                type="button"
                className={b('menu-down-button')}
                onClick={() => dispatch(changeActiveModal('promocodes'))}
              >
                {t('profile.nav.promo-codes', 'Промокоды')}
              </button>
              {isUserPartner
                && (
                  <button
                    type="button"
                    className={b('menu-down-button')}
                    onClick={() => { navigate('/partner-program'); setIsOpened(false); }}
                  >
                    {t('profile.nav.partner-program', 'Партнёрская программа')}
                  </button>
                )}
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => dispatch(changeActiveModal('settings'))}
              >
                {t('profile.nav.settings', 'Настройки')}
              </button>
            </>
          </Desktop>
          <Mobile>
            <>
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => { navigate('/profile/withdrawals'); setIsOpened(false); }}
              >
                {t('wallets.withdrawal', 'Вывод')}
              </button>
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => { navigate('/bet-history'); setIsOpened(false); }}
              >
                {t('profile.nav.bet-history', 'История ставок')}
              </button>
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => { navigate('/profile/transactions'); setIsOpened(false); }}
              >
                {t('profile.nav.transactions', 'Детализация')}
              </button>
              <button
                type="button"
                className={b('menu-down-button')}
                onClick={() => { navigate('/profile/promo-codes'); setIsOpened(false); }}
              >
                {t('profile.nav.promo-codes', 'Промокоды')}
              </button>
              {isUserPartner
                && (
                  <button
                    type="button"
                    className={b('menu-down-button')}
                    onClick={() => { navigate('/partner-program'); setIsOpened(false); }}
                  >
                    {t('profile.nav.partner-program', 'Партнёрская программа')}
                  </button>
                )}
              <button className={b('menu-down-button')}
                type="button"
                onClick={() => { navigate('/profile/settings'); setIsOpened(false); }}
              >
                {t('profile.nav.settings', 'Настройки')}
              </button>
            </>
          </Mobile>
          <button className={b('menu-down-button')}
            type="button"
            onClick={handleLogoutClick}
          >
            {t('user-nav.logout', 'Выйти')}
          </button>
        </div>
      )}
    </div >
  );
};

export default UserNav;
