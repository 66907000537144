import React from 'react';

import SectionIcon from 'components/shared/SectionIcon/SectionIcon';
import { bemCn } from 'utils/bem-cn';

import IconBox from '../IconBox/IconBox';
import WidgetHeader from './components/WidgetHeader/WidgetHeader';

import type { SiteSection } from 'types/siteSections';

import './PageHeader.scss';

type ControlPanel = {
  type: IconType;
  onClick?: () => void;
};

type WidgetPanel = {
  link: string;
  subtitle: string;
};

type Props = {
  id: SiteSection;
  icon?: React.ReactNode;
  title: string;
  controlPanel?: ControlPanel[];
  widgetPanel?: WidgetPanel;
};

type IconType = 'caret' | 'search' | 'star' | 'cancel';

const PageHeader = ({ id, title, controlPanel, widgetPanel, icon }: Props) => {
  const b = bemCn('page-header');
  return (
    <div className={b()}>
      <div className={b('cell', b('cell-left'))}>
        <div className={b('icon-wrapper')}>
          {icon ?? (
            <SectionIcon className={b('icon')}
              id={id}
              isBackgroundShowing
            />
          )}
        </div>
        <h1 className={b('title')}>
          {title}
        </h1>
      </div>
      <div className={b('cell', b('control-panel'))}>
        {controlPanel?.map((button) => (
          <IconBox
            key={button.type}
            type={button.type}
            onClick={button.onClick}
          />
        ))}
        {widgetPanel && (
          <WidgetHeader
            link={widgetPanel.link}
            subtitle={widgetPanel.subtitle}
          />
        )}
      </div>
    </div>
  );
};

export default PageHeader;
