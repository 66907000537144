import { forwardRef } from 'react';

import { bemCn } from 'utils/bem-cn';

import './CustomCheckbox.scss';

const b = bemCn('custom-checkbox');

type Props = {
  className?: string;
  children?: React.ReactNode;
  id?: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: boolean) => void;
  inverted?: boolean;
  isError?: boolean;
  errorMessage?: string;
}
// eslint-disable-next-line react/display-name
const CustomCheckbox = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {
    className,
    children,
    id,
    name,
    checked,
    disabled,
    onBlur,
    onChange,
    inverted,
    isError,
    errorMessage,
  } = props;

  const showError = errorMessage && isError;

  return (
    <label
      className={b({ disabled, inverted, error: isError }, className)}
      htmlFor={id}
    >
      {showError && <span className={b('error')}>{errorMessage}</span>}
      <span className={b('wrapper')}>
        <input className='visually-hidden'
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onBlur={onBlur}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.checked)}
          ref={ref}
        />
        <span className={b('icon')} />
        {children && <span className={b('label')}>{children}</span>}
      </span>
    </ label>
  );
});

export default CustomCheckbox;
