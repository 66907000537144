import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import TopupForm from './TopupForm/TopupForm';

import type { TTopupMethodsItem } from 'types/payments-data';


import './TopupModal.scss';

type Props = {
  activeMethod: TTopupMethodsItem | null;
  isOpen: boolean;
  onClose: () => void;
};

const TopupModal = ({ activeMethod, onClose, isOpen }: Props) => {
  const { t } = useTranslation();

  const b = bemCn('topup-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose}>
      <h2 className={b('title')}>
        {t('topups.modal.title', 'Пополнить')}
      </h2>
      {activeMethod && (
        <TopupForm paymentMethod={activeMethod} />
      )}
    </AppModal>
  );
};

export default TopupModal;
